import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo, useState } from "react";
import { FormField } from "components/form/types";
import { ITalentProps } from "state/types";
import { Spinner } from "react-bootstrap";
import { useStripe } from "@stripe/react-stripe-js";
import { Resolver } from "react-hook-form";
import { billingDetailFormProps, billingDetailFormSchema } from "./constants";

type ReturnProps = {
  formId: string;
  fieldSections: Record<string, FormField[]>;
  submitBtn: {
    value?: string | JSX.Element;
    disabled: boolean;
  };
  onSubmit: (data: Record<string, string>) => void;
  resolver?: Resolver<Record<string, string>, Record<string, string>>;
  defaultValues?: Record<string, string>;
  children?: React.ReactNode;
  resetOnSuccess?: boolean;
};

export default function useMandateForm(
  client: ITalentProps["talentState"]["client"],
  showError: (unknown) => void,
  clientSecret: string,
  setShowConfirmation: (value: boolean) => void,
): ReturnProps {
  const defaultValues = {
    name: client?.fullName || "",
    email: client?.email || "",
    phone: client?.phone || "",
    country: client?.country || "",
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const buttonValue = useMemo(() => {
    if (isSubmitting) {
      return <Spinner animation="border" size="sm" />;
    }

    return billingDetailFormProps.submitBtn.value;
  }, [isSubmitting]);

  const stripe = useStripe();

  const onSubmit = useCallback(
    async (data) => {
      setIsSubmitting(true);
      let showConfirmation = false;
      try {
        if (!stripe) {
          showError({
            title: "Stripe",
            message: "Error occurred with stripe, unable to process request.",
          });
          return;
        }

        const { setupIntent, error } = await stripe.collectBankAccountForSetup({
          clientSecret,
          params: {
            payment_method_type: "us_bank_account",
            payment_method_data: {
              billing_details: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                address: {
                  country: data.country,
                  city: data.city,
                  state: data.state,
                  postal_code: data.postalCode,
                },
              },
            },
          },
          expand: ["payment_method"],
        });

        if (error) {
          console.error(error.message);
          // PaymentMethod collection failed for some reason.
          showError({
            title: "Error Occurred",
            message: error.message,
          });
        } else if (setupIntent.status === "requires_payment_method") {
          // Customer canceled the hosted verification modal. Present them with other
          // payment method type options.
          showError({
            title: "Action Cancelled",
            message: "You cancelled the process.",
          });
          setIsSubmitting(false);
        } else if (setupIntent.status === "requires_confirmation") {
          // We collected an account - possibly instantly verified, but possibly
          // manually-entered. Display payment method details and mandate text
          // to the customer.
          showConfirmation = true;
        } else if (setupIntent.status === "requires_action") {
          const { next_action: nextAction } = setupIntent;
          console.log({ nextAction });
        }
      } catch (error) {
        console.error({ error });

        showError({
          title: "Error",
          message: "Error setting up mandate",
        });
      } finally {
        setIsSubmitting(false);
        setShowConfirmation(showConfirmation);
      }
    },
    [clientSecret],
  );

  const formProps = useMemo(
    () =>
      ({
        ...billingDetailFormProps,
        fieldSections: billingDetailFormProps.fields,
        submitBtn: {
          ...billingDetailFormProps.submitBtn,
          disabled: isSubmitting,
          value: buttonValue,
        },
        onSubmit,
        resolver: yupResolver(billingDetailFormSchema),
        resetOnSuccess: true,
        defaultValues,
      } as ReturnProps),
    [onSubmit, defaultValues, isSubmitting, buttonValue],
  );

  return formProps;
}
