import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightAlt } from "assets/icons";
import { perksShowcase } from "utils";
import * as Styled from "./styles";

const Perks: React.FC = () => {
  return (
    <>
      <Styled.PerksWelcomeContainer>
        <Styled.PerksWelcomeCanvas>
          <Styled.PerksHeader>Perks are waiting for you!</Styled.PerksHeader>
          <Styled.PerksContent>
            We've partnered with more than 15+ companies across the globe to offer the best
            discounts and rewards to anyone using Remotebase.
          </Styled.PerksContent>
        </Styled.PerksWelcomeCanvas>
      </Styled.PerksWelcomeContainer>
      <Styled.MainContainer>
        <Styled.MainHeading>All Perks</Styled.MainHeading>
        <Styled.PerksContainer>
          {perksShowcase.map((perksShowcaseObj) => {
            return (
              <Styled.PerksCard>
                <Styled.PerkImg className={`${perksShowcaseObj?.class}`}>
                  <img src={`${perksShowcaseObj?.imageLink}`} alt="Img" />
                </Styled.PerkImg>
                <Styled.SubHeading>{perksShowcaseObj?.subHeading}</Styled.SubHeading>
                <Styled.PerkContent>{perksShowcaseObj?.content}</Styled.PerkContent>
                <Styled.PerkFooter>
                  <Styled.PerksButton>
                    <Link
                      to={{
                        pathname: `${perksShowcaseObj?.path}`,
                        state: { cheked: true },
                      }}
                    >
                      Get Perk
                      <ArrowRightAlt />
                    </Link>
                  </Styled.PerksButton>
                  <Styled.PerksLink target="_blank" href={`${perksShowcaseObj.visitLink}`}>
                    Visit link & Sign up
                  </Styled.PerksLink>
                </Styled.PerkFooter>
              </Styled.PerksCard>
            );
          })}
        </Styled.PerksContainer>
      </Styled.MainContainer>
    </>
  );
};

export default Perks;
