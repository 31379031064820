import React, { Fragment } from "react";
import TalentComponent from "./TalentComponent";

interface Props {
  interviewingOnly?: boolean;
}
const DashboardComponent: React.FC<Props> = ({ interviewingOnly }) => {
  return (
    <Fragment>
      <TalentComponent shouldCallApi interviewingOnly={interviewingOnly} />
    </Fragment>
  );
};

export default DashboardComponent;
