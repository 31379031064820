import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { SharedFormProps } from "components/form/types";
import { useSendEmail } from "hooks";
import { EmailTypes } from "@remotebase/amplify-constants/API";
import { ITalentProps } from "state/types";
import { initFormProps, supportSchema } from "./constants";
import { SupportEmail } from "../../../../utils/constants";

export default function useSupportForm(
  client: ITalentProps["talentState"]["client"],
  showError: (unknown) => void,
): SharedFormProps {
  const defaultValues = {
    fullName: client?.fullName || "",
    email: client?.email || "",
    companyName: client?.company?.name || "",
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const buttonValue = useMemo(() => {
    if (isSubmitting) return <Spinner animation="border" />;
    return initFormProps.submitBtn.value;
  }, [isSubmitting]);
  const { sendEmail } = useSendEmail();

  const onSubmit = useCallback(
    async (data) => {
      setIsSubmitting(true);
      try {
        await sendEmail({
          variables: {
            input: {
              toEmail: SupportEmail,
              emailType: EmailTypes.ClientContactSupport,
              messageData: JSON.stringify({
                companyName: data.companyName,
                clientName: data.fullName,
                clientEmail: data.email,
                clientMessage: data.message,
              }),
            },
          },
        });
        showError({
          title: "Success",
          message: "Message sent Successfully",
        });
      } catch (error) {
        console.error({ error });

        showError({
          title: "Error",
          message: "Error sending feedback",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [sendEmail],
  );

  const formProps = useMemo(
    () =>
      ({
        ...initFormProps,
        fields: initFormProps.fields.map((field) => ({
          ...field,
          readOnly: Boolean(field.name && defaultValues[field.name]),
        })),
        submitBtn: {
          ...initFormProps.submitBtn,
          disabled: isSubmitting,
          value: buttonValue,
        },
        onSubmit,
        resolver: yupResolver(supportSchema),
        resetOnSuccess: true,
        defaultValues,
      } as SharedFormProps),
    [onSubmit, defaultValues, isSubmitting, buttonValue],
  );

  return formProps;
}
