export enum RemoteBaseAnalyticsEvents {
  SUCCESSFUL_SIGNUP = "successful-signup",

  // Software Developer AI Engineer
  FINISH_SIGNUP_AIDEV = "finish-signup-aidev",
  // Software Developer Analytics Events
  FINISH_SIGNUP_DEV = "finish-signup-dev",
  // Java
  TAKE_DOMAIN_TEST_JAVA = "take-dt-java",
  PASS_DOMAIN_TEST_JAVA = "pass-dt-java",
  FAIL_DOMAIN_TEST_JAVA = "fail-dt-java",
  // React and Node
  TAKE_DOMAIN_TEST_REACT_NODE = "take-dt-react-node",
  PASS_DOMAIN_TEST_REACT_NODE = "pass-dt-react-node",
  FAIL_DOMAIN_TEST_REACT_NODE = "fail-dt-react-node",
  // Rails (Ror)
  TAKE_DOMAIN_TEST_RAILS = "take-dt-rails",
  PASS_DOMAIN_TEST_RAILS = "pass-dt-rails",
  FAIL_DOMAIN_TEST_RAILS = "fail-dt-rails",
  // Python
  TAKE_DOMAIN_TEST_PYTHON = "take-dt-python",
  PASS_DOMAIN_TEST_PYTHON = "pass-dt-python",
  FAIL_DOMAIN_TEST_PYTHON = "fail-dt-python",
  // App (Android and React Native)
  TAKE_DOMAIN_TEST_APP = "take-dt-app",
  PASS_DOMAIN_TEST_APP = "pass-dt-app",
  FAIL_DOMAIN_TEST_APP = "fail-dt-app",
  // For all other techs
  TAKE_DOMAIN_TEST_OTHER = "take-dt-other",
  PASS_DOMAIN_TEST_OTHER = "pass-dt-other",
  FAIL_DOMAIN_TEST_OTHER = "fail-dt-other",

  // Performance Marketer Analytics Events
  FINISH_SIGNUP_PM = "finish-signup-pm",
  TAKE_DOMAIN_TEST_PM = "take-dt-pm",
  PASS_DOMAIN_TEST_PM = "pass-dt-pm",
  FAIL_DOMAIN_TEST_PM = "fail-dt-pm",

  // Technical Recruiter Analytics Events
  FINISH_SIGNUP_TR = "finish-signup-tr",
  TAKE_DOMAIN_TEST_TR = "take-dt-tr",
  PASS_DOMAIN_TEST_TR = "pass-dt-tr",
  FAIL_DOMAIN_TEST_TR = "fail-dt-tr",

  // SDR Analytics Events
  FINISH_SIGNUP_SDR = "finish-signup-sdr",
  TAKE_DOMAIN_TEST_SDR = "take-dt-sdr",
  PASS_DOMAIN_TEST_SDR = "pass-dt-sdr",
  FAIL_DOMAIN_TEST_SDR = "fail-dt-sdr",

  // Designer Analytics Events
  FINISH_SIGNUP_DESIGN = "finish-signup-design",
  TAKE_DOMAIN_TEST_DESIGN = "take-dt-design",
  PASS_DOMAIN_TEST_DESIGN = "pass-dt-design",
  FAIL_DOMAIN_TEST_DESIGN = "fail-dt-design",

  // HR Interview Events
  PASS_HR = "pass-hr",
  SUBMIT_HR = "submit-hr",
  FAIL_HR = "fail-hr",
  START_HR = "start-hr",

  // HR Interview Events Talently
  PASS_HR_TALENTLY = "pass-hr-talently",
  SUBMIT_HR_TALENTLY = "submit-hr-talently",
  FAIL_HR_TALENTLY = "fail-hr-talently",
  START_HR_TALENTLY = "start-hr-talently",

  // Problem Solving Test Events
  TAKE_PS_TEST = "take-ps-test",
  PASS_PS_TEST = "pass-ps-test",
  FAIL_PS_TEST = "fail-ps-test",

  // Final Interview Events
  PASS_FINAL_INTERVIEW = "pass-final-interview",
  FAIL_FINAL_INTERVIEW = "fail-final-interview",

  NETWORK = "Network",
  HIRED = "Hired",

  TALENT_PROFILE_UPDATE = "talent-profile-update",
  TALENT_PROFILE_UPDATE_SALARY = "talent-profile-update-salary",
  TALENT_PROFILE_UPDATE_RESUME = "talent-profile-update-resume",
  TALENT_JOB_APPLIED = "talent-job-applied",
  TALENT_PROFILE_UPDATE_FAILED = "talent-profile-update-failed",
  TALENT_PROFILE_UPDATE_SALARY_FAILED = "talent-profile-update-salary-failed",
  TALENT_PROFILE_UPDATE_RESUME_FAILED = "talent-profile-update-resume-failed",
  TALENT_JOB_APPLIED_FAILED = "talent-job-applied-failed",

  REFERRAL_INVITED_EMAILS = "referral-invited-emails",
  REFERRAL_COPIED_URL = "referral-copied-urls",

  RECRUITER_COMPLETED_PROFILE = "recruiter-profile-completed",
  RECRUITER_REFERRAL_INVITED_EMAILS = "recruiter-referral-invited-emails",
  RECRUITER_REFERRAL_COPIED_URL = "recruiter-referral-copied-urls",

  REDIRECTED_FROM = "heard-from",
  REFERRAL_JOINED = "referral-joined",
  PLACED = "Placed",

  // Talently Analytics Events
  SIGN_UP_VIEW = "sign-up-view",
  SIGN_UP_CLICK = "sign-up-click",
  SIGN_UP_ERROR_CLICK = "sign-up-error-click",

  SIGN_IN_VIEW = "sign-in-view",
  SIGN_IN_CLICK = "sign-in-click",
  SIGN_IN_ERROR = "sign-in-error",

  SIGN_UP_VERIFY_SUCCESS = "sign-up-verify-success",
  SIGN_UP_VERIFY_ERROR = "sign-up-verify-error",

  FORGOT_PASSWORD_CLICK = "forgot-password-click",
  RESET_PASSWORD_CLICK = "reset-password-click",

  EXPLORE_VIEW_PAGE = "explore-view-page",
  PROFILE_CARD_CLICK = "profile-card-click",
  PROFILE_VIDEO_CLICK = "profile-video-click",
  SEARCH_RESULTS = "search-results",

  EXPLORE_CLICK = "explore-click",
  FAVOURITES_CLICK = "favorite-click",
  ARCHIVE_CLICK = "archive-click",
  FAVORITE_PROFILE_CLICK = "favorite-profile-click",
  UNFAVORITE_PROFILE_CLICK = "unfavorite-profile-click",
  UNFAVORITE_PROFILE_CLICK_ERROR = "unfavorite-profile-click-error",
  ARCHIVE_PROFILE_CLICK = "archive-profile-click",
  UNARCHIVE_PROFILE_CLICK = "unarchive-profile-click",
  UNARCHIVE_PROFILE_CLICK_ERROR = "unarchive-profile-click-error",

  CHAT_CLICK = "chat-click",
  SETTINGS_CLICK = "settings-click",

  PROFILE_DETAIL_VIEW = "profile-detail-view",
  PROFILE_DETAIL_VIDEO_CLICK = "profile-detail-video-click",
  PROFILE_CONTACT_DETAIL_CLICK = "profile-contact-detail-click",
  PROFILE_CONTACT_DETAIL_VIEW = "profile-contact-detail-view",
  PROFILE_CHAT_CLICK = "profile-chat-click",
  CREDIT_USE_VIEW = "credit-use-view",
  CREDIT_USE_CLICK = "credit-use-click",
  PROFILE_UPDATE_SUCCESS = "profile-update-success",
  PROFILE_UPDATE_ERROR = "profile-update-error",
  COMPANY_UPDATE_SUCCESS = "company-update-success",
  COMPANY_UPDATE_ERROR = "company-update-error",

  SETTINGS_VIEW = "settings-view",
  SETTINGS_MENU_CLICK = "settings-menu-click",
  YOUR_ACCOUNT_VIEW = "your-account-view",
  YOUR_ACCOUNT_CLICK = "your-account-click",
  SUBSCRIPTION_VIEW = "subscription-view",
  SUBSCRIPTION_CLICK = "subscription-click",
  CANCEL_SUBSCRIPTION_SUCCESS = "cancel-subscription-success",
  CANCEL_SUBSCRIPTION_ERROR = "cancel-subscription-error",

  PASSWORD_CHANGE_VIEW = "password-change-view",
  PASSWORD_CHANGE_SUCCESS = "password-change-success",
  PASSWORD_CHANGE_ERROR = "password-change-error",

  ABOUT_US_VIEW = "about-us-view",
  TERMS_CONDITIONS_VIEW = "terms-conditions-view",
  RESUME_PARSE_SUCCESS = "resume-parse-success",
  RESUME_PARSE_FAIL = "resume-parse-fail",
}

export type AnalyticsMetricsFrontEnd = {
  title?: string;
  rawPath?: string;
  path?: string;
  company?: string | undefined;
  screenWidth?: number;
  screenHeight?: number;
  windowWidth?: number;
  windowHeight?: number;
  scrollX?: number;
  scrollY?: number;
  host?: string;
  clientType?: unknown;
  clientName?: string;
  loggedIn?: boolean;
  userAgent?: string;
  userSource?: string;
  internalUser?: boolean;
  incubatorSource?: string;
  discountCode?: string;
  pageName?: string;
  sectionName?: string;
  elementName?: string;
  metaData?: string;
};

export type TrackFrontEnd = {
  (name: RemoteBaseAnalyticsEvents, metrics?: AnalyticsMetricsFrontEnd | unknown): void;
  (
    name: `${string}-${string}-${string}-${string}`,
    metrics?: {
      actionType: string;
      elementName: string;
    },
  ): void;
};

export enum FBPixelCustomEvents {
  DEVELOPER_SIGNUP = "developer-signup",
  DEVELOPER_SIGNIN = "developer-signin",
  DEVELOPER_TECH_CLICK = "developer-tech-signup",
}

export enum FBPixeEventTypes {
  TRACK_CUSTOM = "trackCustom",
}

export type AnalyticsRegisterEvent = (
  eventName: RemoteBaseAnalyticsEvents,
  properties?: AnalyticsMetricsFrontEnd,
) => void;
