import { SidebarProps, SidebarType } from "utils";
import { useCallback, useEffect, useState } from "react";
import { LAPTOP_VIEW } from "./sidebar.helper";

export default function useSidebar(): SidebarProps {
  const [sidebarType, setSidebarType] = useState(SidebarType.Login);
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(false);
  const toggleSidebar = useCallback(() => setSidebarIsCollapsed((prev) => !prev), []);

  const handleResize = useCallback(
    () => setSidebarIsCollapsed(window.innerWidth < LAPTOP_VIEW),
    [],
  );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);

    return (): void => window.removeEventListener("resize", handleResize, false);
  }, []);

  return {
    sidebarType,
    setSidebarType,
    sidebarIsCollapsed,
    toggleSidebar,
  };
}
