import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ModelSortDirection } from "@remotebase/amplify-constants/API";
import { ITalentProps } from "state/types";
import { withTalent } from "state/talent";
import { BillingFeatureToggleContext } from "state/billingFeatureToggle/billingFeatureToggle.provider";
import { FetchClientSecretForBillingDetails } from "./components";
import { useGetSetupIntent } from "./hooks";
import ErrorComponent from "./components/Error";
import BillingPage from "./helper";

const Billing: FC<ITalentProps> = ({ talentState: { client } }) => {
  const { getSetupIntentInDb, data: setupIntent, error } = useGetSetupIntent();
  const [isLoading, setIsLoading] = useState(true);
  const { billingEnabled } = useContext(BillingFeatureToggleContext);

  useEffect(() => {
    if (client) {
      getSetupIntentInDb({
        variables: { clientId: client?.id, sortDirection: ModelSortDirection.DESC, limit: 1 },
      }).then(() => setIsLoading(false));
    }
  }, [client]);

  const billingPage = useMemo(() => {
    if (setupIntent) {
      return <BillingPage setupIntent={setupIntent} />;
    }
    if (!isLoading && billingEnabled) {
      // request has been made and no setupIntent was found, create new setupIntent
      return <FetchClientSecretForBillingDetails />;
    }
    return null;
  }, [isLoading, setupIntent]);

  if (error) {
    return <ErrorComponent />;
  }

  if (isLoading || !billingPage || !client) {
    return <Spinner animation="border" />;
  }

  return billingPage;
};

export default withTalent(Billing);
