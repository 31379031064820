/* eslint-disable max-len */

import { matchStage } from "@remotebase/amplify-constants/API";

export const trimSize = 300;

export const limit = 1000;

enum JobPerks {
  Remote = "Remote",
  OfficeOptional = "OfficeOptional",
  FlexibleHours = "FlexibleHours",
  MedicalInsurance = "MedicalInsurance",
  CompanyLaptop = "CompanyLaptop",
  PaidLeaves = "PaidLeaves",
  GymAllowance = "GymAllowance",
  MarketCompetitiveSalaries = "MarketCompetitiveSalaries",
  LearningPlatforms = "LearningPlatforms",
  AnnualRetreat = "AnnualRetreat",
}

export const JOB_PERKS_DESCRIPTION = {
  [JobPerks.Remote]: "Fully remote",
  [JobPerks.OfficeOptional]: "Office optional",
  [JobPerks.FlexibleHours]: "Flexible timings. You decide your work schedule",
  [JobPerks.MedicalInsurance]: "Medical Insurance",
  [JobPerks.CompanyLaptop]: "Company laptop",
  [JobPerks.PaidLeaves]: "Paid leaves",
  [JobPerks.GymAllowance]: "Gym allowance",
  [JobPerks.MarketCompetitiveSalaries]: "Market competitive salaries",
  [JobPerks.LearningPlatforms]: "Insane learning and growth",
  [JobPerks.AnnualRetreat]: "Annual company retreat",
};

export enum EmploymentType {
  PERMANENT = "Full-time",
  CONTRACTUAL = "Contract",
}
export const pageSize = 5;

export interface CreateMatchJobProps {
  talentId?: string;
  jobId?: string;
  stage: matchStage;
}

export enum SkillsNameMap {
  Graphql = "GraphQL",
  React = "React.js",
  Angular = "Angular",
  Vue = "Vue.js",
  NodeJs = "Node.js",
  PHP = "PHP",
  Oracle = "Oracle",
  DotNet = ".Net",
  Wordpress = "WordPress",
  Ror = "RoR",
  Python = "Python",
  Django = "Django",
  Javascript = "JavaScript",
  Typescript = "TypeScript",
  Csharp = "C#",
  CPlusPlus = "C++",
  Mysql = "MySQL",
  Postgres = "PostgreSQL",
  d3 = "D3.js",
  Aws = "AWS",
  MongoDb = "MongoDB",
  Docker = "docker",
  DevOps = "DevOps",
  Drupal = "Drupal",
  Golang = "GO",
  Redux = "Redux",
  Express = "Express.js",
  ReactAndNode = "React.js + Node.js",
  ReactNative = "React Native",
  Java = "Java",
  Magento = "Magento",
  Amplify = "Amplify",
  Android = "Android",
  Flutter = "Flutter",
  IOS = "IOS",
  DataScience = "Data Science",
  TECHNICAL_RECRUITER = "Technical Recriuter",
  SDR = "SDR",
  PERFORMANCE_MARKETING_SPECIALIST = "Performance Marketing Specialist",
  DESIGNER = "Designer",
}
