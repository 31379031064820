import React from "react";
import { imageUrls } from "../utils";
import * as S from "./styles";

interface IMaintenance {
  isTalently?: boolean;
}

export const Maintenance: React.FC<IMaintenance> = ({ isTalently }) => {
  const imageSource = isTalently ? imageUrls.talentlyLogoImg : imageUrls.newRBLogo;
  return (
    <S.MaintenanceWrapper>
      <S.MaintenanceHeaderWrapper>
        <S.MaintenanceHeaderContainer>
          <S.HeaderLogoBox>
            <S.HeaderLogoImg src={imageSource} alt="logo" />
          </S.HeaderLogoBox>
        </S.MaintenanceHeaderContainer>
      </S.MaintenanceHeaderWrapper>
      <S.MaintenanceBody>
        <S.MaintenanceTitle>This Site is Currently Under Maintenance</S.MaintenanceTitle>
        <S.MaintenancePara>
          We've got something new for you, please check back soon.
        </S.MaintenancePara>
        <S.MaintenanceMainImgWrapper>
          <img src={imageUrls.maintenance} />
        </S.MaintenanceMainImgWrapper>
      </S.MaintenanceBody>
      <S.MaintenanceFloatingImgWrapper>
        <S.MaintenanceFloatingImg>
          <img src={imageUrls.bgMaintenance} />
        </S.MaintenanceFloatingImg>
        <S.MaintenanceFloatingImg>
          <img src={imageUrls.bgMaintenance} />
        </S.MaintenanceFloatingImg>
        <S.MaintenanceFloatingImg>
          <img src={imageUrls.bgMaintenance} />
        </S.MaintenanceFloatingImg>
      </S.MaintenanceFloatingImgWrapper>
    </S.MaintenanceWrapper>
  );
};
export default Maintenance;
