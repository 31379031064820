import styled from "styled-components";

export const ResendLinkAlign = styled.div`
  text-align: right;
  margin-bottom: 0.3rem;
`;

export const ResendLink = styled.p`
  font-size: 16px;
  color: #5138ed;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #2b15ba;
    text-decoration: underline;
  }
`;

export const ResendLinkDisabled = styled.p`
  font-size: 16px;
  color: gray;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: inline-flex;
  text-decoration: none;
  cursor: not-allowed;
`;

export default ResendLink;
