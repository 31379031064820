import { getIcon } from "hooks/utils";
import React from "react";
import { Modal } from "react-bootstrap";
import { ICONS } from "utils";
import * as Styled from "./styles";

interface Props {
  heading?: string;
  body: string;
  onCancel?: () => void;
}

export const UpdateScheduleModal: React.FC<Props> = (props) => {
  const { heading, body, onCancel } = props;
  return (
    <Styled.TestModal
      show
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Body>
        <Styled.CrossIconBox onClick={onCancel}>{getIcon(ICONS.CLOSE)}</Styled.CrossIconBox>
        <Styled.H1>{heading}</Styled.H1>
        <Styled.ModalPara dangerouslySetInnerHTML={{ __html: body }} />
        <Styled.ModalBtn onClick={onCancel}>OK</Styled.ModalBtn>
      </Modal.Body>
    </Styled.TestModal>
  );
};

export default UpdateScheduleModal;
