import "assets/css/icon.css";
import { AvailabilityComponent } from "components";
import React from "react";
import { TalentProvider } from "state/talent";
import * as Styled from "./styles";

const AvailabilityPage: React.FC = () => {
  return (
    <Styled.AvailabilityWrapper>
      <Styled.AvailabilityContainer>
        <TalentProvider>
          <AvailabilityComponent />
        </TalentProvider>
      </Styled.AvailabilityContainer>
    </Styled.AvailabilityWrapper>
  );
};

export default AvailabilityPage;
