import styled from "styled-components";

export const TitleLabel = styled.div`
  font-size: 16px;
  font-family: inter700;
  font-weight: bold;
  margin-bottom: 0;
`;
export const Bullet = styled.div`
  background: black;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
`;
export const RoleLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: inter400;
  margin-bottom: 0;
`;

export const EmploymentHistoryTitle = styled.div`
  position: relative;
  width: 100%;
  height: 29px;
`;

export const EmploymentHistoryTopSection = styled.div`
  width: 100%;
  height: 83px;

  background: #ffffff;
  border-radius: 10px 10px 0 0;

  padding: 27px 48px;

  z-index: 70;
`;

export const ProfileDownloadBottomSection = styled.div`
  width: 100%;
  height: 192px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0 0 10px 10px;

  padding: 42px 48px;

  z-index: 70;
`;

export const ProfileGridWrapper = styled.div`
  width: 100%;
`;

export const JobRoleTitle = styled.div`
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
`;

export const JobDuration = styled.div`
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
`;

export const DurationText = styled.div`
  width: 100%;
  height: 17px;
  margin-top: 0px;
  padding-top: 0px;

  font-family: "inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Additional Black */

  color: #212121;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ProfileEmploymentHistory = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 10px 10px 10px 10px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  //height: 277px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  z-index: 50;
`;
/*
export const RightFrame = styled.div`
  /* Frame 7658

  /* Auto layout

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 752px;
  height: 130px;

  /* Inside auto layout

  flex: none;
  order: 1;
  flex-grow: 1;
`;
*/
export const CompanyImageCover = styled.div`
  width: 48px;
  height: 48px;
  margin-left: 48px;
  margin-top: 42px;
`;

export const CompanyImage = styled.img`
  border-radius: 0%;
  width: 100%;
  height: 100%;
`;

export const ProfileEmploymentHistoryLeft = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  width: 120px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ProfileFeedbackDetailsRight = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 42px 48px 42px 0px;
  gap: 0px;

  width: 100%;
`;

export const ProfileEmploymentHistoryContainer = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;

  width: 100%;

  background: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  border-top: 1px solid #e0e0e0;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const EmploymentDetails = styled.div`
  /* My typical projects are online stores in woocommerce,
  develop sites on wp, front-end build and
  solve different problems with WP, Woo,
  and other plugins. So my work consist
  in coding from PSD to HTML, in integration in cms,
  in writing of modules, in the initial technical
  support and training how to use the site, fix crushed sites. The... */

  width: 100%;
  //height: 66px;
  margin-top: 16px;
  font-family: "inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  /* Additional - Dark Grey */

  color: #7c7c7c;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const SectionHeading = styled.p`
  margin-bottom: 0;
  font-family: "poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;

  /* identical to box height, or 29px */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;

  z-index: 100;
`;

export const WrapAddNew = styled.div`
  position: absolute;
  right: -8px;
  top: 0;
`;

export const AddNew = styled.p`
  color: #3c65fe;
  margin-bottom: 0;
  cursor: pointer;
`;

export const Span = styled.span`
  margin-bottom: 0;
`;

export const TitleWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
  width: 100%;
`;

export const IconGroup = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.5rem;

  & svg {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
`;
