import styled from "styled-components";

export const HeroStyled = styled.div`
  width: 100%;
  padding: 36px 0 42px;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1312px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
`;

export const HeroContent = styled.div`
  width: 100%;
  max-width: 957px;
  margin: 0 auto;
  text-align: center;
`;

export const HeroTitle = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  margin: 0 auto 6px;
  font-family: "Platform", sans-serif;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 67px;
  }
`;

export const HeroPara = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  margin: 0 auto;
  max-width: 864px;
  font-family: "Avenir", sans-serif;
`;
