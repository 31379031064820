import styled from "styled-components";
import { Button } from "react-bootstrap";
import { StyledProps } from "../../../utils";

export const GenericBtn = styled(Button)<{
  $width: string;
  $color?: string;
  $backgroundColor?: string;
  $borderRadius?: string;
  $border?: string;
  $hoverColor?: string;
  $hoverBackground?: string;
  $cursor: string;
  disabled?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: ${(props): StyledProps => props.$width ?? "100%"};
  height: 3rem;
  border-width: 0;
  background-color: ${(props): StyledProps => props.$backgroundColor ?? "#3c65fe"};
  border: ${(props): StyledProps => props.$border ?? "none"};
  color: ${(props): StyledProps => props.$color ?? "#ffffff"};
  font-size: 0.875rem;
  border-radius: ${(props): StyledProps => props.$borderRadius ?? "0.5rem"};
  font-weight: 500;
  letter-spacing: -0.025em;
  text-decoration: none;
  cursor: ${(props): StyledProps => props.$cursor};
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    min-height: 50px;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: ${(props): StyledProps => props.$hoverBackground ?? "#3c65fe"};
    border: ${(props): StyledProps =>
      props.$hoverColor ? `1px solid ${props.$hoverColor}` : "none"};
    box-shadow: none;
    color: ${(props): StyledProps => props.$hoverColor ?? "#ffffff"};
  }
`;

export const raw = "aa";
