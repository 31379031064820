import { getIcon } from "hooks/utils";
import React, { useState } from "react";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { isEmptyString, TextDraft } from "@remotebase/components";
import withTalentMatchCompany from "state/talent/withTalentMatchCompanyHoc";
import withError from "state/error/withErrorHoc";
import { WithTalentMatchCompanyProps } from "hooks/types";
import { ErrorProps, feedbackStages } from "@remotebase/constants";
import { ICONS } from "utils";
import * as Styled from "./styles";

interface Props {
  onCancel: () => void;
}

export const ChangeStatusModal: React.FC<WithTalentMatchCompanyProps & ErrorProps & Props> = ({
  onCancel,
  changeStage,
  showError,
}) => {
  const [dropdownSelect, setDropdownSelect] = useState<string>("");
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [shouldShow, setShouldShow] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const onSubmit = (): void => {
    if (isEmptyString(feedbackText) || isEmptyString(dropdownSelect)) {
      showError({ title: "Error", message: "No feedback provided" });
      onCancel();
    } else {
      showError({ title: "Success", message: "Feedback Submitted" });
      changeStage(dropdownSelect, feedbackText);
      onCancel();
    }
  };
  const setTheFeedbackValue = (e): void => {
    if (e === "<p></p>" && count !== 0) {
      setShouldShow(true);
    } else {
      setCount((prevcount) => prevcount + 1);
      setFeedbackText(e);
      setShouldShow(false);
    }
  };

  return (
    <Styled.TestModal show onHide={onCancel} centered animation={false}>
      <Modal.Body>
        <Styled.CrossIconBox onClick={onCancel}>{getIcon(ICONS.CLOSE)}</Styled.CrossIconBox>
        <Styled.H1>Client Feedback</Styled.H1>
        <Styled.H2>Select Status</Styled.H2>
        <Styled.SortDropdown>
          <Dropdown>
            <DropdownButton
              title={dropdownSelect ? feedbackStages[dropdownSelect].name : "Change Status"}
              onSelect={(e): void => {
                setDropdownSelect(e || "Change Status");
              }}
            >
              {Object.entries(feedbackStages).map((item, index) => (
                <Dropdown.Item key={index} eventKey={item[0]}>
                  {item[1].name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Dropdown>
        </Styled.SortDropdown>
        {dropdownSelect === "changeStatus" && (
          <Styled.ErrorDiv>Please select a value</Styled.ErrorDiv>
        )}
        <Styled.H2>Feedback</Styled.H2>
        <TextDraft initialValue={" "} setValue={setTheFeedbackValue} />
        {shouldShow === true ? (
          <>
            <Styled.ErrorDiv>Please enter feedback text</Styled.ErrorDiv>
          </>
        ) : (
          <></>
        )}
        <Styled.ModalBtn
          disabled={
            feedbackText === "<p></p>" ||
            dropdownSelect === "changeStatus" ||
            dropdownSelect === "" ||
            shouldShow
          }
          onClick={onSubmit}
        >
          Submit Feedback
        </Styled.ModalBtn>
      </Modal.Body>
    </Styled.TestModal>
  );
};

export default withTalentMatchCompany(withError(ChangeStatusModal));
