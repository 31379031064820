import { TechDomainSkills } from "@remotebase/amplify-constants/API";
import { FC } from "react";
import { ShouldRender } from "../..";
import * as Styled from "./styles";

interface Props {
  expertise: TechDomainSkills[] | null | undefined;
}

export const ProfileExpertise: FC<Props> = ({ expertise }) => {
  return (
    <ShouldRender if={expertise && expertise.length > 0}>
      <Styled.ProfileExpertiseContainer>
        <Styled.ProfileExpertiseTitle>Coding Expertise</Styled.ProfileExpertiseTitle>
        <Styled.ProfileExpertiseTagsContainer>
          {(expertise || []).map((skill: string) => (
            <Styled.ProfileExpertiseTags key={skill}>{skill}</Styled.ProfileExpertiseTags>
          ))}
        </Styled.ProfileExpertiseTagsContainer>
      </Styled.ProfileExpertiseContainer>
    </ShouldRender>
  );
};

export default ProfileExpertise;
