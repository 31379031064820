import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import cls from "classnames";
import { defaultRoute, Sidebar } from "utils";
import { getSidebarIcon, sidebarRoutes } from "state/sidebar/sidebar.helper";
import * as Styled from "layouts/sidebar_layout_copy/styles";

type Props = {
  element: Sidebar;
};

export default function SidebarNavItem({ element }: Props): ReturnType<FC> {
  const { pathname } = useLocation();
  const isActive = useMemo(() => pathname === sidebarRoutes[element], [element, pathname]);
  const icon = useMemo(() => getSidebarIcon(element), [element]);

  return (
    <Styled.SidebarNavItem
      to={sidebarRoutes[element] || defaultRoute}
      className={cls({ active: isActive })}
      data-testid={element}
    >
      <span>{icon}</span>
      <span className="nav-item-text">{element}</span>
    </Styled.SidebarNavItem>
  );
}
