import { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProps } from "@remotebase/constants";
import { useForm } from "react-hook-form";
import { AuthenticationInputClient } from "../../formInput";
import {
  AuthForm,
  emailPlaceholder,
  FormSchema,
  passwordPlaceholder,
  UnAuthRoutes as Route,
} from "../../utils";
import { ShouldRender, AuthSubmitBtnClient } from "../..";
import * as Styled from "./styles";

export const LoginRegisterFormClient: FC<FormProps> = (props) => {
  const { performAction, isLoading, buttonText, showForgotPassword } = props;
  const formHook = useForm({ resolver: yupResolver(FormSchema), mode: "onChange" });

  return (
    <form onSubmit={formHook.handleSubmit(performAction)}>
      <Styled.FormGroups>
        <AuthenticationInputClient
          type="email"
          label="Email"
          placeholder={emailPlaceholder}
          title={AuthForm.email}
          handler={formHook}
        />
        <AuthenticationInputClient
          type="password"
          label="Password"
          placeholder={passwordPlaceholder}
          title={AuthForm.password}
          handler={formHook}
        />
      </Styled.FormGroups>
      <ShouldRender if={showForgotPassword}>
        <Styled.ForgotPassAlign>
          <Styled.ForgotPassword to={Route.ForgotPassword}>Forgot password?</Styled.ForgotPassword>
        </Styled.ForgotPassAlign>
      </ShouldRender>
      <AuthSubmitBtnClient isLoading={isLoading} buttonText={buttonText} />
    </form>
  );
};

export default LoginRegisterFormClient;
