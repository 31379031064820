import React, { Fragment } from "react";
import { StartCheckIcon } from "../../assets/icons";
import * as S from "./styles";
import TalentFeedbackCard from "./TalentFeedbackCard";

interface Props {
  name?: string | null;
  imgLink?: string | null;
  expectedSalary?: string;
  skillScore?: number;
  isSelected?: boolean;
  closeFeedbackPopup: () => void;
}

export const FeedbackComplete: React.FC<Props> = ({
  closeFeedbackPopup,
  name,
  imgLink,
  expectedSalary,
  skillScore,
  isSelected,
}) => {
  return (
    <Fragment>
      <S.MainContent>
        <S.MainContainer>
          <S.FeedbackCompleteMainHeader>
            <StartCheckIcon />
            <S.Title>All Done, Feedback Complete</S.Title>
            <S.SubTitle>Your feedback has been submitted to {name}</S.SubTitle>
          </S.FeedbackCompleteMainHeader>
          <TalentFeedbackCard
            name={name}
            imgLink={imgLink}
            expectedSalary={expectedSalary}
            skillScore={skillScore}
            isSelected={isSelected}
          />
          <S.SubmitButton onClick={(): void => closeFeedbackPopup()}>
            Back to Homepage
          </S.SubmitButton>
        </S.MainContainer>
      </S.MainContent>
    </Fragment>
  );
};

export default FeedbackComplete;
