import { ErrorProps } from "@remotebase/constants";
import { useUpdateTalentMatchCompany } from "hooks";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import withError from "state/error/withErrorHoc";
import { feedbackQuestion } from "utils";
import { BtnLoader } from "@remotebase/components/loader";
import { ShouldRender, imageUrls } from "@remotebase/components";
import * as S from "./styles";

interface Props {
  name?: string | null;
  role?: string | null;
  imgLink?: string | null;
  jobMatchId?: string | null;
  version?: number | null;
  isRejected?: boolean;
  setIsRejected: Dispatch<SetStateAction<boolean | undefined>>;
  handleIsSubmitted: Dispatch<SetStateAction<boolean>>;
}

export const FeedbackForm: React.FC<Props & ErrorProps> = ({
  name,
  role,
  imgLink,
  jobMatchId,
  isRejected,
  setIsRejected,
  showError,
  handleIsSubmitted,
}) => {
  const { updateTalentCompanyMatch, data, error } = useUpdateTalentMatchCompany();
  const [loading, setloading] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");

  const onSubmit = (): void => {
    setloading(true);
    updateTalentCompanyMatch({
      variables: {
        payload: {
          jobMatchID: jobMatchId,
          feedback: {
            otherFeedback: feedbackComment,
            isRejected,
          },
        },
      },
    });
  };
  useEffect(() => {
    setloading(false);
    if (data) {
      showError({
        title: "Success",
        message: "Feedback Submitted Successfully",
      });
      handleIsSubmitted(true);
    } else if (error) {
      showError({
        title: "Failure",
        message: error.message,
      });
    }
  }, [data, error]);

  return (
    <Fragment>
      <S.MainContent>
        <S.MainContainer>
          <S.MainHeader>
            <S.Title>Give Feedback</S.Title>
            <S.AvatarCanvas>
              <S.Avatar src={imgLink ?? imageUrls.userDefault} />
              <S.AvatarDetails>
                <S.AvatarName>{name}</S.AvatarName>
                <S.AvataRole>{role}</S.AvataRole>
              </S.AvatarDetails>
            </S.AvatarCanvas>
          </S.MainHeader>
          <S.SubTitle>{feedbackQuestion}</S.SubTitle>
          <S.FeedbackCommentCanvas>
            <S.AcceptButton
              selected={isRejected === false}
              onClick={(): void => {
                setIsRejected(false);
              }}
            >
              Accept Talent
            </S.AcceptButton>
            <S.RejectButton
              selected={isRejected}
              onClick={(): void => {
                setIsRejected(true);
              }}
            >
              Reject Talent
            </S.RejectButton>
          </S.FeedbackCommentCanvas>
          <S.FeedbackTextarea
            value={feedbackComment}
            onChange={(e): void => setFeedbackComment(e.target.value)}
          />
          <S.SubmitButton disabled={isRejected === undefined} onClick={onSubmit}>
            <ShouldRender if={!loading}>
              <Fragment>Submit Feedback</Fragment>
            </ShouldRender>
            <ShouldRender if={loading}>
              <BtnLoader />
            </ShouldRender>
          </S.SubmitButton>
        </S.MainContainer>
      </S.MainContent>
    </Fragment>
  );
};

export default withError(FeedbackForm);
