import { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PayloadType } from "@remotebase/constants";
import { useForm } from "react-hook-form";
import { AuthenticationInputClient } from "../../formInput";
import { AuthForm, passwordPlaceholder, SetPasswordSchema, setPasswordText } from "../../utils";
import { AuthSubmitBtnClient } from "../..";
import * as Styled from "./styles";

interface Props {
  isLoading: boolean;
  setPassword: (payload: PayloadType) => void;
  oldPassword: string;
}

export const SetPasswordFormClient: FC<Props> = ({ setPassword, isLoading, oldPassword }) => {
  const handler = useForm({
    resolver: yupResolver(SetPasswordSchema(oldPassword)),
    mode: "onChange",
  });
  const {
    formState: { isValid },
  } = handler;

  return (
    <form onSubmit={handler.handleSubmit(setPassword)}>
      <Styled.FormGroups>
        <AuthenticationInputClient
          type="password"
          label="Password"
          placeholder={passwordPlaceholder}
          title={AuthForm.password}
          handler={handler}
        />
      </Styled.FormGroups>
      <AuthSubmitBtnClient
        isLoading={isLoading}
        buttonText={setPasswordText}
        passwordIsValid={isValid}
      />
    </form>
  );
};

export default SetPasswordFormClient;
