import { SidebarProps } from "utils";
import React, { useContext } from "react";
import { SidebarContext } from "./sidebar.context";

export function withSidebar<T>(Component: React.FC<T & SidebarProps>): React.FC<T> {
  return (props: T): JSX.Element => {
    const contextValue = useContext<SidebarProps>(SidebarContext);

    return <Component {...props} {...contextValue} />;
  };
}
export default withSidebar;
