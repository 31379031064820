/* eslint-disable prettier/prettier */
import styled from "styled-components";

export const MiddleContainer = styled.div`
  margin: 0 auto;
`;

export const RetrySpan = styled.span`
  color: blue;
  cursor: pointer;
`;

export const Container = styled.div`
  height: 40rem !important;
`;

export const LoaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const InfoTextContainer = styled.div`
  padding: 1rem;
`;

export const ProcessInfoPara = styled.p`
  font-size: 1.3em;
`;

export const HeaderInfoPara = styled.div`
  color: #4d526d;
`;

export const SectionHeaderSection = styled.section``;

export const SectionHeader = styled.h5``;

export const WithdrawMandateContainer = styled.section`
  background-color: #3617ba;
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  margin-top: 2rem;
  @media (max-width: 850px) {
    padding: 1rem 0.4rem;
  }
`;

export const SomeMandateInfoPara = styled.p`
  color: white;
  font-size: 1.5em;
  margin: 0 1rem;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const WithdrawMandateBtn = styled.button`
  padding: 1rem;
  height: 4rem;
  border-radius: 1rem;
  border: none;
  min-width: 13rem;
`;

export const MandateInfoCardIconDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d526d;
`;
export const MandateInfoCardDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: center;
`;
export const MandateInfoCardDetailLabelPara = styled.p`
  color: #4d526d;
`;
export const MandateInfoCardDetailLabelValuePara = styled.p`
  font-weight: 500;
  color: #1a0224;
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const InfoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 2;
  padding: 0;
  justify-content: space-between;
  @media (max-width: 850px) {
    flex-wrap: none;
    flex-direction: column;
  }
`;

export const InfoItem = styled.li`
  list-style-type: none;
  flex-basis: 45%;
  margin-bottom: 1rem;
  height: 8rem;
`;

export const ProcessingIconContainer = styled.div`
  background: url("./ProcessingIcon.gif");
  width: 5rem;
  height: 5rem;
  object: fit;
`;

export const AchTermsAndConditionParaContainer = styled.div``;

export const AchTermsAndConditionPara = styled.p`
  text-align: justify;
  font-size: 1.2em;
`;

export const AgreeCheckBox = styled.input`
  height: 1.5rem;
  width: 1.2rem;
  vertical-align: middle;
  position: relative;
  bottom: 1.8px;
`;
export const AgreeCheckBoxLabel = styled.label`
  cursor: pointer;
  margin-left: 1.1rem;
  display: block;
`;

export const BillingDetailsFormRowContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
`;
export const BillingDetailsFormColumn = styled.div`
  width: 100%;
`;

export const BillingDetailsFormInputFieldRow = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
`;

export const BillingDetailsProceedBtn = styled.button`
  background-color: #3617ba;
  padding: 0.8rem 0;
  color: white;
  &:hover {
    color: white;
  }
`;

export const AgreeCheckboxContainer = styled.div`
  display: flex;
  height: 2rem;
  width: 60%;
  font-size: 1.2em;
  font-weight: bold;
  align-items: center;
`;

export const LableTextSpan = styled.span`
  margin-left: 0.4rem;
`;

export const AcceptTermsAndConditionBtn = styled.button`
  background-color: #3617ba;
  padding: 0.8rem 0;
`;
