export enum ProfileDetail {
  Expertise = "Expertise",
  EnglishLevel = "English Level",
  YearOfExperience = "Year Experience",
  Email = "Email",
  Phone = "Phone",
}

export enum ProfileFormActions {
  UpdatePhone = "phone",
  UpdateEnglishProficiency = "englishProficiency",
  UpdateYearsOfExperience = "experience",
  UpdateRole = "role",
  UpdateAvailability = "availability",
  UpdateFirstName = "firstName",
  UpdateLastName = "lastName",
  UpdateTalentTitle = "title",
  UpdateTalentBio = "bio",
  ResetForm = "ResetForm",
}

export const DefaultDetailsToShow: ProfileDetail[] = [
  ProfileDetail.Expertise,
  ProfileDetail.EnglishLevel,
  ProfileDetail.YearOfExperience,
];

export enum Industry {
  IT = "IT",
  Automotive = "Automotive",
  Education = "Education",
  Healthcare = "Healthcare",
  Agriculture = "Agriculture",
  ECommerce = "ECommerce",
  Retail = "Retail",
  ProfessionalServices = "Professional Services",
  Transportation = "Transportation",
  Electronics = "Electronics",
  Manufacturing = "Manufacturing",
  Aerospace = "Aerospace",
  Engineering = "Engineering",
  Finance = "Finance",
  Law = "Law",
  Textile = "Textile",
  Other = "Other",
}
