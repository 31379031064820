import styled from "styled-components";

export const TalentProfileDetailList = styled.div`
  /* Frame 7626 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  height: fit-content;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;
export default TalentProfileDetailList;
