import { FC } from "react";
import { IAuthProps } from "@remotebase/constants";
import { SetPasswordFormClient } from "./setPasswordForm";
import * as Styled from "./styles";
import { RemotebaseLogo } from "../commons";
import { setPasswordTitle, setPasswordFormTagline } from "../../utils";

type Props = IAuthProps & {
  oldPassword: string;
};

export const AuthCommonSetPasswordClient: FC<Props> = ({
  formProps: { isLoading, performAction },
  oldPassword,
}) => {
  return (
    <Styled.SetPasswordWrapper>
      <RemotebaseLogo />
      <Styled.SetPasswordFormWrapper>
        <Styled.SetPasswordHeading>{setPasswordTitle}</Styled.SetPasswordHeading>
        <Styled.SetPasswordDescription>{setPasswordFormTagline}</Styled.SetPasswordDescription>
        <SetPasswordFormClient
          setPassword={performAction}
          isLoading={isLoading}
          oldPassword={oldPassword}
        />
      </Styled.SetPasswordFormWrapper>
    </Styled.SetPasswordWrapper>
  );
};

export default AuthCommonSetPasswordClient;
