import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 100%;
  background-size: cover;
  padding: 57px 0 60px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  font-family: "Avenir", sans-serif;

  @media and (min-width: 768px) {
    padding: 57px 0 83px;
    max-height: 550px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  flex-grow: 1;

  max-width: 1014px;
  z-index: 0;
  padding: 2rem;
`;

export const Form = styled.div`
  background: #fff;
  box-shadow: 0px 24px 44px rgba(168, 155, 155, 0.2);
  padding: 2rem;
  font-family: "Avenir", sans-serif;
  font-size: 1rem;
  color: #312a50;

  form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    grid-gap: 1.5rem 1rem;

    label {
      line-height: 1.5rem;
    }

    .form-control {
      border-radius: 2px;
      padding: 1rem 1.125rem;
      line-height: 1.5rem;
      font-family: "Avenir", sans-serif;
      box-shadow: none;

      &:focus:not(:read-only),
      &:-webkit-autofill:not(:read-only),
      &:-webkit-autofill:hover:not(:read-only),
      &:-webkit-autofill:focus:not(:read-only),
      &:-webkit-autofill:active:not(:read-only) {
        box-shadow: 0 0 0 30px #fff inset;
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        -webkit-text-fill-color: #312a50;
      }
    }

    .btn.btn-primary {
      padding: 10px;
      flex: 0 1 199px;
      height: 61px;
      margin-top: 2.5rem;

      background: #3618ba;
      border-radius: 1rem;
      border: none;

      &:disabled {
        background: rgba(54, 24, 186, 0.5);
      }
    }
  }

  @media and (min-width: 768px) {
    padding: 4rem;
    form {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, auto);

      & div:nth-child(n + 5) {
        grid-column: 1 / 3;
      }
    }
  }
`;
