import { FC } from "react";
import { ShouldRender } from "../../..";
import { VerifiedIcon } from "../../../assets/icons";
import * as Styled from "../styles";
import { DetailProps } from "../../../utils";

export const Detail: FC<DetailProps> = ({ detailName, detailValue, verifiedFlag }) => {
  return (
    <Styled.TalentProfileDetailElement>
      <Styled.DetailNameContainer>
        <Styled.DetailNameText>{detailName}</Styled.DetailNameText>
      </Styled.DetailNameContainer>
      <Styled.DetailValueContainer>
        <Styled.DetailValueText>{detailValue}</Styled.DetailValueText>
        <ShouldRender if={verifiedFlag}>
          <VerifiedIcon />
        </ShouldRender>
      </Styled.DetailValueContainer>
    </Styled.TalentProfileDetailElement>
  );
};

export default Detail;
