import { useLazyQuery } from "@apollo/client";
import { GetStripePublishableKeyQuery } from "@remotebase/amplify-constants/API";
import { getQuery } from "hooks/utils";
import { getStripePublishableKey } from "@remotebase/amplify-constants/graphql/queries";
import { ReturnPropsGetStripePk } from "./types";

export const useGetStripePK = (completionHandler?: () => void): ReturnPropsGetStripePk => {
  const [performGetStripePK, { data: response, loading: isLoading, error, called }] =
    useLazyQuery<GetStripePublishableKeyQuery>(getQuery(getStripePublishableKey), {
      fetchPolicy: "network-only",
      onCompleted: () => {
        if (completionHandler) completionHandler();
      },
    });
  const data = response?.getStripePublishableKey?.data ?? null;
  return {
    performGetStripePK,
    data,
    isLoading,
    error,
    called,
  };
};

export default useGetStripePK;
