import { FC } from "react";
import { SidebarProps } from "utils";
import { withSidebar } from "state/sidebar";
import SidebarMenu from "./SidebarMenu";
import * as Styled from "./styles";

const SidebarList: FC<SidebarProps> = () => {
  return (
    <Styled.SidebarList>
      <Styled.SidebarGroup>
        <Styled.Caption>
          Engineer <span>Profile(s)</span>
        </Styled.Caption>
        <SidebarMenu />
      </Styled.SidebarGroup>
    </Styled.SidebarList>
  );
};

export default withSidebar(SidebarList);
