import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ContinueBtnBox = styled.div`
  position: relative;
  justify-content: flex-start;
  margin-top: 28px;
`;

export const LoginBtn = styled(Button)`
  font-size: 16px;
  position: relative;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.025em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5138ed;
  border: none;
  border-radius: 27.5px;
  width: 100%;
  min-height: 56px;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    min-height: 50px;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #2b15ba;
    border: none;
    box-shadow: none;
    color: #fff;
  }
`;
