import styled from "styled-components";

export const ProfileFeedbackDetails = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ProfileFeedbackDetailsContainer = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;

  width: 100%;

  background: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ProfileFeedbackDetailsLeft = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  width: 36%;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #e0e0e0;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ProfileFeedbackDetailsRight = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 40px 28px 40px;
  gap: 28px;

  width: 64%;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const ProfileGridWrapper = styled.div`
  width: 100%;
`;
