import styled from "styled-components";

export const ProfileGridWrapper = styled.div`
  width: 100%;
`;

export const ProfileResumeDownload = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 27px 48px;
  //gap: 10px;

  width: 100%;
  height: 277px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  z-index: 50;
`;

export const ProfileDownloadTopSection = styled.div`
  width: 100%;
  height: 83px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;

  padding: 27px 48px;

  z-index: 70;

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const ProfileDownloadBottomSection = styled.div`
  width: 100%;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0 0 10px 10px;

  padding: 42px 48px;

  z-index: 70;

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const ProfileDownloadTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 29px;

  font-family: "poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;

  /* identical to box height, or 29px */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;

  z-index: 100;

  @media only screen and (max-width: 575px) {
    font-size: 20px;
  }
`;

export const ProfileDownloadBottomBody = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 24px;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ProfileDownloadBottomChest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const ProfileDownloadBottomText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ProfileDownloadBottomName = styled.div`
  font-family: "inter700";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ProfileDownloadBottomNameSpan = styled.span`
  font-family: "inter400";
  font-weight: 400;
`;

export const ProfileDownloadBottomDesc = styled.div`
  font-family: "inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const ProfileDownloadButton = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 5px;

  width: 152px;
  height: 44px;

  /* Additional Black */
  border: 1px solid #212121;
  border-radius: 10px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
`;

export const ProfileDownloadButtonText = styled.div`
  width: 91px;
  height: 14px;

  font-family: "inter500";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ProfileDownloadButtonVectorContainer = styled.div`
  width: 16px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ProfileDownloadButtonVector = styled.img`
  max-width: 100%;
`;

export const WrapTip = styled.div`
  position: absolute;
  right: -8px;
  & svg {
    & path {
      fill: #000000;
    }
  }
`;

export const SectionHeading = styled.p`
  margin-bottom: 0;
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row-reverse;
`;
