import styled from "styled-components";

export const TalentProfileDescription = styled.div<{
  $editMode?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
`;

export const SectionHeadingContainer = styled.div`
  /* Frame 7626 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 10px;

  width: 100%;
  height: fit-content;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const SectionHeading = styled.h1`
  height: fit-content;

  font-family: "Poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const DescriptionTextContainer = styled.div`
  /* Frame 7624 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  height: fit-content;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const DescriptionText = styled.p`
  height: fit-content;

  font-family: "Inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 0px;
`;

export const DescriptionReadMore = styled.a`
  width: 100%;
  height: 22px;
  cursor: pointer;

  font-family: "Inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  text-decoration-line: underline;

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const TextInput = styled.input`
  display: block;
  height: 100% !important;
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;
  outline: none;
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
`;

export const TextAreaInput = styled.textarea`
  height: 15rem;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  outline: none;
  margin-bottom: 0 !important;
`;

export const TextAreaDummy = styled.div`
  height: 15rem;
  width: 100%;
  font-weight: 400;
  font-size: 0.875rem;
  outline: none;
  margin-bottom: 0;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  padding: 20px 25px;
`;

export const DummyParagraph = styled.p`
  opacity: 0.3;
  margin-bottom: 0;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  align-self: end;
`;

export const SaveButton = styled.button`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: #3f73f6 !important;
  border: none;
  transition: all 0.25s;
  margin-left: 5px;

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff !important;
      stroke: #fff !important;
    }
    & rect {
      fill: #fff;
    }
    & circle {
      fill: #3f73f6;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }
`;
export const CharactersLabel = styled.span`
  font-size: 0.8rem;
`;

export const CharactersCount = styled.span`
  font-size: 0.8rem;
`;

export const BioTitleText = styled.a`
  width: 100%;
  font-size: 1rem;
  text-decoration-line: underline;
  color: #212121;
  cursor: pointer;
`;
