export enum YourFeedbackType {
  failCulturalFitInterview = "Failed Cultural Fit Interview",
  failSystemDesignInterview = "Failed System Design Interview",
  failTechInterview = "Failed Tech Interview",
  interested = "Interested",
  interviewing = "Interviewing",
  onboarded = "On boarded",
  passCulturalFitInterview = "Passed Cultural Fit Interview",
  passSystemDesignInterview = "Passed System Design Interview",
  passTechInterview = "Passed Tech Interview",
  referred = "Referred",
  rejected = "Rejected",
}
export default YourFeedbackType;
