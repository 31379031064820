import * as yup from "yup";
import { FormField } from "components/form/types";

const formFields = {
  basicDetails: [
    {
      type: "text",
      name: "name",
      "aria-label": "Full name",
      placeholder: "Full Name",
      required: true,
      autoFocus: true,
    },
    {
      type: "email",
      name: "email",
      "aria-label": "Email",
      placeholder: "Email Address",
      required: true,
    },
    {
      type: "tel",
      name: "phone",
      "aria-label": "Phone Number",
      placeholder: "Mobile Number",
      required: true,
    },
  ],

  address: [
    {
      type: "text",
      name: "country",
      "aria-label": "Country Code",
      placeholder: "Country",
      required: true,
      readOnly: true,
    },
    {
      type: "text",
      name: "city",
      "aria-label": "City",
      placeholder: "City",
      required: true,
    },
    {
      type: "text",
      name: "state",
      "aria-label": "State",
      placeholder: "State",
      required: true,
    },
    {
      type: "text",
      name: "postalCode",
      "aria-label": "Postal Code",
      placeholder: "Postal Code",
      required: true,
    },
  ],
} as Record<string, FormField[]>;

export const billingDetailFormProps = {
  formId: "billingDetailForm",
  fields: formFields,
  submitBtn: {
    value: "Proceed",
  },
};

export const billingDetailFormSchema = yup.object({
  name: yup
    .string()
    .required("Full name is required")
    .min(3, "Full name must be at least 3 characters")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: yup.string().required("Email is required").email(),
  phone: yup.number().required("mobile number is required").min(5, "Enter a valid phone number"),
  country: yup.string().required("Country is required").min(2, "Enter a valid country"),
  city: yup.string().required("City is required").min(3, "Enter a valid city"),
  state: yup.string().required("State is required").min(2, "Enter a valid state"),
  postalCode: yup.string().required("Postal code is required").min(3, "Enter a valid postal code"),
});
