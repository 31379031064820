/* eslint-disable max-len */
import { StripeSetupIntent, StripeSetupIntentStatus } from "@remotebase/amplify-constants/API";

import {
  FetchClientSecretForBillingDetails,
  BillingDetails,
  MandateProcessing,
  HandleMandateProvided,
} from "./components";

export function BillingPage({ setupIntent }: { setupIntent: StripeSetupIntent }): JSX.Element {
  const furtherActionMandateMsg = `
    Your Mandate is being processed. Please check your email, for any potential emails from Stripe and your bank.
    This may take a few hours to be completely processed.
    `;

  switch (setupIntent.status) {
    case StripeSetupIntentStatus.canceled:
    case StripeSetupIntentStatus.failed:
    case StripeSetupIntentStatus.mandate_withdrawn:
      return <FetchClientSecretForBillingDetails />;
    case StripeSetupIntentStatus.requires_payment_method:
      return (
        <BillingDetails
          clientSecret={setupIntent.clientSecret}
          setupIntentId={setupIntent.setupIntentId}
        />
      );
    case StripeSetupIntentStatus.requires_confirmation:
      return (
        <BillingDetails
          clientSecret={setupIntent.clientSecret}
          setupIntentId={setupIntent.setupIntentId}
          skipPaymentMethod={true}
        />
      );
    case StripeSetupIntentStatus.processing:
      return <MandateProcessing />;
    case StripeSetupIntentStatus.succeeded:
      return <HandleMandateProvided />;
    case StripeSetupIntentStatus.requires_action:
      return <MandateProcessing customMsg={furtherActionMandateMsg} />;

    default:
      return <HandleMandateProvided />;
  }
}

export default BillingPage;
