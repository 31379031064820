import withApolloProvider from "hooks/apollo/withApollo";
import SidebarLayout from "layouts/sidebar_layout_copy";
import {
  DashboardPage,
  TalentsProfilePage,
  AvailabilityPage,
  NotFound,
  FavoritesPage,
  MaintenancePage,
  TermsPage,
  SupportPage,
  Billing,
} from "pages";
import { Route, Switch, useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import { withSidebar } from "state/sidebar";
import {
  combineHOCs,
  AuthRoutes as path,
  SidebarType,
  SidebarProps,
  UnAuthRoutes,
  shouldShowMaintenence,
} from "utils";
import { Fragment } from "react";

import PerksPage from "pages/perks";
import PerkPage from "pages/perk";

type Props = SidebarProps;
const AuthRouter: React.FC<Props> = (props) => {
  const history = useHistory();

  const { setSidebarType } = props;

  if (shouldShowMaintenence) {
    history.replace(UnAuthRoutes.Maintenance);
  } else {
    setSidebarType(SidebarType.Dashboard);
  }

  return (
    <Fragment>
      <Switch>
        <Route exact path={UnAuthRoutes.Maintenance} component={MaintenancePage} />
        <ThemeProvider theme={theme}>
          <SidebarLayout>
            <Route exact path={path.Talents} component={DashboardPage} />
            <Route exact path={path.Terms} component={TermsPage} />
            <Route exact path={path.Perk} component={PerkPage} />
            <Route exact path={path.Perks} component={PerksPage} />
            <Route exact path={path.Dashbord} component={DashboardPage} />
            <Route exact path={path.Support} component={SupportPage} />
            <Route exact path={path.Favorites} component={FavoritesPage} />
            <Route exact path={path.Availabilty} component={AvailabilityPage} />
            <Route exact path={path.Billing} component={Billing} />
            <Route exact path={path.Talent} component={TalentsProfilePage} />
            <Route exact path={path.NotFound} component={NotFound} />
          </SidebarLayout>
        </ThemeProvider>
      </Switch>
    </Fragment>
  );
};

export default combineHOCs(withApolloProvider, withSidebar)(AuthRouter);
