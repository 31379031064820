import { FC, useCallback, useEffect, useState } from "react";
import { AuthProps, unverifiedUser, UnAuthRoutes as Route } from "utils";
import { withAuth } from "state/auth";
import { useLogin, useNewPassword } from "hooks";
import { useHistory } from "react-router-dom";
import { AuthCommonLoginRegisterClient, ShouldRender } from "@remotebase/components";
import { AuthType, PayloadType, UserType } from "@remotebase/constants";
import AuthCommonSetPasswordClient from "@remotebase/components/authentication/setPasswordClient";

const Login: FC<AuthProps> = ({ getAuth }) => {
  const [selectedAuthType, setSelectedAuthType] = useState(AuthType.SignIn);
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState("");

  const {
    performAction: login,
    res: { success: loginSuccess, isLoading: loginLoading, data: user, error },
  } = useLogin(setOldPassword);

  const {
    performAction: setPassword,
    res: { success: passwordSuccess, isLoading: passwordLoading },
  } = useNewPassword();

  const setPasswordHandler = useCallback(
    async ({ password }: PayloadType) => {
      if (!user) return;
      await setPassword({ user, password });
    },
    [user],
  );

  useEffect(() => {
    if (error === unverifiedUser) {
      history.push(Route.Reverify);
      return;
    }

    if (!loginSuccess || !user) return;
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") setSelectedAuthType(AuthType.NewPassword);
    else getAuth();
  }, [loginSuccess, user, error]);

  useEffect(() => {
    if (passwordSuccess && !passwordLoading) getAuth(true);
  }, [passwordSuccess, passwordLoading]);

  return (
    <>
      <ShouldRender if={selectedAuthType === AuthType.SignIn}>
        <AuthCommonLoginRegisterClient
          formProps={{ isLoading: loginLoading, performAction: login }}
          userType={UserType.Client}
          authType={selectedAuthType}
        />
      </ShouldRender>
      <ShouldRender if={selectedAuthType === AuthType.NewPassword}>
        <AuthCommonSetPasswordClient
          formProps={{ isLoading: passwordLoading, performAction: setPasswordHandler }}
          oldPassword={oldPassword}
        />
      </ShouldRender>
    </>
  );
};

export default withAuth(Login);
