import React, { Fragment, useEffect } from "react";
import {
  ArrowRightAlt,
  ChevronLeft,
  EnvelopeIcon,
  FeatureIcon,
  GiftIcon,
  GlobeIcon,
  PerksMethodIcon,
} from "assets/icons";
import { useHistory, useLocation } from "react-router-dom";
import * as Styled from "./styles";
import perks from "../../utils/constants/perks";

const Perk: React.FC = () => {
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const perksData = perks.find((perksObj) => (perksObj.path as string) === pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Styled.PerksWrapper>
        <Styled.PageHead>
          <Styled.BackLink to="#" onClick={goBack}>
            <Styled.BackLinkIcon>
              <ChevronLeft />
            </Styled.BackLinkIcon>
            <Styled.BackLinkText>go back</Styled.BackLinkText>
          </Styled.BackLink>
        </Styled.PageHead>
        <Styled.PageBody>
          <Styled.PerksContent>
            <Styled.PerksHead>
              <Styled.PerksTitle>{perksData?.heading}</Styled.PerksTitle>
              <Styled.PerksPara>{perksData?.para}</Styled.PerksPara>
            </Styled.PerksHead>
            <Styled.OfferBoxWrapper>
              <Styled.OfferBox>
                <Styled.OfferImgCanvas className={`${perksData?.class}`}>
                  <Styled.OfferImg>
                    <img src={`${perksData?.logo}`} alt="Img" />
                  </Styled.OfferImg>
                </Styled.OfferImgCanvas>
                <Styled.OfferContent>
                  <Styled.OfferInner>
                    <Styled.OfferSquare>
                      <GiftIcon />
                    </Styled.OfferSquare>
                    <Styled.OfferDetails>
                      <Styled.OfferTitle>Special Offer</Styled.OfferTitle>
                      <Styled.OfferPara>{perksData?.offer}</Styled.OfferPara>
                      <Styled.OfferBtn href={perksData?.learnMore} target="_blank">
                        Learn more
                        <ArrowRightAlt />
                      </Styled.OfferBtn>
                    </Styled.OfferDetails>
                  </Styled.OfferInner>
                </Styled.OfferContent>
              </Styled.OfferBox>
            </Styled.OfferBoxWrapper>
          </Styled.PerksContent>
          <Styled.PerksFeature>
            <Styled.FeatureGrid>
              <Styled.FeatureCard>
                <Styled.FeatureHead>
                  <Styled.FeatureIcon>
                    <FeatureIcon />
                  </Styled.FeatureIcon>
                  <Styled.FeatureTitle>Key Features</Styled.FeatureTitle>
                </Styled.FeatureHead>
                <Styled.FeatureBody>
                  {perksData?.features?.map((feature) => {
                    return <Styled.FeatureItem>{feature}</Styled.FeatureItem>;
                  })}
                </Styled.FeatureBody>
              </Styled.FeatureCard>
              <Styled.FeatureCard>
                <Styled.FeatureHead>
                  <Styled.FeatureIcon>
                    <PerksMethodIcon />
                  </Styled.FeatureIcon>
                </Styled.FeatureHead>
                <Styled.FeatureBody>
                  <Styled.FeatureTitle>Perk Redemption Method</Styled.FeatureTitle>
                  {perksData?.redemption.includes("https://") ? (
                    <>
                      <Styled.FeatureText>Please visit us at</Styled.FeatureText>
                      <Styled.FeatureLinkWhite href={perksData?.redemption} target="_blank">
                        {perksData?.redemption}
                      </Styled.FeatureLinkWhite>
                    </>
                  ) : (
                    <Styled.FeatureTextRedemption>
                      {perksData?.redemption}
                    </Styled.FeatureTextRedemption>
                  )}
                </Styled.FeatureBody>
              </Styled.FeatureCard>
              <Styled.FeatureCard>
                <Styled.FeatureHead>
                  <Styled.FeatureIcon>
                    <GlobeIcon />
                  </Styled.FeatureIcon>
                  <Styled.FeatureTitle>Regions Supported</Styled.FeatureTitle>
                </Styled.FeatureHead>
                <Styled.FeatureBody>
                  {perksData?.regionSupported?.map((region) => {
                    return <Styled.FeatureItem>{region}</Styled.FeatureItem>;
                  })}
                </Styled.FeatureBody>
              </Styled.FeatureCard>
              <Styled.FeatureCard>
                <Styled.FeatureHead>
                  <Styled.FeatureIcon>
                    <EnvelopeIcon />
                  </Styled.FeatureIcon>
                  <Styled.FeatureTitle>Contact information</Styled.FeatureTitle>
                </Styled.FeatureHead>
                <Styled.FeatureBody>
                  {perksData?.contact?.map((contact) => {
                    return <Styled.FeatureItem>{contact}</Styled.FeatureItem>;
                  })}
                </Styled.FeatureBody>
              </Styled.FeatureCard>
            </Styled.FeatureGrid>
          </Styled.PerksFeature>
        </Styled.PageBody>
      </Styled.PerksWrapper>
    </Fragment>
  );
};

export default Perk;
