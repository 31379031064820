/* eslint-disable max-len */
export const termsAndConditionTextA = `By clicking [accept], you authorize Remotebase to debit the bank account specified above
          for any amount owed for charges arising from your use of Remotebase’s services and/or
          purchase of products from Remotebase, pursuant to Remotebase’s website and terms, until
          this authorization is revoked. You may amend or cancel this authorization at any time by
          providing notice to Remotebase with 30 (thirty) days notice.`;

export const termsAndConditionTextB = `If you use Remotebase’s services or purchase additional products periodically pursuant to
          Remotebase’s terms, you authorize Remotebase to debit your bank account periodically.
          Payments that fall outside of the regular debits authorized above will only be debited
          after your authorization is obtained.`;
export const processingMadateMessage =
  "Please wait, while we process your mandate. A mail will be sent to you with updates";
export const microdepositsVerificationProcessMsg =
  "Your account needs to be verified via microdeposits,link to complete the process has been sent to your email";
export const cancelledOperationMsg = "You cancelled the operation";
export const errorOnProcessingMsg =
  "Error occurred while processing your request, please try again";
export const SectionHeaderText = "Mandate Provided";
export const mandateProvidedInfoText = "Below is the mandate provided";
export const someInfoHereText = "Some information here about the Mandate";
export const defaultMandateProcessingText =
  "Mandate is being processed. Please check back later, an email will be sent to you when there is updates";
export const pleaseProvideBillingDetailsHeaderText = "Please provide billing details";
export const pleaseProvideInfoParaText =
  "This allows remotebase to automatically process due charges from your bank account";
