import styled from "styled-components";

export const SlimmerWrapper = styled.div`
  margin: 1rem 1.5rem;
`;

export const SlimmerHeading = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

export const AvatarEditorContainer = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileImage = styled.img`
  width: 110px;
  height: 110px;
  left: 5px;
  top: 5px;
  border-radius: 100px;
  z-index: 0;
`;

export const ImageEditContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;

export const RotateIconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ZoomIconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VerticalLine = styled.div`
  display: inline-grid;
  border-left: 1px solid #ccc;
  height: 20px;
`;
