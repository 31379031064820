import { ButtonGroup, Container, Form, Spinner } from "react-bootstrap";
import { useStripe } from "@stripe/react-stripe-js";
import { ErrorProps } from "@remotebase/constants";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import withError from "state/error/withErrorHoc";
import { ShouldRender } from "@remotebase/components";

import {
  AcceptTermsAndConditionBtn,
  AchTermsAndConditionPara,
  AchTermsAndConditionParaContainer,
  AgreeCheckBox,
  AgreeCheckBoxLabel,
  AgreeCheckboxContainer,
  LableTextSpan,
  SectionHeader,
} from "../styles";
import { useSaveStripeSetupIntent } from "../../hooks";
import ErrorComponent from "../Error";
import MandateProcessing from "../MandateProcessing";
import {
  cancelledOperationMsg,
  errorOnProcessingMsg,
  microdepositsVerificationProcessMsg,
  processingMadateMessage,
  termsAndConditionTextA,
  termsAndConditionTextB,
} from "../constants";
import { processNextAction, saveSetupIntentToDbAndUpdateUserAgreement } from "./helpers";

type Props = {
  clientSecret: string;
  setupIntentId: string;
} & ErrorProps;

const initialState = {
  userAgrees: false,
  customMsg: "",
  showProcessMandatePage: false,
};

function AchTermsAndCondition({ showError, clientSecret, setupIntentId }: Props): JSX.Element {
  const {
    saveSetupIntentInDb,
    isLoading,
    called: saveSetupIntentInDbCalled,
  } = useSaveStripeSetupIntent();

  const [state, setState] = useState(initialState);
  const { userAgrees, customMsg, showProcessMandatePage } = state;
  const stripe = useStripe();
  const history = useHistory();

  async function handleConfirmSetupIntent(): Promise<void> {
    if (!stripe) {
      return;
    }
    const { setupIntent, error } = await stripe.confirmUsBankAccountSetup(clientSecret);
    if (error) {
      // The payment failed for some reason.
      showError({
        title: "Failed",
        message: error.message ?? "",
      });
      return;
    }

    const setShowProcessMandagePage = (newValue: boolean): void =>
      setState((prev) => ({ ...prev, showProcessMandatePage: newValue }));

    const setCustomMsg = (newCustomMsg: string): void =>
      setState((prev) => ({ ...prev, customMsg: newCustomMsg }));

    processNextAction(
      setupIntent,
      showError,
      cancelledOperationMsg,
      history,
      processingMadateMessage,
      setShowProcessMandagePage,
      setCustomMsg,
      microdepositsVerificationProcessMsg,
    );
  }

  if (!stripe || !clientSecret || !setupIntentId) {
    return <ErrorComponent />;
  }

  if (showProcessMandatePage) {
    return <MandateProcessing customMsg={customMsg} />;
  }

  const handleSubmit = (event): void => {
    event.preventDefault();
    // save setupIntent in db
    saveSetupIntentToDbAndUpdateUserAgreement(
      saveSetupIntentInDb,
      setupIntentId,
      showError,
      errorOnProcessingMsg,
      history,
      handleConfirmSetupIntent,
    );
  };

  const btnIsLoading = isLoading || saveSetupIntentInDbCalled;
  const btnText = btnIsLoading ? <Spinner animation="border" size="sm" /> : "Accept";
  return (
    <Container className="mt-5">
      <SectionHeader className="mb-3">Terms and Condition:</SectionHeader>
      <AchTermsAndConditionParaContainer>
        <AchTermsAndConditionPara>{termsAndConditionTextA}</AchTermsAndConditionPara>
        <AchTermsAndConditionPara>{termsAndConditionTextB}</AchTermsAndConditionPara>
      </AchTermsAndConditionParaContainer>
      <Form onSubmit={handleSubmit} className="mt-3">
        <AgreeCheckboxContainer className="mb-3 d-flex">
          <AgreeCheckBoxLabel className="form-check-label" htmlFor="agreeCheckboxId">
            <AgreeCheckBox
              onChange={(event): void => {
                setState((prev) => ({ ...prev, userAgrees: event.target.checked }));
              }}
              type="checkbox"
              className="form-check-input"
              checked={userAgrees}
              id="agreeCheckboxId"
            />
            <LableTextSpan> {"I agree to the terms and condition"}</LableTextSpan>
          </AgreeCheckBoxLabel>
        </AgreeCheckboxContainer>
        <ShouldRender
          if={userAgrees}
          children={
            <ButtonGroup className="w-50 mt-4 mx-auto">
              <AcceptTermsAndConditionBtn className="btn text-white" disabled={btnIsLoading}>
                {btnText}
              </AcceptTermsAndConditionBtn>
            </ButtonGroup>
          }
        />
      </Form>
    </Container>
  );
}

export default withError(AchTermsAndCondition);
