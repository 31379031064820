import { UserType } from "@remotebase/constants";
import { imageUrls } from "@remotebase/components";
import { FC } from "react";
import * as text from "../../utils";
import * as Styled from "./styles";
import ClientReviewCarousal from "../clientReviewCarousal";

interface Props {
  authComponent: JSX.Element;
  userType?: UserType;
}

export const AuthenticationHeaderClient: FC<Props> = (props) => (
  <Styled.LoginWrapper>
    <Styled.LoginContent>
      <Styled.LoginCanvas>{props.authComponent}</Styled.LoginCanvas>
    </Styled.LoginContent>
    <Styled.LoginBg data-background={imageUrls.authBg}>
      <Styled.LoginBgTop>
        <Styled.Card>
          <Styled.CardText1>{text.talentName}</Styled.CardText1>
          <Styled.CardText2>{text.talentRoleAndLocation}</Styled.CardText2>
          <Styled.CardText2>{text.talentSkillScore}</Styled.CardText2>
          <Styled.Br />
          <Styled.CardText2>{text.talentAvailability}</Styled.CardText2>
        </Styled.Card>
      </Styled.LoginBgTop>
      <Styled.LoginBgBottom data-background={imageUrls.authBgBottom}>
        <Styled.LoginBgBottomContent>
          <Styled.LoginTalentList>
            <Styled.LoginTalent>
              <ClientReviewCarousal />
            </Styled.LoginTalent>
          </Styled.LoginTalentList>
        </Styled.LoginBgBottomContent>
      </Styled.LoginBgBottom>
    </Styled.LoginBg>
  </Styled.LoginWrapper>
);

export default AuthenticationHeaderClient;
