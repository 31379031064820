import styled from "styled-components";

export const SidebarCloseBtn = styled.button`
  position: relative;
  top: -3px;
  display: none;
  margin-right: 18px;
  margin-top: 6px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }

  .icon {
    font-size: 20px;
    fill: #11142d;
  }

  @media (max-width: 767px) {
    display: inline-flex;
  }
`;

export const SidebarPicWhite = styled.img`
  width: 176px;
  @media (max-width: 767px) {
    width: 140px;
  }
  /* display: none; */
`;

export const SidebarPicBlack = styled.img`
  width: 176px;
  display: none;
`;
