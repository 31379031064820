import React from "react";
import { IAuthProps } from "@remotebase/constants";
import {
  UnAuthRoutes as Route,
  forgotPassword,
  signIn,
  alreadyMember,
  forgetPasswordFormTagline,
} from "../..";
import ForgotPasswordForm from "./forgotPasswordForm";
import { RemotebaseLogo } from "../commons";
import * as Styled from "./styles";

export const AuthCommonForgetPass: React.FC<IAuthProps> = ({
  formProps: { isLoading, performAction },
}) => {
  return (
    <Styled.LoginWrapper>
      <RemotebaseLogo />
      <Styled.LoginHeading>{forgotPassword}</Styled.LoginHeading>
      <Styled.LoginDescrip>{forgetPasswordFormTagline}</Styled.LoginDescrip>
      <ForgotPasswordForm isLoading={isLoading} sendCode={performAction} />
      <Styled.AccountText>
        {alreadyMember}
        <Styled.SignupLink to={Route.Login}>{signIn}</Styled.SignupLink>
      </Styled.AccountText>
    </Styled.LoginWrapper>
  );
};

export default AuthCommonForgetPass;
