import styled from "styled-components";
import { Link } from "react-router-dom";

export const SetPasswordWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
`;

export const SetPasswordFormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SetPasswordHeading = styled.h1`
  font-family: "Raleway";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: 0.175px;
  text-transform: capitalize;
  color: #202223;
  margin-bottom: 1rem;
`;

export const SetPasswordDescription = styled.p`
  line-height: 130%;
  color: #312a50;
  margin-bottom: 1.125rem;
`;

export const FormGroups = styled.div`
  display: grid;
  row-gap: 10px;
  margin-bottom: 22px;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 16px;
  }

  &.active {
    label {
      color: #898989;
    }
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  color: #bfbfbf;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 24px;
`;

export const FormField = styled.input`
  font-size: 16px;
  color: #898989;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: flex;
  background-color: #fff;
  border: 1px solid #bbbbbb;
  border-radius: 27.5px;
  padding: 8px 24px;
  width: 100%;
  height: calc(2.375em + 1rem + 2px);
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    height: calc(2em + 1rem + 2px);
  }

  &.focus-visible,
  &:focus {
    background-color: #fff;
    border: 2px solid #5138ed;
    outline: none;
    box-shadow: none;
    color: #404040;
  }
`;

export const ForgotPassword = styled(Link)`
  font-size: 16px;
  color: #5138ed;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #2b15ba;
    text-decoration: underline;
  }
`;

export const AccountText = styled.p`
  line-height: 130%;
  text-align: center;
  color: #312a50;
  margin-top: 25px;
`;

export const SignupLink = styled(Link)`
  color: #3617ba;
  margin-left: 5px;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: #3617ba;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 15px;
  font-size: 0.8rem;
`;
