import styled from "styled-components";
import { Button, Row, Pagination } from "react-bootstrap";
import { StyledProps } from "utils";

export const DivWrapper = styled.div``;

export const DivWrapperSalary = styled.div`
  width: 92px;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  margin-top: 4px;
`;

export const DivWrapperRole = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`;

export const DashboardHead = styled.div`
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
  }
`;

export const DashboardHeading = styled.h1`
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 134.5% !important;
  /* identical to box height, or 27px */

  color: #1a0224 !important;

  @media only screen and (max-width: 767px) {
    font-size: 14px !important;
  }
`;

export const ClearResults = styled.a`
  font-size: 14px;
  color: #e3e4f4;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: 4px;
  transition: all 0.25s;

  @media only screen and (max-width: 575px) {
    margin-bottom: 20px;
    margin-left: auto;
  }

  &:hover,
  &:focus {
    color: #11142d;
    text-decoration: none;
  }
`;

export const HeadCount = styled.div`
  width: 150px;
  height: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #4f4f4f;
  margin-bottom: 40px;
`;

export const DashboardPara = styled.p`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 134.5%;

  color: #4d526d;

  margin-bottom: 30px;
  margin-top: 5px;
`;

export const DashboardBPara = styled.p`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 134.5%;
  /* identical to box height, or 27px */

  color: #1a0224;
  margin-bottom: 30px;
  margin-top: 5px;
`;

export const DashboardRow = styled(Row)`
  margin-bottom: 10px;
`;

export const DashboardBoPara = styled.p`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 134.5%;
  /* identical to box height, or 27px */

  color: #1a0224;
  margin-bottom: 30px;
  margin-top: 5px;
`;

export const SearchGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const SearchInput = styled.input`
  font-size: 14px !important;
  color: #11142d !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
  border-radius: 40px !important;
  border: 1px solid #fff !important;
  padding: 9px 20px 9px 50px !important;
  height: calc(1.715em + 1rem + 2px) !important;
  margin-bottom: 0 !important;
  transition: all 0.25s;

  &::-webkit-input-placeholder {
    color: #979ba4 !important;
  }

  &:-ms-input-placeholder {
    color: #979ba4 !important;
  }

  &::placeholder {
    color: #979ba4 !important;
  }

  &.focus-visible,
  &:focus {
    border: 1px solid #979ba4 !important;
    outline: none;
  }
`;

export const SearchIcon = styled.span`
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: absolute;
  left: 20px;
  top: 11px;
`;

export const DashboardOpt = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  @media only screen and (max-width: 1199px) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 479px) {
    grid-column-gap: 10px;
  }
`;

export const SortDropdown = styled.div`
  width: 100%;

  & .dropdown {
    & .dropdown-toggle {
      font-size: 14px;
      color: #979ba4;
      font-weight: 500;
      background-color: #ffffff;
      border-radius: 40px;
      border: 1px solid #fff;
      padding: 7px 16px 7px 42px;
      height: 42px;
      width: 100%;
      transition: all 0.25s;

      &:after {
        content: none;
      }

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active:focus {
        background-color: #ffffff;
        border: 1px solid #979ba4;
        box-shadow: none;
        color: #979ba4;
      }
    }

    & .dropdown-menu {
      border: none;
      border-radius: 12px;
      left: 0 !important;
      right: 0 !important;
      min-width: 10px;
      box-shadow: 0px 1px 10px rgb(0 0 0 / 5%);
    }

    & .dropdown-item {
      font-size: 14px;
      color: #979ba4;
      font-weight: 500;
      padding: 6px 16px;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: #e9ecef;
        color: #979ba4;
      }
    }
  }
`;

export const SortDropdownIcon = styled.span`
  font-size: 14px;
  color: #979ba4;
  width: 14px;
  height: 14px;
  display: inline-flex;
  position: absolute;
  left: 20px;
  top: 15px;
`;

export const CodeGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const CodeInput = styled.input`
  font-size: 14px !important;
  color: #11142d !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
  border-radius: 40px !important;
  border: 1px solid #fff !important;
  padding: 9px 20px 9px 50px !important;
  height: calc(1.715em + 1rem + 2px) !important;
  margin-bottom: 0 !important;
  transition: all 0.25s;

  &::-webkit-input-placeholder {
    color: #979ba4 !important;
  }

  &:-ms-input-placeholder {
    color: #979ba4 !important;
  }

  &::placeholder {
    color: #979ba4 !important;
  }

  &.focus-visible,
  &:focus {
    border: 1px solid #979ba4 !important;
    outline: none;
  }
`;

export const CodeIcon = styled.span`
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: absolute;
  left: 20px;
  top: 15px;
`;

export const TalentGird = styled.div`
  width: 100%;
  margin-bottom: 24px;

  & .talent__carousel {
    margin: 0 -12px;
  }
`;

export const TalentBoxCanvas = styled.div`
  padding: 0 12px;
  height: 100%;
`;

export const TalentBox = styled.div`
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  width: 100%;
  padding: 0px;
  position: relative;
  height: 100%;

  &:hover {
    & .engineerOverlay {
      opacity: 1;
      pointer-events: initial;

      & button,
      & span {
        transform: translate(0px, 0px);
        transition-delay: 0.1s;
      }
    }
  }
`;

export const TalentBoxMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 1279px) {
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const TalentBoxImgContainer = styled.div`
  width: 100%;
  height: 100px;
  padding: 10px;
`;

export const TalentInnerBg = styled.img`
  width: 100%;
  background: rgba(54, 23, 186, 0.57);
  border-radius: 5px;
  height: 61px;
`;

export const TalentBoxImg = styled.img`
  object-fit: cover;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 22px;
  top: 25px;
  border-radius: 50%;
`;

export const DeveloperInfoWrapper = styled.div`
  margin-top: 1px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
`;

export const DeveloperPic = styled.img`
  width: 48px;
  height: 48px;
  left: 4px;
  top: 4px;
  border-radius: 35px;
  object-fit: cover;
`;

export const DeveloperPicInit = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background: #7c7c7c;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  align-items: center;
  padding-top: 12px;
  flex-shrink: 0;
`;

export const DeveloperInit = styled.span`
  width: 31px;
  height: 24px;

  /* Heading Text - H5 Semibold */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  /* Primary - White */

  color: #ffffff;
`;

export const DeveloperNameInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1px 0 0 8px;
`;

export const DeveloperNameStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DeveloperInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DeveloperName = styled.span`
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 134.5% !important;
  color: #1a0224 !important;
  margin-right: 8px !important;
`;

export const DeveloperTitle = styled.div`
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 134.5% !important;
  color: #4d526d !important;
  margin-top: 5px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;
export const DeveloperFeedBack = styled.div`
  margin-top: 15px;
`;

export const DeveloperProfileButton = styled.button`
  width: 127px;
  height: 41.87px;
  border: 0.697802px solid #4d526d;
  border-radius: 11.1648px;
  padding: 15.97802px;
  padding-left: 10px;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 30px;
  font-family: "Proxima Nova" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #312a50;
  background-color: #fff;
  &:hover {
    color: #fff;
    background: #3615bd;
    border: 1px solid #3615bd;
    text-decoration: none;
    &:before {
      height: 200%;
    }
  }
`;

export const DeveloperRate = styled.div`
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 130% !important;
  color: #4d526d;
  margin-top: 10px;
`;

export const DeveloperSeparator = styled.div`
  width: 0px;
  height: 14.96px;
  border: 0.623368px solid #4d526d;
  margin-top: 10px;
`;

export const DeveloperSkillScore = styled.div`
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 130% !important;
  color: #4d526d !important;
  margin-top: 10px;
`;

export const FavouriteDiv = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: 5px;
  text-align: center;
  align-items: center;
  padding-top: 8px;

  background: rgba(217, 217, 217, 0.5);
  border-radius: 50%;
`;

export const FavouriteIcon = styled.span`
  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const MenuIcon = styled.span`
  position: absolute;
  right: 8px;
  top: 4px;
  z-index: 1;

  svg {
    fill: #fff;
    width: 16px;
    height: 16px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const DeveloperDescription = styled.div`
  height: 76px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  margin-top: 20px;
  padding: 0px 12px;
`;

export const DeveloperSelectionButton = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 5px;
  gap: 4px;
  width: 116px;
  height: 28px;
  border: 1px solid #3615bd;
  border-radius: 60px;
  background-color: "#27ae60";
  border: 1px solid "#27ae60";
  color: "#fff";
  font-size: 12px;
  margin-left: 10px;
  margin-top: -5px;
`;

export const TalentSelectionButtonIcon = styled.div<{
  isStarIcon: boolean;
}>`
  display: flex;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
    fill: ${(props): StyledProps => (props.isStarIcon ? "#fff" : "#3615BD")};
  }
`;

export const DivWrapperAvailability = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 5px;
  gap: 4px;
  width: 110px;
  height: 28px;
  border: 1px solid #3615bd;
  border-radius: 60px;
`;

export const TalentSalaryWithAvailability = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 12px 20px 12px;
  border-bottom: 1px solid #e9ecef;
`;

export const TalentSelectedText = styled.span`
  margin-left: 6px;
  margin-top: 1px;
`;

export const TalentSkillWrapper = styled.div`
  margin-top: 10px;
  padding: 0px 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SkillScore = styled.div`
  width: 62px;
  height: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
`;

export const TalentDetailSection = styled.div``;

export const TalentBoxOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  opacity: 0;
  pointer-events: none;
`;

export const EngineeOverlayBtn = styled(Button)`
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  background-color: #49d5bb;
  border: none;
  border-radius: 30px;
  min-width: 90px;
  min-height: 28px;
  transform: translate(0px, 550px);
  transition: all 0.2s 0.1s ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: #219c85;
    border: none;
    box-shadow: none;
    color: #fff;
  }
`;

export const EngineeOverlayLock = styled.span`
  display: inline-flex;
  transform: translate(0px, 550px);
  transition: all 0.2s 0.1s ease-in-out;

  & svg {
    width: 48px;
    height: 48px;

    & path {
      fill: #7b8292;
    }
  }
`;

export const TalentLeft = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 102px;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const TalentImgCanvas = styled.div`
  width: 92px;
  height: 92px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  pointer-events: none;
  margin-bottom: 12px;
`;

export const TalentImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TalentName = styled.h6`
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 4px;
  word-break: break-word;
`;

export const TalentRole = styled.div`
  font-size: 9px;
  color: #7b8292;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 4px;
  word-break: break-word;
`;

export const TalentExperience = styled.div`
  font-size: 9px;
  color: #7b8292;
  font-weight: 500;
  line-height: 12px;
`;

export const TalentDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: 28px;

  @media only screen and (max-width: 1199px) {
    padding-left: 14px;
  }

  @media only screen and (max-width: 575px) {
    padding: 24px 0px 0px;
  }
`;

export const ProgressBarCanvas = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 152px;
  overflow-y: auto;
  padding-right: 2px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #eaeaea;
    border-radius: 8px;
  }
`;

export const ProgressBarFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProgressBarHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const ProgressBarLabel = styled.label`
  font-size: 9px;
  color: #7b8292;
  font-weight: 600;
  margin-bottom: 0;
`;

export const ProgressBarNumber = styled.span`
  font-size: 9px;
  color: #14142b;
  font-weight: 600;
  display: inline-flex;
  margin-left: 5px;
  padding-left: 5px;
  position: relative;

  &:before {
    content: "";
    width: 2px;
    height: 2px;
    flex-shrink: 0;
    display: flex;
    background-color: #c4c4c4;
    border-radius: 10px;
    position: absolute;
    top: 5px;
    left: 0;
  }
`;

export const ProgressBar = styled.div<{
  width: string;
}>`
  width: ${(props): StyledProps => props.width};
  height: 7px;
  background-color: #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0px 8px;
`;

export const ProgressBarFill = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #3615bd;
`;

export const TalentFeature = styled.div`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8eede;
  border-radius: 12px;
  padding: 5px;
  filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
  position: absolute;
  left: -8px;
  top: -8px;
  z-index: 2;
`;

export const EllipsisBtn = styled(Button)`
  font-size: 16px;
  color: #c4c4c4;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 14px;
  top: 16px;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #c4c4c4;
  }
`;

export const NoDataText = styled.h1`
  font-size: 28px;
  color: #000000;
  font-weight: 800;
  text-align: center;
  margin-top: 10vh;
`;

export const TalentPagination = styled(Pagination)`
  justify-content: flex-end;
  display: flex;
  margin-bottom: 0;
  margin-top: auto;

  .page-link {
    font-size: 14px;
    background-color: #6c5dd3 !important;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 30px !important;
    border: none;
    margin: 0 6px;
  }

  .page-item.active .page-link {
    color: #fff !important;
    background-color: #6c5dd3 !important;
  }
  .page-link svg {
    width: 14px;
    height: 14px;
  }
  .page-link svg path {
    fill: #fff;
  }

  .page-item.disabled .page-link {
    opacity: 0.5;
    border: none !important;
    background-color: none;
  }
`;

export const HeartIcon = styled.div`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
`;

export const TalentListPagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ClearFilters = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: #979ba4;
  cursor: pointer;
  min-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  &:hover,
  &:focus {
    color: #000000;
    text-decoration: none;
  }

  @media only screen and (min-width: 1200px) {
    text-align: right;
    width: auto;
  }
`;

export const CarouselBtnCanvas = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    position: absolute;
    top: -80px;
    right: 36px;
    margin-top: 0;
  }
`;

export const CarouselBtn = styled.button`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  position: static;

  @media screen and (min-width: 576px) {
    width: 45px;
    height: 45px;
  }

  & svg {
    width: 8px;
    height: 8px;

    @media screen and (min-width: 576px) {
      width: 12px;
      height: 12px;
    }

    & path {
      fill: #312a50;
    }
  }

  &:disabled {
    background-color: #f0f0f0;
  }
`;
export const FeedbackFailedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 18px;
  gap: 10px;
  width: 135px;
  height: 24px;
  background: rgba(251, 125, 87, 0.05);
  border-radius: 4px;
`;
export const FeedbackFailedMessage = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #fb7d57;
`;

export const FeedbackSubmittedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 18px;
  gap: 10px;
  background: rgba(65, 184, 131, 0.05);
  border-radius: 4px;
  width: 146px;
  height: 24px;
`;

export const FeedbackSubmittedMessage = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #41b883;
`;
