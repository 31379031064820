import React, { FC, useState, useMemo, Fragment } from "react";
import Countdown from "react-countdown";
import { resendCodeText, CountdownCallbackProp, countdownDefaultValue } from "../../utils";
import * as Styled from "./styles";

interface Props {
  children?: React.ReactNode;
}

export const RenderCountdown: FC<Props> = ({ children }) => {
  const [restartSignal, setRestartSignal] = useState(1);
  const [disabled, setDisabled] = useState(true);

  const durationElement = ({
    formatted: { seconds },
    completed,
  }: CountdownCallbackProp): JSX.Element => {
    if (completed) return <Fragment>{children}</Fragment>;

    const secondsToDisplay = Number(seconds) > 0 ? seconds : countdownDefaultValue / 1000;
    return (
      <Styled.ResendLinkDisabled>{`${resendCodeText} in ${secondsToDisplay}`}</Styled.ResendLinkDisabled>
    );
  };

  const handleClick = (): void => {
    if (!disabled) {
      setRestartSignal((prevState) => prevState + 1);
      setDisabled(true);
    }
  };

  const CountdownComponent = useMemo(
    () => (
      <Countdown
        onComplete={() => setDisabled(false)}
        date={Date.now() + countdownDefaultValue}
        key={restartSignal}
        renderer={durationElement}
      />
    ),
    [restartSignal],
  );

  return <div onClick={handleClick}>{CountdownComponent}</div>;
};

export default RenderCountdown;
