import { TalentsProfileVersion } from "components";
import { FC } from "react";
import { TalentProvider } from "state/talent";
import * as Styled from "./styles";

const TalentsProfilePage: FC = () => {
  return (
    <Styled.TalentsWrapper>
      <TalentProvider>
        <TalentsProfileVersion />
      </TalentProvider>
    </Styled.TalentsWrapper>
  );
};

export default TalentsProfilePage;
