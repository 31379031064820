import styled from "styled-components";

export const TalentProfileDetailElement = styled.div`
  /* Frame 7668 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 48.6px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const DetailNameContainer = styled.div`
  /* Frame 6322 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 187px;

  width: 100%;
  height: 19px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const DetailValueContainer = styled.div`
  /* Frame 6327 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 17px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const DetailNameText = styled.span`
  /* Expertise */

  width: 100%;
  height: 19px;

  font-family: "Inter500";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const DetailValueText = styled.span`
  /* Full-stack engineer */

  max-width: 100%;
  height: 17px;

  font-family: "Inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Additional - Dark Grey */
  color: #7c7c7c;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const DetailVerifiedCointainer = styled.div`
  /* material-symbols:verified */

  width: 16px;
  height: 16px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const DetailVerifiedImg = styled.img`
  /* material-symbols:verified */

  width: 16px;
  height: 16px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;
