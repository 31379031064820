import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 2.5rem;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
`;

export const Paragraph = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
  color: #808191;
  white-space: pre-line;
`;

export const H5 = styled.h5`
  font-size: 1rem;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.05rem;
`;

export const ViewJobBtn = styled.button`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 0.8rem;
  color: white;
  background-color: #3c65fe;
  border: 1px solid #e4e4e4;
  display: none;
`;

export const CardWrapper = styled.div<{
  $matched?: boolean;
  $isEligible?: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-columns: calc(100% - 100px - 3rem) 100px;
  gap: 3rem;
  justify-content: space-between;
  background-color: #fcfcfc;
  border: 2px solid #dddddd;
  border-radius: 1rem;
  padding: 1.25rem 1rem 1.25rem 1rem;
  margin-bottom: 0.5rem;

  &:hover {
    border: 2px solid #c9d4ff;
    background-color: #f4f7ff;
  }
  &:hover ${ViewJobBtn} {
    display: block;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const LogoWrapper = styled.div`
  height: fit-content;
  padding: 6px;
  border-radius: 50%;
  background-color: #e8e8e8;
`;

export const CompanyLogo = styled.img`
  width: 25px;
  height: 25px;
`;

export const CompanyHeader = styled.div`
  display: block;
`;

export const NameLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  margin-bottom: 0.5rem;
  color: #000000;
`;

export const TitleBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Span = styled.span`
  font-size: 0.75rem;
`;

export const Dot = styled.span`
  width: 0.125rem;
  height: 0.125rem;
  border-radius: 50%;
  background-color: #000000;
`;

export const CompanyDetail = styled.p`
  font-size: 0.75rem;
  color: #808191;
`;

export const BtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 0.5rem;
  align-items: center;
`;

export const StackBtn = styled.button`
  width: fit-content;
  padding: 0.1rem 1.15rem;
  border-radius: 1rem;
  border: 1px solid #e8e9ea;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  background-color: #ffffff;
  cursor: default !important;
`;

export const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-end;
`;

export const MatchedBtn = styled.button`
  padding: 0.12rem 0.75rem 0.06rem;
  border-radius: 0.4rem;
  border: none;
  font-size: 0.626rem;
  font-weight: 600;
  font-family: roboto;
  letter-spacing: 0.35px;
  background-color: #59c2f9;
  color: #ffffff;
  cursor: default !important;
`;

export const EligibleBtn = styled.button`
  padding: 0.12rem 0.75rem 0.06rem;
  border-radius: 0.4rem;
  border: none;
  font-size: 0.626rem;
  font-weight: 600;
  font-family: roboto;
  letter-spacing: 0.35px;
  background-color: #8cc84b;
  color: #ffffff;
  cursor: default !important;
`;

export const UnAvailable = styled.button`
  padding: 0.12rem 0.75rem 0.06rem;
  border-radius: 0.4rem;
  border: none;
  font-size: 0.626rem;
  font-weight: 600;
  font-family: roboto;
  letter-spacing: 0.35px;
  background-color: #ca544f;
  color: #ffffff;
  cursor: default !important;
`;

export const BreakTag = styled.br`
  margin: 0;
`;

export const ImageSection = styled.div`
  overflow: hidden;
  height: 31.25rem;
  position: relative;

  svg {
    position: absolute;
    top: -3.125rem;
    left: 45%;
    transform: translateX(-50%);
    width: 31.25rem;
    height: 31.25rem;
  }
`;

export const HR = styled.hr`
  border-width: 0.1px;
  margin-top: 2rem;
  margin-bottom: 1.75rem;
`;

export const DotWrapper = styled.div`
  display: flex;
  gap: 0.15rem;
  align-items: center;
  cursor: pointer;
`;
