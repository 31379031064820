import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { clientReviews } from "../../utils";
import * as S from "./styles";

export const ClientReviewCarousal: React.FC = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: number): void => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={3000}
        pause={false}
        controls={false}
        indicators={false}
      >
        {clientReviews.map((review, i) => (
          <S.StyledCarouselItem key={i}>
            <S.ItemCanvas>{review.content}</S.ItemCanvas>
            <S.Content>
              <div>
                <S.Name>{review.name}</S.Name>
                <S.Company>{review.company}</S.Company>
              </div>
              <S.Image src={review.clientImg} />
            </S.Content>
          </S.StyledCarouselItem>
        ))}
      </Carousel>
      <S.CarouselIndicator className="carousel-indicators">
        <S.CarouselDot
          className={`${index === 0 && "active"}`}
          onClick={(): void => setIndex(0)}
        ></S.CarouselDot>
        <S.CarouselDot
          className={` ${index === 1 && "active"}`}
          onClick={(): void => setIndex(1)}
        ></S.CarouselDot>
        <S.CarouselDot
          className={`${index === 2 && "active"}`}
          onClick={(): void => setIndex(2)}
        ></S.CarouselDot>
      </S.CarouselIndicator>
    </>
  );
};

export default ClientReviewCarousal;
