import styled from "styled-components";

export const ReferralTermsAndConditions = styled.b`
  font-size: 18px;
  color: #11142d;
  font-family: "Poppins", sans-serif;
`;

export const ReferralText = styled.small`
  font-size: 16px;
  color: #808191;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  max-width: 550px;
`;

export default ReferralText;
