import * as yup from "yup";
import { FormField } from "components/form/types";

const formFields = [
  {
    type: "text",
    name: "fullName",
    "aria-label": "Full name",
    placeholder: "John Doe",
    required: true,
    autoFocus: true,
  },
  {
    type: "email",
    name: "email",
    "aria-label": "Email",
    placeholder: "you@emailId.com",
    required: true,
  },
  {
    type: "text",
    name: "companyName",
    "aria-label": "Company name",
    placeholder: "Company name",
    required: true,
  },
  {
    type: "textarea",
    name: "message",
    "aria-label": "How may we help you?",
    placeholder: "Type your message….",
    required: true,
    rows: 5,
  },
] as FormField[];

export const initFormProps = {
  formId: "main_support_form",
  fields: formFields,
  submitBtn: {
    value: "Send Message",
  },
};

export const supportSchema = yup
  .object({
    fullName: yup
      .string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    companyName: yup
      .string()
      .required("Company name is required")
      .min(3, "Company name must be at least 3 characters"),
    email: yup.string().required("Email is required").email(),
    message: yup
      .string()
      .required("Message is required")
      .min(20, "Message must be at least 20 characters"),
  })
  .required();
