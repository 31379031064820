import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledProps } from "utils";

export const LayoutWrapper = styled.div`
  * {
    transition: all 0.2s ease-in-out;
  }
`;

export const Sidebar = styled.aside`
  background-color: #3617ba;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 258px;
  padding: 2.5rem;
  z-index: 2;

  ${(props): string | false =>
    props["data-collapsed"] &&
    `
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const SidebarTop = styled.header`
  position: relative;
  margin-bottom: 5rem;
`;

export const SidebarLogo = styled(Image)`
  margin-left: -1.5rem;
  margin-top: -0.95rem;

  ${(props): string | false =>
    props["data-collapsed"] &&
    `
    transform: scale(2);
    margin-top: -0.75rem;
    margin-left: 0;
  `}
`;

export const SidebarToggle = styled.button`
  background-color: #3617ba;
  position: absolute;
  top: -0.5rem;
  right: ${(props): string | false => (props["data-collapsed"] ? "-2.5rem" : "-4.5rem")};
  border: none;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border-radius: 0 50% 50% 0;

  svg {
    width: 1rem;
    fill: ${({ theme }): StyledProps => theme.background};
  }
`;

export const SidebarNav = styled.nav`
  margin: 6px 0;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;

  ${(props): string | false =>
    props["data-collapsed"] &&
    `
    .nav-item-text {
      display: none;
    }
  `}
`;

export const SidebarNavItem = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Avenir", sans-serif;
  transition: all 0.2s ease;

  svg path {
    transition: all 0.2s ease;
    stroke-opacity: 0.5;
  }

  &:hover,
  &.active {
    color: ${({ theme }): StyledProps => theme.background};
    text-decoration: none;
    transform: scale(1.05);

    svg path {
      stroke-opacity: 1;
    }
  }
`;

export const SidebarGraphics = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  z-index: -1;
`;

export const Main = styled.main`
  margin-left: 5rem;
  padding: 2rem;
  background-color: #f8fcee;
  min-height: 100vh;

  @media (min-width: 479px) {
    padding: 2rem 3rem;
  }

  @media (min-width: 1023px) {
    margin-left: ${(props): string | false => (props["data-full"] ? "5rem" : "258px")};
    padding: 2rem 3.5rem;
    text-align: left;
  }
`;

export const MainHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8fcee;
  padding: 2.375rem 0 1.5rem;
  margin: -2rem auto 1rem;
  max-width: 1280px;

  & > * {
    margin-bottom: 0;
  }

  @media (min-width: 479px) {
    flex-direction: row;
    padding-top: 2rem;
    justify-content: space-between;
  }
`;

export const MainHeaderTitle = styled.h2`
  font-family: "Raleway";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.7rem;
  color: #1a0224;
`;

export const MainHeaderNav = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;

  @media (min-width: 479px) {
    column-gap: 1rem;
    width: auto;
  }

  @media (min-width: 1023px) {
    column-gap: 1.5rem;
  }
`;

export const MainNavItem = styled.li`
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: ${(props): StyledProps =>
    `url(${props["data-src"]}) no-repeat center center / cover`};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  z-index: 2;
`;

export const MainNavDropdown = styled(Main)`
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  right: 0;
  left: 5rem;
  padding-top: 7rem;
  cursor: auto;
  margin: 0;

  ${(props): string | false =>
    props["data-collapsed"] &&
    `
    width: 0;
    left: 100%;
  `}

  @media (min-width: 479px) {
    padding-top: 5rem;
  }

  @media (min-width: 1023px) {
    left: ${(props): string | false => (props["data-full"] ? "5rem" : "258px")};
  }
`;

export const MainNavDropdownWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

export const MainDropdownContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  border-radius: 11px;
  overflow: hidden;
  padding: ${(props): StyledProps =>
    props?.["data-full-padding"] ? "2rem 2rem 1.5rem" : "0.5rem 0.75rem"};
  background-color: ${({ theme }): StyledProps => theme.background};
  box-shadow: 0px 30.8491px 61.6981px rgba(52, 64, 84, 0.14);
  margin-left: auto;
  width: 100%;
  max-width: 456px;
  height: auto;
  max-height: 68vh;
  overflow-y: auto;

  @media (min-width: 479px) {
    width: fit-content;
    margin-top: 2px;
  }
`;

export const MainDropdownHeading = styled.h3`
  margin: 1rem 0 1.75rem;
  max-width: 109px;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5%;
  color: #1a0224;
`;

export const MainDropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const MainDropdownItem = styled.li`
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  padding: 1.5rem 1rem;
  font-family: "Avenir";
  font-size: 0.75rem;
  color: #4d526d;
  max-width: 401px;
  background: #f8fced;
  border-radius: 5px;
`;

export const MainDropdownImg = styled(Image)`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

export const MainDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  & > * {
    margin: 0;
  }
`;

export const MainDropdownTitle = styled.h4`
  font-family: "Raleway";
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #1a0224;
  line-height: 1.375rem;
  color: #1a0224;
`;

export const MainDropdownDescription = styled.p`
  line-height: 1rem;
`;

export const MainDropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  text-decoration: none;
  margin-top: ${(props): StyledProps => (props?.["data-full-width"] ? "0.75rem" : "0")};
  font-family: "Proxima Nova";
  font-size: ${(props): StyledProps => (props?.["data-full-width"] ? "0.75rem" : "0.625rem")};
  line-height: ${(props): StyledProps => (props?.["data-full-width"] ? "0.75rem" : "0.625rem")};
  color: #312a50;
  border: 1px solid #312a50;
  border-radius: 0.5rem;
  padding: 0.625rem 1.25rem;
  width: ${(props): StyledProps => (props?.["data-full-width"] ? "100%" : "fit-content")};

  &:hover {
    text-decoration: none;
    color: #312a50;
  }

  &.logout {
    font-size: 1rem;
    line-height: 1.125rem;
    width: 100%;
    color: #ff0000;
    border: none;
    svg {
      width: 1rem;
      height: 1rem;

      path {
        fill: #ff0000;
      }
    }
  }
`;
