import { ChangeStatusModal } from "components/modal";
import React, { useState } from "react";
import * as S from "./styles";

export const Feedback: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <S.FeedbackBtn onClick={(): void => setShowModal(true)}>Feedback</S.FeedbackBtn>
      {showModal && <ChangeStatusModal onCancel={(): void => setShowModal(false)} />}
    </>
  );
};

export default Feedback;
