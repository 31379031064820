import { FC } from "react";
import useSidebar from "state/sidebar/use-sidebar";
import { SidebarContext } from "./sidebar.context";

export const SidebarProvider: FC = (props) => {
  const contextValue = useSidebar();

  return <SidebarContext.Provider value={contextValue}>{props.children}</SidebarContext.Provider>;
};

export default SidebarProvider;
