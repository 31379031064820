export enum Sidebar {
  Overview = "Overview",
  Availabilty = "Availabilty",
  Profile = "Profile",
  DomainTesting = "Domain Test",
  HRScreening = "HR Screening",
  ProblemSolving = "Problem Solving",
  FinalInterview = "Final Tech Interview",
  Make300 = "Make $300 🔥 ",
  Settings = "Settings",
  SignUp = "Sign up",
  Resume = "Resume",
  FinishSignUp = "Finish sign-up",
  Favorites = "Favorites",
  Talents = "Engineers",
  Terms = "Terms and Privacy Policy",
  Support = "Contact Support",
  Billing = "Billing",
  Perks = "Perks",
}

export enum SidebarStatusIcons {
  Tick = "tick",
  Normal = "normal",
  Lock = "lock",
  Cross = "cross",
}
