/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const clientReviews = [
  {
    content:
      "Remotebase always delivers - whether it is intricate tech work or just skillful client handling. Providing the right person for the right job has truly become their niche and it has been an incredible journey so far!",
    name: "Sam Udotong",
    company: "Fireflies.ai",
    clientImg: "/images/sam.png",
  },
  {
    content:
      "Remotebase has been one of my smartest hiring decisions. It has boosted our business and as per our requirements, they provided us with a team of dedicated remote developers in the US who are equally passionate about building our product into the finest one.",
    name: "Pablo Montay",
    company: "Spacetrics",
    clientImg: "/images/pablo.png",
  },
  {
    content:
      "We had to finish a project earlier than expected and needed to hire remote engineers urgently. I must commend how the Remotebase developer team rose to the occasion and pulled off this feat with dedication and some genius on-ground decision-making.",
    name: "Aditya Mishra",
    company: "QUME",
    clientImg: "/images/aditya.png",
  },
];
