import * as S from "../../styles";

export const FeedbackFailed: React.FC = () => {
  return (
    <S.FeedbackFailedContainer>
      <S.FeedbackFailedMessage>Feedback Pending</S.FeedbackFailedMessage>
    </S.FeedbackFailedContainer>
  );
};

export default FeedbackFailed;
