import womenImg from "../../assets/images/women.svg";
import handImg from "../../assets/images/hand.svg";
import hundredImg from "../../assets/images/hundred.svg";
import userDefault from "../../assets/images/userDefault.png";
import newRBLogo from "../../assets/images/new-remotebase-logo.png";
import maintenance from "../../assets/images/maintenance.png";
import bgMaintenance from "../../assets/images/bgMaintenance.png";
import talentlyLogoImg from "../../assets/images/talently-logo.svg";
import authBg from "../../assets/images/auth-bg.png";
import authBgBottom from "../../assets/images/auth-bg-bottom.png";

// eslint-disable-next-line import/prefer-default-export
export const imageUrls = {
  womenImg,
  handImg,
  hundredImg,
  userDefault,
  newRBLogo,
  maintenance,
  bgMaintenance,
  talentlyLogoImg,
  authBg,
  authBgBottom,
};
