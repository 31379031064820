import React, { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { convertToSeconds } from "utils";
import * as S from "./styles";

interface Props {
  name: string;
}

const TimeRange: FC<Props> = ({ name }) => {
  const { register, setValue } = useFormContext();

  const { startTime, endTime } = useWatch({ name });

  useEffect(() => {
    if ((convertToSeconds(startTime) || 0) > (convertToSeconds(endTime) || 0) || !endTime)
      setValue(`${name}.endTime`, startTime);
  }, [startTime, endTime]);

  return (
    <S.TimeRangeContainer>
      <S.StyledTimeSelect register={register} name={`${name}.startTime`} />
      <span>-</span>
      <S.StyledTimeSelect register={register} name={`${name}.endTime`} />
    </S.TimeRangeContainer>
  );
};
export default TimeRange;
