import styled from "styled-components";

export const MaintenanceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Platform";
`;

export const MaintenanceHeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  bottom: auto;
  left: 0px;
  right: 0px;
  padding: 32px;
  z-index: 2;
  @media only screen and (min-width: 1200px) {
    padding: 63px 32px;
  }
`;

export const MaintenanceHeaderContainer = styled.div`
  width: 100%;
  max-width: 1218px;
  margin: 0px auto;
`;

export const HeaderLogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 180px;
  max-height: 24px;
  @media only screen and (min-width: 576px) {
    max-width: 205px;
    max-height: 27px;
  }
`;

export const HeaderLogoImg = styled.img`
  max-width: 100%;
`;

export const MaintenanceBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 910px;
  margin: 91px auto 0px auto;
  padding: 7px 32px 20px 32px;
  // @media only screen and (min-width: 1200px) {
  //   margin: 153px auto 0px auto;
  // }
`;

export const MaintenanceTitle = styled.div`
  max-width: 705px;
  margin: 0px auto;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #312a50;
  @media only screen and (min-width: 576px) {
    font-size: 56px;
    line-height: 67px;
  }
`;

export const MaintenancePara = styled.div`
  max-width: 845px;
  margin: 6px auto 56px auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #312a50;
`;

export const MaintenanceMainImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 375px;
  max-height: 362px;
  margin: 0px auto;
  & img {
    max-width: 100%;
  }
`;

export const MaintenanceFloatingImgWrapper = styled.div`
  width: 100%;
`;

export const MaintenanceFloatingImg = styled.div`
  position: absolute;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  max-width: 45px;
  max-height: 45px;
  margin: 0px auto;
  & img {
    max-width: 100%;
  }
  &:nth-child(1) {
    left: 49px;
    right: auto;
    top: 182px;
    bottom: auto;
  }
  &:nth-child(2) {
    top: 53px;
    bottom: auto;
    left: 40%;
    right: auto;
  }
  &:nth-child(3) {
    top: 70%;
    bottom: auto;
    right: 104px;
    left: auto;
  }
`;
