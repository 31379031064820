import { useLazyQuery } from "@apollo/client";
import { GetBillingToggleQuery } from "@remotebase/amplify-constants/API";
import { getQuery } from "hooks/utils";
import { getBillingToggle } from "@remotebase/amplify-constants/graphql/queries";
import { ReturnPropsGetBillingToggle } from "hooks/types";

export const useBillingToggle = (completionHandler?: () => void): ReturnPropsGetBillingToggle => {
  const [performGetBillingToggle, { data: response, loading: isLoading, error, called }] =
    useLazyQuery<GetBillingToggleQuery>(getQuery(getBillingToggle), {
      fetchPolicy: "network-only",
      onCompleted: () => {
        if (completionHandler) completionHandler();
      },
    });
  const data = response?.getBillingToggle?.data ?? null;
  return {
    performGetBillingToggle,
    data,
    isLoading,
    error,
    called,
  };
};

export default useBillingToggle;
