import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledProps } from "utils";

export const LogoutBtnWrapper = styled.div`
  position: relative;

  @media (max-width: 1199px) {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const LogoutBtnBox = styled(Link)`
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 62px;
  border-radius: 12px;
  color: #11142d;
  cursor: pointer;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;

  &:hover {
    background: #fc532d;
    color: #ffffff;
    text-decoration: none;
  }

  @media (max-width: 1023px) {
    padding-left: 0;
  }
`;

export const LogoutBtnInner = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin: 2px 0;
  &:hover {
    text-decoration: none;
  }
  svg path,
  @media (max-width: 1023px) {
    padding-left: 0;
    &:hover {
      color: #808191;
    }
  }

  @media (max-width: 767px) {
    padding-left: 3px;
  }
`;

export const LogoutBtnText = styled.div`
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 600;
  color: ${(props): StyledProps => props.theme.primaryFontColor};
  ${LogoutBtnBox}:hover & {
    color: ${(props): StyledProps => props.theme.background};
  }
`;
