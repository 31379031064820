import styled from "styled-components";
import SelectTime from "../SelectTime";

export const StyledTimeSelect = styled(SelectTime)`
  outline: none;
  border: 0 !important;
  background-color: #edf0f2;
  margin: 0 !important;
  padding: 0 !important;
  width: 6.875rem !important;
  text-align: center;
`;
export const TimeDropDown = styled.select`
  background-color: #edf0f2 !important;
`;

export const TimeInput = styled.input`
  outline: none;
  border: 0 !important;
  background-color: transparent;
  margin: 0 !important;
  padding: 0 !important;
  width: 6.875rem !important;
`;

export const TimeRangeContainer = styled.div`
  background-color: #edf0f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3125rem;
  padding: 0 1rem;
  min-width: 21.875rem;
`;

export const TimeSelector = styled.div`
  display: flex;
`;

export const Day = styled.div`
  font-size: 1.125rem;
`;

export const Error = styled.div`
  width: 21.875rem;
  color: red;
`;

export const Action = styled.button`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  flex-shrink: 0;
  border: 0.125rem solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem;
  margin: 0;
  background-color: white;
`;

export const SideContent = styled.div`
  width: 3.25rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
