import styled from "styled-components";

export const CustomLabel = styled.label`
  font-size: 16px;
  line-height: 18px;
  color: #7f8a9c;
  margin-bottom: 14px;
  width: 100%;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const CustomError = styled.p`
  color: red !important;
  margin-top: -30px !important;
  font-size: 0.8rem !important;
  position: absolute;
  right: 15px;
`;

export const PhoneInputCanvas = styled.div`
  & .PhoneInput {
    position: relative;
  }
  & .PhoneInputCountry {
    position: absolute;
    top: 19px;
    left: 18px;

    @media only screen and (max-width: 767px) {
      top: 15px;
    }

    & select {
      height: auto;
      margin-bottom: 0;
    }
  }
  & .PhoneInputInput {
    padding-left: 62px !important;
  }
`;
