import React from "react";
import * as Styled from "../styles";

interface Props {
  feedback?: any | null;
}

export const ViewFeedback: React.FC<Props> = ({ feedback }) => {
  const { description } = feedback || {};
  const myOwnFeedback: any = feedback;
  return (
    <Styled.TechFeedbackWrapper>
      <>
        {description != null ? (
          <Styled.ProfileBlockDescrip
            dangerouslySetInnerHTML={{ __html: description || " " }}
          ></Styled.ProfileBlockDescrip>
        ) : (
          <Styled.ProfileBlockDescrip
            dangerouslySetInnerHTML={{ __html: myOwnFeedback || " " }}
          ></Styled.ProfileBlockDescrip>
        )}
      </>
    </Styled.TechFeedbackWrapper>
  );
};

export default ViewFeedback;
