import { useMutation } from "@apollo/client";
import { CreateAvailabilityMutation, CreateAvailabilityMutationVariables } from "API";
import { createAvailability } from "graphql/mutations";
import { UseClientAvailabilityReturnType } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

export const useClientAvailability = (): UseClientAvailabilityReturnType => {
  const [createClientAvailability, { data, loading, error }] = useMutation<
    CreateAvailabilityMutation,
    CreateAvailabilityMutationVariables
  >(getQuery(createAvailability));

  const res = data?.createAvailability;

  return {
    createClientAvailability,
    data: res,
    loading,
    error,
  };
};

export default useClientAvailability;
