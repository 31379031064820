/* eslint-disable max-len */
function MoneyIcon(): JSX.Element {
  return (
    <svg
      width="60%"
      height="60%"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10.5C3 9.70435 3.31607 8.94129 3.87868 8.37868C4.44129 7.81607 5.20435 7.5 6 7.5H30C30.7956 7.5 31.5587 7.81607 32.1213 8.37868C32.6839 8.94129 33 9.70435 33 10.5V25.5C33 26.2956 32.6839 27.0587 32.1213 27.6213C31.5587 28.1839 30.7956 28.5 30 28.5H6C5.20435 28.5 4.44129 28.1839 3.87868 27.6213C3.31607 27.0587 3 26.2956 3 25.5V10.5Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 22.5C20.4853 22.5 22.5 20.4853 22.5 18C22.5 15.5147 20.4853 13.5 18 13.5C15.5147 13.5 13.5 15.5147 13.5 18C13.5 20.4853 15.5147 22.5 18 22.5Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 13.5C4.5913 13.5 6.11742 12.8679 7.24264 11.7426C8.36786 10.6174 9 9.0913 9 7.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 28.5C27 26.9087 27.6321 25.3826 28.7574 24.2574C29.8826 23.1321 31.4087 22.5 33 22.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MoneyIcon;
