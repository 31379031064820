import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { AuthRoutes, SidebarPropsWithChildren } from "utils";
import { ArrowLeftIcon, ArrowRightIcon, SidebarGlobe } from "assets/icons";
import { withSidebar } from "state/sidebar";
import SidebarMain from "components/sidebar_copy/sidebar-main";
import SidebarNav from "components/sidebar_copy/sidebar-nav";
import { TalentProvider } from "state/talent";
import * as Styled from "./styles";

const SidebarLayout: FC<SidebarPropsWithChildren> = ({
  children,
  sidebarIsCollapsed,
  toggleSidebar,
}) => {
  const logoSrc = useMemo(
    () => `/images/logo-${sidebarIsCollapsed ? "icon" : "white"}.svg`,
    [sidebarIsCollapsed],
  );

  const ToggleIcon = useMemo(
    () => (sidebarIsCollapsed ? ArrowRightIcon : ArrowLeftIcon),
    [sidebarIsCollapsed],
  );

  return (
    <TalentProvider>
      <Styled.LayoutWrapper>
        <Styled.Sidebar data-collapsed={sidebarIsCollapsed}>
          <Styled.SidebarTop>
            <Link to={AuthRoutes.Talents}>
              <Styled.SidebarLogo src={logoSrc} alt="logo" data-collapsed={sidebarIsCollapsed} />
            </Link>
            <Styled.SidebarToggle data-collapsed={sidebarIsCollapsed} onClick={toggleSidebar}>
              <ToggleIcon />
            </Styled.SidebarToggle>
          </Styled.SidebarTop>
          <SidebarNav />
          <Styled.SidebarGraphics>
            <SidebarGlobe />
          </Styled.SidebarGraphics>
        </Styled.Sidebar>
        <SidebarMain>{children}</SidebarMain>
      </Styled.LayoutWrapper>
    </TalentProvider>
  );
};

export default withSidebar(SidebarLayout);
