import styled from "styled-components";

export const ProfileExpertiseContainer = styled.div`
  background-color: #ffffff;
  border-radius: 24px;
  width: 100%;
`;

export const ProfileExpertiseTagsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 24px;
  padding-top: 10px;
`;

export const ProfileExpertiseTitle = styled.h3`
  font-size: 18px;
  color: #11142d;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 2px;
  font-family: "Poppins600", sans-serif;
  font-style: normal;
  color: #3c3c3c;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 10px;
  }
`;

export const ProfileExpertiseTags = styled.label`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f1f2f2;
  margin-right: 15px;
  color: #262627;
  border-radius: 30px;
  font-size: 14px;
  line-height: 120%;
`;
