import { FC, Fragment } from "react";
import { BtnLoader } from "../../loader";
import { ShouldRender } from "../..";
import * as Styled from "./styles";

interface Props {
  isLoading?: boolean;
  buttonText?: string;
  passwordIsValid?: boolean;
}

export const AuthSubmitBtnClient: FC<Props> = ({ isLoading, buttonText, passwordIsValid }) => {
  const disabled = passwordIsValid !== undefined ? isLoading || !passwordIsValid : isLoading;

  return (
    <Styled.LoginBtn type="submit" disabled={disabled}>
      <ShouldRender if={!isLoading}>
        <Fragment>{buttonText}</Fragment>
      </ShouldRender>
      <ShouldRender if={isLoading}>
        <BtnLoader />
      </ShouldRender>
    </Styled.LoginBtn>
  );
};

export default AuthSubmitBtnClient;
