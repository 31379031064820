import styled from "styled-components";

export const FormGroup = styled.div`
  &.active {
    label {
      color: #898989;
    }
  }
`;

export const FormLabel = styled.label`
  color: #bfbfbf;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const FormField = styled.input`
  color: #898989;
  letter-spacing: -0.025em;
  background-color: transparent;
  border: 1px solid rgba(101, 101, 101, 0.2);
  border-radius: 3px;
  padding: 1rem 1.625rem;
  width: 100%;
  transition: all 0.25s ease;

  &::placeholder {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: rgba(66, 57, 113, 0.6);
  }

  &:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    border: 2px solid #5138ed;
    outline: none;
    color: #404040;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin: 10px 0;
  font-size: 0.8rem;
`;
