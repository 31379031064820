import styled from "styled-components";

export const TalentsWrapper = styled.div`
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Avenir", sans-serif;
  font-size: 0.875rem;
  color: #312a50;
  transition: all 0.2s ease-in-out;
`;

export const TalentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

export const BackLink = styled.a`
  font-size: 14px;
  color: #808191;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #3c1fc9;

    & svg {
      & path {
        fill: #3c1fc9;
      }
    }
  }
`;

export const BackLinkIcon = styled.span`
  width: 20px;
  height: 14px;
  display: inline-flex;
  margin-right: 4px;

  & svg {
    & path {
      fill: #808191;
    }
  }
`;

export const BackLinkText = styled.span`
  display: inline-flex;
`;
