import React, { useEffect } from "react";
import { AuthProps, CustomerSuccessEmail, SupportEmail, autoScrollOnTop } from "utils";
import AOS from "aos";
import DashboardComponent from "components/dashboard";
import { withAuth } from "state/auth";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";
import { useSendEmail } from "hooks";
import { EmailTypes } from "@remotebase/amplify-constants/API";
import * as Styled from "./styles";

const Dashboard: React.FC<ITalentProps & AuthProps> = ({
  talentState,
  authState,
  setAuthState,
}) => {
  const { sendEmail } = useSendEmail();

  useEffect(() => {
    autoScrollOnTop();
    AOS.init();
  }, []);

  useEffect(() => {
    if (talentState?.client && authState?.shouldContactSupport)
      sendEmail({
        variables: {
          input: {
            toEmail: `${SupportEmail},${CustomerSuccessEmail}`,
            emailType: EmailTypes.ClientFirstLogin,
            messageData: JSON.stringify({
              clientName: talentState?.client?.fullName,
              companyName: talentState?.client?.company?.name,
              email: talentState?.client?.email,
            }),
          },
        },
      });
    return (): void => setAuthState({ ...authState, shouldContactSupport: false });
  }, [talentState?.client, authState?.shouldContactSupport]);

  return (
    <Styled.DashboardWrapper>
      <Styled.DashboardInner>
        <DashboardComponent />
      </Styled.DashboardInner>
    </Styled.DashboardWrapper>
  );
};

export default withAuth(withTalent(Dashboard));
