import { Auth } from "@aws-amplify/auth";
import { useCallback, useContext, useState } from "react";
import {
  ApiCustomHookStateType,
  ApiHookReturnType,
  apiInitialState,
  getSuccessResponse,
  getErrorResponse,
  ISignInResponse,
  ISetPasswordPayload,
} from "hooks/utils";
import { loginErrorHeading } from "utils";
import { updateErrorState } from "@remotebase/components";
import { IErrorContextType } from "@remotebase/constants";
import ErrorContext from "state/error/error.context";

export const useNewPassword = (): ApiHookReturnType<ISignInResponse, ISetPasswordPayload> => {
  const [res, setRes] = useState<ApiCustomHookStateType<ISignInResponse>>(apiInitialState);
  const { setErrorState, errorState } = useContext<IErrorContextType>(ErrorContext);

  const setNewPassword = useCallback(
    async (payload: ISetPasswordPayload): Promise<void> => {
      const { password, user } = payload;
      setRes({ ...apiInitialState, isLoading: true });
      try {
        const response: ISignInResponse = await Auth.completeNewPassword(user, password);
        setRes(getSuccessResponse(response));
      } catch (error) {
        const { message } = error;
        setRes(getErrorResponse(message));
        updateErrorState({ title: loginErrorHeading, message }, setErrorState);
      }
    },
    [errorState],
  );
  return { res, performAction: setNewPassword };
};

export default useNewPassword;
