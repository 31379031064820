import { FC, ReactNode, useMemo, useState } from "react";
import { codeToCountry } from "@remotebase/constants";
import { ShouldRender } from "@remotebase/components";
import { TechDomainSkillsProgress } from "@remotebase/amplify-constants/API";
import { ArrowUp } from "../../assets/icons";
import * as Styled from "../styles";
import { imageUrls } from "../../utils";

export const availbilityOptions = [
  { value: "available", label: "Available for work" },
  { value: "unavailable", label: "Not Available" },
];

export const markAvailable = "available";

export interface ProfileInfoSectionProps {
  imageLink?: string | null;
  title?: string | null;
  fullName?: string;
  country?: string | null;
  children?: ReactNode;
  domainTests: (TechDomainSkillsProgress | null)[] | null | undefined;
  jobMatchVersion?: number;
  jobMatchId?: string;
  skillScore?: number;
  feedbackLength: number;
  expectedSalary?: string;
  isAvailable?: boolean;
  saveAvailability?: (availability: boolean) => void;
  FeedbackComponent?: FC<ProfileInfoSectionProps>;
}
export const ProfileInfoSection: FC<ProfileInfoSectionProps> = (props) => {
  const { FeedbackComponent, ...rest } = props;
  const { children, fullName, title, imageLink, country, isAvailable, saveAvailability } = rest;
  // domainTests
  // const skillScore = useMemo(() => getOverallTestScore(props.domainTests), [domainTests]);

  const availabilityValue = useMemo(
    () => availbilityOptions.find((it) => (it.value === markAvailable) === isAvailable)?.label,
    [isAvailable],
  );

  const [showOptions, setShowOptions] = useState(false);

  const showAvailabilityOptions = (): void => {
    if (saveAvailability) setShowOptions(!showOptions);
  };

  const saveAvailabilityOption = (availability: boolean): void => {
    if (saveAvailability) {
      saveAvailability?.(availability);
      setShowOptions(!showOptions);
    }
  };

  return (
    <Styled.EditTalentInfoTop>
      <Styled.EditTalentInfoTopLeft>
        <Styled.EditTalentAvatarWrapper>
          <Styled.EditTalentAvatar>
            <img src={imageLink ?? imageUrls.userDefault} alt="profile-img" />
            {children}
          </Styled.EditTalentAvatar>
          <Styled.EditTalentAvatarBadgeWrapper>
            <Styled.EditTalentAvatarBadge
              onClick={showAvailabilityOptions}
              $isEditable={!!saveAvailability}
              $availabilityValue={availabilityValue}
            >
              {availabilityValue}{" "}
              {saveAvailability && (
                <Styled.SvgArrowDown>
                  <ArrowUp />
                </Styled.SvgArrowDown>
              )}
            </Styled.EditTalentAvatarBadge>
            <ShouldRender if={showOptions}>
              <Styled.AvailabiltyOptionsDiv>
                {availbilityOptions.map((option, index) => {
                  const availability = option.value === markAvailable;
                  return (
                    <Styled.AvailabiltyOptions
                      onClick={(): void => saveAvailabilityOption(availability)}
                      key={`availability-${index}`}
                    >
                      {option.label}
                    </Styled.AvailabiltyOptions>
                  );
                })}
              </Styled.AvailabiltyOptionsDiv>
            </ShouldRender>
          </Styled.EditTalentAvatarBadgeWrapper>
        </Styled.EditTalentAvatarWrapper>
        <Styled.EditTalentDetail>
          <Styled.EditTalentInfo>
            <Styled.EditTalentName>{fullName}</Styled.EditTalentName>
            {children}
          </Styled.EditTalentInfo>
          <Styled.EditTalentDesignation>
            {title ?? "Talent"} {country ? `• ${codeToCountry[country]}` : ""}
          </Styled.EditTalentDesignation>
          <Styled.EditTalentScore>
            {/* <span> {skillScore}% </span> Skill Score */}
          </Styled.EditTalentScore>
        </Styled.EditTalentDetail>
      </Styled.EditTalentInfoTopLeft>
      <ShouldRender if={Boolean(FeedbackComponent)}>
        {FeedbackComponent ? <FeedbackComponent {...rest} /> : null}
      </ShouldRender>
    </Styled.EditTalentInfoTop>
  );
};

export default ProfileInfoSection;
