import { FC, Fragment } from "react";
import { EducationEntry } from "@remotebase/amplify-constants/API";
import { CrossIcon, EditRoundedIcon } from "../../assets/icons";
import { extractMonthAndYear } from "../../utils";
import { ShouldRender } from "../../shouldRender";
import * as Styled from "../styles";

interface Props {
  educationHistory: Array<EducationEntry | null>;
  addEducationBtn?: React.ReactNode;
  editEducation?: (index: number, education: EducationEntry) => void;
  deleteEducation?: (id: number) => void;
  isAdmin?: boolean;
}
export const EducationSection: FC<Props> = ({
  educationHistory,
  addEducationBtn,
  deleteEducation,
  editEducation,
  isAdmin,
}) => {
  const deleteHandler = (e: React.MouseEvent, index: number): void => {
    e.stopPropagation();
    deleteEducation?.(index);
  };
  return (
    <Fragment>
      <Styled.EducationDetailTop>
        <Styled.EducationDetailTitle>Education</Styled.EducationDetailTitle>
        {addEducationBtn}
      </Styled.EducationDetailTop>
      <Styled.EducationDetailList>
        {educationHistory.length > 0 ? (
          educationHistory?.map(
            (education, index) =>
              education && (
                <Styled.EducationDetailItem key={`education-${index}`}>
                  <ShouldRender if={isAdmin}>
                    <Styled.ModifyTopIcon>
                      <CrossIcon onClick={(e): void => deleteHandler(e, index)} />
                      <EditRoundedIcon onClick={(): void => editEducation?.(index, education)} />
                    </Styled.ModifyTopIcon>
                  </ShouldRender>
                  <Styled.EducationInner>
                    <Styled.EducationDetailSbHd>{education?.school}</Styled.EducationDetailSbHd>
                  </Styled.EducationInner>
                  <Styled.EducationDetailPara>{education?.degree},</Styled.EducationDetailPara>
                  <Styled.EducationDetailPara>
                    {`${extractMonthAndYear(education?.startDate)} -
                  ${extractMonthAndYear(education?.endDate)}`}
                  </Styled.EducationDetailPara>
                </Styled.EducationDetailItem>
              ),
          )
        ) : (
          <Styled.NoEducation>No Data Available</Styled.NoEducation>
        )}
      </Styled.EducationDetailList>
    </Fragment>
  );
};

export default EducationSection;
