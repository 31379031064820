/* eslint-disable max-len */

function CalendarIcon(): JSX.Element {
  return (
    <svg
      width="60%"
      height="60%"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 6H9C5.68629 6 3 8.68629 3 12V27C3 30.3137 5.68629 33 9 33H27C30.3137 33 33 30.3137 33 27V12C33 8.68629 30.3137 6 27 6Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3V9"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 3V9"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 15H33"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarIcon;
