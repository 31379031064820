import { Link } from "react-router-dom";
import styled from "styled-components";

export const BannerImgBox = styled.div`
  margin-top: -36px;
  font-size: 0;
`;

export const SidebarBanner = styled.div`
  position: relative;
  margin-top: 86px;
  padding: 8px 14px 24px;
  border-radius: 16px;
  background: #cfc8ff;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: -8px;
    z-index: -1;
    border-radius: 12px;
    background: rgba(207, 200, 255, 0.5);
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`;

export const BannerText = styled.div`
  font-size: 14px;
  color: #7365d2;
  font-weight: 500;
  text-align: left;
  max-width: 150px;
  margin: 20px auto;
`;
export const SlackAnchorTag = styled(Link)`
  text-decoration: none !important;
`;

export const JoinSlackBtn = styled.button`
  font-size: 14px;
  color: #5f75ee;
  font-weight: 700;
  background-color: #fff;
  border-radius: 16px;
  min-height: 56px;
  width: 100%;
  border: none;
  box-shadow: none;

  &:hover {
    color: #6c5dd3;
    border: none;
    box-shadow: none;
    background-color: #fff;
  }
`;
