import { FC } from "react";
import { SidebarType as Type, Sidebar, SidebarStatusIcons, AuthRoutes as Route } from "utils";
import MainDropdownProfile from "components/sidebar_copy/main-dropdown-profile";
// import MainDropdownNotifications from "components/sidebar_copy/main-dropdown-notifications";
import { ISidebarMenu } from "./sidebar.interface";
import {
  CodeTestingIcon,
  HRScreeningIcon,
  Make250Icon,
  OverviewIcon,
  ProfileIcon,
  SettingsIcon,
  LiveCodingIcon,
  OfferIcon,
  HeartThree,
  TalentsIcon,
  SupportIcon,
  TermsIcon,
  NotificationIcon,
  PerksIcon,
} from "../../assets/icons";

export const sidebarIcons = {
  [Sidebar.Overview]: <OverviewIcon />,
  [Sidebar.Favorites]: <HeartThree />,
  [Sidebar.Availabilty]: <ProfileIcon />,
  [Sidebar.Profile]: <ProfileIcon />,
  [Sidebar.DomainTesting]: <CodeTestingIcon />,
  [Sidebar.HRScreening]: <HRScreeningIcon />,
  [Sidebar.ProblemSolving]: <LiveCodingIcon />,
  [Sidebar.FinalInterview]: <OfferIcon />,
  [Sidebar.Make300]: <Make250Icon />,
  [Sidebar.Settings]: <SettingsIcon />,
  [Sidebar.SignUp]: <OverviewIcon />,
  [Sidebar.Resume]: <CodeTestingIcon />,
  [Sidebar.FinishSignUp]: <HRScreeningIcon />,
  [Sidebar.Talents]: <TalentsIcon />,
  [Sidebar.Support]: <SupportIcon />,
  [Sidebar.Terms]: <TermsIcon />,
  [Sidebar.Billing]: <TermsIcon />,
  [Sidebar.Perks]: <PerksIcon />,
};

export const getStatus = (index: number, stageLevel: number): string => {
  if (stageLevel < 0 && index === Math.abs(stageLevel) - 1) {
    return SidebarStatusIcons.Cross;
  }
  if (index > -1 && index < Math.abs(stageLevel)) {
    return SidebarStatusIcons.Tick;
  }
  if (
    (stageLevel > -1 && index > stageLevel) ||
    (stageLevel < 0 && index > Math.abs(stageLevel) - 1)
  ) {
    return SidebarStatusIcons.Lock;
  }
  return SidebarStatusIcons.Normal;
};

export const getSidebarIcon = (element: Sidebar): JSX.Element | null =>
  ({
    [Sidebar.Talents]: sidebarIcons[Sidebar.Talents],
    [Sidebar.Support]: sidebarIcons[Sidebar.Support],
    [Sidebar.Terms]: sidebarIcons[Sidebar.Terms],
    [Sidebar.Billing]: sidebarIcons[Sidebar.Billing],
    [Sidebar.Perks]: sidebarIcons[Sidebar.Perks],
  }[element] || null);

export enum SidebarDropdown {
  Profile = "profile",
  Notification = "notification",
}

export const getSidebarDropdownIcon = (name: SidebarDropdown): FC | null =>
  ({
    [SidebarDropdown.Notification]: NotificationIcon,
  }[name] || null);

export const LAPTOP_VIEW = 1023;

export const mainNavItems = {
  // [SidebarDropdown.Notification]: MainDropdownNotifications,
  // TODO: in future to enable notification have to uncomment this
  [SidebarDropdown.Profile]: MainDropdownProfile,
};

export const sidebarRoutes = {
  [Sidebar.Talents]: Route.Talents,
  [Sidebar.Terms]: Route.Terms,
  [Sidebar.Billing]: Route.Billing,
  [Sidebar.Support]: Route.Support,
  [Sidebar.Perks]: Route.Perks,
};

export const SidebarProfileOptions = [
  Sidebar.DomainTesting,
  Sidebar.ProblemSolving,
  Sidebar.HRScreening,
  Sidebar.FinalInterview,
];

export const SidebarCreateProfileOptions = [Sidebar.Resume, Sidebar.FinishSignUp];

export const sidebarMenu: ISidebarMenu = {
  [Type.Dashboard]: [
    Sidebar.Talents,
    Sidebar.Billing,
    Sidebar.Perks,
    Sidebar.Terms,
    Sidebar.Support,
  ],
  [Type.DashboardWithoutBilling]: [Sidebar.Talents, Sidebar.Perks, Sidebar.Terms, Sidebar.Support],
  [Type.Create_Profile]: [Sidebar.SignUp, SidebarCreateProfileOptions, Sidebar.Perks],
};
