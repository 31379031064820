import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import "./styles/custom.css";

const StyledModal = styled(Modal)`
  position: absolute;
  z-index: 600;

  .modal-dialog {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
  }

  .modal-content {
    height: 100%;
    width: 50vw;
    border-radius: 0;
    z-index: 600;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 0;
  position: relative;
  height: 100%;
  z-index: 600;
`;

export const Popup: React.FC<{ show: boolean; onHide: () => void }> = ({
  show,
  onHide,
  children,
}) => {
  return (
    <StyledModal backdropClassName="custom-backdrop" show={show} onHide={onHide}>
      <StyledModalBody>{children}</StyledModalBody>
    </StyledModal>
  );
};

export default Popup;
