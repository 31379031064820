import { Fragment } from "react";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types/talent.interface";
import { TalentsListShimmer } from "shimmerPages";
import { ShouldRender } from "@remotebase/components";
import TalentListPagination from "./components/pagination";
import * as Styled from "./styles";

const TalentComponent: React.FC<ITalentProps> = ({ talentState, interviewingOnly }) => {
  return (
    <Fragment>
      <Styled.DashboardHead>
        <Styled.DashboardHeading>
          Hello {talentState?.client?.fullName}! Welcome to your RemoteBase Dashboard.
        </Styled.DashboardHeading>
        <Styled.DashboardPara>
          Select your preferred candidate based on your job posting.
        </Styled.DashboardPara>
      </Styled.DashboardHead>
      <ShouldRender if={talentState?.loading}>
        <TalentsListShimmer />
      </ShouldRender>
      <ShouldRender if={!talentState?.loading}>
        <TalentListPagination interviewingOnly={interviewingOnly} />
      </ShouldRender>
    </Fragment>
  );
};

export default withTalent(TalentComponent);
