import React, { useState } from "react";
import { ITalentStateType } from "state/types/talent.interface";
import { initialTalentContext } from "utils";
import { TalentContext } from "./talent.context";

export const TalentProvider: React.FC = (props) => {
  const [talentState, setTalentState] = useState<ITalentStateType>(initialTalentContext);

  return (
    <TalentContext.Provider value={{ talentState, setTalentState }}>
      {props.children}
    </TalentContext.Provider>
  );
};

export default TalentProvider;
