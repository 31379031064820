import {
  FullPageLoader,
  ShouldRender,
  ProfileInfoSection,
  ProfileSkillSection,
  ProfileInnerDetailsSection,
  SummarySection,
  EducationSection,
  EmploymentSection,
} from "@remotebase/components";
import { Country } from "@remotebase/amplify-constants/API";
// TODO: Uncomment this when certification section is ready
// import { AwardIcon, VerifyTickIcon } from "assets/icons";
import { FC, Fragment } from "react";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";
import { useGetJobMatch } from "hooks";
import FeedbackWrapper from "components/TalentFeedback";
import * as Styled from "./styles";

const TalentsProfileView: FC<ITalentProps> = (props) => {
  const { currentTalent } = useGetJobMatch(props);
  const country = currentTalent?.talent?.country as Country;
  const talentProfileVersion = currentTalent?.talentProfileVersion;

  return (
    <Fragment>
      <ShouldRender if={!currentTalent}>
        <FullPageLoader />
      </ShouldRender>
      <ShouldRender if={currentTalent}>
        <Styled.ProfilePageBody>
          <Styled.ProfilePageSection>
            <ProfileInfoSection
              fullName={talentProfileVersion?.talent?.fullName}
              country={country}
              title={talentProfileVersion?.title}
              imageLink={talentProfileVersion?.imgLink}
              domainTests={talentProfileVersion?.talent?.profile?.takenDomainTests}
              jobMatchVersion={currentTalent?.version}
              jobMatchId={currentTalent?.id}
              expectedSalary={talentProfileVersion?.expectedSalary || ""}
              FeedbackComponent={FeedbackWrapper}
              feedbackLength={currentTalent?.feedback ? 1 : 0}
              isAvailable={talentProfileVersion?.isAvailable}
            />
            <ProfileSkillSection skills={talentProfileVersion?.skills || []} />
          </Styled.ProfilePageSection>
          <Styled.ProfilePageSection>
            <SummarySection summary={talentProfileVersion?.bio || ""} client={true} />
          </Styled.ProfilePageSection>
          {/*  TODO: Uncomment this when certification section is ready
          <Styled.ProfilePageSection>
            <Styled.ProfilePageTitle>Certification</Styled.ProfilePageTitle>
            <Styled.ProfileCertificateContainer>
              {talentProfileVersion?.certifications.map(({ name, verifyingBody }) => (
                <Styled.ProfileCertificate key={name}>
                  <Styled.ProfileCertificateIcon>
                    <AwardIcon />
                  </Styled.ProfileCertificateIcon>
                  <Styled.ProfileCertificateDescription>
                    <Styled.ProfileCertificateTitle>{name}</Styled.ProfileCertificateTitle>
                    <Styled.ProfileCertificateSubtitle>
                      <span>Verified by {verifyingBody}</span>
                      <VerifyTickIcon />
                    </Styled.ProfileCertificateSubtitle>
                  </Styled.ProfileCertificateDescription>
                </Styled.ProfileCertificate>
              ))}
            </Styled.ProfileCertificateContainer>
          </Styled.ProfilePageSection> */}
          <Styled.ProfilePageSection>
            <EducationSection educationHistory={talentProfileVersion?.educationHistory || []} />
          </Styled.ProfilePageSection>
          <Styled.ProfilePageSection>
            <EmploymentSection employmentHistory={talentProfileVersion?.employmentHistory || []} />
          </Styled.ProfilePageSection>
          <Styled.DetailsSectionWrapper>
            <ProfileInnerDetailsSection
              expectedSalaryInUsd={talentProfileVersion?.expectedSalaryInUsd}
              experience={talentProfileVersion?.experience || ""}
              englishProficiency={talentProfileVersion?.englishProficiency || ""}
            />
          </Styled.DetailsSectionWrapper>
        </Styled.ProfilePageBody>
      </ShouldRender>
    </Fragment>
  );
};

export default withTalent(TalentsProfileView);
