import styled from "styled-components";

export const NumberInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
`;

export const NumberInputField = styled.input`
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;

  font-size: 16px;
  color: #292930;
  margin-bottom: 0 !important;
  border-radius: 27.5px !important;

  ${(props) =>
    props.disabled &&
    `
    background-color: #ededed
  `}
`;
