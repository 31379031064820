import { Fragment } from "react";
import { SupportHero, SupportForm } from "./components";
// SupportMedium

const SupportComponent: React.FC = () => {
  return (
    <Fragment>
      <SupportHero />
      <SupportForm />
      {/* <SupportMedium /> */}
    </Fragment>
  );
};

export default SupportComponent;
