import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const VideoHeader = styled.div`
  width: 170px;
  height: 22px;

  font-family: "Poppins600", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;

  color: #212121;

  flex: none;
  order: 0;
  flex-grow: 0;
`;
