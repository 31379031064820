import withApolloProvider from "hooks/apollo/withApollo";
import { useContext, useEffect, useMemo, useState } from "react";
import { JobMatchTalent, ITalentContextType, ITalentProps } from "state/types/talent.interface";
import { initialTalentContext } from "utils";
import { useFetchClient, useJobsByCID, useTalentsByJID } from "hooks";
import { Auth } from "aws-amplify";
import { TalentContext } from "./talent.context";

interface EnginerHocProps {
  shouldCallApi?: boolean;
  interviewingOnly?: boolean;
}
interface IjobIds {
  id?: string;
  name?: string;
}

export function withTalent<T>(
  Component: React.FC<T & EnginerHocProps & ITalentProps>,
): React.FC<T & EnginerHocProps> {
  return withApolloProvider((props: T & EnginerHocProps) => {
    const [jobIds, setJobsIds] = useState<IjobIds[]>([]);
    const [apiCaller, setApiCaller] = useState<boolean>(false);
    const [jobMatchTalents, setJobMatchTalents] = useState<JobMatchTalent[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const { user } = Auth.Credentials.Auth;

    const userSub = useMemo(() => user?.attributes?.sub, [user]);

    let { shouldCallApi } = props;
    const { data: clientData, fetchClient, loading: clientLoading } = useFetchClient();
    const { talentState, setTalentState } = useContext<ITalentContextType>(TalentContext);
    const { getJobsByCID, jobs: jobsList, loading: fetchJobsLoading } = useJobsByCID();
    const {
      getTalentsByJID,
      talentsData: talentListsData,
      loading: fetchTalentsLoading,
    } = useTalentsByJID();

    const callAPI = (companyID): void => {
      setTalentState({ ...talentState, loading: true });
      getJobsByCID({
        variables: {
          companyID,
        },
      });
    };

    useEffect(() => {
      if (!fetchJobsLoading && jobsList) {
        const ids: IjobIds[] = [];
        jobsList.forEach((jobListObj) => {
          ids.push({ id: jobListObj?.id, name: jobListObj?.title });
        });
        setJobsIds([...ids]);
      } else if (!fetchJobsLoading && !jobsList) {
        setTalentState((prevState) => {
          return {
            ...prevState,
            loading: false,
          };
        });
      }
    }, [jobsList, fetchJobsLoading]);

    useEffect(() => {
      if (jobIds.length > 0 && currentIndex < jobIds.length) {
        getTalentsByJID({
          variables: {
            jobID: jobIds[currentIndex].id,
            filter: {
              isPublished: { eq: true },
            },
          },
        });
      }
    }, [jobIds, apiCaller]);

    useEffect(() => {
      if (!fetchTalentsLoading && talentListsData) {
        setJobMatchTalents([
          ...jobMatchTalents,
          {
            id: jobIds[currentIndex]?.id,
            name: jobIds[currentIndex]?.name,
            talent: talentListsData.map((talent) => talent?.talentProfileVersion),
            feedback: talentListsData?.map((talent) => talent?.feedback),
            jobMatchIds: talentListsData?.map((talent) => talent?.id),
          },
        ]);
        setCurrentIndex(currentIndex + 1);
        if (currentIndex < jobIds.length) {
          setApiCaller((apiCallerPrev) => !apiCallerPrev);
        }
      }
    }, [talentListsData, fetchTalentsLoading]);
    useEffect(() => {
      if (currentIndex === jobIds.length && jobMatchTalents.length > 0) {
        setTalentState((prevState) => {
          return {
            ...prevState,
            JobMatchTalents: jobMatchTalents,
            loading: false,
          };
        });
      } else if (jobMatchTalents.length > 0) {
        setTalentState((prevState) => {
          return {
            ...prevState,
            JobMatchTalents: jobMatchTalents,
            loading: true,
          };
        });
      }
    }, [jobMatchTalents]);

    const cleanTalentState = (): void => {
      setTalentState({ ...initialTalentContext });
    };

    useEffect(() => {
      if (clientLoading)
        setTalentState((previous) => ({
          ...previous,
          loading: clientLoading,
        }));
      shouldCallApi = true;
    }, [clientLoading]);

    useEffect(() => {
      if (clientData && !clientLoading) {
        callAPI(clientData.companyID);
        setTalentState((prevState) => ({
          ...prevState,
          client: clientData,
        }));
      }
    }, [clientData, clientLoading]);

    useEffect(() => {
      if (userSub && !talentState.client && shouldCallApi) {
        fetchClient({
          variables: {
            id: userSub,
          },
        });
      }
    }, []);
    const talentProps: ITalentProps = {
      talentState: {
        ...talentState,
      },
      cleanTalentState,
      setTalentState,
    };
    return <Component {...props} {...talentProps} />;
  });
}
export default withTalent;
