import styled from "styled-components";
import { StyledProps } from "../../../utils";

export const InterestContainer = styled.div`
  background-color: #ffffff;
  overflow: scroll;
  overflow: hidden;
  width: 100%;
`;

export const InterestTagsContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
`;

export const InterestTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 2px;
  font-family: "Poppins600", sans-serif;
  font-style: normal;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 10px;
  }
`;

export const InterestTags = styled.label<{
  isEditing?: boolean;
  isSelected?: boolean;
}>`
  padding: 0.25rem 1rem;
  background-color: ${(props): StyledProps =>
    props.isEditing && props.isSelected ? "#3615bd" : "#f1f2f2"};
  color: ${(props): StyledProps => (props.isEditing && props.isSelected ? "#f1f2f2" : "#262627")};
  border-radius: 30px;
  font-size: 14px;
  position: relative;
  &{label} {
    margin-bottom: 0;
  }
  &:hover {
    cursor: ${(props): StyledProps => (props.isEditing ? "pointer" : "none")};
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  float: right;
`;

export const SaveButton = styled.button`
  position: relative;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: #3f73f6 !important;
  border: none;
  transition: all 0.25s;
  margin-left: 5px;

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff !important;
      stroke: #fff !important;
    }
    & rect {
      fill: #fff;
    }
    & circle {
      fill: #3f73f6;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }
`;

export const EditTagsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
`;

export const EditInterestContainer = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin-top: 0.625rem;
  padding: 0.5rem 1rem;
`;
