import styled from "styled-components";
import { StyledProps } from "@remotebase/constants";

export const PillCover = styled.div`
  border-radius: 2rem;
  border: ${(props): StyledProps => `1px solid ${props.color}`};
  padding: 0.35rem 1.25rem;
  display: flex;
  column-gap: 0.5rem;

  * {
    font-family: "Roboto400", sans-serif;
  }
`;

export const PillIcon = styled.div`
  height: 100%;
  width: 1rem;
`;

export const PillText = styled.p`
  color: ${(props): StyledProps => `${props.color}`};
  font-weight: 400;
  margin-bottom: 0;
`;
