import styled from "styled-components";

export const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fbf9f3;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 28px;
  font-family: "Poppins", sans-serif;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export default ProfileBlock;
