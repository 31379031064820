import React, { Dispatch, SetStateAction, useState } from "react";
import { ShouldRender } from "@remotebase/components";
import FeedbackComplete from "./FeedbackComplete";
import FeedbackForm from "./FeedbackForm";
import * as S from "./styles";

interface Props {
  jobMatchId?: string;
  version?: number;
  imgLink?: string | null;
  expectedSalary?: string;
  skillScore?: number;
  name?: string;
  role?: string | null;
  closeFeedbackPopup: () => void;
  currentView: number;
  setCurrentView: Dispatch<SetStateAction<number>>;
}

export const TalentFeedback: React.FC<Props> = ({
  name,
  role,
  imgLink,
  expectedSalary,
  skillScore,
  jobMatchId,
  version,
  closeFeedbackPopup,
}) => {
  const [isRejected, setIsRejected] = useState();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  return (
    <S.Main>
      <ShouldRender if={!feedbackSubmitted}>
        <FeedbackForm
          name={name}
          role={role}
          imgLink={imgLink}
          jobMatchId={jobMatchId}
          version={version}
          isRejected={isRejected}
          setIsRejected={setIsRejected}
          handleIsSubmitted={setFeedbackSubmitted}
        />
      </ShouldRender>

      <ShouldRender if={feedbackSubmitted}>
        <FeedbackComplete
          name={name}
          imgLink={imgLink}
          expectedSalary={expectedSalary}
          skillScore={skillScore}
          isSelected={isRejected === false}
          closeFeedbackPopup={closeFeedbackPopup}
        />
      </ShouldRender>
    </S.Main>
  );
};

export default TalentFeedback;
