import React from "react";
import { upperFirst } from "lodash";
import { UseFormReturn } from "react-hook-form";
import { AuthForm } from "../utils";
import * as Styled from "./styles";

interface Props {
  type?: string;
  label?: string;
  title: AuthForm;
  handler: UseFormReturn;
  placeholder: string;
}
export const AuthenticationInput: React.FC<Props> = ({
  label,
  title,
  type,
  handler,
  placeholder,
}) => {
  const {
    register,
    formState: { errors },
  } = handler;
  return (
    <Styled.FormGroup>
      <Styled.FormLabel>{label}</Styled.FormLabel>
      <Styled.FormField type={type} placeholder={placeholder} {...register(title)} />
      <Styled.ErrorMessage>{upperFirst(errors[title]?.message)}</Styled.ErrorMessage>
    </Styled.FormGroup>
  );
};

export default AuthenticationInput;
