import { MutationFunctionOptions, useMutation } from "@apollo/client";
import {
  WithDrawACHMandateMutation,
  WithDrawACHMandateMutationVariables,
} from "@remotebase/amplify-constants/API";
import { getQuery } from "hooks/utils";
import { withDrawACHMandate } from "@remotebase/amplify-constants/graphql/mutations";
import { ReturnPropsWithdrawMandate } from "./types";

export const useWithdrawMandate = (
  completionHandler?: () => void,
): ReturnPropsWithdrawMandate<
  MutationFunctionOptions<WithDrawACHMandateMutation, WithDrawACHMandateMutationVariables>
> => {
  const [withDrawMandate, { data: response, loading: isLoading, error }] = useMutation<
    WithDrawACHMandateMutation,
    WithDrawACHMandateMutationVariables
  >(getQuery(withDrawACHMandate), {
    onCompleted: () => {
      if (completionHandler) completionHandler();
    },
  });
  // const jsonString = response?.withDrawACHMandate;
  const data = response?.withDrawACHMandate;
  return {
    withDrawMandate,
    data,
    isLoading,
    error,
  };
};

export default useWithdrawMandate;
