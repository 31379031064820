import React, { useState } from "react";
import { AuthRoutes as Route } from "utils";
import { withSidebar } from "state/sidebar";
import SidebarList from "./SidebarList";
import * as Styled from "./styles";
import SidebarTop from "./SidebarTop";
import SidebarLogoutBtn from "./SidebarLogoutBtn";

export type SidebarActiveType = {
  active: string;
};

export const Sidebar: React.FC = () => {
  const [toggle, setToggle] = useState(false);
  const handleClick = (): void => {
    setToggle(!toggle);
  };
  return (
    <Styled.SidebarWrapper>
      <Styled.Sidebar active={toggle ? "active" : ""}>
        <SidebarTop handleClick={handleClick} toggle={toggle} />
        <Styled.SIDEBAR_WRAPPER>
          <Styled.SidebarInner>
            <Styled.SidebarLogo to={Route.Dashbord}>
              <Styled.SidebarPic src="img/logo-sm.png" alt="" />
            </Styled.SidebarLogo>
            <Styled.SidebarList>
              <SidebarList />
            </Styled.SidebarList>
            {/* <SidebarBanner /> */}
            <SidebarLogoutBtn />
          </Styled.SidebarInner>
        </Styled.SIDEBAR_WRAPPER>
      </Styled.Sidebar>
    </Styled.SidebarWrapper>
  );
};

export default withSidebar(Sidebar);
