import { matchStage } from "@remotebase/amplify-constants/API";
import {
  FullPageLoader,
  ShouldRender,
  ViewProfile,
  ProfileResumeDownload,
  ProfileFeedbackDetails,
} from "@remotebase/components";
import { FC, Fragment } from "react";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";
import Feedback from "./Feedback";
import InviteInterView from "./InviteInterView";
import ProfileSpecs from "./ProfileSpecs";
import * as Styled from "./styles";

const TalentsProfileView: FC<ITalentProps> = ({ talentState }) => {
  const { talent, job, stage } = talentState.currentTalent || {};
  const { loading } = talentState;

  return (
    <Fragment>
      <ShouldRender if={loading || !talentState.currentTalent}>
        <FullPageLoader />
      </ShouldRender>
      <ShouldRender if={!loading && talentState.currentTalent}>
        <Fragment>
          {job && (
            <ViewProfile data={talent || null} jobTitle={job?.title} stage={stage}>
              <Feedback />
              {stage !== matchStage.onboarded && stage !== matchStage.rejected && (
                <InviteInterView />
              )}
            </ViewProfile>
          )}
          <Styled.ProfilePageBody>
            <ProfileFeedbackDetails data={talent || null}>
              <ProfileSpecs />
            </ProfileFeedbackDetails>
            <ProfileResumeDownload data={talent || null} />
          </Styled.ProfilePageBody>
        </Fragment>
      </ShouldRender>
    </Fragment>
  );
};

export default withTalent(TalentsProfileView);
