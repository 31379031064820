import { FC } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import cls from "classnames";
import { useForm } from "react-hook-form";
import { SharedFormProps } from "./types";
import { renderFormFields } from "./utils";

export default function SharedForm({
  formId,
  fields,
  onSubmit,
  resolver,
  defaultValues,
  children,
  submitBtn,
  resetOnSuccess,
}: SharedFormProps): ReturnType<FC> {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver, defaultValues, mode: "onChange" });

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleSubmit(onSubmit)();
    if (resetOnSuccess) reset();
  };

  return (
    <form id={formId} onSubmit={onSubmitHandler} data-hs-cf-bound>
      {fields.map((field) => (
        <Form.Group
          key={field.name}
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="0"
          controlId={`${formId}${field.name}}`}
          className={cls("aos-item", { hidden: field.hidden })}
        >
          {field?.["aria-label"] && (
            <Form.Label>
              {field?.["aria-label"]}
              {field?.required && " *"}
            </Form.Label>
          )}
          {renderFormFields(field, register, errors, control)}
          {field?.name && (
            <Form.Text className="text-danger text-capitalize">
              {errors[field.name]?.message}
            </Form.Text>
          )}
        </Form.Group>
      ))}
      {children}
      <ButtonGroup>
        <Button type="submit" disabled={!isValid || submitBtn.disabled}>
          {submitBtn.value}
        </Button>
      </ButtonGroup>
    </form>
  );
}
