import styled from "styled-components";

export const PerksWelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff9f2;
  border: 1px solid #f4f3f6;
  padding: 38px 54px;
  grid-gap: 10px;
  margin: 0 -2rem;

  @media only screen and (min-width: 479px) {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  @media only screen and (min-width: 1023px) {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
`;

export const PerksWelcomeCanvas = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

export const PerksHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 134.5%;
  color: #1a0224;
  font-family: "Raleway", sans-serif;
`;

export const PerksContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 134.5%;
  color: #4d526d;
  font-family: "Avenir", sans-serif;
  max-width: 880px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 24px auto 0;
`;

export const MainHeading = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 134.5%;
  color: #1a0224;
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
`;

export const PerksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px 20px;
  grid-gap: 16px;
  background: #faf6ff;
  border-radius: 15px;

  @media only screen and (min-width: 576px) {
    padding: 36px 34px;
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const PerksCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px 16px;
  background: #fff;
  border-radius: 10px;
  font-family: "Avenir", sans-serif;
`;

export const PerkImg = styled.div`
  width: auto;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  padding: 0 20px;

  & img {
    pointer-events: none;
    object-fit: contain;
    max-width: 100%;
  }
  &.additional {
    padding: 30px;
    background-color: rgb(40, 94, 73);
    border-radius: 5px;
  }
  &.additionalDeel {
    padding: 30px;
    background-color: rgb(21, 53, 122);
    border-radius: 5px;
  }
  &.additionalSwag {
    padding: 30px;
    background-color: rgb(53, 119, 211);
    border-radius: 5px;
  }
  &.additionalClever {
    padding: 40px;
    background-color: rgb(218, 50, 50);
    border-radius: 5px;
  }
  &.additionalVeremark {
    background-color: rgb(248, 248, 249);
    border-radius: 5px;
    padding: 30px;
  }
  &.letswork {
    background-color: rgb(68, 117, 246);
    border-radius: 5px;
    padding: 25px;
  }
  &.additionalMercury {
    background: rgb(212, 214, 230);
    border-radius: 5px;
    padding: 25px;
  }
  &.additonalPassword {
    background: rgb(26, 140, 255);
    border-radius: 5px;
    padding: 25px;
  }
  &.additionalClose {
    background: rgb(217, 217, 217);
    border-radius: 5px;
    padding: 30px;
  }
`;

export const SubHeading = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  text-align: center;
  background: #f6efff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 13px;
`;

export const PerkContent = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 134.5%;
  color: #4d526d;
  margin-bottom: 22px;
`;

export const PerkFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  width: 100%;
`;

export const PerksButton = styled.div`
  display: inline-flex;

  & a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #312a50;
    border: 1px solid #312a50;
    border-radius: 4px;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    min-width: 86px;
    min-height: 27px;
    transition: all 0.25s ease;
    font-family: "Avenir", sans-serif;

    & svg {
      width: 10px;
      height: 10px;

      & path {
        fill: #312a50;
        transition: all 0.25s ease;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: #312a50;
      background: #f6efff;
      border: 1px solid #312a50;
      text-decoration: none;
      box-shadow: none;

      & svg {
        & path {
          fill: #312a50;
        }
      }
    }
  }
`;

export const PerksLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #8f8f8fcc;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: #312a50;
    text-decoration: none;
  }
`;

export const PrivacyPolicyStyled = styled.div`
  margin: 0px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  ul {
    margin: 0px;
    padding: 0px;
  }
  ul li {
    list-style: none;
  }
  a:hover {
    text-decoration: none;
  }
  .off-canvas-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .header__title {
    font-size: 28px;
    color: #4d4d4d;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 35px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    letter-spacing: 5.12px;
    text-transform: uppercase;
    width: 100%;
    max-width: 890px;
    padding: 0px 24px;
  }

  @media only screen and (min-width: 375px) {
    .header__title {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media only screen and (min-width: 768px) {
    .header__title {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 6.12px;
    }
  }

  @media only screen and (min-width: 992px) {
    .header__title {
      font-size: 60px;
      line-height: 70px;
    }
  }

  /* Privacy & policy header */

  /* privacy & policy content */

  .privacy__wrapper {
    width: 100%;
    padding-top: 65px;
    padding-bottom: 100px;
  }
  .privacy__container {
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .privacy__date {
    font-size: 20px;
    color: #323232;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 54px;
    text-align: center;
    width: 100%;
  }
  .privacy__heading {
    font-size: 28px;
    color: #19191b;
    font-weight: 700;
    line-height: 34px;
    margin: 0px 0px 15px 0px;
    letter-spacing: -1.43364px;
    width: 100%;
  }
  .privacy__sub-hd {
    font-size: 20px;
    color: #19191b;
    font-weight: 700;
    line-height: 38px;
    margin: 0px 0px 7px 0px;
    letter-spacing: -1.43364px;
    width: 100%;
  }
  .privacy__para {
    width: 100%;
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .privacy__list li {
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-left: 36px;
    margin-bottom: 4px;
  }
  .privacy__list li:before {
    content: "";
    width: 4px;
    height: 4px;
    flex-shrink: 0;
    display: inline-flex;
    background: #4d4d4d;
    border-radius: 10px;
    position: absolute;
    left: 15px;
    top: 8px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .privacy__defination {
    width: 100%;
    padding-bottom: 30px;
  }
  .privacy__inf-col {
    width: 100%;
    padding-bottom: 17px;
  }
  .privacy__inf-col .privacy__heading {
    margin-bottom: 6px;
  }
  .privacy__inf-col .privacy__para {
    margin-bottom: 6px;
  }
  .privacy__inf-list {
    width: 100%;
    padding: 23px 0px 30px 0px;
  }
  .privacy__rights-list {
    width: 100%;
    padding: 0px 0px 30px 0px;
  }
  .prv__comp-address {
    width: 100%;
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 768px) {
    .privacy__heading {
      font-size: 31px;
      line-height: 37px;
    }
    .privacy__para,
    .prv__comp-address {
      font-size: 19px;
      line-height: 24px;
    }
    .privacy__list li {
      font-size: 19px;
      line-height: 24px;
    }
  }
`;
export default PrivacyPolicyStyled;
