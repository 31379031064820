import styled from "styled-components";

export const FieldWrap = styled.div`
  position: relative;
`;

export const EntryFieldBox = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 767px) {
    &:not(:last-child) {
      margin-bottom: 24px;
      max-width: 100%;
    }
  }
`;

export const FieldLine = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldLabel = styled.label`
  text-align: left;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #292930;
  margin-bottom: 16px;
`;

export const FieldInput = styled.input`
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;

  width: 100%;
  border: 1px solid #e3e6e9;
  border-radius: 16px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #292930;
  height: 72px;
  padding: 0 24px 0 72px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: inherit;
  height: 72px;

  &:disabled {
    background-color: #ecf2f6;
    color: #99a1aa;
  }

  &::-webkit-input-placeholder {
    color: #999fae;
  }
  &::-ms-input-placeholder {
    color: #99a1aa;
  }
  &::placeholder {
    color: #99a1aa;
  }
  &:focus {
    border-color: transparent;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 25px rgba(41, 41, 48, 0.1);
    box-shadow: 0 10px 25px rgba(41, 41, 48, 0.1);
  }

  @media only screen and (max-width: 767px) {
    padding-left: 50px;
    height: 50px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
`;

export const FieldError = styled.p`
  position: absolute;
  color: red;
  text-align: right;
  font-size: 12px;
  right: 10px;
  margin: 0;
  bottom: 4px;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 15px;
  font-size: 0.8rem;
`;

export const IconBox = styled.div`
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 767px) {
    left: 20px;
  }
`;
