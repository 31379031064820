import styled from "styled-components";

const animate = `
animation: shimmer 4s infinite linear;
background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
background-size: 1000px 100%;`;

const shimmerStyle = `
background-color: #ededed;
border-radius: 4px;
display: inline-flex;`;

export const AppShimmer = styled.div`
  display: flex;
`;

export const SidebarShimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 256px;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 991px) {
    width: 96px;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 140px;

  @media only screen and (max-width: 991px) {
    height: 96px;
    border-bottom: 1px solid #e4e4e4;
    padding: 20px;
  }
`;

export const Logo = styled.div`
  width: 176px;
  height: 30px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (max-width: 991px) {
    width: 32px;
    height: 16px;
  }
`;
export const SidebarContent = styled.div`
  max-height: 100%;
  padding: 0 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (max-width: 767px) {
    max-height: 100%;
    padding: 0 20px 30px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @media only screen and (max-width: 991px) {
    padding-top: 16px;
  }
`;

export const Title = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 100px;
  height: 18px;
  margin-left: 20px;
  margin-bottom: 18px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (max-width: 991px) {
    margin: 0 0 16px;
  }
`;

export const SidebarShimmerTop = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 30px;

  &:after {
    content: "";
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 1px;
    background: #f0f3f6;
  }

  @media only screen and (max-width: 991px) {
    &:after {
      left: 0;
      right: 0;
    }
  }
`;

export const SidebarNavLink = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 56px;
  padding: 2px 20px;

  @media only screen and (max-width: 991px) {
    padding: 4px;
    justify-content: center;
  }
`;
export const SidebarNavIcon = styled.span`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 18px;

  ${shimmerStyle}
  ${animate}

  @media only screen and (max-width: 1199px) {
    margin: 0 20px;
  }

  @media only screen and (max-width: 991px) {
    margin: 0;
  }
`;

export const SidebarNavText = styled.span`
  width: 66px;
  height: 18px;
  flex-shrink: 0;
  ${shimmerStyle}
  ${animate}

  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
export const SidebarSubMenuLine = styled.div`
  content: "";
  width: 3px;
  position: absolute;
  left: 54px;
  top: 25px;
  bottom: 25px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (max-width: 1199px) {
    display: none;
  }
`;

export const SidebarMenuInner = styled.div`
  padding-left: 24px;
  position: relative;

  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
`;

export const SsTitle = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 100px;
  height: 18px;
  margin-left: 20px;
  margin-bottom: 18px;

  @media only screen and (max-width: 991px) {
    margin: 0 0 16px;
  }
`;

export const JoinPdfBox = styled.div`
  width: 100%;
  min-height: 278px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-top: 86px;
  margin-bottom: 40px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const ProfileBox = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px;

  @media only screen and (max-width: 991px) {
    padding: 0 20px 0 8px;
    margin-top: 20px;
  }
`;

export const ProfileImg = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  ${shimmerStyle}
  ${animate}
`;

export const ProfileInfoBox = styled.div`
  width: 100%;
  padding: 0 5px 0 16px;

  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const Name = styled.div`
  width: 100%;
  height: 17px;
  margin-bottom: 2px;
  ${shimmerStyle}
  ${animate}
`;
export const Designation = styled.div`
  width: 100%;
  height: 16px;
  margin-top: 4px;
  ${shimmerStyle}
  ${animate}
`;
export const ArrowIcon = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin: 0 4px 0 6px;
  ${shimmerStyle}
  ${animate}

@media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const HamburgerBox = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid #e4e4e4;
  height: 96px;
  padding: 12px 24px;
  background-color: #fff;
  display: none;

  @media only screen and (max-width: 767px) {
    display: flex;
  }
`;

export const HamburgerIcon = styled.div`
  width: 32px;
  height: 16px;
  ${shimmerStyle}
  ${animate}
`;
