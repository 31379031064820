import styled from "styled-components";

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins", sans-serif;
  min-height: calc(100vh - 96px);

  @media only screen and (max-width: 1023px) {
    min-height: calc(100vh - 126px);
  }

  @media only screen and (max-width: 767px) {
    min-height: calc(100vh - 160px);
  }
`;

export const DashboardInner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
