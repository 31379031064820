import styled from "styled-components";
import { Carousel } from "react-bootstrap";

export const StyledCarouselItem = styled(Carousel.Item)`
  height: 100%;
  padding: 20px;
`;

export const CarouselIndicator = styled.div`
  display: flex;
  justify-content: center;
  //   margin-top: 10px;
  position: absolute;
  bottom: -25px;
`;

export const CarouselDot = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ffffff55;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.active {
    width: 12px;
    height: 12px;
    background-color: #fff;
  }
`;

export const ItemCanvas = styled.div`
  height: 100px;
  text-align: left;
  color: #fff;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
`;

export const Name = styled.div`
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
`;

export const Company = styled.div`
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
`;

export const Image = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;
