import { FlattenSimpleInterpolation } from "styled-components";
import * as yup from "yup";
import { AssertsShape, Assign, ObjectShape, TypeOfShape } from "yup/lib/object";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";

export const confirmNewPassword = "Must be same as password above";

export const samePassword = "New password must be different from old password";

export enum AuthForm {
  email = "email",
  password = "password",
  confirmPassword = "confirmPassword",
  code = "code",
}

export const FormSchema = yup.object().shape({
  [AuthForm.email]: yup.string().email().required().trim(),
  [AuthForm.password]: yup.string().required().min(8).max(20),
});

export const codeValidation = yup.object().shape({
  code: yup.string().required().min(6),
});

export const ForgotPasswordFormSchema = yup.object().shape({
  [AuthForm.email]: yup.string().email().required().trim(),
});

export const SetPasswordSchema = (
  oldPassword?: string,
): yup.ObjectSchema<
  Assign<
    ObjectShape,
    {
      password: RequiredStringSchema<string | undefined, AnyObject>;
    }
  >,
  AnyObject,
  TypeOfShape<
    Assign<ObjectShape, { password: RequiredStringSchema<string | undefined, AnyObject> }>
  >,
  AssertsShape<{ password: RequiredStringSchema<string | undefined, AnyObject> }>
> =>
  yup.object().shape({
    [AuthForm.password]: yup
      .string()
      .required()
      .min(8)
      .max(20)
      .notOneOf([oldPassword], samePassword),
  });

export const ResetPasswordFormSchema = yup.object().shape({
  code: yup.string().required().min(6),
  password: yup.string().required().min(8).max(20),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], confirmNewPassword),
});

export type StyledProps = FlattenSimpleInterpolation | string;
