import { useMutation } from "@apollo/client";
import { CreateSetupIntentForACHMandateMutation } from "@remotebase/amplify-constants/API";
// import { sendEmail as sendEmailQ } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "hooks/utils";
import { createSetupIntentForACHMandate } from "@remotebase/amplify-constants/graphql/mutations";
import { ReturnPropsFetchSetupIntentClientSecret } from "./types";

export const useSetupIntentClientSecret = (
  completionHandler?: () => void,
): ReturnPropsFetchSetupIntentClientSecret => {
  const [createSetupIntent, { data: response, loading: isLoading, error, called }] =
    useMutation<CreateSetupIntentForACHMandateMutation>(getQuery(createSetupIntentForACHMandate), {
      onCompleted: () => {
        if (completionHandler) completionHandler();
      },
    });

  const data = response?.createSetupIntentForACHMandate?.data ?? null;
  return {
    createSetupIntent,
    data,
    isLoading,
    error,
    createSetupIntentTriggered: called,
  };
};

export default useSetupIntentClientSecret;
