import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const ProfileDetails = styled.div`
  /* Profile Detail */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 48px 40px;
  margin: 48px 40px;
  gap: 28px;

  // width: 100%;
  height: fit-content;

  background: #ffffff;
  /* Gray 5 */
  border-width: 0 0 0 0;
  border-style: solid;
  border-color: #e0e0e0;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const SectionName = styled.h3`
  /* Profile Detail */

  height: 22px;

  font-family: "poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  color: #3c3c3c;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const NameEditIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SectionHeading = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0rem 0.5rem 2rem 0.75rem;
`;

export const EditorWrapper = styled.div`
  margin: 1rem 0.5rem;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin: 1.5rem 1rem 0;
`;

export const CancelBtn = styled(Button)`
  background-color: transparent;
  width: 5rem;
  height: 3rem;
  color: #3c65fe;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  border-width: 0;
`;

export const SubmitBtn = styled(Button)`
  position: relative;
  width: 5rem;
  height: 3rem;
  background-color: #3c65fe;
  border: 1px solid #3c65fe;
  color: #ffffff;
  font-size: 0.875rem;
  gap: 5px;
  border-radius: 0.5rem;
  font-weight: 500;
`;

export const FormBody = styled.div`
  display flex;
  flex-direction: column;
  margin: 0rem 0.5rem 2rem 0.75rem;
`;

export const FormGroup = styled.div`
  display: block;
`;
