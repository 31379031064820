import styled from "styled-components";

export const QuestionDraft = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 10px;
  position: relative;

  &.inactive {
    background-color: #ededed;
  }

  & .rdw-editor-wrapper {
    position: relative;
  }

  & .rdw-embedded-wrapper,
  & .rdw-image-wrapper,
  & .rdw-emoji-wrapper,
  & .rdw-colorpicker-wrapper,
  & .rdw-link-wrapper {
    position: static;
  }

  & .rdw-image-modal,
  & .rdw-emoji-modal,
  & .rdw-embedded-modal,
  & .rdw-link-modal,
  & .rdw-colorpicker-modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: 250px;

    @media screen and (max-width: 575px) {
      width: calc(100% - 20px);
      transform: initial;
      top: 54%;
      left: 10px;
      right: 10px;
    }
  }

  & .rdw-image-modal,
  & .rdw-embedded-modal,
  & .rdw-link-modal {
    height: auto;
  }
  & .rdw-editor-toolbar {
    padding: 12px 8px 8px;
    margin-bottom: 0;
    border-radius: 6px 6px 0px 0px;

    & .rdw-inline-wrapper {
      border-right: 1px solid #dddfe2;

      @media screen and (max-width: 575px) {
        width: 100%;
        justify-content: space-between;
      }

      & .rdw-option-wrapper {
        border: none;

        &:hover {
          box-shadow: none;
        }
      }
    }

    & .rdw-block-wrapper,
    & .rdw-fontsize-wrapper,
    & .rdw-fontfamily-wrapper {
      @media screen and (max-width: 575px) {
        width: 33.33%;
      }
    }

    & .rdw-block-wrapper,
    & .rdw-fontsize-wrapper,
    & .rdw-fontfamily-wrapper {
      border-right: 1px solid #dddfe2;

      & .rdw-dropdown-wrapper {
        border: none;
        height: 24px;

        & .rdw-dropdown-selectedtext {
          font-size: 14px;
          color: #808191;
          padding: 0px 6px;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          box-shadow: none;
        }
      }

      & .rdw-fontsize-dropdown {
        min-width: 60px;

        @media screen and (max-width: 575px) {
          min-width: 100%;
        }
      }
    }

    & .rdw-list-wrapper,
    & .rdw-text-align-wrapper {
      border-right: 1px solid #dddfe2;

      @media screen and (max-width: 575px) {
        width: 50%;
        justify-content: space-between;
      }
    }
  }

  & .rdw-editor-main {
    min-height: 120px;
    padding: 0 20px;
    word-break: break-word;
  }
`;

export const DraftGroup = styled.div`
  position: relative;
`;

export const DraftLabel = styled.label`
  font-size: 14px;
  color: #808191;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  color: #ff0000;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
`;
