import { FC } from "react";
import * as Styled from "./styles";

export const TalentBoxShimmer: FC = () => {
  return (
    <Styled.TalentBox>
      <Styled.TalentLeft>
        <Styled.TalentImage />
        <Styled.TalentName />
        <Styled.TalentInfo />
        <Styled.TalentInfo />
      </Styled.TalentLeft>

      <Styled.TalentRight>
        <Styled.TalentProgress />
        <Styled.TalentProgress />
        <Styled.TalentProgress />
      </Styled.TalentRight>
    </Styled.TalentBox>
  );
};

export default TalentBoxShimmer;
