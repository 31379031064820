import { FC } from "react";
import { AuthRoutes as Route, imageUrl } from "utils";
import * as Styled from "./styles";

interface Props {
  handleClick: () => void;
  toggle: boolean;
}

const SidebarTop: FC<Props> = (props) => {
  const { handleClick, toggle } = props;
  const logoOnClick = (): Route => {
    return Route.Dashbord;
  };
  return (
    <Styled.SidebarTop>
      <Styled.SidebarCloseBtn onClick={handleClick}>
        <i className="icon-cross" />
      </Styled.SidebarCloseBtn>

      <Styled.SidebarTopLogo to={logoOnClick} className="app__logo">
        <Styled.SidebarPicBlack src={imageUrl.logo} alt="remotebase_logo" />
        <Styled.SidebarPicWhite src={imageUrl.logo} alt="remotebase_logo" />
      </Styled.SidebarTopLogo>

      <Styled.SidebarBurger
        className={toggle ? "active" : ""}
        onClick={handleClick}
      ></Styled.SidebarBurger>
    </Styled.SidebarTop>
  );
};

export default SidebarTop;
