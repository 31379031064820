import styled from "styled-components";

export const Caption = styled.div`
  margin-bottom: 16px;
  padding-left: 20px;
  color: #808191;
  font-size: 14px;
  @media (max-width: 1023px) {
    padding-left: 0;
    text-align: center;
    margin-top: 16px;
    span {
      display: none;
    }
  }
  @media (max-width: 767px) {
    padding-left: 20px;
    text-align: left;
    span {
      display: inline;
    }
  }
`;

export const SidebarList = styled.div`
  margin-bottom: auto;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const SidebarGroup = styled.div`
  &:not(:last-child) {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 30px;
  }
  &:not(:last-child):before {
    content: "";
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 1px;
    background: #f0f3f6;
  }
  @media (max-width: 1023px) {
    &:not(:last-child):before {
      left: -20px;
      right: -20px;
    }
    /* ADD THIS AFTER YOU'VE DONE THE TOGGLE THINGY */
    /* .sidebar.active .sidebar__group:before {
      left: 20px;
      right: 20px;
    } */
  }
  @media (max-width: 767px) {
    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 15px;
    }
    &:not(:last-child):before {
      left: 20px;
      right: 20px;
    }
  }
`;
