import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarLink = styled(Link)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  &:hover {
    background: #ffffff;
    -webkit-box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
    text-decoration: none;
  }
`;

export default SidebarLink;
