import {
  Talent,
  TechDomainSkills,
  TechDomainSkillsProgress,
} from "@remotebase/amplify-constants/API";
import { FC } from "react";
import { ProfileExpertise, VideoIntroduction, TalentProfileDetail, SkillsSection } from "..";
import { ShouldRender } from "../..";
import * as Styled from "./styles";

interface Props {
  data: Talent | null;
  children: React.ReactNode;
}

export const ProfileFeedbackDetails: FC<Props> = ({ data, children }) => {
  const talentProfile = data?.profile || {};
  const { takenDomainTests, videoResponse } = data?.profile || {};

  const codingExpertise = (takenDomainTests || [])
    .filter((dt: TechDomainSkillsProgress | null) => dt?.isPass)
    .map((dt: TechDomainSkillsProgress | null) => dt?.skill) as TechDomainSkills[];

  return (
    <Styled.ProfileGridWrapper>
      <ShouldRender if={Object.keys(talentProfile).length !== 0}>
        <Styled.ProfileFeedbackDetails>
          <Styled.ProfileFeedbackDetailsContainer>
            <Styled.ProfileFeedbackDetailsLeft>
              <TalentProfileDetail data={data as Talent} />
              <SkillsSection data={data} />
            </Styled.ProfileFeedbackDetailsLeft>
            <Styled.ProfileFeedbackDetailsRight>
              <VideoIntroduction hrVideoResponse={videoResponse || []} />
              <ProfileExpertise expertise={codingExpertise} />
              {children}
            </Styled.ProfileFeedbackDetailsRight>
          </Styled.ProfileFeedbackDetailsContainer>
        </Styled.ProfileFeedbackDetails>
      </ShouldRender>
    </Styled.ProfileGridWrapper>
  );
};

export default ProfileFeedbackDetails;
