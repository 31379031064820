/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Fragment, useMemo, useState, useRef } from "react";
import { Talent, matchStage } from "@remotebase/amplify-constants/API";
import {
  codeToCountry,
  Color,
  DefaultNumber,
  matchStageHumanFriendly,
} from "@remotebase/constants";
import SliderComponent from "rc-slider";
import * as Chip from "../../../Chip";
import { ProfileEditIcon } from "../../../assets";
import { EditToolTip } from "../../../modal";
import { imageUrls } from "../../..";
import * as S from "../styles";
import { CheckMarkFilled } from "../../../icons";
import ShouldRender from "../../../shouldRender";
import { getAbbreviatedSalaryWithSymbol } from "../../../utils";

interface IViewProfileProps {
  data: Talent | null;
  children?: React.ReactNode;
  jobTitle?: string;
  stage?: matchStage;
  selfEdit?: boolean;
  handleNewPhotoUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openProfileInfoModal?: () => void;
}

export const ViewProfile: FC<IViewProfileProps> = ({
  data,
  children,
  jobTitle,
  stage,
  selfEdit,
  handleNewPhotoUpload,
  openProfileInfoModal,
}) => {
  const uploadImageRef = useRef<HTMLInputElement>(null);

  const [isMouseOverAvatar, setMouseOverAvatar] = useState(false);
  const { fullName, profile, role, country } = data || {};
  const { imgLink, takenDomainTests, isAvailable, problemSolvingPercentage } = profile || {};

  const displayedUserImg = useMemo(() => imgLink ?? imageUrls.userDefault, [imgLink]);

  const handleImageEditBtnClick = (): void => {
    uploadImageRef.current?.click();
  };
  const handleEditClick = (): void => {
    if (openProfileInfoModal) openProfileInfoModal();
  };

  const skillScore = useMemo(() => {
    if (!takenDomainTests?.length) return problemSolvingPercentage || DefaultNumber;

    let totalTestScore = takenDomainTests.reduce((prev, current) => {
      if (!current) return prev;
      return prev + (current.percentage || DefaultNumber);
    }, DefaultNumber);
    let totalTestsTaken = takenDomainTests.length;

    if (problemSolvingPercentage) {
      totalTestScore += problemSolvingPercentage;
      totalTestsTaken += 1;
    }

    return (totalTestScore / totalTestsTaken).toFixed();
  }, [takenDomainTests, problemSolvingPercentage]);

  const countryName = useMemo(() => {
    return country ? codeToCountry[country] : "";
  }, [country]);

  const availabilityBtn = useMemo(() => {
    return (
      <Chip.Container variant={isAvailable ? "primary" : "danger"}>
        {isAvailable && (
          <Chip.Icon>
            <CheckMarkFilled />
          </Chip.Icon>
        )}
        <Chip.Text>{!isAvailable && "Not"} Available</Chip.Text>
      </Chip.Container>
    );
  }, [isAvailable]);

  return (
    <Fragment>
      <S.ProfileInfoContainer>
        <S.ProfileImageCover
          onMouseEnter={(): void => setMouseOverAvatar(true)}
          onMouseLeave={(): void => setMouseOverAvatar(false)}
          isMouseOver={isMouseOverAvatar}
        >
          <S.ProfileImage isMouseOver={isMouseOverAvatar} src={displayedUserImg} />
          <ShouldRender if={selfEdit}>
            <S.ImageEditContainer isMouseOver={isMouseOverAvatar}>
              <ProfileEditIcon onClick={handleImageEditBtnClick} width={20} />
            </S.ImageEditContainer>
          </ShouldRender>
          <S.PhotoInput
            ref={uploadImageRef}
            type="file"
            accept="image/*"
            onChange={handleNewPhotoUpload}
          />
        </S.ProfileImageCover>

        <S.TalentNameCover>
          <S.TalentNameEdit>
            <S.TalentName>{fullName?.toLowerCase()}</S.TalentName>
            <ShouldRender if={selfEdit}>
              <S.WrapTip>
                <EditToolTip handleEditClick={handleEditClick} />
              </S.WrapTip>
            </ShouldRender>
          </S.TalentNameEdit>
          {jobTitle && <S.JobTitle>{jobTitle}</S.JobTitle>}
          <S.TalentExpertise>
            <p>{role?.toLowerCase()}</p>
            <span />
            <p>{countryName.toLowerCase()}</p>
          </S.TalentExpertise>
          <S.TalentAvailabilityCover>{availabilityBtn}</S.TalentAvailabilityCover>
        </S.TalentNameCover>
        <S.TalentProfileStats>
          <S.TalentProfileStat>
            <p>
              <span>{skillScore}% </span>Skill Score
            </p>
            <S.StatSliderCover>
              <SliderComponent
                min={0}
                max={100}
                defaultValue={0}
                value={skillScore as number}
                trackStyle={{ backgroundColor: Color.PRIMARY, height: "3px" }}
                railStyle={{ backgroundColor: Color.GREY, height: "3px" }}
                handleStyle={{ display: "none" }}
              />
            </S.StatSliderCover>
          </S.TalentProfileStat>
          {data?.country && data.profile?.expectedSalary && (
            <S.TalentProfileStat>
              <p>
                <Fragment>
                  <span>
                    {getAbbreviatedSalaryWithSymbol(data.profile.expectedSalary, data.country)}
                  </span>
                  &nbsp;/month
                </Fragment>
              </p>
            </S.TalentProfileStat>
          )}
          {stage && (
            <Chip.Container size="small" variant="grey" title={matchStageHumanFriendly[stage].name}>
              <Chip.Text>{matchStageHumanFriendly[stage].name}</Chip.Text>
            </Chip.Container>
          )}
        </S.TalentProfileStats>
        {children}
      </S.ProfileInfoContainer>
    </Fragment>
  );
};

export default ViewProfile;
