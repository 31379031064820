/* eslint-disable max-len */
function ATMCardIcon(): JSX.Element {
  return (
    <svg
      width="60%"
      height="60%"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 6H6C4.34315 6 3 7.34315 3 9V27C3 28.6569 4.34315 30 6 30H30C31.6569 30 33 28.6569 33 27V9C33 7.34315 31.6569 6 30 6Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15.024C17.6088 14.5497 17.118 14.1674 16.5625 13.904C16.007 13.6406 15.4003 13.5027 14.7855 13.5C12.42 13.5 10.5 15.5145 10.5 18C10.5 20.4855 12.42 22.5 14.7855 22.5C16.0665 22.5 17.2155 21.9105 18 20.976M25.5 18C25.5 20.4855 23.58 22.5 21.2145 22.5C18.8475 22.5 16.929 20.4855 16.929 18C16.929 15.5145 18.8475 13.5 21.2145 13.5C23.58 13.5 25.5 15.5145 25.5 18Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ATMCardIcon;
