import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ContinueBtnBox = styled.div`
  position: relative;
  justify-content: flex-start;
  margin-top: 28px;
`;

export const LoginBtn = styled(Button)`
  position: relative;
  color: #fff;
  width: 100%;
  padding: 20px 0;
  background: #3617ba !important;
  border: 1px solid rgba(101, 101, 101, 0.2);
  border-radius: 3px;
  font-size: 12;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  transition: all 0.25s ease;
`;
