import { TechDomainSkills } from "@remotebase/amplify-constants/API";
import { SelectOption } from "./types";

export const skillSelectOptions: Array<SelectOption> = Object.keys(TechDomainSkills).map(
  (value) => ({
    value,
    label: value,
  }),
);

export const overall = "Overall ";
export const problemSolving = "Problem Solving";

export const scoreLabel = (skill: string): string => `${skill} Score`;
export const skillSelectLabel = "Select Domain";
export const totalScoreLabel = (skill: string): string => `${skill} Total Score`;
