import React from "react";
import { upperFirst } from "lodash";
import { FieldValues, FormState, UseFormReturn } from "react-hook-form";
import { ShouldRender } from "@remotebase/components";
import cls from "classnames";
import { AuthForm } from "../utils";
import * as Styled from "./styles/authenticationInputClient.styled";

interface Props {
  type?: string;
  label?: string;
  title: AuthForm;
  handler: UseFormReturn;
  placeholder: string;
  showLabel?: boolean;
}
interface IFormState extends FormState<FieldValues> {
  name: string;
}
export const AuthenticationInputClient: React.FC<Props> = ({
  label,
  title,
  type,
  handler,
  placeholder,
  showLabel = false,
}) => {
  const {
    register,
    formState: { errors },
  } = handler;
  const isActive = (handler.formState as IFormState).name === title;
  return (
    <Styled.FormGroup className={cls({ active: isActive })}>
      <ShouldRender if={showLabel}>
        <Styled.FormLabel>{label}</Styled.FormLabel>
      </ShouldRender>
      <Styled.FormField type={type} placeholder={placeholder} {...register(title)} />
      <Styled.ErrorMessage>{upperFirst(errors[title]?.message)}</Styled.ErrorMessage>
    </Styled.FormGroup>
  );
};

export default AuthenticationInputClient;
