import { useMutation } from "@apollo/client";
import { SendEmailMutation, SendEmailMutationVariables } from "@remotebase/amplify-constants/API";
import { sendEmail as sendEmailQ } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "../utils";
import { ReturnPropsSendEmail } from "../types";

export const useSendEmail = (completionHandler?: () => void): ReturnPropsSendEmail => {
  const [sendEmail, { data: sendEmailData, loading: isLoading, error }] = useMutation<
    SendEmailMutation,
    SendEmailMutationVariables
  >(getQuery(sendEmailQ), {
    onCompleted: () => {
      if (completionHandler) completionHandler();
    },
  });

  return {
    sendEmail,
    data: sendEmailData?.sendEmail?.data,
    isLoading,
    error,
  };
};

export default useSendEmail;
