import { Button, DropdownButton } from "react-bootstrap";
import styled from "styled-components";

export const ProfilePageBody = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 48px;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  @media only screen and (max-width: 767px) {
    gap: 20px;
  }
`;

export const ProfileSpecs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

export const BtnLoaderBox = styled(Button)`
  background: #3c65fe;
  height: 42px;
  width: 100%;
  border: none;
  box-shadow: none;
  position: relative;
  font-weight: 500;
`;

export const InviteBtnCover = styled.div`
  text-align: right;
  grid-row-start: 2;
  grid-column-start: 3;
  width: 194px;
  height: 42px;
  font-size: 14px;
  margin-top: -15px;
`;

export const InviteBtn = styled(Button)`
  background: #3c65fe;
  border-radius: 8px;
  width: 194px;
  height: 42px;
  font-size: 14px;
`;

export const DropdownBtn = styled(DropdownButton)`
  width: 100%;
  background: #3c65fe;
  border-radius: 8px;
  width: 194px;
  height: 42px;
  font-size: 14px;

  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #3c65fe;
    }
  }
  & .dropdown-toggle {
    font-size: 14px;
    width: 194px;
    height: 42px;
    background-color: #3c65fe;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      height: 46px;
      font-size: 12px;
      padding: 4px 15px;
    }

    &:focus,
    &:active {
      background-color: #3c65fe;
      box-shadow: none !important;
      border: 1px solid #dddfe2 !important;
    }
  }
  & .dropdown-menu {
    border-radius: 6px;
    padding: 6px 0;
    width: 194px;
    left: 0 !important;
    box-shadow: 0px 0px 14px rgb(0 0 0 / 10%);
  }
  & .dropdown-item {
    color: #474d58;
    font-size: 14px;
    padding: 6px 12px;
    white-space: initial;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 5px 10px;
    }

    &:hover,
    &:focus,
    &.active {
      color: #474d58;
      background-color: #e9ecef;
    }
  }
`;

export const TalentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

export const BackLink = styled.a`
  font-size: 14px;
  color: #808191;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #3c1fc9;

    & svg {
      & path {
        fill: #3c1fc9;
      }
    }
  }
`;

export const BackLinkIcon = styled.span`
  width: 20px;
  height: 14px;
  display: inline-flex;
  margin-right: 4px;

  & svg {
    & path {
      fill: #808191;
    }
  }
`;

export const BackLinkText = styled.span`
  display: inline-flex;
`;

export const FeedbackBtn = styled(Button)`
  font-size: 14px;
  text-align: center;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 6px;
  min-width: 150px;
  height: 42px;
  background-color: #3f73f6;
  border: none;
  transition: all 0.2s ease;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #2a5bd7;
    color: #fff;
    box-shadow: none;
  }
`;
