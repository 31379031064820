import { useLazyQuery, useMutation } from "@apollo/client";
import { ClientInterviewInviteMutation, ClientInterviewInviteMutationVariables } from "API";
import { getClient } from "graphql/queries";
import { calEventsForClient } from "@remotebase/amplify-constants/modified";
import { clientInterviewInvite } from "graphql/mutations";
import { getQuery } from "hooks/utils/helpers";
import {
  ReturnPropsCallInterview,
  ReturnPropsClientCalendarInvite,
  ReturnPropsFetchClient,
} from "hooks/types";
import {
  CalEventsForClientQuery,
  CalEventsForClientQueryVariables,
  GetClientQuery,
  GetClientQueryVariables,
} from "@remotebase/amplify-constants/API";

export const useFetchClient = (): ReturnPropsFetchClient => {
  const [fetchClient, { data, loading, error }] = useLazyQuery<
    GetClientQuery,
    GetClientQueryVariables
  >(getQuery(getClient));

  const result = data?.getClient || null;

  return {
    fetchClient,
    data: result,
    loading,
    error,
  };
};

export const useCallClientInterview = (): ReturnPropsCallInterview => {
  const [createClientInterview, { data, loading }] = useMutation<
    ClientInterviewInviteMutation,
    ClientInterviewInviteMutationVariables
  >(getQuery(clientInterviewInvite));

  return {
    createClientInterview,
    data,
    loading,
  };
};

export const useCalEvents = (): ReturnPropsClientCalendarInvite => {
  const [getEvents, { data, loading }] = useLazyQuery<
    CalEventsForClientQuery,
    CalEventsForClientQueryVariables
  >(getQuery(calEventsForClient));

  const res = data?.calEventsForClient?.items;

  return {
    getEvents,
    data: res,
    loading,
  };
};
