import { Auth } from "aws-amplify";
import { AuthProps, initialAuthContext, shouldUseIntercom } from "utils";
import { FC, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { withAuth } from "state/auth";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";

export const LogoutPage: FC<AuthProps & ITalentProps> = ({ setAuthState, cleanTalentState }) => {
  const { update } = useIntercom();
  const history = useHistory();

  const logUserOut = async (): Promise<void> => {
    await Auth.signOut();
    setAuthState({ ...initialAuthContext });
    cleanTalentState();
    history.push("/login");
  };
  useEffect(() => {
    if (shouldUseIntercom) update();
    logUserOut();
  }, []);
  return <Fragment />;
};

export default withAuth(withTalent(LogoutPage));
