import styled from "styled-components";

export const WrapPhoneInput = styled.div`
  display: block;
  width: 100%;

  & input {
    border: none;
    outline: none;
  }
`;

export const PhoneInputLabel = styled.label`
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 0.875rem;
  color: #808191;
  font-weight: 500;

  @media only screen and (max-width: 767px) {
    font-size: 0.75rem;
    margin-bottom: 0.35rem;
  }
`;

export const PhoneInputError = styled.p`
  color: red !important;
  font-size: 0.8rem !important;
`;

export const ProfilePhoneInputCanvas = styled.div`
  & .PhoneInput {
    position: relative;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  & .PhoneInputCountry {
    position: relative;

    & select {
      height: auto;
      margin-bottom: 0;
    }
  }
  & .PhoneInputInput {
    padding-left: 0.5rem !important;
  }
`;
