import { FC, useMemo } from "react";
import { sidebarMenu, withSidebar } from "state/sidebar";
import { Sidebar, SidebarProps } from "utils";
import * as Styled from "layouts/sidebar_layout_copy/styles";
import SidebarNavItem from "./sidebar-nav-item";

const SidebarNav: FC<SidebarProps> = ({ sidebarType, sidebarIsCollapsed }) => {
  const sidebarItems: Array<Sidebar> = useMemo(() => sidebarMenu[sidebarType], [sidebarType]);
  if (!sidebarItems?.length) return null;

  return (
    <Styled.SidebarNav data-collapsed={sidebarIsCollapsed}>
      {sidebarItems.map((item) => (
        <SidebarNavItem key={item} element={item} />
      ))}
    </Styled.SidebarNav>
  );
};

export default withSidebar(SidebarNav);
