import { feedbackType } from "@remotebase/amplify-constants/API";

import { IFeedbackStateType, IFetchTalentStateType } from "state/types/talentFeedback.interface";

export const defaultInterviewDropdownValue = "Invite for an Interview";

export const EditBtnText = "Edit";
export const SaveBtnText = "Save";

export const fetchTalentDefault: IFetchTalentStateType = {
  data: null,
  isEditable: false,
  fetchFeedbackLoading: false,
  saveFeedbackLoading: false,
  matchedCompanies: [],
};
export const feedbackDefault: IFeedbackStateType = {
  talentFeedback: [],
  resumeLink: null,
  matchedCompanies: [],
  isNetwork: null,
  companies: [],
};
export const experienceLabel = "Experience(In Years)";
export const lockedLabel = "Locked?";
export const publishedLabel = "Published?";
export const rockstarLabel = "Rockstar?";
export const scoreLabel = "Score";
export const totalScoreLabel = "Total Score";
export const testPassedLabel = "Passed?";
export const talentMatchModalTitle = "Talent Match";
export const networkLabel = "Network";
export const sentimentLabel = "Sentiment";
export const selectCompanyLabel = "Select company";
export enum TalentProfile {
  resumeLink = "resumeLink",
  talentFeedback = "talentFeedback",
  matchedCompanies = "matchedCompanies",
  isNetwork = "isNetwork",
}

export const feedBackTypeText = {
  HRFeedback: "HR Screening",
  MiddleTierFeedback: "Middle-Tier Feedback",
  FinalInterviewFeedback: "Final Tech Interview",
  CulturalFeedback: "Cultural Feedback",
};

export interface IFeedbackSummary {
  type: feedbackType;
  score: number;
}

export const initialTalentContext = {
  data: [],
  filter: "",
  searchName: "",
  currentTalent: null,
  loading: false,
  client: null,
  clientFeedback: undefined,
};
