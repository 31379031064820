import styled from "styled-components";

export const TalentHeader = styled.h1`
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 114.5% !important;
  color: #1a0224 !important;
`;

export default TalentHeader;
