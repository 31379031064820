import { MutationFunctionOptions, useLazyQuery } from "@apollo/client";
import {
  GetStripeSetupIntentByClientIdQuery,
  GetStripeSetupIntentByClientIdQueryVariables,
} from "@remotebase/amplify-constants/API";
import { getQuery } from "hooks/utils";
import { getStripeSetupIntentByClientId } from "@remotebase/amplify-constants/graphql/queries";
import { ReturnPropsGetStripeSetupIntent } from "./types";

export const useGetSetupIntent = (
  completionHandler?: () => void,
): ReturnPropsGetStripeSetupIntent<
  MutationFunctionOptions<
    GetStripeSetupIntentByClientIdQuery,
    GetStripeSetupIntentByClientIdQueryVariables
  >
> => {
  const [getSetupIntentInDb, { data: response, loading: isLoading, error }] = useLazyQuery<
    GetStripeSetupIntentByClientIdQuery,
    GetStripeSetupIntentByClientIdQueryVariables
  >(getQuery(getStripeSetupIntentByClientId), {
    fetchPolicy: "network-only",
    onCompleted: () => {
      if (completionHandler) completionHandler();
    },
  });
  const data = response?.getStripeSetupIntentByClientId?.items?.[0] ?? null;
  return {
    getSetupIntentInDb,
    data,
    isLoading,
    error,
  };
};

export default useGetSetupIntent;
