export const registerFormTagline = "Enter your details below";
export const confirmEmailFormTagline =
  "Enter your Email verification code below and Please check all inboxes";
export const forgetPasswordFormTagline = "Recover your account";
export const setPasswordFormTagline = "Set your new password";
export const resetPasswordFormTagline = "Set a new password";
export const signupButtonText = "Sign Up";
export const loginButtonText = "Log In";
export const sendCodeText = "Send Code";
export const setPasswordText = "Set Password";
export const emailVerifyToastTitle = "Verify Email";
export const loginToastTitle = "Login";
export const reVerifyText = "Verify";
export const entryLoginButtonText = "Sign in with Email";
export const entrySignUpButtonText = "Sign up with Email";
export const loginHeader = "Login 🤞";
export const loginHeaderClient = "Welcome Back";
export const talentSubHeader = "To Get Hired Into Remotebase.";
export const clientSubHeader = `Kindly login to your RemoteBase Portal to hire
awesome talents from all over the world`;
export const recruiterSubHeader = "To Remotebase recruiters portal.";
export const adminSubHeader = "To Remotebase Admin portal.";
export const loginSeparatorText = "or Sign in with Email";
export const signUpHeader = "SignUp 🤞";
export const reverifyHeader = "Reverify Account";
export const signUpSeparatorText = "or Sign up with Email";
export const signUpFooterTag = "Don't have an account?";
export const loginFooterTag = "Already have an account?";
export const resetPasswordText = "Reset Password";
export const registeredEmail = "Enter your registered email below:";
export const forgotPassword = "Forgot Password";
export const setPasswordTitle = "Set Password";
export const signIn = "Sign In";
export const alreadyMember = "Already a member?";
export const codeText = "Code";
export const newPassword = "New Password";
export const confirmPassword = "Confirm Password";

export const talentName = "Fadepo Richard";
export const talentRoleAndLocation = "React Native Developer • Cardiff, UK";
export const talentSkillScore = "76% Skill Score";
export const talentAvailability = "Available for Hire";

export const clientHeaderText = "Access Top 1% of world's leading talent pool to hire directly 🤞";
export const talentHeaderText = "Work alongside the top 1% of the world's leading talent pool 🤞";
export const recruiterHeaderText = "Welcome to Remotebase recruiters portal 🤞";
export const adminHeaderText = "Welcome to Remotebase admin portal 🤞";

export const passwordPlaceholder = "password";
export const passwordLabel = "Password";
export const newPasswordPlaceholder = "new password";
export const confirmPasswordPlaceholder = "confirm password";
export const emailPlaceholder = "name@example.com";
export const codePlaceholder = "code";

export const resendCodeText = "Resend code";
export const countdownDefaultValue = 60_000;
