import { ClientCalendarInvite, JobMatch } from "@remotebase/amplify-constants/API";
import { BtnLoader, ShouldRender } from "@remotebase/components";
import { ErrorProps, HttpStatus } from "@remotebase/constants";
import { UpdateScheduleModal } from "components/modal";
import { useCalEvents, useCallClientInterview } from "hooks";
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { withTalent } from "state/talent";
import withError from "state/error/withErrorHoc";
import { ITalentProps } from "state/types";
import { defaultInterviewDropdownValue } from "utils";
import * as Styled from "./styles";

const InviteInterview: React.FC<ITalentProps & ErrorProps> = ({
  talentState: { currentTalent, client },
  showError,
  updateJobMatch,
}) => {
  const [dropdownSelect, handleSelect] = useState<string>("");
  const [InterviewEventTypes, setInterviewTypes] = useState<
    Array<ClientCalendarInvite | null | undefined>
  >([]);
  const getTitle = (): string => {
    return InterviewEventTypes?.find((e) => e?.title === dropdownSelect)
      ? dropdownSelect
      : defaultInterviewDropdownValue;
  };
  const { createClientInterview, loading, data } = useCallClientInterview();
  const { getEvents, data: clientEvents } = useCalEvents();
  const modalHeading = "Client Availability";
  const modalBody = "Please first set your availability.";
  const [showModal, setShowModal] = useState(false);

  const inviteTalent = (eventID?: string): void => {
    if (client?.availability?.length) {
      createClientInterview({
        variables: {
          talentMatchJobId: currentTalent?.id,
          eventID,
          clientID: client.id,
        },
      });
    } else setShowModal(true);
  };

  useEffect(() => {
    if (!clientEvents && client) getEvents({ variables: { clientID: client?.id } });
  }, []);
  useEffect(() => {
    const code = data?.clientInterviewInvite?.code;
    if (code) {
      if (code === HttpStatus.SUCCESS) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateJobMatch(data?.clientInterviewInvite?.data as JobMatch);
        showError({ title: "Success", message: "Talent Invited" });
      } else showError({ title: "Error", message: "Error: Invitation Not Sent!" });
    }
  }, [data?.clientInterviewInvite?.code]);

  useEffect(() => {
    if (clientEvents) setInterviewTypes(clientEvents);
  }, [clientEvents]);
  return (
    <>
      {showModal && (
        <UpdateScheduleModal
          onCancel={(): void => setShowModal(false)}
          heading={modalHeading}
          body={modalBody}
        />
      )}
      {InterviewEventTypes?.length ? (
        <Styled.InviteBtnCover>
          <ShouldRender if={!loading && !currentTalent?.stage.includes("fail")}>
            <Styled.DropdownBtn title={getTitle()} onSelect={handleSelect}>
              {InterviewEventTypes.map((item, index) => (
                <Dropdown.Item
                  onClick={(): void => inviteTalent(item?.id)}
                  key={index}
                  eventKey={item?.title}
                  active={item?.title === dropdownSelect}
                >
                  {item?.title}
                </Dropdown.Item>
              ))}
            </Styled.DropdownBtn>
          </ShouldRender>
          <ShouldRender if={loading}>
            <Styled.BtnLoaderBox>
              <BtnLoader />
            </Styled.BtnLoaderBox>
          </ShouldRender>
        </Styled.InviteBtnCover>
      ) : (
        <Styled.InviteBtnCover>
          <Styled.InviteBtn onClick={inviteTalent}>Invite for an Interview</Styled.InviteBtn>
        </Styled.InviteBtnCover>
      )}
    </>
  );
};

export default withTalent(withError(InviteInterview));
