import styled, { css } from "styled-components";
import { IUploadBoxDesign, StyledProps } from "@remotebase/constants";

export const UploadDocumentBox = styled.label<IUploadBoxDesign>`
  border: 2px dashed #cccccc;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  width: 100%;
  max-width: 640px;
  padding: 20px;
  cursor: pointer;
  margin: 40px 0;
  text-align: center;
  input[type="file"] {
    display: none;
  }

  ${(props): StyledProps =>
    props.fileUpload
      ? css`
          border-color: rgba(76, 74, 247, 0.25);
        `
      : css``}
  ${(props): StyledProps =>
    props.dragState
      ? css`
          border-color: rgba(76, 74, 247, 0.7);
        `
      : css``}
`;

export const NextBtn = styled.button`
  opacity: 1;
  position: relative;
  min-width: 126px;
  background: #4c40f7;
  color: #ffffff;
  font-size: 14px;
  background: #ff2c2c;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  transition: all 0.2s;
  color: white !important;
  font-weight: 700;
  border: none;
  min-height: 48px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  &:disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  color: #ffffff;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;

export const UploadBoxHeading = styled.h2`
  font-size: 24px;
`;

export const PurpleFont = styled.span`
  color: #4c40f7;
`;

export const UploadIconBox = styled.span`
  display: inline-flex;
  margin-bottom: 32px;
  img {
    width: 95px;
  }
`;

export const UploadDocumentText = styled.p`
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin: 0 !important;
`;

export const UploadDocumentError = styled.p`
  margin: 0 !important;
  margin-top: -30px !important;
  font-size: 16px !important;
  color: red !important;
  text-align: center !important;
`;

export const NoteSpan = styled.span`
  font-size: 16px !important;
`;

export const TypeSpan = styled.span`
  font-size: 16px !important;
  color: #f00;
`;
