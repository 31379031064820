/* eslint-disable import/no-extraneous-dependencies */
import { round } from "lodash";
import {
  Country,
  TechDomainSkills,
  TechDomainSkillsProgress,
} from "@remotebase/amplify-constants/API";
import moment from "moment";
import { nonTechTalentCategorySkills } from "@remotebase/constants";
import { fullMonthYearPattern, markPresent } from "./employmentHistory";

export const invalidPhotoErrorTitle = "Can't Read File";
export const invalidPhotoErrorMsg = `Your photo couldn't be uploaded. Photos should be less than 4 MB and
                                     saved as JPG, PNG, GIF or TIFF files.`;
export const networkFailureErrorTitle = "Failed To Save";
export const networkFailureErrorMsg = `There was a network error while uploading your photo.
                                       Please try again.`;
export const acceptedImgSize = 4194304;
export const acceptedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/tiff"];

// fetching coding expertise by filtering out all the passed domain tests
export const extractCodingExpertise = (
  takenDomainTests: (TechDomainSkillsProgress | null)[] | null | undefined,
): (TechDomainSkills | undefined)[] => {
  return (takenDomainTests || [])
    .filter((dt: TechDomainSkillsProgress | null) => dt?.isPass)
    .sort((a: TechDomainSkillsProgress | null, b: TechDomainSkillsProgress | null) => {
      return (b?.score || 0) / (b?.totalScore || 1) - (a?.score || 0) / (a?.totalScore || 1);
    })
    .map(
      (dt: TechDomainSkillsProgress | null) =>
        (nonTechTalentCategorySkills[dt?.skill as string] as TechDomainSkills) || dt?.skill,
    );
};

export const getAbbreviatedSalaryWithSymbol = (salary: string, country: Country): string | null => {
  const splitSalary = salary.split(" ");
  if (splitSalary.length !== 2) return null;
  const [amount, currency] = splitSalary;
  const currencySymbol = currency || country;
  return `${currencySymbol} ${round(+amount / 1000, 1)}k`;
};

export const extractMonthAndYear = (input?: string | null): string => {
  if (!input) return markPresent;
  return moment(input).format(fullMonthYearPattern);
};

export enum ModalComponentSize {
  "sm",
  "lg",
  "xl",
}

export const actionBtnWidth = "5rem";
