import useBillingToggle from "hooks/query/useBillingToggle";
import { FC, createContext, useEffect } from "react";

const BILLING_STATE = { ON: "ON", OFF: "OFF" };

export type BillingFeatureProps = {
  billingEnabled: boolean;
  isLoadingBillingFeatureStatus: boolean;
};
const defaultValue: BillingFeatureProps = {
  billingEnabled: false,
  isLoadingBillingFeatureStatus: true,
};
export const BillingFeatureToggleContext = createContext(defaultValue);

export const BillingFeatureToggleProvider: FC = (props) => {
  const {
    performGetBillingToggle,
    data: billingToggleValue,
    called,
    isLoading,
  } = useBillingToggle();

  useEffect(() => {
    performGetBillingToggle();
  }, []);

  const contextValue: BillingFeatureProps = {
    billingEnabled: billingToggleValue === BILLING_STATE.ON,
    isLoadingBillingFeatureStatus: isLoading || !called,
  };
  return (
    <BillingFeatureToggleContext.Provider value={contextValue}>
      {props.children}
    </BillingFeatureToggleContext.Provider>
  );
};

export default BillingFeatureToggleProvider;
