import { FC } from "react";
import { Feedback } from "@remotebase/amplify-constants/API";
import { SentimentBadge } from "./SentimentBadge";
import * as Styled from "./styles";

interface Props {
  feedback?: Feedback | null;
  label: string;
}

const ViewFeedbackHeader: FC<Props> = ({ feedback, label }) => {
  return (
    <Styled.ProfileHead>
      <Styled.ProfileBlockTitle>{label}</Styled.ProfileBlockTitle>
      <Styled.IconBlock>
        <SentimentBadge sentiment={feedback?.sentiment} />
      </Styled.IconBlock>
    </Styled.ProfileHead>
  );
};

export default ViewFeedbackHeader;
