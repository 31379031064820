/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const completeTalentProfile = /* GraphQL */ `
  mutation CompleteTalentProfile {
    completeTalentProfile {
      code
      message
      data
    }
  }
`;
export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate {
    createCandidate {
      code
      message
      data
    }
  }
`;
export const disqualifyCandidateFromWorkable = /* GraphQL */ `
  mutation DisqualifyCandidateFromWorkable($candidateId: String) {
    disqualifyCandidateFromWorkable(candidateId: $candidateId) {
      code
      message
      data
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $email: String
    $comment: String
    $isPrivate: Boolean
    $attachment: WorkableAttachmentInput
  ) {
    createComment(
      email: $email
      comment: $comment
      isPrivate: $isPrivate
      attachment: $attachment
    ) {
      code
      message
      data
    }
  }
`;
export const matchTalentToJobs = /* GraphQL */ `
  mutation MatchTalentToJobs(
    $jobs: [TalentJobsInput]!
    $talentID: ID!
    $talentEmail: String!
  ) {
    matchTalentToJobs(
      jobs: $jobs
      talentID: $talentID
      talentEmail: $talentEmail
    ) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const moveCandidate = /* GraphQL */ `
  mutation MoveCandidate($email: String, $targetStage: String) {
    moveCandidate(email: $email, targetStage: $targetStage) {
      code
      message
      data
    }
  }
`;
export const sendClientNewJobMatchEmail = /* GraphQL */ `
  mutation SendClientNewJobMatchEmail($jobMatch: NewClientJobMatchInput) {
    sendClientNewJobMatchEmail(jobMatch: $jobMatch) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: SendEmailInputType) {
    sendEmail(input: $input) {
      code
      message
      data
    }
  }
`;
export const subscribeToMove = /* GraphQL */ `
  mutation SubscribeToMove(
    $stage: String
    $atsJobId: String
    $talentCategory: String
  ) {
    subscribeToMove(
      stage: $stage
      atsJobId: $atsJobId
      talentCategory: $talentCategory
    ) {
      code
      message
      data
    }
  }
`;
export const clientInterviewInvite = /* GraphQL */ `
  mutation ClientInterviewInvite(
    $talentMatchJobId: String!
    $eventID: ID!
    $clientID: ID!
  ) {
    clientInterviewInvite(
      talentMatchJobId: $talentMatchJobId
      eventID: $eventID
      clientID: $clientID
    ) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability(
    $availabilities: [CreateAvailabilityInput!]!
    $timezone: String!
  ) {
    createAvailability(availabilities: $availabilities, timezone: $timezone) {
      code
      message
    }
  }
`;
export const fetchJobMatches = /* GraphQL */ `
  mutation FetchJobMatches($companyID: String, $filter: MatchStageFilterInput) {
    fetchJobMatches(companyID: $companyID, filter: $filter) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const submitCalendarBooking = /* GraphQL */ `
  mutation SubmitCalendarBooking(
    $token: String!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
    $timezone: String!
  ) {
    submitCalendarBooking(
      token: $token
      startTime: $startTime
      endTime: $endTime
      timezone: $timezone
    ) {
      code
      message
      data
    }
  }
`;
export const updateJobMatchStage = /* GraphQL */ `
  mutation UpdateJobMatchStage($payload: UpdateJobMatchStageInput!) {
    updateJobMatchStage(payload: $payload) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const saveClientSubscriptionReceipt = /* GraphQL */ `
  mutation SaveClientSubscriptionReceipt(
    $payload: SaveSubscriptionReceiptInput!
  ) {
    saveClientSubscriptionReceipt(payload: $payload) {
      code
      message
    }
  }
`;
export const chargeClientForTalentInformation = /* GraphQL */ `
  mutation ChargeClientForTalentInformation($talentID: ID!, $jobID: ID!) {
    chargeClientForTalentInformation(talentID: $talentID, jobID: $jobID) {
      code
      message
      data
    }
  }
`;
export const disableClientBasicUser = /* GraphQL */ `
  mutation DisableClientBasicUser {
    disableClientBasicUser {
      code
      message
      data
    }
  }
`;
export const createOrUpdateJobMatch = /* GraphQL */ `
  mutation CreateOrUpdateJobMatch($input: CreateOrUpdateJobMatchInput!) {
    createOrUpdateJobMatch(input: $input) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const createOrUpdateJobMatchAdmin = /* GraphQL */ `
  mutation CreateOrUpdateJobMatchAdmin($input: CreateOrUpdateJobMatchInput!) {
    createOrUpdateJobMatchAdmin(input: $input) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const updateJobMatchFeedback = /* GraphQL */ `
  mutation UpdateJobMatchFeedback($payload: UpdateJobMatchFeedbackInput!) {
    updateJobMatchFeedback(payload: $payload) {
      code
      message
      data {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        clientFeedback {
          referred
          interviewing
          passTechInterview
          failTechInterview
          passCulturalFitInterview
          failCulturalFitInterview
          passSystemDesignInterview
          failSystemDesignInterview
          rejected
          onboarded
          interested
        }
        isPaidFor
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        createdAt
        updatedAt
        version
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const updateCompanyData = /* GraphQL */ `
  mutation UpdateCompanyData($payload: updateCompanyDataInput!) {
    updateCompanyData(payload: $payload) {
      code
      message
      data {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($referrerId: String) {
    acceptInvitation(referrerId: $referrerId) {
      code
      message
      data {
        refferedBy
      }
    }
  }
`;
export const inviteEmails = /* GraphQL */ `
  mutation InviteEmails($emails: [String]) {
    inviteEmails(emails: $emails) {
      code
      message
      data
    }
  }
`;
export const reInviteEmail = /* GraphQL */ `
  mutation ReInviteEmail($email: String) {
    reInviteEmail(email: $email) {
      code
      message
      data
    }
  }
`;
export const generateRecruitersReport = /* GraphQL */ `
  mutation GenerateRecruitersReport($input: GenerateRecruitersReportInput) {
    generateRecruitersReport(input: $input) {
      code
      message
      data
    }
  }
`;
export const generateLeaderBoardReport = /* GraphQL */ `
  mutation GenerateLeaderBoardReport($input: GenerateRecruitersReportInput) {
    generateLeaderBoardReport(input: $input) {
      code
      message
      data
    }
  }
`;
export const getProblemSolvingTest = /* GraphQL */ `
  mutation GetProblemSolvingTest {
    getProblemSolvingTest
  }
`;
export const inviteUserForTest = /* GraphQL */ `
  mutation InviteUserForTest($testId: String!) {
    inviteUserForTest(testId: $testId)
  }
`;
export const setVideoResponse = /* GraphQL */ `
  mutation SetVideoResponse($videoId: String!) {
    setVideoResponse(videoId: $videoId) {
      code
      message
      data
    }
  }
`;
export const createTalentProfileWithUsdSalary = /* GraphQL */ `
  mutation CreateTalentProfileWithUsdSalary(
    $input: CreateTalentProfileWithUsdSalaryInput!
  ) {
    createTalentProfileWithUsdSalary(input: $input) {
      code
      message
      data {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTalentProfileWithUsdSalary = /* GraphQL */ `
  mutation UpdateTalentProfileWithUsdSalary(
    $input: UpdateTalentProfileWithUsdSalaryInput!
  ) {
    updateTalentProfileWithUsdSalary(input: $input) {
      code
      message
      data {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const applyJobWorkable = /* GraphQL */ `
  mutation ApplyJobWorkable($input: ApplyJobWorkableType) {
    applyJobWorkable(input: $input) {
      code
      message
      data {
        uid
        firstname
        lastname
        email
      }
    }
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($input: SendMessageInput) {
    sendMessage(input: $input) {
      code
      message
      data {
        id
        content
        senderInfo {
          id
          fullName
          email
          phone
          role
        }
        conversationId
        createdAt
        updatedAt
        isRead
        version
        readBy {
          id
          fullName
          email
          phone
          role
        }
      }
    }
  }
`;
export const buildConversation = /* GraphQL */ `
  mutation BuildConversation($input: CreateConversationLambdaInput) {
    buildConversation(input: $input) {
      code
      message
      data {
        id
        name
        createdAt
        updatedAt
        isActive
        version
        messages {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          version
          readBy {
            id
            fullName
            email
            phone
            role
          }
        }
        conversationParticipants {
          id
          fullName
          email
          phone
          role
        }
      }
    }
  }
`;
export const markMessageAsRead = /* GraphQL */ `
  mutation MarkMessageAsRead($input: MarkMessageAsReadInput) {
    markMessageAsRead(input: $input) {
      code
      message
      data {
        id
        content
        senderInfo {
          id
          fullName
          email
          phone
          role
        }
        conversationId
        createdAt
        updatedAt
        isRead
        version
        readBy {
          id
          fullName
          email
          phone
          role
        }
      }
    }
  }
`;
export const createOrGetStripeCustomer = /* GraphQL */ `
  mutation CreateOrGetStripeCustomer {
    createOrGetStripeCustomer {
      code
      message
      data {
        customer {
          id
          object
          address
          balance
          created
          currency
          default_source
          delinquent
          description
          discount
          email
          invoice_prefix
          invoice_settings {
            custom_fields
            default_payment_method
            footer
            rendering_options
          }
          livemode
          metadata
          name
          next_invoice_sequence
          phone
          preferred_locales
          shipping
          tax_exempt
          test_clock
        }
        paymentMethods {
          pid
          lastFour
          networks {
            available
            preferred
          }
        }
        subscription {
          id
          object
          application
          application_fee_percent
          automatic_tax {
            enabled
          }
          billing_cycle_anchor
          billing_thresholds
          cancel_at
          cancel_at_period_end
          canceled_at
          collection_method
          created
          currency
          current_period_end
          current_period_start
          customer
          days_until_due
          default_payment_method
          default_source
          default_tax_rates
          description
          discount
          ended_at
          items {
            object
            data {
              id
              object
              billing_thresholds
              created
              metadata
              price {
                id
                object
                active
                billing_scheme
                created
                currency
                custom_unit_amount
                livemode
                lookup_key
                metadata
                nickname
                product
                tax_behavior
                tiers_mode
                transform_quantity
                type
                unit_amount
                unit_amount_decimal
              }
              quantity
              subscription
              tax_rates
            }
            has_more
            url
          }
          latest_invoice
          livemode
          metadata
          next_pending_invoice_item_invoice
          on_behalf_of
          pause_collection
          payment_settings {
            payment_method_options
            payment_method_types
            save_default_payment_method
          }
          pending_invoice_item_interval
          pending_setup_intent
          pending_update
          schedule
          start_date
          status
          test_clock
          transfer_data
          trial_end
          trial_start
        }
      }
    }
  }
`;
export const createStripeSubscription = /* GraphQL */ `
  mutation CreateStripeSubscription($priceId: String!) {
    createStripeSubscription(priceId: $priceId) {
      code
      message
      data {
        stripeSubscriptionId
        clientSecret
      }
    }
  }
`;
export const cancelStripeSubscription = /* GraphQL */ `
  mutation CancelStripeSubscription($subscriptionId: String!) {
    cancelStripeSubscription(subscriptionId: $subscriptionId) {
      code
      message
      data
    }
  }
`;
export const updateSubscriptionPaymentMethod = /* GraphQL */ `
  mutation UpdateSubscriptionPaymentMethod(
    $subscriptionId: String!
    $paymentMethodId: String!
  ) {
    updateSubscriptionPaymentMethod(
      subscriptionId: $subscriptionId
      paymentMethodId: $paymentMethodId
    ) {
      code
      message
      data
    }
  }
`;
export const createSetupIntentForACHMandate = /* GraphQL */ `
  mutation CreateSetupIntentForACHMandate {
    createSetupIntentForACHMandate {
      code
      data {
        clientSecret
        setupIntentId
      }
      message
    }
  }
`;
export const withDrawACHMandate = /* GraphQL */ `
  mutation WithDrawACHMandate($paymentMethodId: String!) {
    withDrawACHMandate(paymentMethodId: $paymentMethodId) {
      code
      message
      data
    }
  }
`;
export const saveStripeSetupIntent = /* GraphQL */ `
  mutation SaveStripeSetupIntent($setupIntentId: String!) {
    saveStripeSetupIntent(setupIntentId: $setupIntentId) {
      code
      message
      data
    }
  }
`;
export const getParsedResumeData = /* GraphQL */ `
  mutation GetParsedResumeData($resumeLink: String!) {
    getParsedResumeData(resumeLink: $resumeLink) {
      code
      message
      data {
        basic_info {
          github_main_page_url
          linkedin_url
          portfolio_website_url
        }
        education_history {
          degree
          school
          startDate
          endDate
        }
        employment_history {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
      }
    }
  }
`;
export const createAndValidateInvoiceBatch = /* GraphQL */ `
  mutation CreateAndValidateInvoiceBatch($payload: String!) {
    createAndValidateInvoiceBatch(payload: $payload) {
      code
      message
      data {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
    }
  }
`;
export const consumeBatchId = /* GraphQL */ `
  mutation ConsumeBatchId($batchId: String!) {
    consumeBatchId(batchId: $batchId) {
      code
      message
      data
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      id
      developersCompletedProfiles
      developersJoined
      recruitersJoined
      hiredToday
      takenDomainTests
      passedDomainTests
      takenProblemSolvingTests
      passedProblemSolving
      developersReferredByRecruiters
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      id
      developersCompletedProfiles
      developersJoined
      recruitersJoined
      hiredToday
      takenDomainTests
      passedDomainTests
      takenProblemSolvingTests
      passedProblemSolving
      developersReferredByRecruiters
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      id
      developersCompletedProfiles
      developersJoined
      recruitersJoined
      hiredToday
      takenDomainTests
      passedDomainTests
      takenProblemSolvingTests
      passedProblemSolving
      developersReferredByRecruiters
      createdAt
      updatedAt
      version
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      clientId
      invoiceBatchId
      invoiceNumber
      amount
      status
      reason
      stripeInvoiceId
      stripeInvoicePdfUrl
      description
      createdAt
      updatedAt
      invoiceBatch {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      clientId
      invoiceBatchId
      invoiceNumber
      amount
      status
      reason
      stripeInvoiceId
      stripeInvoicePdfUrl
      description
      createdAt
      updatedAt
      invoiceBatch {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      clientId
      invoiceBatchId
      invoiceNumber
      amount
      status
      reason
      stripeInvoiceId
      stripeInvoicePdfUrl
      description
      createdAt
      updatedAt
      invoiceBatch {
        id
        csvFileUrl
        createdBy
        approvedBy
        status
        invoiceBatchNumber
        createdAt
        sortHashKey
        errorMsg
        invoicesToProcess
        invoicesProcessed
        updatedAt
        version
      }
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createInvoiceBatch = /* GraphQL */ `
  mutation CreateInvoiceBatch(
    $input: CreateInvoiceBatchInput!
    $condition: ModelInvoiceBatchConditionInput
  ) {
    createInvoiceBatch(input: $input, condition: $condition) {
      id
      csvFileUrl
      createdBy
      approvedBy
      status
      invoiceBatchNumber
      createdAt
      sortHashKey
      errorMsg
      invoicesToProcess
      invoicesProcessed
      updatedAt
      version
    }
  }
`;
export const updateInvoiceBatch = /* GraphQL */ `
  mutation UpdateInvoiceBatch(
    $input: UpdateInvoiceBatchInput!
    $condition: ModelInvoiceBatchConditionInput
  ) {
    updateInvoiceBatch(input: $input, condition: $condition) {
      id
      csvFileUrl
      createdBy
      approvedBy
      status
      invoiceBatchNumber
      createdAt
      sortHashKey
      errorMsg
      invoicesToProcess
      invoicesProcessed
      updatedAt
      version
    }
  }
`;
export const deleteInvoiceBatch = /* GraphQL */ `
  mutation DeleteInvoiceBatch(
    $input: DeleteInvoiceBatchInput!
    $condition: ModelInvoiceBatchConditionInput
  ) {
    deleteInvoiceBatch(input: $input, condition: $condition) {
      id
      csvFileUrl
      createdBy
      approvedBy
      status
      invoiceBatchNumber
      createdAt
      sortHashKey
      errorMsg
      invoicesToProcess
      invoicesProcessed
      updatedAt
      version
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      invoiceId
      amount
      transactionId
      paymentStatus
      paymentMethod
      reason
      createdAt
      updatedAt
      invoice {
        id
        clientId
        invoiceBatchId
        invoiceNumber
        amount
        status
        reason
        stripeInvoiceId
        stripeInvoicePdfUrl
        description
        createdAt
        updatedAt
        invoiceBatch {
          id
          csvFileUrl
          createdBy
          approvedBy
          status
          invoiceBatchNumber
          createdAt
          sortHashKey
          errorMsg
          invoicesToProcess
          invoicesProcessed
          updatedAt
          version
        }
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
            availableSlots {
              startTime
              endTime
            }
          }
          googleCalendarId
          remainingCredits
          totalCredits
          stripeCustomerId
          currentStripeSubscriptionId
          hasMandate
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      invoiceId
      amount
      transactionId
      paymentStatus
      paymentMethod
      reason
      createdAt
      updatedAt
      invoice {
        id
        clientId
        invoiceBatchId
        invoiceNumber
        amount
        status
        reason
        stripeInvoiceId
        stripeInvoicePdfUrl
        description
        createdAt
        updatedAt
        invoiceBatch {
          id
          csvFileUrl
          createdBy
          approvedBy
          status
          invoiceBatchNumber
          createdAt
          sortHashKey
          errorMsg
          invoicesToProcess
          invoicesProcessed
          updatedAt
          version
        }
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
            availableSlots {
              startTime
              endTime
            }
          }
          googleCalendarId
          remainingCredits
          totalCredits
          stripeCustomerId
          currentStripeSubscriptionId
          hasMandate
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      invoiceId
      amount
      transactionId
      paymentStatus
      paymentMethod
      reason
      createdAt
      updatedAt
      invoice {
        id
        clientId
        invoiceBatchId
        invoiceNumber
        amount
        status
        reason
        stripeInvoiceId
        stripeInvoicePdfUrl
        description
        createdAt
        updatedAt
        invoiceBatch {
          id
          csvFileUrl
          createdBy
          approvedBy
          status
          invoiceBatchNumber
          createdAt
          sortHashKey
          errorMsg
          invoicesToProcess
          invoicesProcessed
          updatedAt
          version
        }
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
            availableSlots {
              startTime
              endTime
            }
          }
          googleCalendarId
          remainingCredits
          totalCredits
          stripeCustomerId
          currentStripeSubscriptionId
          hasMandate
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              id
              streetAddress
              apartmentAddress
              postalCode
              district
              city
              country
            }
            industry
            associatedWith
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createStripeSetupIntent = /* GraphQL */ `
  mutation CreateStripeSetupIntent(
    $input: CreateStripeSetupIntentInput!
    $condition: ModelStripeSetupIntentConditionInput
  ) {
    createStripeSetupIntent(input: $input, condition: $condition) {
      id
      status
      paymentMethodId
      setupIntentId
      mandateId
      mandateStatus
      createdAt
      clientId
      clientSecret
      updatedAt
    }
  }
`;
export const updateStripeSetupIntent = /* GraphQL */ `
  mutation UpdateStripeSetupIntent(
    $input: UpdateStripeSetupIntentInput!
    $condition: ModelStripeSetupIntentConditionInput
  ) {
    updateStripeSetupIntent(input: $input, condition: $condition) {
      id
      status
      paymentMethodId
      setupIntentId
      mandateId
      mandateStatus
      createdAt
      clientId
      clientSecret
      updatedAt
    }
  }
`;
export const deleteStripeSetupIntent = /* GraphQL */ `
  mutation DeleteStripeSetupIntent(
    $input: DeleteStripeSetupIntentInput!
    $condition: ModelStripeSetupIntentConditionInput
  ) {
    deleteStripeSetupIntent(input: $input, condition: $condition) {
      id
      status
      paymentMethodId
      setupIntentId
      mandateId
      mandateStatus
      createdAt
      clientId
      clientSecret
      updatedAt
    }
  }
`;
export const createCalendarBooking = /* GraphQL */ `
  mutation CreateCalendarBooking(
    $input: CreateCalendarBookingInput!
    $condition: ModelCalendarBookingConditionInput
  ) {
    createCalendarBooking(input: $input, condition: $condition) {
      id
      clientId
      eventId
      startTime
      endTime
      meetingLink
      talentId
      jobMatchId
      inviteToken
      feedback
      createdAt
      updatedAt
      version
      engineerId
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateCalendarBooking = /* GraphQL */ `
  mutation UpdateCalendarBooking(
    $input: UpdateCalendarBookingInput!
    $condition: ModelCalendarBookingConditionInput
  ) {
    updateCalendarBooking(input: $input, condition: $condition) {
      id
      clientId
      eventId
      startTime
      endTime
      meetingLink
      talentId
      jobMatchId
      inviteToken
      feedback
      createdAt
      updatedAt
      version
      engineerId
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteCalendarBooking = /* GraphQL */ `
  mutation DeleteCalendarBooking(
    $input: DeleteCalendarBookingInput!
    $condition: ModelCalendarBookingConditionInput
  ) {
    deleteCalendarBooking(input: $input, condition: $condition) {
      id
      clientId
      eventId
      startTime
      endTime
      meetingLink
      talentId
      jobMatchId
      inviteToken
      feedback
      createdAt
      updatedAt
      version
      engineerId
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      companyID
      timezone
      availability {
        day
        date
        availableSlots {
          startTime
          endTime
        }
      }
      googleCalendarId
      remainingCredits
      totalCredits
      stripeCustomerId
      currentStripeSubscriptionId
      hasMandate
      createdAt
      updatedAt
      version
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      companyID
      timezone
      availability {
        day
        date
        availableSlots {
          startTime
          endTime
        }
      }
      googleCalendarId
      remainingCredits
      totalCredits
      stripeCustomerId
      currentStripeSubscriptionId
      hasMandate
      createdAt
      updatedAt
      version
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      companyID
      timezone
      availability {
        day
        date
        availableSlots {
          startTime
          endTime
        }
      }
      googleCalendarId
      remainingCredits
      totalCredits
      stripeCustomerId
      currentStripeSubscriptionId
      hasMandate
      createdAt
      updatedAt
      version
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const createClientCalendarInvite = /* GraphQL */ `
  mutation CreateClientCalendarInvite(
    $input: CreateClientCalendarInviteInput!
    $condition: ModelClientCalendarInviteConditionInput
  ) {
    createClientCalendarInvite(input: $input, condition: $condition) {
      id
      clientID
      title
      clientEmail
      eventLength
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateClientCalendarInvite = /* GraphQL */ `
  mutation UpdateClientCalendarInvite(
    $input: UpdateClientCalendarInviteInput!
    $condition: ModelClientCalendarInviteConditionInput
  ) {
    updateClientCalendarInvite(input: $input, condition: $condition) {
      id
      clientID
      title
      clientEmail
      eventLength
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteClientCalendarInvite = /* GraphQL */ `
  mutation DeleteClientCalendarInvite(
    $input: DeleteClientCalendarInviteInput!
    $condition: ModelClientCalendarInviteConditionInput
  ) {
    deleteClientCalendarInvite(input: $input, condition: $condition) {
      id
      clientID
      title
      clientEmail
      eventLength
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      logo
      stage
      tagline
      description
      address {
        id
        streetAddress
        apartmentAddress
        postalCode
        district
        city
        country
      }
      industry
      associatedWith
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      logo
      stage
      tagline
      description
      address {
        id
        streetAddress
        apartmentAddress
        postalCode
        district
        city
        country
      }
      industry
      associatedWith
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      logo
      stage
      tagline
      description
      address {
        id
        streetAddress
        apartmentAddress
        postalCode
        district
        city
        country
      }
      industry
      associatedWith
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      companyID
      status
      title
      description
      responsibilites
      tags
      requiredTechDomainSkills
      seniorityLevel
      isPublic
      isPermanent
      internalNotes
      department
      jobPerks
      createdAt
      updatedAt
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      companyID
      status
      title
      description
      responsibilites
      tags
      requiredTechDomainSkills
      seniorityLevel
      isPublic
      isPermanent
      internalNotes
      department
      jobPerks
      createdAt
      updatedAt
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      companyID
      status
      title
      description
      responsibilites
      tags
      requiredTechDomainSkills
      seniorityLevel
      isPublic
      isPermanent
      internalNotes
      department
      jobPerks
      createdAt
      updatedAt
      company {
        id
        name
        logo
        stage
        tagline
        description
        address {
          id
          streetAddress
          apartmentAddress
          postalCode
          district
          city
          country
        }
        industry
        associatedWith
        createdAt
        updatedAt
      }
    }
  }
`;
export const createJobMatch = /* GraphQL */ `
  mutation CreateJobMatch(
    $input: CreateJobMatchInput!
    $condition: ModelJobMatchConditionInput
  ) {
    createJobMatch(input: $input, condition: $condition) {
      id
      talentID
      jobID
      lastActivity
      stage
      process
      isRockstar
      isPublished
      clientFeedback {
        referred
        interviewing
        passTechInterview
        failTechInterview
        passCulturalFitInterview
        failCulturalFitInterview
        passSystemDesignInterview
        failSystemDesignInterview
        rejected
        onboarded
        interested
      }
      isPaidFor
      feedback {
        communicationSkillsLevel
        techSkillAndDomainKnowledgeLevel
        problemSolvingSkillLevel
        isRejected
        otherFeedback
      }
      talentProfileVersionID
      createdAt
      updatedAt
      version
      job {
        id
        companyID
        status
        title
        description
        responsibilites
        tags
        requiredTechDomainSkills
        seniorityLevel
        isPublic
        isPermanent
        internalNotes
        department
        jobPerks
        createdAt
        updatedAt
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      talentProfileVersion {
        id
        name
        skills
        experience
        isAvailable
        social
        englishProficiency
        expectedSalary
        expectedSalaryInUsd
        interests
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        imgLink
        bio
        title
        talentID
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updateJobMatch = /* GraphQL */ `
  mutation UpdateJobMatch(
    $input: UpdateJobMatchInput!
    $condition: ModelJobMatchConditionInput
  ) {
    updateJobMatch(input: $input, condition: $condition) {
      id
      talentID
      jobID
      lastActivity
      stage
      process
      isRockstar
      isPublished
      clientFeedback {
        referred
        interviewing
        passTechInterview
        failTechInterview
        passCulturalFitInterview
        failCulturalFitInterview
        passSystemDesignInterview
        failSystemDesignInterview
        rejected
        onboarded
        interested
      }
      isPaidFor
      feedback {
        communicationSkillsLevel
        techSkillAndDomainKnowledgeLevel
        problemSolvingSkillLevel
        isRejected
        otherFeedback
      }
      talentProfileVersionID
      createdAt
      updatedAt
      version
      job {
        id
        companyID
        status
        title
        description
        responsibilites
        tags
        requiredTechDomainSkills
        seniorityLevel
        isPublic
        isPermanent
        internalNotes
        department
        jobPerks
        createdAt
        updatedAt
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      talentProfileVersion {
        id
        name
        skills
        experience
        isAvailable
        social
        englishProficiency
        expectedSalary
        expectedSalaryInUsd
        interests
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        imgLink
        bio
        title
        talentID
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deleteJobMatch = /* GraphQL */ `
  mutation DeleteJobMatch(
    $input: DeleteJobMatchInput!
    $condition: ModelJobMatchConditionInput
  ) {
    deleteJobMatch(input: $input, condition: $condition) {
      id
      talentID
      jobID
      lastActivity
      stage
      process
      isRockstar
      isPublished
      clientFeedback {
        referred
        interviewing
        passTechInterview
        failTechInterview
        passCulturalFitInterview
        failCulturalFitInterview
        passSystemDesignInterview
        failSystemDesignInterview
        rejected
        onboarded
        interested
      }
      isPaidFor
      feedback {
        communicationSkillsLevel
        techSkillAndDomainKnowledgeLevel
        problemSolvingSkillLevel
        isRejected
        otherFeedback
      }
      talentProfileVersionID
      createdAt
      updatedAt
      version
      job {
        id
        companyID
        status
        title
        description
        responsibilites
        tags
        requiredTechDomainSkills
        seniorityLevel
        isPublic
        isPermanent
        internalNotes
        department
        jobPerks
        createdAt
        updatedAt
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      talentProfileVersion {
        id
        name
        skills
        experience
        isAvailable
        social
        englishProficiency
        expectedSalary
        expectedSalaryInUsd
        interests
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        imgLink
        bio
        title
        talentID
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createPersonSubscriptions = /* GraphQL */ `
  mutation CreatePersonSubscriptions(
    $input: CreatePersonSubscriptionsInput!
    $condition: ModelPersonSubscriptionsConditionInput
  ) {
    createPersonSubscriptions(input: $input, condition: $condition) {
      id
      clientId
      recruiterId
      talentId
      transactionId
      receipt
      startDate
      endDate
      validationResponse
      productId
      isCancelled
      platform
      status
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updatePersonSubscriptions = /* GraphQL */ `
  mutation UpdatePersonSubscriptions(
    $input: UpdatePersonSubscriptionsInput!
    $condition: ModelPersonSubscriptionsConditionInput
  ) {
    updatePersonSubscriptions(input: $input, condition: $condition) {
      id
      clientId
      recruiterId
      talentId
      transactionId
      receipt
      startDate
      endDate
      validationResponse
      productId
      isCancelled
      platform
      status
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deletePersonSubscriptions = /* GraphQL */ `
  mutation DeletePersonSubscriptions(
    $input: DeletePersonSubscriptionsInput!
    $condition: ModelPersonSubscriptionsConditionInput
  ) {
    deletePersonSubscriptions(input: $input, condition: $condition) {
      id
      clientId
      recruiterId
      talentId
      transactionId
      receipt
      startDate
      endDate
      validationResponse
      productId
      isCancelled
      platform
      status
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createTalentProfileVersion = /* GraphQL */ `
  mutation CreateTalentProfileVersion(
    $input: CreateTalentProfileVersionInput!
    $condition: ModelTalentProfileVersionConditionInput
  ) {
    createTalentProfileVersion(input: $input, condition: $condition) {
      id
      name
      skills
      experience
      isAvailable
      social
      englishProficiency
      expectedSalary
      expectedSalaryInUsd
      interests
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      imgLink
      bio
      title
      talentID
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updateTalentProfileVersion = /* GraphQL */ `
  mutation UpdateTalentProfileVersion(
    $input: UpdateTalentProfileVersionInput!
    $condition: ModelTalentProfileVersionConditionInput
  ) {
    updateTalentProfileVersion(input: $input, condition: $condition) {
      id
      name
      skills
      experience
      isAvailable
      social
      englishProficiency
      expectedSalary
      expectedSalaryInUsd
      interests
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      imgLink
      bio
      title
      talentID
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deleteTalentProfileVersion = /* GraphQL */ `
  mutation DeleteTalentProfileVersion(
    $input: DeleteTalentProfileVersionInput!
    $condition: ModelTalentProfileVersionConditionInput
  ) {
    deleteTalentProfileVersion(input: $input, condition: $condition) {
      id
      name
      skills
      experience
      isAvailable
      social
      englishProficiency
      expectedSalary
      expectedSalaryInUsd
      interests
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      imgLink
      bio
      title
      talentID
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      senderInfo {
        id
        fullName
        email
        phone
        role
      }
      conversationId
      createdAt
      updatedAt
      isRead
      readBy {
        id
        fullName
        email
        phone
        role
      }
      version
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      senderInfo {
        id
        fullName
        email
        phone
        role
      }
      conversationId
      createdAt
      updatedAt
      isRead
      readBy {
        id
        fullName
        email
        phone
        role
      }
      version
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      senderInfo {
        id
        fullName
        email
        phone
        role
      }
      conversationId
      createdAt
      updatedAt
      isRead
      readBy {
        id
        fullName
        email
        phone
        role
      }
      version
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      isActive
      lastMessageAt
      version
      messages {
        items {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          readBy {
            id
            fullName
            email
            phone
            role
          }
          version
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
        }
        nextToken
      }
      conversationParticipants {
        items {
          id
          conversationId
          talentId
          clientId
          recruiterId
          createdAt
          updatedAt
          version
          client {
            id
            fullName
            email
            phone
            country
            role
            companyID
            timezone
            availability {
              day
              date
              availableSlots {
                startTime
                endTime
              }
            }
            googleCalendarId
            remainingCredits
            totalCredits
            stripeCustomerId
            currentStripeSubscriptionId
            hasMandate
            createdAt
            updatedAt
            version
            company {
              id
              name
              logo
              stage
              tagline
              description
              address {
                id
                streetAddress
                apartmentAddress
                postalCode
                district
                city
                country
              }
              industry
              associatedWith
              createdAt
              updatedAt
            }
          }
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
          recruiter {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              currentEarnings
              profession
              createdAt
              updatedAt
              version
              recruiterProfileRecruiterId
            }
          }
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      isActive
      lastMessageAt
      version
      messages {
        items {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          readBy {
            id
            fullName
            email
            phone
            role
          }
          version
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
        }
        nextToken
      }
      conversationParticipants {
        items {
          id
          conversationId
          talentId
          clientId
          recruiterId
          createdAt
          updatedAt
          version
          client {
            id
            fullName
            email
            phone
            country
            role
            companyID
            timezone
            availability {
              day
              date
              availableSlots {
                startTime
                endTime
              }
            }
            googleCalendarId
            remainingCredits
            totalCredits
            stripeCustomerId
            currentStripeSubscriptionId
            hasMandate
            createdAt
            updatedAt
            version
            company {
              id
              name
              logo
              stage
              tagline
              description
              address {
                id
                streetAddress
                apartmentAddress
                postalCode
                district
                city
                country
              }
              industry
              associatedWith
              createdAt
              updatedAt
            }
          }
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
          recruiter {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              currentEarnings
              profession
              createdAt
              updatedAt
              version
              recruiterProfileRecruiterId
            }
          }
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      isActive
      lastMessageAt
      version
      messages {
        items {
          id
          content
          senderInfo {
            id
            fullName
            email
            phone
            role
          }
          conversationId
          createdAt
          updatedAt
          isRead
          readBy {
            id
            fullName
            email
            phone
            role
          }
          version
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
        }
        nextToken
      }
      conversationParticipants {
        items {
          id
          conversationId
          talentId
          clientId
          recruiterId
          createdAt
          updatedAt
          version
          client {
            id
            fullName
            email
            phone
            country
            role
            companyID
            timezone
            availability {
              day
              date
              availableSlots {
                startTime
                endTime
              }
            }
            googleCalendarId
            remainingCredits
            totalCredits
            stripeCustomerId
            currentStripeSubscriptionId
            hasMandate
            createdAt
            updatedAt
            version
            company {
              id
              name
              logo
              stage
              tagline
              description
              address {
                id
                streetAddress
                apartmentAddress
                postalCode
                district
                city
                country
              }
              industry
              associatedWith
              createdAt
              updatedAt
            }
          }
          conversation {
            id
            name
            createdAt
            updatedAt
            isActive
            lastMessageAt
            version
            messages {
              items {
                id
                content
                conversationId
                createdAt
                updatedAt
                isRead
                version
              }
              nextToken
            }
            conversationParticipants {
              items {
                id
                conversationId
                talentId
                clientId
                recruiterId
                createdAt
                updatedAt
                version
              }
              nextToken
            }
          }
          recruiter {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              currentEarnings
              profession
              createdAt
              updatedAt
              version
              recruiterProfileRecruiterId
            }
          }
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createConversationParticipants = /* GraphQL */ `
  mutation CreateConversationParticipants(
    $input: CreateConversationParticipantsInput!
    $condition: ModelConversationParticipantsConditionInput
  ) {
    createConversationParticipants(input: $input, condition: $condition) {
      id
      conversationId
      talentId
      clientId
      recruiterId
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updateConversationParticipants = /* GraphQL */ `
  mutation UpdateConversationParticipants(
    $input: UpdateConversationParticipantsInput!
    $condition: ModelConversationParticipantsConditionInput
  ) {
    updateConversationParticipants(input: $input, condition: $condition) {
      id
      conversationId
      talentId
      clientId
      recruiterId
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deleteConversationParticipants = /* GraphQL */ `
  mutation DeleteConversationParticipants(
    $input: DeleteConversationParticipantsInput!
    $condition: ModelConversationParticipantsConditionInput
  ) {
    deleteConversationParticipants(input: $input, condition: $condition) {
      id
      conversationId
      talentId
      clientId
      recruiterId
      createdAt
      updatedAt
      version
      client {
        id
        fullName
        email
        phone
        country
        role
        companyID
        timezone
        availability {
          day
          date
          availableSlots {
            startTime
            endTime
          }
        }
        googleCalendarId
        remainingCredits
        totalCredits
        stripeCustomerId
        currentStripeSubscriptionId
        hasMandate
        createdAt
        updatedAt
        version
        company {
          id
          name
          logo
          stage
          tagline
          description
          address {
            id
            streetAddress
            apartmentAddress
            postalCode
            district
            city
            country
          }
          industry
          associatedWith
          createdAt
          updatedAt
        }
      }
      conversation {
        id
        name
        createdAt
        updatedAt
        isActive
        lastMessageAt
        version
        messages {
          items {
            id
            content
            senderInfo {
              id
              fullName
              email
              phone
              role
            }
            conversationId
            createdAt
            updatedAt
            isRead
            readBy {
              id
              fullName
              email
              phone
              role
            }
            version
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
          }
          nextToken
        }
        conversationParticipants {
          items {
            id
            conversationId
            talentId
            clientId
            recruiterId
            createdAt
            updatedAt
            version
            client {
              id
              fullName
              email
              phone
              country
              role
              companyID
              timezone
              availability {
                day
                date
              }
              googleCalendarId
              remainingCredits
              totalCredits
              stripeCustomerId
              currentStripeSubscriptionId
              hasMandate
              createdAt
              updatedAt
              version
              company {
                id
                name
                logo
                stage
                tagline
                description
                industry
                associatedWith
                createdAt
                updatedAt
              }
            }
            conversation {
              id
              name
              createdAt
              updatedAt
              isActive
              lastMessageAt
              version
              messages {
                nextToken
              }
              conversationParticipants {
                nextToken
              }
            }
            recruiter {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                currentEarnings
                profession
                createdAt
                updatedAt
                version
                recruiterProfileRecruiterId
              }
            }
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
          }
          nextToken
        }
      }
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createRecruiter = /* GraphQL */ `
  mutation CreateRecruiter(
    $input: CreateRecruiterInput!
    $condition: ModelRecruiterConditionInput
  ) {
    createRecruiter(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        currentEarnings
        profession
        createdAt
        updatedAt
        version
        recruiterProfileRecruiterId
      }
    }
  }
`;
export const updateRecruiter = /* GraphQL */ `
  mutation UpdateRecruiter(
    $input: UpdateRecruiterInput!
    $condition: ModelRecruiterConditionInput
  ) {
    updateRecruiter(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        currentEarnings
        profession
        createdAt
        updatedAt
        version
        recruiterProfileRecruiterId
      }
    }
  }
`;
export const deleteRecruiter = /* GraphQL */ `
  mutation DeleteRecruiter(
    $input: DeleteRecruiterInput!
    $condition: ModelRecruiterConditionInput
  ) {
    deleteRecruiter(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        currentEarnings
        profession
        createdAt
        updatedAt
        version
        recruiterProfileRecruiterId
      }
    }
  }
`;
export const createRecruiterProfile = /* GraphQL */ `
  mutation CreateRecruiterProfile(
    $input: CreateRecruiterProfileInput!
    $condition: ModelRecruiterProfileConditionInput
  ) {
    createRecruiterProfile(input: $input, condition: $condition) {
      id
      currentEarnings
      profession
      createdAt
      updatedAt
      version
      recruiterProfileRecruiterId
    }
  }
`;
export const updateRecruiterProfile = /* GraphQL */ `
  mutation UpdateRecruiterProfile(
    $input: UpdateRecruiterProfileInput!
    $condition: ModelRecruiterProfileConditionInput
  ) {
    updateRecruiterProfile(input: $input, condition: $condition) {
      id
      currentEarnings
      profession
      createdAt
      updatedAt
      version
      recruiterProfileRecruiterId
    }
  }
`;
export const deleteRecruiterProfile = /* GraphQL */ `
  mutation DeleteRecruiterProfile(
    $input: DeleteRecruiterProfileInput!
    $condition: ModelRecruiterProfileConditionInput
  ) {
    deleteRecruiterProfile(input: $input, condition: $condition) {
      id
      currentEarnings
      profession
      createdAt
      updatedAt
      version
      recruiterProfileRecruiterId
    }
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral(
    $input: CreateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    createReferral(input: $input, condition: $condition) {
      id
      referrerId
      referralReferredId
      recruiterId
      email
      createdAt
      updatedAt
      version
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      referrer {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      referred {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral(
    $input: UpdateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    updateReferral(input: $input, condition: $condition) {
      id
      referrerId
      referralReferredId
      recruiterId
      email
      createdAt
      updatedAt
      version
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      referrer {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      referred {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deleteReferral = /* GraphQL */ `
  mutation DeleteReferral(
    $input: DeleteReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    deleteReferral(input: $input, condition: $condition) {
      id
      referrerId
      referralReferredId
      recruiterId
      email
      createdAt
      updatedAt
      version
      recruiter {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          currentEarnings
          profession
          createdAt
          updatedAt
          version
          recruiterProfileRecruiterId
        }
      }
      referrer {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      referred {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createTalent = /* GraphQL */ `
  mutation CreateTalent(
    $input: CreateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    createTalent(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTalent = /* GraphQL */ `
  mutation UpdateTalent(
    $input: UpdateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    updateTalent(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteTalent = /* GraphQL */ `
  mutation DeleteTalent(
    $input: DeleteTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    deleteTalent(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      country
      role
      createdAt
      updatedAt
      version
      profile {
        id
        skills
        experience
        isAvailable
        social
        englishProficiency
        problemSolvingScore
        problemSolvingPercentage
        problemSolvingTest {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        isPassPS
        isPassHR
        isPassFinalTech
        isHired
        isNetwork
        isPlaced
        hrInterviewVideoId
        videoResponse {
          question
          thumbnail
          link
        }
        statusMeta
        heardFrom
        currentSalary
        expectedSalary
        expectedSalaryInUsd
        interests
        talentFeedback {
          type
          score
          sentiment
          description
          givenAt
          meta
        }
        resumeLink
        takenDomainTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        educationHistory {
          degree
          school
          startDate
          endDate
        }
        employmentHistory {
          title
          summary
          startDate
          endDate
          company
          currentJob
        }
        socialProfiles {
          urlType
          url
        }
        resetTests {
          domainSkillTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          problemSolvingTests {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          hrInterviews {
            hrVideoId
            isPass
            dateAttempted
            dateOfHRStatus
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
          finalInterviews {
            isPass
            dateAttempted
            feedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
          }
        }
        imgLink
        bio
        title
        talentTypeID
        analyticsData {
          source
          medium
          campaign
          referral
        }
        isProfileCompleted
        workableId
        aiInterviewInProgress
        videoInterviewProvider
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            problemSolvingTest {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            isPlaced
            hrInterviewVideoId
            videoResponse {
              question
              thumbnail
              link
            }
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            expectedSalaryInUsd
            interests
            talentFeedback {
              type
              score
              sentiment
              description
              givenAt
              meta
            }
            resumeLink
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            educationHistory {
              degree
              school
              startDate
              endDate
            }
            employmentHistory {
              title
              summary
              startDate
              endDate
              company
              currentJob
            }
            socialProfiles {
              urlType
              url
            }
            resetTests {
              domainSkillTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              problemSolvingTests {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              hrInterviews {
                hrVideoId
                isPass
                dateAttempted
                dateOfHRStatus
              }
              finalInterviews {
                isPass
                dateAttempted
              }
            }
            imgLink
            bio
            title
            talentTypeID
            analyticsData {
              source
              medium
              campaign
              referral
            }
            isProfileCompleted
            workableId
            aiInterviewInProgress
            videoInterviewProvider
            createdAt
            updatedAt
            version
            talent {
              id
              fullName
              email
              phone
              country
              role
              createdAt
              updatedAt
              version
              profile {
                id
                skills
                experience
                isAvailable
                social
                englishProficiency
                problemSolvingScore
                problemSolvingPercentage
                isPassPS
                isPassHR
                isPassFinalTech
                isHired
                isNetwork
                isPlaced
                hrInterviewVideoId
                statusMeta
                heardFrom
                currentSalary
                expectedSalary
                expectedSalaryInUsd
                interests
                resumeLink
                imgLink
                bio
                title
                talentTypeID
                isProfileCompleted
                workableId
                aiInterviewInProgress
                videoInterviewProvider
                createdAt
                updatedAt
                version
                talentProfileTalentId
              }
            }
            talentProfileTalentId
            talentType {
              id
              name
              title
              icon
              sequence
              skills {
                name
                title
              }
              interests {
                name
                title
              }
              sortType
              stages {
                type
                isOptional
                sequenceNumber
                passingPercentage
              }
              videoInterviewProvider
              hrInterviewJobId
              hrInterviewAIURL
              atsJobID
              isActive
              createdAt
              updatedAt
            }
          }
        }
        talentProfileTalentId
        talentType {
          id
          name
          title
          icon
          sequence
          skills {
            name
            title
          }
          interests {
            name
            title
          }
          sortType
          stages {
            type
            isOptional
            sequenceNumber
            passingPercentage
          }
          videoInterviewProvider
          hrInterviewJobId
          hrInterviewAIURL
          atsJobID
          isActive
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTalentProfile = /* GraphQL */ `
  mutation CreateTalentProfile(
    $input: CreateTalentProfileInput!
    $condition: ModelTalentProfileConditionInput
  ) {
    createTalentProfile(input: $input, condition: $condition) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      workableId
      aiInterviewInProgress
      videoInterviewProvider
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      talentProfileTalentId
      talentType {
        id
        name
        title
        icon
        sequence
        skills {
          name
          title
        }
        interests {
          name
          title
        }
        sortType
        stages {
          type
          isOptional
          sequenceNumber
          passingPercentage
        }
        videoInterviewProvider
        hrInterviewJobId
        hrInterviewAIURL
        atsJobID
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTalentProfile = /* GraphQL */ `
  mutation UpdateTalentProfile(
    $input: UpdateTalentProfileInput!
    $condition: ModelTalentProfileConditionInput
  ) {
    updateTalentProfile(input: $input, condition: $condition) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      workableId
      aiInterviewInProgress
      videoInterviewProvider
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      talentProfileTalentId
      talentType {
        id
        name
        title
        icon
        sequence
        skills {
          name
          title
        }
        interests {
          name
          title
        }
        sortType
        stages {
          type
          isOptional
          sequenceNumber
          passingPercentage
        }
        videoInterviewProvider
        hrInterviewJobId
        hrInterviewAIURL
        atsJobID
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTalentProfile = /* GraphQL */ `
  mutation DeleteTalentProfile(
    $input: DeleteTalentProfileInput!
    $condition: ModelTalentProfileConditionInput
  ) {
    deleteTalentProfile(input: $input, condition: $condition) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      workableId
      aiInterviewInProgress
      videoInterviewProvider
      createdAt
      updatedAt
      version
      talent {
        id
        fullName
        email
        phone
        country
        role
        createdAt
        updatedAt
        version
        profile {
          id
          skills
          experience
          isAvailable
          social
          englishProficiency
          problemSolvingScore
          problemSolvingPercentage
          problemSolvingTest {
            testId
            candidateId
            score
            isPass
            provider
            dateAttempted
            testEnd
            attemptId
            percentage
            totalScore
            testLink
          }
          isPassPS
          isPassHR
          isPassFinalTech
          isHired
          isNetwork
          isPlaced
          hrInterviewVideoId
          videoResponse {
            question
            thumbnail
            link
          }
          statusMeta
          heardFrom
          currentSalary
          expectedSalary
          expectedSalaryInUsd
          interests
          talentFeedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
          resumeLink
          takenDomainTests {
            skill
            score
            isPass
            provider
            testStart
            testEnd
            percentage
            totalScore
            testLink
            domainTestData
          }
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          resetTests {
            domainSkillTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            problemSolvingTests {
              testId
              candidateId
              score
              isPass
              provider
              dateAttempted
              testEnd
              attemptId
              percentage
              totalScore
              testLink
            }
            hrInterviews {
              hrVideoId
              isPass
              dateAttempted
              dateOfHRStatus
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
            finalInterviews {
              isPass
              dateAttempted
              feedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
            }
          }
          imgLink
          bio
          title
          talentTypeID
          analyticsData {
            source
            medium
            campaign
            referral
          }
          isProfileCompleted
          workableId
          aiInterviewInProgress
          videoInterviewProvider
          createdAt
          updatedAt
          version
          talent {
            id
            fullName
            email
            phone
            country
            role
            createdAt
            updatedAt
            version
            profile {
              id
              skills
              experience
              isAvailable
              social
              englishProficiency
              problemSolvingScore
              problemSolvingPercentage
              problemSolvingTest {
                testId
                candidateId
                score
                isPass
                provider
                dateAttempted
                testEnd
                attemptId
                percentage
                totalScore
                testLink
              }
              isPassPS
              isPassHR
              isPassFinalTech
              isHired
              isNetwork
              isPlaced
              hrInterviewVideoId
              videoResponse {
                question
                thumbnail
                link
              }
              statusMeta
              heardFrom
              currentSalary
              expectedSalary
              expectedSalaryInUsd
              interests
              talentFeedback {
                type
                score
                sentiment
                description
                givenAt
                meta
              }
              resumeLink
              takenDomainTests {
                skill
                score
                isPass
                provider
                testStart
                testEnd
                percentage
                totalScore
                testLink
                domainTestData
              }
              educationHistory {
                degree
                school
                startDate
                endDate
              }
              employmentHistory {
                title
                summary
                startDate
                endDate
                company
                currentJob
              }
              socialProfiles {
                urlType
                url
              }
              imgLink
              bio
              title
              talentTypeID
              analyticsData {
                source
                medium
                campaign
                referral
              }
              isProfileCompleted
              workableId
              aiInterviewInProgress
              videoInterviewProvider
              createdAt
              updatedAt
              version
              talent {
                id
                fullName
                email
                phone
                country
                role
                createdAt
                updatedAt
                version
              }
              talentProfileTalentId
              talentType {
                id
                name
                title
                icon
                sequence
                sortType
                videoInterviewProvider
                hrInterviewJobId
                hrInterviewAIURL
                atsJobID
                isActive
                createdAt
                updatedAt
              }
            }
          }
          talentProfileTalentId
          talentType {
            id
            name
            title
            icon
            sequence
            skills {
              name
              title
            }
            interests {
              name
              title
            }
            sortType
            stages {
              type
              isOptional
              sequenceNumber
              passingPercentage
            }
            videoInterviewProvider
            hrInterviewJobId
            hrInterviewAIURL
            atsJobID
            isActive
            createdAt
            updatedAt
          }
        }
      }
      talentProfileTalentId
      talentType {
        id
        name
        title
        icon
        sequence
        skills {
          name
          title
        }
        interests {
          name
          title
        }
        sortType
        stages {
          type
          isOptional
          sequenceNumber
          passingPercentage
        }
        videoInterviewProvider
        hrInterviewJobId
        hrInterviewAIURL
        atsJobID
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const createForexRates = /* GraphQL */ `
  mutation CreateForexRates(
    $input: CreateForexRatesInput!
    $condition: ModelForexRatesConditionInput
  ) {
    createForexRates(input: $input, condition: $condition) {
      id
      currencyType
      usdConversionRate
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateForexRates = /* GraphQL */ `
  mutation UpdateForexRates(
    $input: UpdateForexRatesInput!
    $condition: ModelForexRatesConditionInput
  ) {
    updateForexRates(input: $input, condition: $condition) {
      id
      currencyType
      usdConversionRate
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteForexRates = /* GraphQL */ `
  mutation DeleteForexRates(
    $input: DeleteForexRatesInput!
    $condition: ModelForexRatesConditionInput
  ) {
    deleteForexRates(input: $input, condition: $condition) {
      id
      currencyType
      usdConversionRate
      createdAt
      updatedAt
      version
    }
  }
`;
export const createTalentProfileTransient = /* GraphQL */ `
  mutation CreateTalentProfileTransient(
    $input: CreateTalentProfileTransientInput!
    $condition: ModelTalentProfileTransientConditionInput
  ) {
    createTalentProfileTransient(input: $input, condition: $condition) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      talentProfileTalentId
      source
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateTalentProfileTransient = /* GraphQL */ `
  mutation UpdateTalentProfileTransient(
    $input: UpdateTalentProfileTransientInput!
    $condition: ModelTalentProfileTransientConditionInput
  ) {
    updateTalentProfileTransient(input: $input, condition: $condition) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      talentProfileTalentId
      source
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteTalentProfileTransient = /* GraphQL */ `
  mutation DeleteTalentProfileTransient(
    $input: DeleteTalentProfileTransientInput!
    $condition: ModelTalentProfileTransientConditionInput
  ) {
    deleteTalentProfileTransient(input: $input, condition: $condition) {
      id
      skills
      experience
      isAvailable
      social
      englishProficiency
      problemSolvingScore
      problemSolvingPercentage
      problemSolvingTest {
        testId
        candidateId
        score
        isPass
        provider
        dateAttempted
        testEnd
        attemptId
        percentage
        totalScore
        testLink
      }
      isPassPS
      isPassHR
      isPassFinalTech
      isHired
      isNetwork
      isPlaced
      hrInterviewVideoId
      videoResponse {
        question
        thumbnail
        link
      }
      statusMeta
      heardFrom
      currentSalary
      expectedSalary
      expectedSalaryInUsd
      interests
      talentFeedback {
        type
        score
        sentiment
        description
        givenAt
        meta
      }
      resumeLink
      takenDomainTests {
        skill
        score
        isPass
        provider
        testStart
        testEnd
        percentage
        totalScore
        testLink
        domainTestData
      }
      educationHistory {
        degree
        school
        startDate
        endDate
      }
      employmentHistory {
        title
        summary
        startDate
        endDate
        company
        currentJob
      }
      socialProfiles {
        urlType
        url
      }
      resetTests {
        domainSkillTests {
          skill
          score
          isPass
          provider
          testStart
          testEnd
          percentage
          totalScore
          testLink
          domainTestData
        }
        problemSolvingTests {
          testId
          candidateId
          score
          isPass
          provider
          dateAttempted
          testEnd
          attemptId
          percentage
          totalScore
          testLink
        }
        hrInterviews {
          hrVideoId
          isPass
          dateAttempted
          dateOfHRStatus
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
        finalInterviews {
          isPass
          dateAttempted
          feedback {
            type
            score
            sentiment
            description
            givenAt
            meta
          }
        }
      }
      imgLink
      bio
      title
      talentTypeID
      analyticsData {
        source
        medium
        campaign
        referral
      }
      isProfileCompleted
      talentProfileTalentId
      source
      createdAt
      updatedAt
      version
    }
  }
`;
export const createTalentProfileProcessedIDs = /* GraphQL */ `
  mutation CreateTalentProfileProcessedIDs(
    $input: CreateTalentProfileProcessedIDsInput!
    $condition: ModelTalentProfileProcessedIDsConditionInput
  ) {
    createTalentProfileProcessedIDs(input: $input, condition: $condition) {
      id
      talentProfileId
      createdAt
      updatedAt
      version
      talentProfileTalentId
    }
  }
`;
export const updateTalentProfileProcessedIDs = /* GraphQL */ `
  mutation UpdateTalentProfileProcessedIDs(
    $input: UpdateTalentProfileProcessedIDsInput!
    $condition: ModelTalentProfileProcessedIDsConditionInput
  ) {
    updateTalentProfileProcessedIDs(input: $input, condition: $condition) {
      id
      talentProfileId
      createdAt
      updatedAt
      version
      talentProfileTalentId
    }
  }
`;
export const deleteTalentProfileProcessedIDs = /* GraphQL */ `
  mutation DeleteTalentProfileProcessedIDs(
    $input: DeleteTalentProfileProcessedIDsInput!
    $condition: ModelTalentProfileProcessedIDsConditionInput
  ) {
    deleteTalentProfileProcessedIDs(input: $input, condition: $condition) {
      id
      talentProfileId
      createdAt
      updatedAt
      version
      talentProfileTalentId
    }
  }
`;
export const createTalentType = /* GraphQL */ `
  mutation CreateTalentType(
    $input: CreateTalentTypeInput!
    $condition: ModelTalentTypeConditionInput
  ) {
    createTalentType(input: $input, condition: $condition) {
      id
      name
      title
      icon
      sequence
      skills {
        name
        title
      }
      interests {
        name
        title
      }
      sortType
      stages {
        type
        isOptional
        sequenceNumber
        passingPercentage
      }
      videoInterviewProvider
      hrInterviewJobId
      hrInterviewAIURL
      atsJobID
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateTalentType = /* GraphQL */ `
  mutation UpdateTalentType(
    $input: UpdateTalentTypeInput!
    $condition: ModelTalentTypeConditionInput
  ) {
    updateTalentType(input: $input, condition: $condition) {
      id
      name
      title
      icon
      sequence
      skills {
        name
        title
      }
      interests {
        name
        title
      }
      sortType
      stages {
        type
        isOptional
        sequenceNumber
        passingPercentage
      }
      videoInterviewProvider
      hrInterviewJobId
      hrInterviewAIURL
      atsJobID
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteTalentType = /* GraphQL */ `
  mutation DeleteTalentType(
    $input: DeleteTalentTypeInput!
    $condition: ModelTalentTypeConditionInput
  ) {
    deleteTalentType(input: $input, condition: $condition) {
      id
      name
      title
      icon
      sequence
      skills {
        name
        title
      }
      interests {
        name
        title
      }
      sortType
      stages {
        type
        isOptional
        sequenceNumber
        passingPercentage
      }
      videoInterviewProvider
      hrInterviewJobId
      hrInterviewAIURL
      atsJobID
      isActive
      createdAt
      updatedAt
    }
  }
`;
