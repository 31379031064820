import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledProps } from "../../../utils";

export const H3 = styled.h3`
  font-size: 1.5rem;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.25rem;
`;

export const Paragraph = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const H5 = styled.h5`
  font-size: 1rem;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: -0.25rem;
`;

export const H6 = styled.h6`
  font-size: 0.875rem;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: -0.25rem;
`;

export const CardWrapper = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 1.25rem 2rem 0.5rem 2rem;
  margin-bottom: 0.5rem;
`;

export const HR = styled.hr`
  border-width: 0.0063rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

export const InnerWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const LogoWrapper = styled.div`
  padding: 0.375rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.0625rem solid #e8e8e8;
`;

export const CompanyLogo = styled.img`
  width: 1.5625rem;
  height: 1.5625rem;
`;

export const NameLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  margin-bottom: 0.5rem;
`;

export const Span = styled.span`
  font-size: 0.75rem;
`;

export const Dot = styled.span`
  width: 0.125rem;
  height: 0.125rem;
  border-radius: 50%;
  background-color: #000000;
`;

export const CompanyDetail = styled.p`
  font-size: 0.75rem;
`;

export const BtnGroup = styled.p`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;
`;

export const StackBtn = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  font-size: 0.75rem;
  font-weight: 500;
  color: #8083a3;
  background-color: #f5f5fa;
  text-align: center;
`;

export const ViewBtn = styled.button`
  padding: 0 1.5rem;
  border-radius: 1rem;
  border: none;
  font-size: 0.75rem;
  height: 1.5rem;
  cursor: pointer;
`;

export const BackArrow = styled(Link)`
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
  left: -1rem;
  right: 0;
  transform: rotate(-180deg);
`;

export const JobDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

export const AboutSection = styled.div`
  flex: 0.65;
`;

export const PriceSection = styled.div`
  background-color: #ffffff;
  border-radius: 1rem;
  border: 0.0625rem solid #e8e8e8;
  flex: 0.35;
  padding: 1rem 1.5rem 2rem;
  height: fit-content;
`;

export const UL = styled.ul`
  margin-top: 1rem;
`;

export const LI = styled.li`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const Company = styled.div`
  margin-top: 2rem;
`;

export const LogoName = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Salary = styled.p`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  font-family: "Poppins", sans-serif;
`;

export const JobPost = styled.div`
  color: #808191;
`;

export const JobText = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;

export const PostLogoWrap = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CompanyName = styled.div`
  color: #000000;
  font-weight: 500;
`;

export const DetailsSection = styled.div`
  color: #808191;
  font-weight: 500;
`;

export const DetailType = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DetailLogo = styled.img`
  width: 1.5625rem;
  height: 1.5625rem;
`;

export const DetailText = styled.div`
  color: #808191;
`;

export const ApplyBtn = styled.button<{ isApplied?: boolean }>`
  position: relative;
  width: 100%;
  background-color: #6c5dd3;
  color: #ffffff;
  border-radius: 20.4688rem;
  border: none;
  width: 100%;
  min-height: 3.5rem;
  text-decoration: none;
  font-weight: 600;
  margin-top: 2rem;
  cursor: ${(props): StyledProps => (props.isApplied ? "none" : "pointer")};
  opacity: ${(props): StyledProps => (props.isApplied ? "0.5" : "1")};
`;

export const IconWrapper = styled.div`
  padding: 0.375rem 0.625rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.0625rem solid #e8e8e8;
`;

export const ToastWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
