import React from "react";
import { imageUrls } from "../../../utils";
import * as S from "./styles";

export const RemotebaseLogo: React.FC = () => {
  return (
    <>
      <S.HeaderLogoBox to="/">
        <S.HeaderLogoImg src={imageUrls.newRBLogo} alt="remotebase logo" />
      </S.HeaderLogoBox>
    </>
  );
};

export default RemotebaseLogo;
