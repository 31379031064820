import { IAuthProps } from "@remotebase/constants";
import React from "react";
import {
  UnAuthRoutes as Route,
  alreadyMember,
  signIn,
  resetPasswordText,
  resetPasswordFormTagline,
} from "../..";
import ResetPassForm from "./resetPasswordForm";
import * as Styled from "./styles";

export const AuthCommonResetPass: React.FC<IAuthProps> = ({
  formProps: { isLoading, performAction },
}) => {
  return (
    <Styled.LoginWrapper>
      <Styled.LoginHeading>{resetPasswordText}</Styled.LoginHeading>
      <Styled.LoginDescrip>{resetPasswordFormTagline}</Styled.LoginDescrip>
      <ResetPassForm isLoading={isLoading} submitPass={performAction} />
      <Styled.AccountText>
        {alreadyMember}
        <Styled.SignupLink to={Route.Login}>{signIn}</Styled.SignupLink>
      </Styled.AccountText>
    </Styled.LoginWrapper>
  );
};

export default AuthCommonResetPass;
