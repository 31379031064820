import styled, { css } from "styled-components";
import { ColorObject } from "@remotebase/constants";

const sizeKey = {
  large: "1.75rem",
  small: "1.25rem",
};

export const Wrapper = styled.div<{
  $variant: keyof typeof ColorObject;
  $size: keyof typeof sizeKey;
}>`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  height: 1.75rem;
  border-radius: 2rem;
  font-family: "Roboto";
  border-width: 1px;
  border-style: solid;
  ${({ $variant, $size }) =>
    css`
      color: ${ColorObject[$variant]};
      border-color: ${ColorObject[$variant]};
      height: ${sizeKey[$size]};
    `}
`;

export const Icon = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.div`
  flex-grow: 1;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;
