import styled from "styled-components";

export const AuthPeopleWorkingBox = styled.div`
  pointer-events: none;
  margin-top: 60px;
`;

export const PeopleWorkingImgBox = styled.div`
  pointer-events: none;
  margin-top: 60px;
`;

export const ImgEntryPic = styled.img`
  width: 100%;
`;
