import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ListTalentProfilesQueryVariables,
  ListJobMatchesQuery,
  UpdateJobMatchStageMutation,
  UpdateJobMatchStageMutationVariables,
  JobMatch,
  UpdateJobMatchFeedbackMutation,
  UpdateJobMatchFeedbackMutationVariables,
} from "@remotebase/amplify-constants/API";

import { updateJobMatchFeedback, updateJobMatchStage } from "graphql/mutations";
import {
  ReturnPropsGetTalentMatchCompany,
  ReturnPropsUpdateTalentMatchCompany,
  ReturnPropsUpdateJobMatchStage,
} from "hooks/types";
import { getQuery } from "hooks/utils/helpers";
import * as queries from "@remotebase/amplify-constants/graphql/queries";

export const useUpdateTalentMatchCompany = (): ReturnPropsUpdateTalentMatchCompany => {
  const [
    updateTalentCompanyMatch,
    {
      data: updateTalentCompanyMatchData,
      loading: updateTalentMatchCompanyLoading,
      error: updateTalentMatchCompanyError,
    },
  ] = useMutation<UpdateJobMatchFeedbackMutation, UpdateJobMatchFeedbackMutationVariables>(
    getQuery(updateJobMatchFeedback),
  );

  return {
    updateTalentCompanyMatch,
    data: updateTalentCompanyMatchData?.updateJobMatchFeedback?.data as JobMatch,
    isLoading: updateTalentMatchCompanyLoading,
    error: updateTalentMatchCompanyError,
  };
};

export const useGetTalentMatchCompany = (): ReturnPropsGetTalentMatchCompany => {
  const [listTalentMatchCompanies, { data, loading }] = useLazyQuery<
    ListJobMatchesQuery,
    ListTalentProfilesQueryVariables
  >(getQuery(queries.listJobMatches));
  const talent = (data?.listJobMatches?.items || null) as ReturnPropsGetTalentMatchCompany["data"];

  return { listTalentMatchCompanies, data: talent, loading };
};

export const useUpdateJobMatchStage = (): ReturnPropsUpdateJobMatchStage => {
  const [
    updateTalentJobMatchStage,
    {
      data: updateTalentCompanyMatchData,
      loading: updateTalentMatchCompanyLoading,
      error: updateTalentMatchCompanyError,
    },
  ] = useMutation<UpdateJobMatchStageMutation, UpdateJobMatchStageMutationVariables>(
    getQuery(updateJobMatchStage),
  );

  return {
    updateTalentJobMatchStage,
    data: updateTalentCompanyMatchData?.updateJobMatchStage as ReturnPropsUpdateJobMatchStage["data"],
    isLoading: updateTalentMatchCompanyLoading,
    error: updateTalentMatchCompanyError,
  };
};
