import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { sentryDSN } from "utils";

export const sentryInit = (): void => {
  if (sentryDSN) {
    console.log(true);
    Sentry.init({
      dsn: sentryDSN,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

export default sentryInit;
