import { ChevronLeft } from "assets/icons";
import { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import TalentProfileView from "./TalentsProfileView";
import * as Styled from "./styles";

const TalentProfileVersion: FC = () => {
  const { goBack } = useHistory();

  return (
    <Fragment>
      <Styled.TalentHead>
        <Styled.BackLink to="#" onClick={goBack}>
          <Styled.BackLinkIcon>
            <ChevronLeft />
          </Styled.BackLinkIcon>
          <Styled.BackLinkText>go back</Styled.BackLinkText>
        </Styled.BackLink>
      </Styled.TalentHead>
      <TalentProfileView />
    </Fragment>
  );
};

export default TalentProfileVersion;
