import { RedXLineIcon, StartCheckIcon } from "assets/icons";
import React from "react";
import { imageUrls } from "@remotebase/components";
import * as S from "./styles";

interface Props {
  name?: string | null;
  imgLink?: string | null;
  skillScore?: number;
  expectedSalary?: string;
  isSelected?: boolean;
}

export const TalentFeedbackCard: React.FC<Props> = ({
  name,
  imgLink,
  // skillScore,
  expectedSalary,
  isSelected,
}) => {
  return (
    <S.TalentBoxCanvas>
      <S.TalentBox>
        <S.TalentBoxMain>
          <S.TalentBoxImgContainer>
            <S.TalentBoxImg src={imgLink ?? imageUrls.userDefault} />
            <S.TalentInnerBg src={"/images/bg-1.png"} />
          </S.TalentBoxImgContainer>
          <S.TalentDetailSection>
            <S.DeveloperInfoWrapper>
              <S.DeveloperNameInfoWrapper>
                <S.DeveloperNameStatusWrapper>
                  <S.DeveloperInnerWrapper>
                    <S.DeveloperName>{name}</S.DeveloperName>
                    <S.InnerWrapper>
                      <S.DeveloperRate>{expectedSalary}/mn</S.DeveloperRate>
                      {/* <S.DeveloperSeparator />
                      <S.DeveloperSkillScore>{skillScore}% Skill Score</S.DeveloperSkillScore> */}
                    </S.InnerWrapper>
                    <S.DeveloperFeedBack>
                      {isSelected ? (
                        <>
                          <StartCheckIcon />
                          <S.SelectionMsg>Accepted by you</S.SelectionMsg>
                        </>
                      ) : (
                        <>
                          <RedXLineIcon />
                          <S.SelectionMsg>Rejected by you</S.SelectionMsg>
                        </>
                      )}
                    </S.DeveloperFeedBack>
                  </S.DeveloperInnerWrapper>
                </S.DeveloperNameStatusWrapper>
              </S.DeveloperNameInfoWrapper>
            </S.DeveloperInfoWrapper>
          </S.TalentDetailSection>
        </S.TalentBoxMain>
      </S.TalentBox>
    </S.TalentBoxCanvas>
  );
};

export default TalentFeedbackCard;
