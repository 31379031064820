import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthType, VerifyformProps } from "@remotebase/constants";
import { AuthenticationInput } from "../../formInput";
import { AuthSubmitBtn } from "../../buttons";
import {
  AuthForm,
  codeValidation,
  codePlaceholder,
  confirmEmailFormTagline,
  reverifyHeader,
  reVerifyText,
  signUpHeader,
} from "../../utils";
import { AuthCommonResendCode } from "../resendVerification";
import * as Styled from "./styles";

export const AuthCommonVerifyAccount: FC<VerifyformProps> = ({
  authType,
  email,
  resendVerification,
  formProps: { isLoading, performAction },
}) => {
  const formHook = useForm({ resolver: yupResolver(codeValidation), mode: "onChange" });

  const heading = useMemo(() => (authType === AuthType.SignUp ? signUpHeader : reverifyHeader), []);

  return (
    <Styled.LoginWrapper>
      <Styled.LoginHeading>{heading}</Styled.LoginHeading>
      <Styled.LoginDescrip>{confirmEmailFormTagline}</Styled.LoginDescrip>
      <form onSubmit={formHook.handleSubmit(({ code }) => performAction({ code }))}>
        <Styled.FormGroups>
          <AuthenticationInput
            type="code"
            placeholder={codePlaceholder}
            label="Verification Code"
            title={AuthForm.code}
            handler={formHook}
          />
        </Styled.FormGroups>
        <AuthCommonResendCode email={email} resendVerificationCode={resendVerification} />
        <AuthSubmitBtn isLoading={isLoading} buttonText={reVerifyText} />
      </form>
    </Styled.LoginWrapper>
  );
};

export default AuthCommonVerifyAccount;
