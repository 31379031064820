import { FC, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { HRVideo } from "../../../utils/constants/types";
import * as Styled from "./videoWidget.styled";

interface Props {
  urls: Array<HRVideo | null>;
}

export const VideoWidget: FC<Props> = ({ urls }) => {
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [question, setQuestion] = useState<string>("");

  useEffect(() => {
    setCurrentUrl(urls[0]?.src || "");
    setQuestion(urls[0]?.question || "");
  }, [urls]);

  const onSelectVideo = (item: HRVideo): void => {
    setCurrentUrl(item.src || "");
    setQuestion(item.question || "");
  };

  return (
    <Styled.videoWidgetWrapper>
      <Styled.videoLinks>
        {urls.map((item, i) => (
          <Styled.videoLinkItem
            className={currentUrl === item?.src ? "selected" : ""}
            onClick={() => onSelectVideo(item!)}
            key={item?.src}
          >
            {i + 1}
          </Styled.videoLinkItem>
        ))}
      </Styled.videoLinks>
      <Styled.questionHeading>{question}</Styled.questionHeading>
      <ReactPlayer controls width={"100%"} height={"100%"} url={currentUrl} />
    </Styled.videoWidgetWrapper>
  );
};

export default VideoWidget;
