import "aos/dist/aos.css";
import Amplify from "aws-amplify";
import { IntercomProvider } from "react-use-intercom";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ErrorProvider from "state/error/error.provider";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./assets/css/icon.css";
import "./assets/css/font.css";
import { AuthProvider } from "state/auth";
import { shouldUseIntercom } from "utils";
import config from "./aws-exports";
import MainRouter from "./router";

Amplify.configure(config);

const App: React.FC = () => {
  return (
    <ErrorProvider>
      <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}
        shouldInitialize={shouldUseIntercom}
        autoBoot
      >
        <Router>
          <div className="App">
            <AuthProvider>
              <Route path="/" component={MainRouter} />
            </AuthProvider>
          </div>
        </Router>
      </IntercomProvider>
    </ErrorProvider>
  );
};

export default App;
