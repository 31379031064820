import * as yup from "yup";
import { EducationFormEnum } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const EducationHistorySchema = yup.object().shape({
  [EducationFormEnum.institution]: yup
    .string()
    .required("Institution is required")
    .matches(/^[aA-zZ& ._-\s()]+$/, "Incorrect university name format")
    .label("School Name"),
  [EducationFormEnum.certificate]: yup
    .string()
    .required("Degree is required")
    .matches(/^[aA-zZ& ._-\s()]+$/, "Incorrect degree name format")
    .label("Degree"),
  [EducationFormEnum.startMonth]: yup
    .number()
    .required("Start month is required")
    .label("Start month"),
  [EducationFormEnum.startYear]: yup
    .number()
    .required("Start year is required")
    .label("Start year"),
  [EducationFormEnum.endMonth]: yup.number().required("End month is required").label("End month"),
  [EducationFormEnum.endYear]: yup.number().required("End year is required").label("End year"),
});
