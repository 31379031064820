import { FC, Fragment } from "react";
import * as S from "./styles";
import { DetailList } from "./components";
import { ShouldRender } from "../../shouldRender";
import { IProfileDetailsProps, DefaultDetailsToShow, ProfileDetail } from "../../utils";
import { EditToolTip } from "../../modal";

export const TalentProfileDetail: FC<IProfileDetailsProps> = ({
  data,
  detailsToShow,
  isEditable,
  open,
}) => {
  const getDetailsToShow = (): ProfileDetail[] => {
    return detailsToShow || DefaultDetailsToShow;
  };

  return (
    <Fragment>
      <S.ProfileDetails>
        <S.NameEditIcon>
          <S.SectionName>Profile Detail</S.SectionName>
          <ShouldRender if={isEditable}>
            <EditToolTip handleEditClick={open} />
          </ShouldRender>
        </S.NameEditIcon>
        <DetailList data={data} detailsToShow={getDetailsToShow()} />
      </S.ProfileDetails>
    </Fragment>
  );
};

export default TalentProfileDetail;
