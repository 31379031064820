import React, { Fragment, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClientAvailability, useFetchClient } from "hooks";
import moment from "moment-timezone";
import {
  convertTime,
  initialTimeConvert,
  days,
  DefaultClientAvailability,
  WeekDays,
  WeekDaysInvert,
  validationSchema,
} from "utils";
import { AvailabilityForm, AvailabilitySlots } from "hooks/types";
import { BtnLoader } from "@remotebase/components";
import withError from "state/error/withErrorHoc";
import { ErrorProps } from "@remotebase/constants";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";
import { Auth } from "aws-amplify";
import * as S from "./styles";
import TimeSelector from "./timeSelector";

const AvailabilityView: React.FC<ITalentProps & ErrorProps> = ({
  talentState: { client },
  showError,
}) => {
  const { user } = Auth.Credentials.Auth;
  const userSub = useMemo(() => {
    return user.attributes.sub;
  }, [user]);
  const { fetchClient } = useFetchClient();

  const [clientAvailability, setClientAvailability] = useState({});
  const [availabilityArray, setAvailabilityArray] = useState<AvailabilitySlots[]>([]);

  useEffect(() => {
    if (client?.availability) {
      setAvailabilityArray(
        client.availability.map((item) => {
          const weekday = WeekDaysInvert[item.day];
          const slots = item.availableSlots.map((slot) => {
            return {
              startTime: initialTimeConvert(slot.startTime),
              endTime: initialTimeConvert(slot.endTime),
            };
          });
          return { [weekday]: slots };
        }),
      );
    }
  }, [client?.availability]);

  const {
    createClientAvailability,
    data: availabilityData,
    loading,
    error,
  } = useClientAvailability();
  const methods = useForm<AvailabilityForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: DefaultClientAvailability,
    mode: "onChange",
  });
  const {
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;
  const formValues = useWatch({ control });
  const existingKeys = Object.keys(formValues).filter((key) => !!formValues[key]);

  const onSubmitHandler = (data): void => {
    Object.keys(data).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => data[key] === undefined && delete data[key],
    );

    const availabilities = Object.keys(data).map((d) => {
      const slots = data[d].reduce((timeslots, t) => {
        if (t)
          timeslots.push({
            startTime: convertTime(t.startTime),
            endTime: convertTime(t.endTime),
          });
        return timeslots;
      }, []);
      return { day: WeekDays[d], availableSlots: slots, date: new Date() };
    });
    createClientAvailability({
      variables: {
        availabilities,
        timezone: moment.tz.guess(),
      },
    });
    reset(data);
  };

  useEffect(() => {
    if (availabilityData?.message) {
      showError({
        title: "Client Availability",
        message: "Availability Status Updated",
      });
      if (!client?.availability)
        fetchClient({
          variables: {
            id: userSub,
          },
        });
    }
  }, [availabilityData]);

  useEffect(() => {
    if (error?.message) {
      showError({
        title: "Client Availability",
        message: "Error: Availability Not Updated!",
      });
    }
  }, [error]);

  useEffect(() => {
    if (availabilityArray) {
      availabilityArray.forEach((element) => {
        setClientAvailability((prevState) => ({
          ...prevState,
          ...element,
        }));
      });
    }
  }, [availabilityArray]);

  useEffect(() => {
    if (Object.keys(clientAvailability).length !== 0) {
      reset(clientAvailability);
    }
  }, [clientAvailability]);

  return (
    <Fragment>
      {!client && <BtnLoader color={"#4c40f7"} />}
      {client && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="d-flex flex-column align-items-center">
              <S.DaysRow>
                {days.map(({ key, text }) => (
                  <S.DaysCol key={key}>
                    <S.Day
                      $selected={existingKeys.includes(key)}
                      type="button"
                      onClick={(): void => {
                        setValue(
                          key,
                          existingKeys.includes(key)
                            ? undefined
                            : [{ startTime: "09:00", endTime: "17:00" }],
                          { shouldDirty: true },
                        );
                      }}
                    >
                      {text}
                    </S.Day>
                  </S.DaysCol>
                ))}
              </S.DaysRow>
              {days
                .filter(({ key }) => existingKeys.includes(key))
                .map(({ key, text }) => (
                  <TimeSelector key={key} name={key} text={text} />
                ))}
              <div>Timezone: {moment.tz.guess()}</div>
              <br />
              <S.SubmitButton
                type="submit"
                disabled={
                  (existingKeys.length === 0 || loading || !isDirty) && client?.availability
                }
              >
                {!loading && <Fragment>{client?.availability ? "Update" : "Add"}</Fragment>}
                {loading && <BtnLoader color={"#4c40f7"} />}
              </S.SubmitButton>
            </div>
          </form>
        </FormProvider>
      )}
    </Fragment>
  );
};

export default withTalent(withError(AvailabilityView));
