import styled from "styled-components";

export const PrivacyPolicyStyled = styled.div`
  margin: 0px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  ul {
    margin: 0px;
    padding: 0px;
  }
  ul li {
    list-style: none;
  }
  a:hover {
    text-decoration: none;
  }
  .off-canvas-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .header__title {
    font-size: 28px;
    color: #4d4d4d;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 35px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    letter-spacing: 5.12px;
    text-transform: uppercase;
    width: 100%;
    max-width: 890px;
    padding: 0px 24px;
  }

  @media only screen and (min-width: 375px) {
    .header__title {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media only screen and (min-width: 768px) {
    .header__title {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 6.12px;
    }
  }

  @media only screen and (min-width: 992px) {
    .header__title {
      font-size: 60px;
      line-height: 70px;
    }
  }

  /* Privacy & policy header */

  /* privacy & policy content */

  .privacy__wrapper {
    width: 100%;
    padding-top: 65px;
    padding-bottom: 100px;
  }
  .privacy__container {
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .privacy__date {
    font-size: 20px;
    color: #323232;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 54px;
    text-align: center;
    width: 100%;
  }
  .privacy__heading {
    font-size: 28px;
    color: #19191b;
    font-weight: 700;
    line-height: 34px;
    margin: 0px 0px 15px 0px;
    letter-spacing: -1.43364px;
    width: 100%;
  }
  .privacy__sub-hd {
    font-size: 20px;
    color: #19191b;
    font-weight: 700;
    line-height: 38px;
    margin: 0px 0px 7px 0px;
    letter-spacing: -1.43364px;
    width: 100%;
  }
  .privacy__para {
    width: 100%;
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .privacy__list li {
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-left: 36px;
    margin-bottom: 4px;
  }
  .privacy__list li:before {
    content: "";
    width: 4px;
    height: 4px;
    flex-shrink: 0;
    display: inline-flex;
    background: #4d4d4d;
    border-radius: 10px;
    position: absolute;
    left: 15px;
    top: 8px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .privacy__defination {
    width: 100%;
    padding-bottom: 30px;
  }
  .privacy__inf-col {
    width: 100%;
    padding-bottom: 17px;
  }
  .privacy__inf-col .privacy__heading {
    margin-bottom: 6px;
  }
  .privacy__inf-col .privacy__para {
    margin-bottom: 6px;
  }
  .privacy__inf-list {
    width: 100%;
    padding: 23px 0px 30px 0px;
  }
  .privacy__rights-list {
    width: 100%;
    padding: 0px 0px 30px 0px;
  }
  .prv__comp-address {
    width: 100%;
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 768px) {
    .privacy__heading {
      font-size: 31px;
      line-height: 37px;
    }
    .privacy__para,
    .prv__comp-address {
      font-size: 19px;
      line-height: 24px;
    }
    .privacy__list li {
      font-size: 19px;
      line-height: 24px;
    }
  }
`;
export default PrivacyPolicyStyled;
