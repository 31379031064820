import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PayloadType } from "@remotebase/constants";
import { AuthenticationInput, AuthSubmitBtn, registeredEmail, sendCodeText } from "../..";
import { AuthForm, ForgotPasswordFormSchema, emailPlaceholder } from "../../utils";
import * as Styled from "./styles";

interface Props {
  isLoading: boolean;
  sendCode: (payload: PayloadType) => Promise<void>;
}

export const ForgotPasswordForm: React.FC<Props> = ({ isLoading, sendCode }) => {
  const handler = useForm({ resolver: yupResolver(ForgotPasswordFormSchema), mode: "onChange" });
  return (
    <form
      onSubmit={handler.handleSubmit((data) => {
        sendCode(data);
      })}
    >
      <Styled.FormGroups>
        <AuthenticationInput
          type="email"
          placeholder={emailPlaceholder}
          label={registeredEmail}
          title={AuthForm.email}
          handler={handler}
        />
      </Styled.FormGroups>
      <AuthSubmitBtn isLoading={isLoading} buttonText={sendCodeText} />
    </form>
  );
};

export default ForgotPasswordForm;
