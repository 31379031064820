import { Button } from "react-bootstrap";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const DashboardHead = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
  }
`;

export const DashboardHeading = styled.h1`
  font-size: 40px;
  color: #11142d;
  font-weight: 600;
  margin-bottom: 16px;

  @media only screen and (max-width: 767px) {
    font-size: 34px;
  }
`;

export const Day = styled.button<{ $selected: boolean }>`
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  background-color: #edf0f2;
  color: black;
  outline: 0;
  border: 0;
  ${({ $selected }): false | FlattenSimpleInterpolation =>
    $selected &&
    css`
      background-color: #6c5dd3;
      color: white;
    `}
`;

export const DaysRow = styled.div`
  width: 23.375rem;
  margin: 0 -0.731rem 1.5rem -0.731rem;
  display: flex;
`;

export const DaysCol = styled.div`
  padding: 0 0.731rem;
`;

export const SubmitButton = styled(Button)`
  background: #6c5dd3;
  min-height: 3rem;
  min-width: 7.5rem;
  border: none;
  box-shadow: none;
  position: relative;
  font-weight: 500;

  &:hover,
  &:active,
  &:focus {
    transition: all 0.2s;
    background-color: #6c5dd3 !important;
    box-shadow: none !important;
  }
`;
