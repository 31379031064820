import autogeneratedSettings from "../../aws-exports";

const config = {
  awsPublic: {
    endpoint: autogeneratedSettings.aws_appsync_graphqlEndpoint,
    authenticationType: "AWS_IAM",
    region: autogeneratedSettings.aws_appsync_region,
  },
  awsPrivate: {
    endpoint: autogeneratedSettings.aws_appsync_graphqlEndpoint,
    authenticationType: autogeneratedSettings.aws_appsync_authenticationType,
    region: autogeneratedSettings.aws_appsync_region,
  },
  awsAmplifyConfig: autogeneratedSettings,
};

export default config;
