import styled from "styled-components";

const ShimmerStyle = `
  background-color: #ededed;
  border-radius: 0.25rem;
  display: inline-flex;`;

const animate = `
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
  background-size: 62.5rem 100%;

  @keyframes fullView {
  100% {
    width: 100%;
  }
}
@keyframes shimmer {
  0% {
    background-position: -62.5rem 0;
  }
  100% {
    background-position: 62.5rem 0;
  }
}
  `;

export const H3 = styled.h3`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 25rem;
  height: 3.125rem;
`;

export const Label = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 11.25rem;
  height: 1.125rem;
  display: block;
`;

export const Paragraph = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 37.5rem;
  height: 0.625rem;
  display: block;
`;

export const HalfParagraph = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 70%;
  max-width: 37.5rem;
  height: 0.625rem;
  display: block;
`;

export const Skill = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 6.25rem;
  height: 2.125rem;
  border-radius: 1rem;
  margin-right: 0.625rem;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column1 = styled.div`
  flex: 60%;
`;

export const ColumnWrapper = styled.div`
  max-width: 95%;
`;

export const Column2 = styled.div`
  flex: 40%;
`;

export const H6 = styled.h6`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 11.25rem;
  height: 1.875rem;
  display: block;
  margin-bottom: 1.875rem;
`;

export const Space = styled.h6`
  margin-bottom: 2.5rem;
`;

export const CardWrapper = styled.div`
  ${ShimmerStyle}
  ${animate}
  background-color: #fcfcfc;
  border: 0.0625rem solid #e4e4e4;
  border-radius: 1rem;
  padding: 1.25rem 2rem 0.5rem 2rem;
  margin-bottom: 0.5rem;
  height: 26.875rem;
  width: 100%;
  /* max-width: 25rem; */
  display: block;
`;

export const HR = styled.hr`
  border-width: 0.0063rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

export const JobPostedBy = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 5rem;
  height: 0.625rem;
  display: block;
`;

export const CompanyLogo = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 6.25rem;
  height: 3.125rem;
  display: block;
`;

export const SmallLine = styled.div`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 25rem;
  height: 0.625rem;
  display: block;
  margin-bottom: 0.3125rem;
`;

export const HalfSmallLine = styled.div`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 12.5rem;
  height: 0.625rem;
  display: block;
  margin-bottom: 0.3125rem;
`;

export const Detail = styled.div`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 3.125rem;
  height: 0.625rem;
  display: block;
  margin-bottom: 0.3125rem;
`;

export const DetailItem = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 6.25rem;
  height: 2.5rem;
  display: block;
`;
