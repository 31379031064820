import styled from "styled-components";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const LoginWrapper = styled.div`
  width: 100%;
`;

export const LoginHeading = styled.h1`
  font-size: 40px;
  color: #404040;
  font-weight: 700;
  letter-spacing: -0.045em;
  margin-bottom: 10px;

  @media only screen and (min-width: 1600px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const LoginDescrip = styled.p`
  font-size: 20px;
  color: #404040;
  font-weight: 300;
  letter-spacing: -0.045em;
  margin-bottom: 66px;

  @media only screen and (min-width: 1600px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const LoginSeparator = styled.div`
  display: flex;
  justify-content: center;
  min-height: 28px;
  position: relative;
  margin-bottom: 14px;

  &:before {
    content: "";
    background-color: #bbbbbb;
    height: 1px;
    position: absolute;
    top: 13px;
    left: 0;
    right: 0;
  }
`;

export const LoginSeparatorText = styled.span`
  font-size: 16px;
  color: #b9b9b9;
  letter-spacing: -0.025em;
  padding: 0 10px;
  display: inline-flex;
  position: relative;
  background-color: #fff;
`;

export const FormGroups = styled.div`
  width: 100%;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 16px;
  }

  &.active {
    label {
      color: #898989;
    }
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  color: #bfbfbf;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 24px;
`;

export const FormField = styled.input`
  font-size: 16px;
  color: #898989;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: flex;
  background-color: #fff;
  border: 1px solid #bbbbbb;
  border-radius: 27.5px;
  padding: 8px 24px;
  width: 100%;
  height: calc(2.375em + 1rem + 2px);
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    height: calc(2em + 1rem + 2px);
  }

  &.focus-visible,
  &:focus {
    background-color: #fff;
    border: 2px solid #5138ed;
    outline: none;
    box-shadow: none;
    color: #404040;
  }
`;

export const ForgotPassAlign = styled.div`
  text-align: right;
  margin-bottom: 28px;
`;

export const ForgotPassword = styled(Link)`
  font-size: 16px;
  color: #5138ed;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #2b15ba;
    text-decoration: underline;
  }
`;

export const LoginBtn = styled(Button)`
  font-size: 16px;
  position: relative;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.025em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5138ed;
  border: none;
  border-radius: 27.5px;
  width: 100%;
  min-height: 56px;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    min-height: 50px;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #2b15ba;
    border: none;
    box-shadow: none;
    color: #fff;
  }
`;

export const AccountText = styled.div`
  font-size: 16px;
  color: #bbbbbb;
  line-height: 24px;
  letter-spacing: -0.09em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media only screen and (max-width: 767px) {
    margin-top: 70px;
  }
`;

export const SignupLink = styled(Link)`
  display: inline-flex;
  cursor: pointer;
  color: #bbbbbb;
  text-decoration: none;
  transition: all 0.25s ease;
  margin-left: 4px;

  &:hover,
  &:focus {
    color: #5138ed;
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 15px;
  font-size: 0.8rem;
`;
