export enum UnAuthRoutes {
  Login = "/login",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Register = "/register",
  Reverify = "/reverify",
  NotFound = "/404",
  Maintenance = "/maintenance",
}

export enum AuthRoutes {
  Dashbord = "/dashboard",
  Favorites = "/favorites",
  Availabilty = "/availability",
  Talent = "/talents/:id",
  NotFound = "/404",
  Logout = "/logout",
  Talents = "/talents",
  Terms = "/terms",
  Support = "/support",
  Billing = "/billing",
  Perks = "/perks",
  Perk = "/perks/:id",
}
