import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FetchJobMatchesMutationVariables,
  FetchJobMatchesMutation,
  GetJobsByCompanyIDQuery,
  MatchesForJobQuery,
  MatchesForJobQueryVariables,
  GetJobsByCompanyIDQueryVariables,
  GetJobMatchQuery,
  GetJobMatchQueryVariables,
  JobMatch,
} from "API";
import { getQuery } from "hooks/utils/helpers";
import {
  getJobMatch,
  getJobsByCompanyID,
  matchesForJob,
} from "@remotebase/amplify-constants/graphql/queries";
import { ReturnPropsFetchJobMatch } from "hooks/types";
import { fetchJobMatches } from "@remotebase/amplify-constants/graphql/mutations";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ITalentProps, ITalentStateType } from "state/types";
import { ReturnPropsJobsCIDList, ReturnPropsTalentList } from "../../../../web/src/hooks/types";

type ReturnPropsGetJobMatch = {
  loading: boolean;
  currentTalent: ITalentStateType["currentTalent"];
};

export const fetchData = (): ReturnPropsFetchJobMatch => {
  const [fetchMatchedJobs, { data, loading, error }] = useMutation<
    FetchJobMatchesMutation,
    FetchJobMatchesMutationVariables
  >(getQuery(fetchJobMatches));

  const result = data || null;

  return {
    fetchMatchedJobs,
    data: result,
    loading,
    error,
  };
};
export const useJobsByCID = (): ReturnPropsJobsCIDList => {
  const [getJobsByCID, { data, loading }] = useLazyQuery<
    GetJobsByCompanyIDQuery,
    GetJobsByCompanyIDQueryVariables
  >(getQuery(getJobsByCompanyID));

  let jobs;
  if (data?.getJobsByCompanyID?.items) {
    jobs = data?.getJobsByCompanyID?.items?.length > 0 ? data?.getJobsByCompanyID?.items : null;
  }

  return { getJobsByCID, loading, jobs };
};

export const useTalentsByJID = (): ReturnPropsTalentList => {
  const [getTalentsByJID, { data, loading }] = useLazyQuery<
    MatchesForJobQuery,
    MatchesForJobQueryVariables
  >(getQuery(matchesForJob));

  const talentsData = (data?.matchesForJob?.items || null) as ReturnPropsTalentList["talentsData"];
  return { getTalentsByJID, loading, talentsData };
};

export const useGetJobMatch = (props: ITalentProps): ReturnPropsGetJobMatch => {
  const {
    talentState: { loading: talentStateIsLoading, currentTalent },
    setTalentState,
  } = props;
  const [getJobMatchById, { data, loading: matchQueryIsLoading }] = useLazyQuery<
    GetJobMatchQuery,
    GetJobMatchQueryVariables
  >(getQuery(getJobMatch));
  const jobMatch = (data?.getJobMatch || null) as JobMatch;
  const router = useLocation<{ jobMatchId: string }>();
  const jobMatchId = router.state?.jobMatchId;

  useEffect(() => {
    if (!jobMatchId) return;
    getJobMatchById({
      variables: {
        id: jobMatchId,
      },
    });
  }, [jobMatchId]);

  useEffect(() => {
    if (!jobMatch) return;
    setTalentState((prev) => ({
      ...prev,
      currentTalent: jobMatch as ITalentStateType["currentTalent"],
    }));
  }, [jobMatch]);

  return {
    loading: talentStateIsLoading || matchQueryIsLoading,
    currentTalent,
  };
};
