import React from "react";
import { WithTalentMatchCompanyProps } from "hooks/types";
import { Link } from "react-router-dom";
import { imageUrl } from "utils";
import withTalentMatchCompany from "state/talent/withTalentMatchCompanyHoc";
import FeedbackFailed from "./FeedbackFailed";
import FeedbackSubmitted from "./FeedbackSubmitted";
import * as S from "../../styles";

interface Props extends WithTalentMatchCompanyProps {
  imgLink: string | null;
  fullName: string | null;
  feedback: string | null;
  bgLink: string;
  title: string | null;
  salary: string | null;
  skillScore: string | null;
  talentID: string | null;
  jobMatchId: string | null;
}

const TalentCard: React.FC<Props> = ({
  imgLink,
  fullName,
  feedback,
  bgLink,
  title,
  salary,
  // skillScore,
  talentID,
  jobMatchId,
}) => (
  <S.TalentBoxCanvas>
    <S.TalentBox>
      <S.TalentBoxMain>
        <S.TalentBoxImgContainer>
          <S.TalentBoxImg src={imgLink ?? imageUrl.userDefault} />
          <S.TalentInnerBg src={bgLink} />
        </S.TalentBoxImgContainer>
        <S.TalentDetailSection>
          <S.DeveloperInfoWrapper>
            <S.DeveloperNameInfoWrapper>
              <S.DeveloperNameStatusWrapper>
                <S.DeveloperInnerWrapper>
                  <S.DeveloperName>{fullName || " "}</S.DeveloperName>
                  <S.DeveloperTitle>{title || " "}</S.DeveloperTitle>
                  <S.InnerWrapper>
                    <S.DeveloperRate>{salary}</S.DeveloperRate>
                    {/* <S.DeveloperSeparator /> */}
                    {/* <S.DeveloperSkillScore>{skillScore || " "}% Skill Score</S.DeveloperSkillScore> */}
                  </S.InnerWrapper>
                  <S.DeveloperFeedBack>
                    {!feedback ? <FeedbackFailed /> : <FeedbackSubmitted />}
                  </S.DeveloperFeedBack>
                  <Link
                    to={{
                      pathname: `/talents/${talentID}`,
                      state: { jobMatchId },
                    }}
                  >
                    <S.DeveloperProfileButton>View Profile</S.DeveloperProfileButton>
                  </Link>
                </S.DeveloperInnerWrapper>
              </S.DeveloperNameStatusWrapper>
            </S.DeveloperNameInfoWrapper>
          </S.DeveloperInfoWrapper>
        </S.TalentDetailSection>
      </S.TalentBoxMain>
    </S.TalentBox>
  </S.TalentBoxCanvas>
);

export default withTalentMatchCompany(TalentCard);
