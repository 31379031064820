import SharedForm from "components/form";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";
import { ErrorProps } from "@remotebase/constants";
import withError from "state/error/withErrorHoc";
import * as Styled from "./styles";
import useSupportForm from "./use-support-form";

export const SupportForm: React.FC<ITalentProps & ErrorProps> = ({ talentState, showError }) => {
  if (!talentState?.client) return null;
  const formProps = useSupportForm(talentState?.client, showError);

  return (
    <Styled.FormWrapper>
      <Styled.FormContainer>
        <Styled.Form
          className="aos-item"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="400"
          data-aos-offset="0"
        >
          <SharedForm {...formProps} />
        </Styled.Form>
      </Styled.FormContainer>
    </Styled.FormWrapper>
  );
};

export default withTalent(withError(SupportForm));
