import React, { useMemo } from "react";
import { ChevronLeft, ChevronRight } from "assets/icons";
import * as S from "../../styles";

type CarouselState = {
  totalItems: number;
  currentSlide: number;
};

type Props = {
  next?: () => void;
  previous?: () => void;
  carouselState?: CarouselState;
};

export const BtnGroup: React.FC<Props> = ({ next, previous, carouselState }) => {
  const { totalItems, currentSlide } = carouselState || {};
  const prevIsDisabled = useMemo(() => currentSlide === 0, [currentSlide]);
  const nextIsDisabled = useMemo(
    () => Boolean(totalItems && currentSlide === totalItems - 3),
    [totalItems, currentSlide],
  );

  return (
    <S.CarouselBtnCanvas>
      <S.CarouselBtn aria-label="Go to previous slide" onClick={previous} disabled={prevIsDisabled}>
        <ChevronLeft />
      </S.CarouselBtn>
      <S.CarouselBtn aria-label="Go to next slide" onClick={next} disabled={nextIsDisabled}>
        <ChevronRight />
      </S.CarouselBtn>
    </S.CarouselBtnCanvas>
  );
};

export default BtnGroup;
