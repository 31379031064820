export enum UnAuthRoutes {
  Login = "/login",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Register = "/register",
  Reverify = "/reverify",
  NotFound = "/404",
}

export enum AuthRoutes {
  Dashbord = "/dashboard",
  CreateProfile = "/createProfile",
  FinishSignUp = "/finishSignUp",
  DomainTests = "/tests",
  ProblemSolving = "/problemSolving",
  Logout = "/logout",
  hrScreening = "/hrScreening",
  finalTechInterview = "/finalTechInterview",
  NotFound = "/404",
  Referral = "/referral",
  Network = "/network",
  Jobs = "/jobs",
  Job = "/job",
}

export enum QueryParams {
  ID = "id",
  PAGE = "page",
}

export default UnAuthRoutes;
