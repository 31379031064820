import { FC } from "react";
import { ResendCodeProp, resendCodeText } from "../../utils";
import * as Styled from "./styles";
import RenderCountdown from "./RenderCountdown";

export const AuthCommonResendCode: FC<ResendCodeProp> = ({ email, resendVerificationCode }) => {
  const handleClick = (): void => {
    if (email !== null) {
      resendVerificationCode(email);
    }
  };

  return (
    <Styled.ResendLinkAlign>
      <RenderCountdown>
        <Styled.ResendLink onClick={(): void => handleClick()}>{resendCodeText}</Styled.ResendLink>
      </RenderCountdown>
    </Styled.ResendLinkAlign>
  );
};

export default AuthCommonResendCode;
