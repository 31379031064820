import React, { Fragment } from "react";
import * as S from "./styles";
import AvailabiltyView from "./AvailabiltyView";

export const AvailabilityComponent: React.FC = () => {
  return (
    <Fragment>
      <S.DashboardHead className="mb-4">
        <S.DashboardHeading>When are you available for taking interviews? 🗓</S.DashboardHeading>
      </S.DashboardHead>
      <AvailabiltyView shouldCallApi />
    </Fragment>
  );
};

export default AvailabilityComponent;
