import { FC, useMemo } from "react";
import { getSidebarDropdownIcon, SidebarDropdown, withSidebar } from "state/sidebar";
import { withTalent } from "state/talent";
import { ITalentProps } from "state/types";
import { SidebarProps } from "utils";
import * as Styled from "../../layouts/sidebar_layout_copy/styles";

type Props = {
  name: SidebarDropdown;
  handleOnClick: (e: React.MouseEvent<HTMLElement>) => void;
} & SidebarProps &
  ITalentProps;

const SidebarMainNavItem: FC<Props> = ({ name, handleOnClick, talentState }) => {
  const Icon = useMemo(() => getSidebarDropdownIcon(name), []);

  return (
    <Styled.MainNavItem
      data-collapse="false"
      data-name={name}
      onClick={handleOnClick}
      data-src={name === SidebarDropdown.Profile && talentState.client?.company?.logo}
    >
      {Icon && <Icon />}
    </Styled.MainNavItem>
  );
};

export default withSidebar(withTalent(SidebarMainNavItem));
