import React, { Fragment } from "react";
import { Feedback, feedbackType } from "@remotebase/amplify-constants/API";
import { ViewFeedbackHeader } from "components";
import { ShouldRender, ViewFeedback, ViewFeedbackRating } from "@remotebase/components";
import * as Styled from "./styles";

interface Props {
  feedback?: Feedback | null;
  label: string;
  type: feedbackType;
}

export const TalentProfileFeedback: React.FC<Props> = ({ feedback, label }) => {
  return (
    <Styled.ProfileBlock>
      <ViewFeedbackHeader feedback={feedback} label={label} />
      <ShouldRender if={feedback?.description}>
        <Fragment>
          <ViewFeedback feedback={feedback} />
          <ShouldRender if={feedback?.score !== undefined}>
            <ViewFeedbackRating feedback={feedback} />
          </ShouldRender>
        </Fragment>
      </ShouldRender>
    </Styled.ProfileBlock>
  );
};

export default TalentProfileFeedback;
