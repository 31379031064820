import styled from "styled-components";
import { StyledProps } from "../../utils";

export const SelectGroupCombine = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const SelectGroup = styled.div`
  width: 100%;
`;

export const SelectLabel = styled.label`
  font-size: 14px;
  color: #808191;
  font-weight: 500;
  display: inline-flex;
`;

export const SelectGroupMain = styled.div<{
  removeMargin?: boolean;
  removeBorderRight?: boolean;
  minHeight?: string;
}>`
  position: relative;
  margin-bottom: ${(props): StyledProps => (props.removeMargin ? "0" : "24px")};
  width: 100%;

  div:nth-child(3) {
    min-height: ${(props): StyledProps => props.minHeight || "44px"};
    border-width: ${(props): StyledProps => (props.minHeight ? "2px" : "1px")};
    border-right-width: ${(props): StyledProps => (props.removeBorderRight ? "0" : "1px")};
    border-radius: ${(props): StyledProps =>
      props.removeBorderRight ? "0.35rem 0 0 0.35rem" : "0.35rem"};
  }

  & svg {
    & path {
      fill: #808191;
    }
  }

  & input {
    height: 20px !important;
  }
`;

export const MonthYearWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  opacity: ${(props): StyledProps => (props.isDisabled ? "0.3" : "1")};
`;
