import { TechDomainSkills } from "@remotebase/amplify-constants/API";
import { IErrorStateType } from "@remotebase/constants/types";
import { rangeRight } from "lodash";
import moment from "moment";
import { SelectOption } from "../utils";

export const getUpdatedAt = (updatedAt?: string | null): string => {
  return moment(updatedAt).fromNow();
};

export const matchSlugUrls = (pathname: string, route: string): boolean => {
  if (!route.includes(":")) return pathname === route;
  const routeArr = route.split("/");
  const pathArr = pathname.split("/");
  if (routeArr.length !== pathArr.length) return false;
  for (const idx in routeArr) {
    if (routeArr[idx].includes(":")) {
      if (!pathArr[idx]?.length) return false;
      routeArr.splice(Number(idx), 1);
      pathArr.splice(Number(idx), 1);
    }
  }
  return pathArr.join("/") === routeArr.join("/");
};

export const isValidRoute = (routesArray: Array<string>, currentPathname: string): boolean => {
  const routesWithoutPages = ["/register", "/reverify", "/"];
  const filteredRoutesArray = routesArray.filter((route) => !routesWithoutPages.includes(route));
  if (filteredRoutesArray.includes(currentPathname)) return true;
  const sluggedRoutes = filteredRoutesArray.filter((route) => route.includes(":"));
  // eslint-disable-next-line guard-for-in
  for (const idx in sluggedRoutes) {
    const isMatched = matchSlugUrls(currentPathname, sluggedRoutes[idx]);
    if (isMatched) return true;
  }
  return false;
};

export const updateErrorState = (
  data: IErrorStateType,
  setErrorState: React.Dispatch<React.SetStateAction<IErrorStateType[]>>,
): void =>
  setErrorState((current) => [...current, { id: Math.floor(Math.random() * 100), ...data }]);

export const isEmptyString = (input?: string | null): boolean =>
  !input || !input.replace(/<\/?[^>]+(>|$)/g, "").length;

export const isEligible = (
  userSkills: (TechDomainSkills | undefined)[],
  jobSkills: (TechDomainSkills | null)[] | undefined,
): boolean => {
  if (!jobSkills) return false;
  if (userSkills && jobSkills) {
    return jobSkills?.every((skill) => skill && userSkills.includes(skill));
  }
  return false;
};

export const checkJobSkill = (
  userSkills: (TechDomainSkills | undefined)[],
  data: string | null,
): boolean => {
  if (userSkills && data) {
    const filterSkills = userSkills?.find((skill) => skill?.toLowerCase() === data.toLowerCase());
    return !!filterSkills;
  }
  return false;
};

export const getTimeDifferenceAndLabel = (updatedAt: Date, currentDate: Date): [number, string] => {
  const MILI_SECONDS = 1000;
  const SECONDS = 60;
  const MINUTES = 60;

  const differenceInHours = Math.floor(
    (+currentDate - +updatedAt) / MILI_SECONDS / SECONDS / MINUTES,
  );
  if (differenceInHours < 24) return [differenceInHours, "hour"];

  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays < 30) return [differenceInDays, "day"];

  const differenceInMonths = Math.floor(differenceInDays / 30);
  if (differenceInMonths < 12) return [differenceInMonths, "month"];

  const differenceInYears = Math.floor(differenceInMonths / 12);
  return [differenceInYears, "years"];
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthsDropdown = (): SelectOption[] =>
  months.map((month, index) => ({
    label: month,
    value: `${index}`,
  }));

export const getYearsDropdown = (start?: number | null): SelectOption[] => {
  const currYear = new Date().getFullYear();

  return rangeRight(start ?? currYear - 75, currYear + 1).map((item) => ({
    value: String(item),
    label: String(item),
  }));
};

export const convertToDate = (date?: string | null): string => {
  return moment(date).format("MMM YYYY");
};

export const cleanArray = <T = unknown>(data?: Array<T | null> | null): Array<T> => {
  return (
    data?.reduce((cleanData, current) => {
      if (!current) return cleanData;

      cleanData.push(current);
      return cleanData;
    }, [] as Array<T>) ?? []
  );
};
