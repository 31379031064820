const secondary = "#11142d";
/* eslint-disable   @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/prefer-default-export
export const customStyles = {
  container: (base: any) => ({
    ...base,
    position: "relative",
    boxSizing: "border-box",
  }),
  control: (base: any, props: any) => ({
    ...base,
    borderRadius: props?.menuIsOpen ? "6px 6px 0 0" : "6px",
    boxShadow: "none",
    backgroundColor: "transparent",
    borderColor: "#dddfe2",
    minHeight: "44px",
    padding: "7px 14px",
    "&:hover": {
      borderColor: "#dddfe2",
    },
    "&:focus, &:active": {
      borderColor: secondary,
    },
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: "4px",
    cursor: "pointer",
    svg: {
      borderRadius: "4px",
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: "4px",
    cursor: "pointer",
    svg: {
      borderRadius: "4px",
    },
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    span: {
      display: "none",
    },
  }),
  menu: (base: any) => ({
    ...base,
    boxShadow: "none",
    zIndex: 55,
    marginTop: 0,
    backgroundColor: "#fff",
  }),
  singleValue: (base: any) => ({
    ...base,
    fontSize: "14px",
    color: "#222222",
  }),
  option: (base: any) => ({
    ...base,
    fontSize: "14px",
    color: "#8e91a0",
    backgroundColor: "#fff",
    wordBreak: "break-word",
    "&:focus": {
      outline: "none",
    },
    "&:hover, &:focus, &:active, &:checked": {
      backgroundColor: secondary,
      color: "white",
    },
  }),
  multiValue: (base: any) => ({
    ...base,
    borderRadius: "4px",
    backgroundColor: "rgb(230,230,230)",
    svg: {
      color: "#fff",
      width: "16px",
      height: "16px",
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    overflowX: "hidden",
    paddingTop: 0,
    backgroundColor: "#fff",
    borderRadius: "0 0 6px 6px",
    border: "1px solid",
    borderColor: secondary,
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#BCBAB8",
      borderRadius: "4px",
    },
  }),
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: "14px",
    color: "#8e91a0",
  }),
  placeholder: (base: any) => ({
    ...base,
    fontSize: "14px",
    color: "#6a7074",
  }),
  input: (base: any) => ({
    ...base,
    lineHeight: "18px",
    backgroundColor: "transparent",
  }),
};
