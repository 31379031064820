import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CheckLabel = styled.label`
  margin-bottom: 0;
`;
