import { FC, useMemo } from "react";
import { AuthType, IAuthProps, UserType } from "@remotebase/constants";
import * as text from "../../utils";
import {
  ShouldRender,
  AuthGoogleClient,
  UnAuthRoutes as Route,
  IFooterProp,
  IAuthHeading,
} from "../..";
import { authSubHeading } from "../../components.helpers";
import { LoginRegisterFormClient } from "./loginRegisterform";
import * as Styled from "./styles";
import { RemotebaseLogo } from "../commons";

export const AuthCommonLoginRegisterClient: FC<IAuthProps> = (props) => {
  const { userType, authType, formProps } = props;

  const authHeading = (): IAuthHeading => {
    switch (authType) {
      case AuthType.SignIn:
        return {
          heading: text.loginHeaderClient,
          separator: text.loginSeparatorText,
          btnText: text.loginButtonText,
        };
      case AuthType.SignUp:
        return {
          heading: text.signUpHeader,
          separator: text.signUpSeparatorText,
          btnText: text.signupButtonText,
        };
      default:
        return {};
    }
  };

  const getAuthFooter = (): IFooterProp => {
    switch (authType) {
      case AuthType.SignIn:
        return {
          footerText: text.signUpFooterTag,
          route: Route.Register,
          footerSubText: text.signupButtonText,
        };
      default:
        return {
          footerText: text.loginFooterTag,
          route: Route.Login,
          footerSubText: text.loginButtonText,
        };
    }
  };

  const { heading, btnText } = authHeading();
  const { footerSubText, route, footerText } = getAuthFooter();

  const shouldShowFooter = useMemo(
    () => userType === UserType.Talent || userType === UserType.Recruiter,
    [userType],
  );
  const shouldShowForgotPassword = useMemo(
    () =>
      authType === AuthType.SignIn &&
      (userType === UserType.Talent || userType === UserType.Recruiter),
    [authType, userType],
  );
  return (
    <Styled.LoginWrapper>
      <RemotebaseLogo />
      <Styled.LoginFormWrapper>
        <Styled.LoginHeading>{heading}</Styled.LoginHeading>
        <Styled.LoginDescrip>{authSubHeading(userType)}</Styled.LoginDescrip>
        <LoginRegisterFormClient
          {...formProps}
          buttonText={btnText}
          showForgotPassword={shouldShowForgotPassword}
        />
        <ShouldRender if={userType === UserType.Talent}>
          <AuthGoogleClient wide />
        </ShouldRender>
        <ShouldRender if={shouldShowFooter}>
          <Styled.AccountText>
            {footerText}
            <Styled.SignupLink to={route}>{footerSubText}</Styled.SignupLink>
          </Styled.AccountText>
        </ShouldRender>
      </Styled.LoginFormWrapper>
    </Styled.LoginWrapper>
  );
};

export default AuthCommonLoginRegisterClient;
