import styled from "styled-components";

export const SidebarSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 24px;
  &:before {
    content: "";
    border-right: 3px dashed #e2e2e2;
    width: 5px;
    position: absolute;
    left: 52px;
    top: 25px;
    bottom: 25px;
    @media (max-width: 1199px) {
      content: none;
    }
  }
  @media (max-width: 1023px) {
    padding-left: 0;
  }
`;

export default SidebarSubMenu;
