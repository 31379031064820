import { AvailabilityForm } from "hooks/types";

import { object, array, string, number } from "yup";

// This function takes in time as string in the format "HH:MM" an returns it in seconds
export const convertToSeconds = (time?: string): null | number => {
  if (!time) return null;

  const [hours, minutes] = time.split(":");
  const hoursNumber = number().cast(hours);
  const minutesNumber = number().cast(minutes);
  if (!hoursNumber) return null;
  // calculation of seconds from hours and minutes, if minutes are undefined we use 0
  // because number().cast("00") gives undefined when minutes are 0.
  return (hoursNumber * 60 + (minutesNumber ?? 0)) * 60;
};

const daySchema = array()
  .of(
    object().shape({
      startTime: string().required("Start time is required"),
      endTime: string()
        .required("End time is required")
        .test(
          "endTimeIsGreaterThanStartTime",
          "End time should be greater than start time",
          (endTime, { parent: { startTime } }) => {
            const startTimeSeconds = convertToSeconds(startTime);
            const endTimeSeconds = convertToSeconds(endTime);
            if (!startTimeSeconds || !endTimeSeconds) return true;

            if (endTimeSeconds <= startTimeSeconds) return false;

            return true;
          },
        ),
    }),
  )
  .test("noOverlappingTimes", "There are overlapping times in your availability", (value) => {
    if (!value || value.length < 2) return true;

    const boundaries = value
      .map(({ startTime, endTime }) => ({
        startSeconds: convertToSeconds(startTime),
        endSeconds: convertToSeconds(endTime),
      }))
      .reduce(
        (iterator, { startSeconds, endSeconds }) => [
          ...iterator,
          { type: "start", Seconds: startSeconds },
          { type: "end", Seconds: endSeconds },
        ],
        [],
      );

    if (boundaries.some(({ Seconds }) => Seconds === null)) return true;

    // sorts the time slots with there time it could be endTime or startTime.
    // So the valid sorted array should have alternating start and end values like S,E,S,E.
    // If there is a sorted array like S,S,E,E this means that the times are overlapping and
    // thus needs to show the overlapping error.

    const sortedBoundaries = boundaries.sort((a, b) => (a?.Seconds || 1) - (b?.Seconds || 0));

    for (let i = 1; i < sortedBoundaries.length - 1; i += 1) {
      const current = sortedBoundaries[i];
      const left = sortedBoundaries[i - 1];
      const right = sortedBoundaries[i + 1];

      if (current.type === left.type || current.type === right.type) return false;
    }

    return true;
  });

export const validationSchema = object().shape({
  monday: daySchema,
  tuesday: daySchema,
  wednesday: daySchema,
  thursday: daySchema,
  friday: daySchema,
  saturday: daySchema,
  sunday: daySchema,
});

export const days: { key: keyof AvailabilityForm; text: string }[] = [
  {
    key: "sunday",
    text: "S",
  },
  {
    key: "monday",
    text: "M",
  },
  {
    key: "tuesday",
    text: "T",
  },
  {
    key: "wednesday",
    text: "W",
  },
  {
    key: "thursday",
    text: "T",
  },
  {
    key: "friday",
    text: "F",
  },
  {
    key: "saturday",
    text: "S",
  },
];

export const WeekDays = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const WeekDaysInvert = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  7: "sunday",
};

export const DefaultClientAvailability = {
  monday: [{ startTime: "09:00", endTime: "17:00" }],
  tuesday: [{ startTime: "09:00", endTime: "17:00" }],
  wednesday: [{ startTime: "09:00", endTime: "17:00" }],
  thursday: [{ startTime: "09:00", endTime: "17:00" }],
  friday: [{ startTime: "09:00", endTime: "17:00" }],
};

export const options = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];
