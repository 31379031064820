import { englishProficiency } from "@remotebase/amplify-constants/API";

export enum TechDomainSkills {
  ReactNative = "ReactNative",
  Ror = "Ror",
  Python = "Python",
  ReactAndNode = "ReactAndNode",
  Android = "Android",
  Java = "Java",
  TECHNICAL_RECRUITER = "TECHNICAL_RECRUITER",
  SDR = "SDR",
  PERFORMANCE_MARKETING_SPECIALIST = "PERFORMANCE_MARKETING_SPECIALIST",
  DESIGNER = "DESIGNER",
}

export const environment = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const toastDelayTime = 6000;
export const errorTimeOut = 8000;

export enum HttpStatus {
  SUCCESS = 200,
  CREATED = 201,
  INTERNAL_SERVER_ERROR = 500,
  BAD_REQUEST = 400,
  UN_AUTHOROZIED = 401,
  RESOURCE_NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 403,
  UNPROCESSABLE_ENTITY = 422,
}

export enum Color {
  WHITE = "#FFFFFF",
  PRIMARY = "#3615BD",
  DANGER = "#D31313FF",
  GREY = "#CCCCCC",
  DARK_BLUE = "#3615BD",
  LIGHT_GREY = "#E0E0E0",
  LIGHT_BLUE = "#3C65FE",
  TRANSPARENT = "transparent",
  GREEN = "#27AE60",
}
// This is similar to the above but in object syntax
export const ColorObject = {
  white: "#FFFFFF",
  primary: "#3615BD",
  danger: "#D31313FF",
  grey: "#CCCCCC",
  darkBlur: "#3615BD",
  lightGrey: "#E0E0E0",
  lightBlue: "#3C65FE",
  transparent: "transparent",
  green: "#27AE60",
};

export const matchStageHumanFriendly: Record<string, { name: string }> = {
  referred: { name: "Referred" },
  interviewing: { name: "Short Listed" },
  passTechInterview: { name: "Technical Interview Passed" },
  failTechInterview: { name: "Technical Interview Failed" },
  passCulturalFitInterview: { name: "Cultural Fit Interview passed" },
  failCulturalFitInterview: { name: "Cultural Fit Interview Failed" },
  passSystemDesignInterview: { name: "System Design Interview Passed" },
  failSystemDesignInterview: { name: "System Design Interview Failed" },
  rejected: { name: "Rejected" },
  onboarded: { name: "OnBoarded" },
  interested: { name: "Interested" },
  sourced: { name: "Sourced" },
  disliked: { name: "Disliked" },
};

export const feedbackStages = {
  changeStatus: { name: "Change Status" },
  passTechInterview: { name: "Technical Interview Passed" },
  failTechInterview: { name: "Technical Interview Failed" },
  passCulturalFitInterview: { name: "Cultural Fit Interview passed" },
  failCulturalFitInterview: { name: "Cultural Fit Interview Failed" },
  passSystemDesignInterview: { name: "System Design Interview Passed" },
  failSystemDesignInterview: { name: "System Design Interview Failed" },
  rejected: { name: "Rejected" },
  onboarded: { name: "OnBoarded" },
};

export const newTalentArrivalDays = 7;
export const DefaultNumber = 0;
export const DayInMillis = 86400000;

export const nonTechTalentCategorySkills: { [key: string]: string } = {
  [TechDomainSkills.DESIGNER]: "UI / UX Designer",
  [TechDomainSkills.SDR]: "SDR",
  [TechDomainSkills.PERFORMANCE_MARKETING_SPECIALIST]: "Performance Marketer",
  [TechDomainSkills.TECHNICAL_RECRUITER]: "Tech Recruiter",
};

export const englishProficiencyMap: { [key: string]: string } = {
  [englishProficiency.Basic]: "Beginner",
  [englishProficiency.Average]: "Intermediate",
  [englishProficiency.Great]: "Fluent",
  [englishProficiency.Native]: "Native/Bilingual",
};
const englishProficiencyUpdated = {
  Beginner: "Beginner",
  Intermediate: "Intermediate",
  Fluent: "Fluent",
  Native: "Native/Bilingual",
};
export const englishProficiencyMapReverse: { [key: string]: string } = {
  [englishProficiencyUpdated.Beginner]: englishProficiency.Basic,
  [englishProficiencyUpdated.Intermediate]: englishProficiency.Average,
  [englishProficiencyUpdated.Fluent]: englishProficiency.Great,
  [englishProficiencyUpdated.Native]: englishProficiency.Native,
};
