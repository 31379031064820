import * as yup from "yup";
import { EmploymentFormEnum } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const EmploymentHistorySchema = yup.object().shape({
  [EmploymentFormEnum.company]: yup
    .string()
    .required("Company name is required")
    .matches(/^[aA-zZ& ._-\s()]+$/, "Incorrect company name format")
    .label("Company Name"),
  [EmploymentFormEnum.title]: yup
    .string()
    .required("Title is required")
    .matches(/^[aA-zZ& ._-\s()]+$/, "Incorrect job title format")
    .label("Job Title"),
  [EmploymentFormEnum.startDate]: yup
    .string()
    .required("Start date is required")
    .label("Start Date"),
  [EmploymentFormEnum.currentJob]: yup.boolean().default(false),
});
