import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledProps } from "utils";

export const TalentHead = styled.header`
  margin-bottom: 1.75rem;
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: fit-content;

  svg path {
    fill: #4d526d;
  }

  &,
  &:hover,
  &:focus {
    text-decoration: none;
    color: #4d526d;
  }
`;

export const BackLinkIcon = styled.span`
  width: 0.5rem;
  height: 1rem;
  display: inline-flex;
  margin-right: 5px;
`;

export const BackLinkText = styled.span`
  text-transform: capitalize;
`;

export const ProfilePageBody = styled.section`
  background-color: #faf6ff;
  border-radius: 1.25rem;
  padding: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    background: url("/images/purple-lines.png") no-repeat center center / cover;
    height: 266px;
    border-radius: 0.75rem 0.75rem 0 0;
    z-index: 0;
  }

  @media (min-width: 576px) {
    padding: 2rem;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
  }
`;

export const ProfilePageSection = styled.div`
  padding: 1rem;
  z-index: 0;
  border-radius: 1rem;
  background-color: ${({ theme }): StyledProps => theme.background};

  &:nth-child(1) {
    background-color: #f8fced;
  }

  &:nth-child(2) {
    background-color: #f6efff;
  }

  @media (min-width: 576px) {
    padding: 1.5rem 2rem;
  }

  @media (min-width: 992px) {
    grid-column: 1 / 3;

    &:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }

    &:nth-child(4) {
      grid-column: 2 / 3;
    }

    // TODO: Replace &:nth-child(3) and &:nth-child(4) styles above when certification section is ready
    // &:nth-child(3),
    // &:nth-child(4) {
    //   grid-column: 1 / 2;
    // }

    // &:nth-child(5) {
    //   grid-column: 2 / 3;
    //   grid-row: 3 / 5;
    // }
  }

  @media (min-width: 1198px) {
    padding: 2.675rem 2.5rem;
  }
`;

export const DetailsSectionWrapper = styled.div`
  padding: 1rem;
  z-index: 0;
  border-radius: 1rem;
  background-color: ${({ theme }): StyledProps => theme.background};

  &:nth-child(1) {
    background-color: #f8fced;
  }

  &:nth-child(2) {
    background-color: #f6efff;
  }

  @media (min-width: 576px) {
    padding: 1.5rem 2rem;
  }

  @media (min-width: 992px) {
    grid-column: 1 / 3;
  }
`;

export const ProfilePageTitle = styled.h2`
  font-family: "Raleway";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  margin-bottom: 1rem;
`;

export const ProfilePageText = styled.p`
  line-height: ${(props): StyledProps => props["data-line-height"] || "160%"};
  margin-bottom: 0;

  ${(props): string | false =>
    props["data-truncate"] === "true" &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
`;

export const ProfileSummaryButton = styled.button`
  font-size: 0.875rem;
  line-height: 160%;
  text-decoration-line: underline;
  color: #312a50;
  border: none;
  background-color: transparent;
  padding-left: 0;
`;

export const ProfileCertificateContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;
  margin: 1rem 0 1.375rem;
`;

export const ProfileCertificate = styled.div`
  display: flex;
  column-gap: 0.75rem;
  align-items: center;
`;

export const ProfileCertificateIcon = styled.div`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #219653;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 21px;
    height: 28px;
  }
`;

export const ProfileCertificateDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  flex: 0 1 179px;
`;

export const ProfileCertificateTitle = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #312a50;
  margin: 0;
`;

export const ProfileCertificateSubtitle = styled.p`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin: 0;

  svg {
    width: 14px;
    height: 14px;
  }

  span {
    display: inline-flex;
    flex: 1;
  }
`;

export const ProfileResumeBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1.5rem;
  border: 1px solid #999;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
`;

export const ProfileResumeBoxHead = styled.header`
  display: flex;
  column-gap: 0.75rem;
  align-items: center;
`;

export const ProfileResumeBoxImageContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #219653;
`;

export const ProfileResumeBoxInfo = styled.div`
  flex: 1;
`;

export const ProfileResumeBoxName = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #312a50;
`;

export const ProfileResumeBoxTitle = styled.p`
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #312a50;
  margin: 0;
`;

export const ProfilePageButton = styled.button`
  background: #3c65fe;
  border-radius: 8px;
  width: 194px;
  height: 42px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  background: #3617ba;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;

  svg path {
    stroke: #fff;
  }
`;
