export enum EmploymentFormEnum {
  company = "company",
  summary = "summary",
  title = "title",
  startDate = "startDate",
  endDate = "endDate",
  currentJob = "currentJob",
  isEdit = "isEdit",
  selectedIndex = "selectedIndex",
}

export interface EmploymentFormData {
  [EmploymentFormEnum.company]?: string | null;
  [EmploymentFormEnum.summary]?: string | null;
  [EmploymentFormEnum.title]?: string | null;
  [EmploymentFormEnum.startDate]?: string | null;
  [EmploymentFormEnum.endDate]?: string | null;
  [EmploymentFormEnum.currentJob]?: boolean | null;
  [EmploymentFormEnum.isEdit]?: boolean | null;
  [EmploymentFormEnum.selectedIndex]?: number | null;
}
