import { AuthProps, AuthRoutes, UnAuthRoutes } from "utils";
import { ShouldRender, FullPageLoader, isValidRoute, ToastContainer } from "@remotebase/components";
import { LogoutPage } from "pages";
import React, { Fragment, useEffect, useState } from "react";
import { Route, RouteProps, Switch, useHistory, useLocation } from "react-router-dom";
import { withAuth } from "state/auth";
import SidebarProvider from "state/sidebar/sidebar.provider";
import { useAnalyticsPageEventCreator } from "hooks/analytics";
import withError from "state/error/withErrorHoc";
import { ErrorProps } from "@remotebase/constants";
import BillingFeatureToggleProvider from "state/billingFeatureToggle/billingFeatureToggle.provider";
import withApolloProvider from "hooks/apollo/withApollo";
import { AuthRoutesArray, mainRoutes, UnAuthRoutesArray, AllRoutesArray } from "./RoutesConstants";

const MainRouter: React.FC<AuthProps & ErrorProps> = ({
  authState: { isLoading, isLoggedIn },
  ...rest
}) => {
  const [pathFound, handlePathFound] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const { performAction: trackPageEvent } = useAnalyticsPageEventCreator();

  useEffect(() => {
    if (AllRoutesArray.includes(pathname)) {
      trackPageEvent(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (typeof isLoggedIn === "boolean" && !isLoading) {
      if (isLoggedIn && !isValidRoute(AuthRoutesArray, pathname)) history.push(AuthRoutes.Talents);
      else if (!isLoggedIn && !isValidRoute(UnAuthRoutesArray, pathname))
        history.push(UnAuthRoutes.Login);
      handlePathFound(true);
    }
  }, [isLoading, isLoggedIn, pathname]);

  return (
    <Fragment>
      <ToastContainer {...rest} />
      <ShouldRender if={!pathFound}>
        <FullPageLoader />
      </ShouldRender>

      <ShouldRender if={pathFound}>
        <BillingFeatureToggleProvider>
          <SidebarProvider>
            <Switch>
              <Route exact path="/logout" component={LogoutPage} />

              {mainRoutes.map((route: RouteProps, index: number) => (
                <Route key={`${index}`} {...route} />
              ))}
            </Switch>
          </SidebarProvider>
        </BillingFeatureToggleProvider>
      </ShouldRender>
    </Fragment>
  );
};

export default withApolloProvider(withError(withAuth(MainRouter)));
