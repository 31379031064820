import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const EditFormLabel = styled.label`
  font-size: 14px;
  line-height: 1.2;
  color: #808191;
  font-weight: 500;
  margin-bottom: 0.5rem;
  width: 100%;

  @media only screen and (max-width: 767px) {
    font-size: 12px;
    margin-bottom: 0.35rem;
  }
`;

export const EditFormError = styled.p`
  color: red !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 0.8rem !important;
  position: absolute;
  right: 0;
`;

export const EditSelectError = styled.p<{ removeMargin?: boolean }>`
  color: red !important;
  font-size: 0.8rem !important;
  position: absolute;
  right: 0;
  bottom: -1.2rem;
  margin-bottom: 0;
`;

export const EditFormInput = styled.input`
  border-radius: 0.35rem;
  height: 2.5rem;
  border: 1px solid #dddfe2;
  padding: 0 0.5rem;
  width: 100%;
`;

export const EditFormTextArea = styled.textarea`
  border-radius: 0.35rem;
  height: 7.5rem;
  border: 1px solid #dddfe2;
  padding: 0.5rem;
  width: 100%;
`;
