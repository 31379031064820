import withApolloProvider from "hooks/apollo/withApollo";
import React, { useState, useEffect, useContext } from "react";
import { useUpdateJobMatchStage } from "hooks";
import { WithTalentMatchCompanyProps } from "hooks/types";
import { ITalentContextType } from "state/types";
import ErrorContext from "state/error/error.context";
import { HttpStatus, IErrorContextType } from "@remotebase/constants";
import { updateErrorState } from "@remotebase/components";
import { TalentContext } from "state/talent/talent.context";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withTalentMatchCompany = (Component: React.FC): React.FC<any> => {
  return withApolloProvider((props) => {
    const { updateTalentJobMatchStage, data: updateData } = useUpdateJobMatchStage();
    const { setErrorState } = useContext<IErrorContextType>(ErrorContext);
    const [loading, setLoading] = useState(false);
    const { talentState, setTalentState } = useContext<ITalentContextType>(TalentContext);
    const { currentTalent } = talentState || {};
    const changeStage = (jobMatchStage, feedback, currentEng = currentTalent): void => {
      setLoading(true);
      updateTalentJobMatchStage({
        variables: {
          payload: {
            jobMatchID: currentEng?.id,
            jobMatchVersion: currentEng?.version,
            jobMatchStage,
            companyID: currentEng?.job?.companyID,
            feedback,
          },
        },
      });
    };
    const onFavoriteClick = (talentFeedback): void => {
      changeStage(
        talentFeedback?.stage === "interviewing" ? "referred" : "interviewing",
        "",
        talentFeedback,
      );
    };

    useEffect(() => {
      if (updateData) {
        if (updateData?.code === HttpStatus.SUCCESS) {
          setLoading(false);
          const index = talentState.data.findIndex((i) => i?.id === updateData.data?.id);
          const arr = [...talentState.data];
          if (updateData?.data?.stage && updateData?.data?.version) {
            arr[index].stage = updateData?.data?.stage;
            arr[index].version = updateData?.data?.version;
          }
          setTalentState({
            ...talentState,
            data: arr,
            ...(currentTalent && { currentTalent: arr[index] }),
          });
          updateErrorState(
            { title: "Success", message: "Status Updated Successfully" },
            setErrorState,
          );
        } else if (updateData?.code !== HttpStatus.SUCCESS)
          updateErrorState(
            { title: "Faliure", message: "Error: Status Not Updated!" },
            setErrorState,
          );
      }
    }, [updateData, updateTalentJobMatchStage]);

    const talentCardProps: WithTalentMatchCompanyProps = {
      loading,
      onFavoriteClick,
      changeStage,
    };

    return <Component {...props} {...talentCardProps} />;
  });
};

export default withTalentMatchCompany;
