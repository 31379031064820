import styled from "styled-components";

export const TalentDetailSection = styled.div`
  /* height: 276px; */
`;

export const TalentBoxCanvas = styled.div`
  padding: 0 60px;
  background-image: blue;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
`;

export const TalentBox = styled.div`
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  width: 100%;
  padding: 0px;
  position: relative;

  &:hover {
    & .engineerOverlay {
      opacity: 1;
      pointer-events: initial;

      & button,
      & span {
        transform: translate(0px, 0px);
        transition-delay: 0.1s;
      }
    }
  }
`;

export const TalentBoxMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px -19.15px 61.6981px rgba(52, 64, 84, 0.14);

  @media only screen and (max-width: 1279px) {
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const TalentBoxImgContainer = styled.div`
  width: 100%;
  height: 100px;
  padding: 10px;
`;

export const TalentInnerBg = styled.img`
  width: 100%;
  background: rgba(54, 23, 186, 0.57);
  border-radius: 5px;
  height: 61px;
`;

export const TalentBoxImg = styled.img`
  object-fit: cover;
  position: absolute;
  width: 60px;
  height: 60px;
  left: calc(50% - 28px);
  top: 25px;
  border-radius: 50%;
`;

export const DeveloperInfoWrapper = styled.div`
  margin-top: 1px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
`;

export const DeveloperPic = styled.img`
  width: 48px;
  height: 48px;
  left: 4px;
  top: 4px;
  border-radius: 35px;
  object-fit: cover;
`;

export const DeveloperPicInit = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background: #7c7c7c;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  align-items: center;
  padding-top: 12px;
  flex-shrink: 0;
`;

export const DeveloperInit = styled.span`
  width: 31px;
  height: 24px;

  /* Heading Text - H5 Semibold */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  /* Primary - White */

  color: #ffffff;
`;

export const DeveloperNameInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1px 0 0 8px;
`;

export const DeveloperNameStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DeveloperInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const DeveloperName = styled.span`
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 134.5% !important;
  color: #1a0224 !important;
  margin-right: 8px !important;
`;

export const DeveloperTitle = styled.div`
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 134.5% !important;
  color: #4d526d !important;
  margin-top: 5px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;
export const DeveloperFeedBack = styled.div`
  margin-bottom: 15px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SelectionMsg = styled.div`
  padding-top: 5px;
`;

export const DeveloperProfileButton = styled.button`
  width: 127px;
  height: 41.87px;
  border: 0.697802px solid #4d526d;
  border-radius: 11.1648px;
  padding: 15.97802px;
  padding-left: 10px;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 30px;
  font-family: "Proxima Nova" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #312a50;
  background-color: #fff;
  &:hover {
    color: #fff;
    background: #3615bd;
    border: 1px solid #3615bd;
    text-decoration: none;
    &:before {
      height: 200%;
    }
  }
`;

export const DeveloperRate = styled.div`
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 130% !important;
  color: #4d526d;
  margin-top: 10px;
`;

export const DeveloperSeparator = styled.div`
  width: 0px;
  height: 14.96px;
  border: 0.623368px solid #4d526d;
  margin-top: 10px;
`;

export const DeveloperSkillScore = styled.div`
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 130% !important;
  color: #4d526d !important;
  margin-top: 10px;
`;

export const FavouriteDiv = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: 5px;
  text-align: center;
  align-items: center;
  padding-top: 8px;

  background: rgba(217, 217, 217, 0.5);
  border-radius: 50%;
`;
