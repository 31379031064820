import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  &.hidden {
    visibility: hidden;
  }
  & .modal-dialog {
    &.w94 {
      max-width: 565px;
    }
  }
`;

export const ModalWrapper = styled.div`
  position: relative;
  max-width: 900px;
  min-width: 660px;
  padding: 15px 40px 15px 20px;
  position: relative;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1.5rem;
  cursor: pointer;
  // padding: 15px 40px 15px 20px;
`;
