import { FC } from "react";
import { useHistory } from "react-router-dom";
import { MiddleContainer, RetrySpan } from "./styles";

const ErrorComponent: FC<{ handleRetryClick?: () => void }> = ({ handleRetryClick }) => {
  const history = useHistory();
  const retryHandler = handleRetryClick || ((): void => history.go(0));
  return (
    <MiddleContainer>
      <p>
        Error occurred, <RetrySpan onClick={retryHandler}>click here to retry</RetrySpan>
      </p>
    </MiddleContainer>
  );
};

export default ErrorComponent;
