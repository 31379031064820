/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCandidateResponseType = {
  __typename: "CreateCandidateResponseType",
  code?: number | null,
  message?: string | null,
  data?: boolean | null,
};

export type WorkableAttachmentInput = {
  testTitle: string,
  pdfUrl: string,
};

export type TalentJobsInput = {
  jobID: string,
  isPublished: boolean,
  matchProcess: matchProcess,
  matchStage: matchStage,
  isRockstar: boolean,
};

export enum matchProcess {
  automated = "automated",
  manual = "manual",
}


export enum matchStage {
  referred = "referred",
  interviewing = "interviewing",
  passTechInterview = "passTechInterview",
  failTechInterview = "failTechInterview",
  passCulturalFitInterview = "passCulturalFitInterview",
  failCulturalFitInterview = "failCulturalFitInterview",
  passSystemDesignInterview = "passSystemDesignInterview",
  failSystemDesignInterview = "failSystemDesignInterview",
  rejected = "rejected",
  onboarded = "onboarded",
  interested = "interested",
  liked = "liked",
  disliked = "disliked",
}


export type MatchEngToJobResponse = {
  __typename: "MatchEngToJobResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<JobMatch | null > | null,
};

export type JobMatch = {
  __typename: "JobMatch",
  id: string,
  talentID: string,
  jobID: string,
  lastActivity: string,
  stage: matchStage,
  process: matchProcess,
  isRockstar?: boolean | null,
  isPublished: boolean,
  clientFeedback?: ClientFeedback | null,
  isPaidFor?: boolean | null,
  feedback?: JobMatchFeedback | null,
  talentProfileVersionID?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  job?: Job | null,
  talentProfileVersion?: TalentProfileVersion | null,
  talent?: Talent | null,
};

export type ClientFeedback = {
  __typename: "ClientFeedback",
  referred?: string | null,
  interviewing?: string | null,
  passTechInterview?: string | null,
  failTechInterview?: string | null,
  passCulturalFitInterview?: string | null,
  failCulturalFitInterview?: string | null,
  passSystemDesignInterview?: string | null,
  failSystemDesignInterview?: string | null,
  rejected?: string | null,
  onboarded?: string | null,
  interested?: string | null,
};

export type JobMatchFeedback = {
  __typename: "JobMatchFeedback",
  communicationSkillsLevel?: number | null,
  techSkillAndDomainKnowledgeLevel?: number | null,
  problemSolvingSkillLevel?: number | null,
  isRejected?: boolean | null,
  otherFeedback?: string | null,
};

export type Job = {
  __typename: "Job",
  id: string,
  companyID: string,
  status: JobStatus,
  title: string,
  description: string,
  responsibilites: string,
  tags: Array< string | null >,
  requiredTechDomainSkills: Array< TechDomainSkills | null >,
  seniorityLevel: SeniorityLevel,
  isPublic?: boolean | null,
  isPermanent?: boolean | null,
  internalNotes?: string | null,
  department?: JobDepartment | null,
  jobPerks?: Array< JobPerks | null > | null,
  createdAt: string,
  updatedAt: string,
  company?: Company | null,
};

export enum JobStatus {
  Draft = "Draft",
  PendingApproval = "PendingApproval",
  Approved = "Approved",
  Declined = "Declined",
  Published = "Published",
  Archived = "Archived",
}


export enum TechDomainSkills {
  Amplify = "Amplify",
  Android = "Android",
  Angular = "Angular",
  Aws = "Aws",
  CPlusPlus = "CPlusPlus",
  Csharp = "Csharp",
  d3 = "d3",
  DESIGNER = "DESIGNER",
  DataScience = "DataScience",
  DataEngineer = "DataEngineer",
  DevOps = "DevOps",
  Django = "Django",
  Docker = "Docker",
  DotNet = "DotNet",
  Drupal = "Drupal",
  Express = "Express",
  Flutter = "Flutter",
  GCP = "GCP",
  Golang = "Golang",
  Graphql = "Graphql",
  IOS = "IOS",
  Java = "Java",
  Javascript = "Javascript",
  Kubernetes = "Kubernetes",
  Magento = "Magento",
  MongoDb = "MongoDb",
  Mysql = "Mysql",
  NextJs = "NextJs",
  NodeJs = "NodeJs",
  Oracle = "Oracle",
  PERFORMANCE_MARKETING_SPECIALIST = "PERFORMANCE_MARKETING_SPECIALIST",
  PHP = "PHP",
  Postgres = "Postgres",
  Python = "Python",
  React = "React",
  ReactAndNode = "ReactAndNode",
  ReactNative = "ReactNative",
  Redux = "Redux",
  Ror = "Ror",
  SDR = "SDR",
  SFCC = "SFCC",
  TECHNICAL_RECRUITER = "TECHNICAL_RECRUITER",
  Typescript = "Typescript",
  Vue = "Vue",
  Wordpress = "Wordpress",
  AzureDevOps = "AzureDevOps",
}


export enum SeniorityLevel {
  Intern = "Intern",
  Associate = "Associate",
  MidCareer = "MidCareer",
  Senior = "Senior",
  Staff = "Staff",
  Manager = "Manager",
  ProgramArchitect = "ProgramArchitect",
  Director = "Director",
  VicePresident = "VicePresident",
  HeadOfDepartment = "HeadOfDepartment",
  CLevel = "CLevel",
}


export enum JobDepartment {
  HumanResource = "HumanResource",
  Recruitment = "Recruitment",
  Finance = "Finance",
  Marketing = "Marketing",
  Branding = "Branding",
  UserExperience = "UserExperience",
  Engineering = "Engineering",
  Operations = "Operations",
  DevRel = "DevRel",
  CustomerSupport = "CustomerSupport",
  Media = "Media",
}


export enum JobPerks {
  Remote = "Remote",
  OfficeOptional = "OfficeOptional",
  FlexibleHours = "FlexibleHours",
  MedicalInsurance = "MedicalInsurance",
  CompanyLaptop = "CompanyLaptop",
  PaidLeaves = "PaidLeaves",
  GymAllowance = "GymAllowance",
  MarketCompetitiveSalaries = "MarketCompetitiveSalaries",
  LearningPlatforms = "LearningPlatforms",
  AnnualRetreat = "AnnualRetreat",
}


export type Company = {
  __typename: "Company",
  id: string,
  name: string,
  logo: string,
  stage: companyStage,
  tagline?: string | null,
  description?: string | null,
  address: Address,
  industry: industry,
  associatedWith?: companyAssociation | null,
  createdAt: string,
  updatedAt: string,
};

export enum companyStage {
  preSeed = "preSeed",
  seed = "seed",
  seriesA = "seriesA",
  seriesB = "seriesB",
  seriesC = "seriesC",
  seriesD = "seriesD",
  public = "public",
  bootstrapped = "bootstrapped",
}


export type Address = {
  __typename: "Address",
  id: string,
  streetAddress: string,
  apartmentAddress?: string | null,
  postalCode?: number | null,
  district?: string | null,
  city: string,
  country: Country,
};

export enum Country {
  AC = "AC",
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TA = "TA",
  TC = "TC",
  TD = "TD",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  XK = "XK",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}


export enum industry {
  IT = "IT",
  Automotive = "Automotive",
  Education = "Education",
  Healthcare = "Healthcare",
  Agriculture = "Agriculture",
  ECommerce = "ECommerce",
  Retail = "Retail",
  ProfessionalServices = "ProfessionalServices",
  Transportation = "Transportation",
  Electronics = "Electronics",
  Manufacturing = "Manufacturing",
  Aerospace = "Aerospace",
  Engineering = "Engineering",
  Finance = "Finance",
  Law = "Law",
  Textile = "Textile",
  Other = "Other",
}


export enum companyAssociation {
  client = "client",
  client_basic = "client_basic",
}


export type TalentProfileVersion = {
  __typename: "TalentProfileVersion",
  id: string,
  name: string,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable: boolean,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  educationHistory?:  Array<EducationEntry | null > | null,
  employmentHistory?:  Array<EmploymentEntry | null > | null,
  socialProfiles?:  Array<SocialProfile | null > | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentID: string,
  createdAt: string,
  updatedAt: string,
  version: number,
  talent?: Talent | null,
};

export enum SocialLinkTypes {
  GITHUB = "GITHUB",
  LINKEDIN = "LINKEDIN",
  HACKERRANK = "HACKERRANK",
  STACKOVERFLOW = "STACKOVERFLOW",
}


export enum englishProficiency {
  Basic = "Basic",
  Average = "Average",
  Great = "Great",
  Native = "Native",
}


export type EducationEntry = {
  __typename: "EducationEntry",
  degree: string,
  school: string,
  startDate?: string | null,
  endDate: string,
};

export type EmploymentEntry = {
  __typename: "EmploymentEntry",
  title?: string | null,
  summary?: string | null,
  startDate: string,
  endDate?: string | null,
  company: string,
  currentJob?: boolean | null,
};

export type SocialProfile = {
  __typename: "SocialProfile",
  urlType: SocialLinkTypes,
  url: string,
};

export type Talent = {
  __typename: "Talent",
  id: string,
  fullName: string,
  email: string,
  phone: string,
  country?: Country | null,
  role: UserRoles,
  createdAt: string,
  updatedAt: string,
  version: number,
  profile?: TalentProfile | null,
};

export type Person = {
  __typename: "Person",
  id: string,
  fullName: string,
  email: string,
  phone: string,
  role: UserRoles,
};

export type Client = {
  __typename: "Client",
  id: string,
  fullName: string,
  email: string,
  phone: string,
  country?: Country | null,
  role: UserRoles,
  companyID?: string | null,
  timezone?: string | null,
  availability?:  Array<ClientAvailability > | null,
  googleCalendarId?: string | null,
  remainingCredits?: number | null,
  totalCredits?: number | null,
  stripeCustomerId?: string | null,
  currentStripeSubscriptionId?: string | null,
  hasMandate?: boolean | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  company?: Company | null,
};

export enum UserRoles {
  CLIENT = "CLIENT",
  ENGINEER = "ENGINEER",
  RECRUITER = "RECRUITER",
  CLIENT_BASIC = "CLIENT_BASIC",
  TALENT = "TALENT",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
  FINANCE = "FINANCE",
  TECHRECRUITER = "TECHRECRUITER",
  CUSTOMERSUPPORT = "CUSTOMERSUPPORT",
  CUSTOMERSUCCESS = "CUSTOMERSUCCESS",
  SALES = "SALES",
}


export type ClientAvailability = {
  __typename: "ClientAvailability",
  day: number,
  date: string,
  availableSlots:  Array<AvailableSlot >,
};

export type AvailableSlot = {
  __typename: "AvailableSlot",
  startTime: string,
  endTime: string,
};

export type Recruiter = {
  __typename: "Recruiter",
  id: string,
  fullName: string,
  email: string,
  phone: string,
  country?: Country | null,
  role: UserRoles,
  createdAt: string,
  updatedAt: string,
  version: number,
  profile?: RecruiterProfile | null,
};

export type RecruiterProfile = {
  __typename: "RecruiterProfile",
  id: string,
  currentEarnings: number,
  profession?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  recruiterProfileRecruiterId?: string | null,
};

export type TalentProfile = {
  __typename: "TalentProfile",
  id: string,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable: boolean,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgress | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?:  Array<VideoResponse | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?:  Array<Feedback | null > | null,
  resumeLink?: string | null,
  takenDomainTests?:  Array<TechDomainSkillsProgress | null > | null,
  educationHistory?:  Array<EducationEntry | null > | null,
  employmentHistory?:  Array<EmploymentEntry | null > | null,
  socialProfiles?:  Array<SocialProfile | null > | null,
  resetTests?: resetTests | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: analyticsData | null,
  isProfileCompleted?: boolean | null,
  workableId?: string | null,
  aiInterviewInProgress?: boolean | null,
  videoInterviewProvider?: VideoInterviewProvider | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  talent?: Talent | null,
  talentProfileTalentId?: string | null,
  talentType?: TalentType | null,
};

export type TechProblemSolvingProgress = {
  __typename: "TechProblemSolvingProgress",
  testId?: string | null,
  candidateId?: string | null,
  score: number,
  isPass: boolean,
  provider?: TechSkillTestProvider | null,
  dateAttempted?: string | null,
  testEnd?: string | null,
  attemptId?: string | null,
  percentage?: number | null,
  totalScore?: number | null,
  testLink?: string | null,
};

export enum TechSkillTestProvider {
  Remotebase = "Remotebase",
  HackerRank = "HackerRank",
}


export type VideoResponse = {
  __typename: "VideoResponse",
  question: string,
  thumbnail: string,
  link: string,
};

export type Feedback = {
  __typename: "Feedback",
  type: feedbackType,
  score?: number | null,
  sentiment?: feedbackSentiment | null,
  description: string,
  givenAt: string,
  meta?: string | null,
};

export enum feedbackType {
  HRFeedback = "HRFeedback",
  MiddleTierFeedback = "MiddleTierFeedback",
  FinalInterviewFeedback = "FinalInterviewFeedback",
  CulturalFeedback = "CulturalFeedback",
  ClientSuccess = "ClientSuccess",
  ClientFailure = "ClientFailure",
}


export enum feedbackSentiment {
  positive = "positive",
  negative = "negative",
  neutral = "neutral",
}


export type TechDomainSkillsProgress = {
  __typename: "TechDomainSkillsProgress",
  skill: TechDomainSkills,
  score: number,
  isPass: boolean,
  provider: TechSkillTestProvider,
  testStart?: string | null,
  testEnd?: string | null,
  percentage?: number | null,
  totalScore?: number | null,
  testLink?: string | null,
  domainTestData?: string | null,
};

export type resetTests = {
  __typename: "resetTests",
  domainSkillTests?:  Array<TechDomainSkillsProgress | null > | null,
  problemSolvingTests?:  Array<TechProblemSolvingProgress | null > | null,
  hrInterviews?:  Array<hrInterViewReset | null > | null,
  finalInterviews?:  Array<finalInterviewReset | null > | null,
};

export type hrInterViewReset = {
  __typename: "hrInterViewReset",
  hrVideoId?: string | null,
  isPass: boolean,
  dateAttempted?: string | null,
  dateOfHRStatus?: string | null,
  feedback?: Feedback | null,
};

export type finalInterviewReset = {
  __typename: "finalInterviewReset",
  isPass: boolean,
  dateAttempted?: string | null,
  feedback?: Feedback | null,
};

export type analyticsData = {
  __typename: "analyticsData",
  source?: string | null,
  medium?: string | null,
  campaign?: string | null,
  referral?: string | null,
};

export enum VideoInterviewProvider {
  Talently = "Talently",
  MyInterview = "MyInterview",
}


export type TalentType = {
  __typename: "TalentType",
  id: string,
  name: string,
  title: string,
  icon: string,
  sequence: number,
  skills:  Array<TalentSkill | null >,
  interests:  Array<TalentInterest | null >,
  sortType: string,
  stages?:  Array<Stage > | null,
  videoInterviewProvider?: VideoInterviewOptions | null,
  hrInterviewJobId: string,
  hrInterviewAIURL?: string | null,
  atsJobID: string,
  isActive?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type TalentSkill = {
  __typename: "TalentSkill",
  name: string,
  title: string,
};

export type TalentInterest = {
  __typename: "TalentInterest",
  name: string,
  title: string,
};

export type Stage = {
  __typename: "Stage",
  type: AssessmentStage,
  isOptional?: boolean | null,
  sequenceNumber: number,
  passingPercentage?: number | null,
};

export enum AssessmentStage {
  ProblemSolving = "ProblemSolving",
  DomainTest = "DomainTest",
  TakeHomeAssignment = "TakeHomeAssignment",
  HRInterview = "HRInterview",
  FinalInterview = "FinalInterview",
}


export enum VideoInterviewOptions {
  Talently = "Talently",
  Random = "Random",
}


export type NewClientJobMatchInput = {
  jobMatchID: string,
  companyID: string,
};

export type UpdateJobMatchStageResponseType = {
  __typename: "UpdateJobMatchStageResponseType",
  code: number,
  message?: string | null,
  data?: JobMatch | null,
};

export type SendEmailInputType = {
  emailType: EmailTypes,
  toEmail: string,
  messageData: string,
};

export enum EmailTypes {
  NewClient = "NewClient",
  NewTalentJobMatch = "NewTalentJobMatch",
  NewClientTalentJobMatch = "NewClientTalentJobMatch",
  RemovedTalentJobMatch = "RemovedTalentJobMatch",
  NewClientFeedback = "NewClientFeedback",
  ClientContactSupport = "ClientContactSupport",
  ClientFirstLogin = "ClientFirstLogin",
}


export type SendEmailResponseType = {
  __typename: "SendEmailResponseType",
  code?: number | null,
  message?: string | null,
  data?: boolean | null,
};

export type ClientInterviewInviteResponse = {
  __typename: "ClientInterviewInviteResponse",
  code?: number | null,
  message?: string | null,
  data?: JobMatch | null,
};

export type CreateAvailabilityInput = {
  day: number,
  availableSlots: Array< SlotInput >,
  date: string,
};

export type SlotInput = {
  startTime: string,
  endTime: string,
};

export type CreateAvailabilityResponseType = {
  __typename: "CreateAvailabilityResponseType",
  code: number,
  message: string,
};

export type MatchStageFilterInput = {
  stage?: StageFilterMappingInput | null,
};

export type StageFilterMappingInput = {
  eq?: matchStage | null,
  ne?: matchStage | null,
};

export type LambdaStringResponse = {
  __typename: "LambdaStringResponse",
  code?: number | null,
  message?: string | null,
  data?: string | null,
};

export type UpdateJobMatchStageInput = {
  jobMatchID: string,
  jobMatchVersion: number,
  companyID: string,
  jobMatchStage: matchStage,
  jobID?: string | null,
  feedback?: string | null,
};

export type SaveSubscriptionReceiptInput = {
  appType: SubscriptionAppType,
  transactionReceipt?: string | null,
  productId: string,
  purchaseToken?: string | null,
};

export enum SubscriptionAppType {
  ios = "ios",
  android = "android",
  stripe = "stripe",
}


export type SaveSubscriptionReceiptResponse = {
  __typename: "SaveSubscriptionReceiptResponse",
  code?: number | null,
  message?: string | null,
};

export type CreateOrUpdateJobMatchInput = {
  id?: string | null,
  talentID: string,
  jobID: string,
  lastActivity?: string | null,
  stage?: matchStage | null,
  process?: matchProcess | null,
  isRockstar?: boolean | null,
  isPublished?: boolean | null,
  clientFeedback?: ClientFeedbackInput | null,
  talentProfileVersionID?: string | null,
};

export type ClientFeedbackInput = {
  referred?: string | null,
  interviewing?: string | null,
  passTechInterview?: string | null,
  failTechInterview?: string | null,
  passCulturalFitInterview?: string | null,
  failCulturalFitInterview?: string | null,
  passSystemDesignInterview?: string | null,
  failSystemDesignInterview?: string | null,
  rejected?: string | null,
  onboarded?: string | null,
  interested?: string | null,
};

export type CreateOrUpdateJobMatchResponse = {
  __typename: "CreateOrUpdateJobMatchResponse",
  code?: number | null,
  message?: string | null,
  data?: JobMatch | null,
};

export type UpdateJobMatchFeedbackInput = {
  jobMatchID: string,
  feedback: JobMatchFeedbackInput,
};

export type JobMatchFeedbackInput = {
  communicationSkillsLevel?: number | null,
  techSkillAndDomainKnowledgeLevel?: number | null,
  problemSolvingSkillLevel?: number | null,
  isRejected?: boolean | null,
  otherFeedback?: string | null,
};

export type updateCompanyDataInput = {
  id: string,
  name?: string | null,
  logo?: string | null,
  stage?: companyStage | null,
  tagline?: string | null,
  description?: string | null,
  address?: AddressInput | null,
  industry?: industry | null,
};

export type AddressInput = {
  id: string,
  streetAddress?: string | null,
  apartmentAddress?: string | null,
  postalCode?: number | null,
  district?: string | null,
  city?: string | null,
  country?: Country | null,
};

export type updateCompanyDataResponseType = {
  __typename: "updateCompanyDataResponseType",
  code: number,
  message?: string | null,
  data?: Company | null,
};

export type AcceptInvitationResponse = {
  __typename: "AcceptInvitationResponse",
  code?: number | null,
  message?: string | null,
  data?: RefferalData | null,
};

export type RefferalData = {
  __typename: "RefferalData",
  refferedBy?: string | null,
};

export type GenerateRecruitersReportInput = {
  fromDate: string,
  toDate: string,
  email?: string | null,
};

export type CreateTalentProfileWithUsdSalaryInput = {
  id?: string | null,
  skills?: Array< string | null > | null,
  experience?: string | null,
  isAvailable: boolean,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgressInput | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  hrInterviewVideoId?: string | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary: string,
  expectedSalary: string,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?: Array< FeedbackInput | null > | null,
  resumeLink?: string | null,
  takenDomainTests?: Array< TechDomainSkillsProgressInput | null > | null,
  resetTests?: resetTestsInput | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  bio?: string | null,
  title?: string | null,
  imgLink?: string | null,
  talentProfileTalentId?: string | null,
  analyticsData?: AnalyticsDataInput | null,
};

export type TechProblemSolvingProgressInput = {
  testId?: string | null,
  candidateId?: string | null,
  score: number,
  isPass: boolean,
  provider?: TechSkillTestProvider | null,
  dateAttempted?: string | null,
  testEnd?: string | null,
  attemptId?: string | null,
  percentage?: number | null,
  totalScore?: number | null,
  testLink?: string | null,
};

export type FeedbackInput = {
  type: feedbackType,
  score?: number | null,
  sentiment?: feedbackSentiment | null,
  description: string,
  givenAt: string,
  meta?: string | null,
};

export type TechDomainSkillsProgressInput = {
  skill: TechDomainSkills,
  score: number,
  isPass: boolean,
  provider: TechSkillTestProvider,
  testStart?: string | null,
  testEnd?: string | null,
  percentage?: number | null,
  totalScore?: number | null,
  testLink?: string | null,
  domainTestData?: string | null,
};

export type resetTestsInput = {
  domainSkillTests?: Array< TechDomainSkillsProgressInput | null > | null,
  problemSolvingTests?: Array< TechProblemSolvingProgressInput | null > | null,
  hrInterviews?: Array< hrInterViewResetInput | null > | null,
  finalInterviews?: Array< finalInterviewResetInput | null > | null,
};

export type hrInterViewResetInput = {
  hrVideoId?: string | null,
  isPass: boolean,
  dateAttempted?: string | null,
  dateOfHRStatus?: string | null,
  feedback?: FeedbackInput | null,
};

export type finalInterviewResetInput = {
  isPass: boolean,
  dateAttempted?: string | null,
  feedback?: FeedbackInput | null,
};

export type EducationEntryInput = {
  degree: string,
  school: string,
  startDate?: string | null,
  endDate: string,
};

export type EmploymentEntryInput = {
  title?: string | null,
  summary?: string | null,
  startDate: string,
  endDate?: string | null,
  company: string,
  currentJob?: boolean | null,
};

export type SocialProfileInput = {
  urlType: SocialLinkTypes,
  url: string,
};

export type AnalyticsDataInput = {
  source?: string | null,
  medium?: string | null,
  campaign?: string | null,
  referral?: string | null,
};

export type TalentProfileWithUsdSalaryResponse = {
  __typename: "TalentProfileWithUsdSalaryResponse",
  code?: number | null,
  message?: string | null,
  data?: TalentProfile | null,
};

export type UpdateTalentProfileWithUsdSalaryInput = {
  id: string,
  skills?: Array< string | null > | null,
  experience?: string | null,
  isAvailable?: boolean | null,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgressInput | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  hrInterviewVideoId?: string | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?: Array< FeedbackInput | null > | null,
  resumeLink?: string | null,
  takenDomainTests?: Array< TechDomainSkillsProgressInput | null > | null,
  resetTests?: resetTestsInput | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  bio?: string | null,
  title?: string | null,
  imgLink?: string | null,
  expectedVersion: number,
  talentProfileTalentId?: string | null,
  analyticsData?: AnalyticsDataInput | null,
  talentTypeID?: string | null,
  isProfileCompleted?: boolean | null,
  aiInterviewInProgress?: boolean | null,
  videoInterviewProvider?: VideoInterviewProvider | null,
};

export type ApplyJobWorkableType = {
  firstname?: string | null,
  lastname?: string | null,
  email?: string | null,
  jobcode?: string | null,
  resumeURL?: string | null,
  phone?: string | null,
};

export type ApplyJobWorkableResponseType = {
  __typename: "ApplyJobWorkableResponseType",
  code?: number | null,
  message?: string | null,
  data?: ApplyJobWorkableDataResponseType | null,
};

export type ApplyJobWorkableDataResponseType = {
  __typename: "ApplyJobWorkableDataResponseType",
  uid?: string | null,
  firstname?: string | null,
  lastname?: string | null,
  email?: string | null,
};

export type SendMessageInput = {
  conversationId: string,
  content: string,
};

export type SendMessageResponse = {
  __typename: "SendMessageResponse",
  code?: number | null,
  message?: string | null,
  data?: MessageResponse | null,
};

export type MessageResponse = {
  __typename: "MessageResponse",
  id?: string | null,
  content?: string | null,
  senderInfo?: PersonInfo | null,
  conversationId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isRead?: boolean | null,
  version?: number | null,
  readBy?:  Array<PersonInfo | null > | null,
};

export type PersonInfo = {
  __typename: "PersonInfo",
  id: string,
  fullName: string,
  email?: string | null,
  phone?: string | null,
  role: UserRoles,
};

export type CreateConversationLambdaInput = {
  recipients: Array< RecipientInput | null >,
};

export type RecipientInput = {
  recipientId: string,
  recipientRole: UserRoles,
};

export type CreateConversationResponse = {
  __typename: "CreateConversationResponse",
  code?: number | null,
  message?: string | null,
  data?: ConversationResponse | null,
};

export type ConversationResponse = {
  __typename: "ConversationResponse",
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isActive?: boolean | null,
  version?: number | null,
  messages?:  Array<MessageResponse | null > | null,
  conversationParticipants?:  Array<PersonInfo | null > | null,
};

export type MarkMessageAsReadInput = {
  messageId: string,
};

export type MarkMessageAsReadResponse = {
  __typename: "MarkMessageAsReadResponse",
  code?: number | null,
  message?: string | null,
  data?: MessageResponse | null,
};

export type CreateOrGetStripeCustomerResponse = {
  __typename: "CreateOrGetStripeCustomerResponse",
  code?: number | null,
  message?: string | null,
  data?: CreateOrGetStripeCustomerResponseData | null,
};

export type CreateOrGetStripeCustomerResponseData = {
  __typename: "CreateOrGetStripeCustomerResponseData",
  customer?: StripeCustomer | null,
  paymentMethods?:  Array<PaymentMethod > | null,
  subscription?: StripeSubscription | null,
};

export type StripeCustomer = {
  __typename: "StripeCustomer",
  id?: string | null,
  object?: string | null,
  address?: string | null,
  balance?: number | null,
  created?: number | null,
  currency?: string | null,
  default_source?: string | null,
  delinquent?: boolean | null,
  description?: string | null,
  discount?: string | null,
  email?: string | null,
  invoice_prefix?: string | null,
  invoice_settings?: InvoiceSettingResponse | null,
  livemode?: boolean | null,
  metadata?: string | null,
  name?: string | null,
  next_invoice_sequence?: number | null,
  phone?: string | null,
  preferred_locales?: string | null,
  shipping?: string | null,
  tax_exempt?: string | null,
  test_clock?: string | null,
};

export type InvoiceSettingResponse = {
  __typename: "InvoiceSettingResponse",
  custom_fields?: string | null,
  default_payment_method?: string | null,
  footer?: string | null,
  rendering_options?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  pid?: string | null,
  lastFour?: string | null,
  networks?: NetworksResponse | null,
};

export type NetworksResponse = {
  __typename: "NetworksResponse",
  available?: Array< string > | null,
  preferred?: string | null,
};

export type StripeSubscription = {
  __typename: "StripeSubscription",
  id?: string | null,
  object?: string | null,
  application?: string | null,
  application_fee_percent?: string | null,
  automatic_tax?: StripeAutomaticTax | null,
  billing_cycle_anchor?: number | null,
  billing_thresholds?: string | null,
  cancel_at?: string | null,
  cancel_at_period_end?: boolean | null,
  canceled_at?: string | null,
  collection_method?: string | null,
  created?: number | null,
  currency?: string | null,
  current_period_end?: number | null,
  current_period_start?: number | null,
  customer?: string | null,
  days_until_due?: string | null,
  default_payment_method?: string | null,
  default_source?: string | null,
  default_tax_rates?: string | null,
  description?: string | null,
  discount?: string | null,
  ended_at?: string | null,
  items?: StripeSubscriptionItems | null,
  latest_invoice?: string | null,
  livemode?: boolean | null,
  metadata?: string | null,
  next_pending_invoice_item_invoice?: string | null,
  on_behalf_of?: string | null,
  pause_collection?: string | null,
  payment_settings?: StripeSubscriptionPaymentSettings | null,
  pending_invoice_item_interval?: string | null,
  pending_setup_intent?: string | null,
  pending_update?: string | null,
  schedule?: string | null,
  start_date?: number | null,
  status?: string | null,
  test_clock?: string | null,
  transfer_data?: string | null,
  trial_end?: string | null,
  trial_start?: string | null,
};

export type StripeAutomaticTax = {
  __typename: "StripeAutomaticTax",
  enabled?: boolean | null,
};

export type StripeSubscriptionItems = {
  __typename: "StripeSubscriptionItems",
  object?: string | null,
  data?:  Array<StripeSubscriptionItemsData > | null,
  has_more?: boolean | null,
  url?: string | null,
};

export type StripeSubscriptionItemsData = {
  __typename: "StripeSubscriptionItemsData",
  id?: string | null,
  object?: string | null,
  billing_thresholds?: string | null,
  created?: number | null,
  metadata?: string | null,
  price?: StripeSubscriptionItemsDataPrice | null,
  quantity?: number | null,
  subscription?: string | null,
  tax_rates?: string | null,
};

export type StripeSubscriptionItemsDataPrice = {
  __typename: "StripeSubscriptionItemsDataPrice",
  id?: string | null,
  object?: string | null,
  active?: boolean | null,
  billing_scheme?: string | null,
  created?: number | null,
  currency?: string | null,
  custom_unit_amount?: string | null,
  livemode?: boolean | null,
  lookup_key?: string | null,
  metadata?: string | null,
  nickname?: string | null,
  product?: string | null,
  recurring?: StripeRecurring | null,
  tax_behavior?: string | null,
  tiers_mode?: string | null,
  transform_quantity?: string | null,
  type?: string | null,
  unit_amount?: number | null,
  unit_amount_decimal?: number | null,
};

export type StripeRecurring = {
  __typename: "StripeRecurring",
  aggregate_usage?: string | null,
  interval?: string | null,
  interval_count?: number | null,
  trial_period_days?: string | null,
  usage_type?: string | null,
};

export type StripeSubscriptionPaymentSettings = {
  __typename: "StripeSubscriptionPaymentSettings",
  payment_method_options?: string | null,
  payment_method_types?: string | null,
  save_default_payment_method?: string | null,
};

export type CreateStripeSubscriptionResponse = {
  __typename: "CreateStripeSubscriptionResponse",
  code?: number | null,
  message?: string | null,
  data?: CreateStripeSubscriptionResponseData | null,
};

export type CreateStripeSubscriptionResponseData = {
  __typename: "CreateStripeSubscriptionResponseData",
  stripeSubscriptionId?: string | null,
  clientSecret?: string | null,
};

export type CreateSetupIntentForACHMandateResponse = {
  __typename: "CreateSetupIntentForACHMandateResponse",
  code?: number | null,
  data: SetupIntentClientSecretAndSetupIntentIdResponse,
  message?: string | null,
};

export type SetupIntentClientSecretAndSetupIntentIdResponse = {
  __typename: "SetupIntentClientSecretAndSetupIntentIdResponse",
  clientSecret: string,
  setupIntentId: string,
};

export type LambdaJSONResponse = {
  __typename: "LambdaJSONResponse",
  code?: number | null,
  message?: string | null,
  data?: string | null,
};

export type ParsedResumeDataResponse = {
  __typename: "ParsedResumeDataResponse",
  code?: number | null,
  message?: string | null,
  data?: ResumeData | null,
};

export type ResumeData = {
  __typename: "ResumeData",
  basic_info?: ResumeBasicInfo | null,
  education_history?:  Array<EducationEntry | null > | null,
  employment_history?:  Array<EmploymentEntry | null > | null,
};

export type ResumeBasicInfo = {
  __typename: "ResumeBasicInfo",
  github_main_page_url?: string | null,
  linkedin_url?: string | null,
  portfolio_website_url?: string | null,
};

export type createAndValidateInvoiceBatchResponse = {
  __typename: "createAndValidateInvoiceBatchResponse",
  code: number,
  message?: string | null,
  data?: InvoiceBatch | null,
};

export type InvoiceBatch = {
  __typename: "InvoiceBatch",
  id: string,
  csvFileUrl: string,
  createdBy: string,
  approvedBy?: string | null,
  status: InvoiceBatchStatus,
  invoiceBatchNumber: number,
  createdAt?: string | null,
  sortHashKey: SortHashKey,
  errorMsg?: string | null,
  invoicesToProcess?: number | null,
  invoicesProcessed?: number | null,
  updatedAt: string,
  version: number,
};

export enum InvoiceBatchStatus {
  INVALID = "INVALID",
  PENDING = "PENDING",
  READY = "READY",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
}


export enum SortHashKey {
  BATCH_INVOICE = "BATCH_INVOICE",
}


export type CreateAnalyticsInput = {
  id?: string | null,
  developersCompletedProfiles?: number | null,
  developersJoined: number,
  recruitersJoined: number,
  hiredToday?: number | null,
  takenDomainTests: number,
  passedDomainTests: number,
  takenProblemSolvingTests: number,
  passedProblemSolving: number,
  developersReferredByRecruiters: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelAnalyticsConditionInput = {
  developersCompletedProfiles?: ModelIntInput | null,
  developersJoined?: ModelIntInput | null,
  recruitersJoined?: ModelIntInput | null,
  hiredToday?: ModelIntInput | null,
  takenDomainTests?: ModelIntInput | null,
  passedDomainTests?: ModelIntInput | null,
  takenProblemSolvingTests?: ModelIntInput | null,
  passedProblemSolving?: ModelIntInput | null,
  developersReferredByRecruiters?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAnalyticsConditionInput | null > | null,
  or?: Array< ModelAnalyticsConditionInput | null > | null,
  not?: ModelAnalyticsConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Analytics = {
  __typename: "Analytics",
  id: string,
  developersCompletedProfiles?: number | null,
  developersJoined: number,
  recruitersJoined: number,
  hiredToday?: number | null,
  takenDomainTests: number,
  passedDomainTests: number,
  takenProblemSolvingTests: number,
  passedProblemSolving: number,
  developersReferredByRecruiters: number,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type UpdateAnalyticsInput = {
  id: string,
  developersCompletedProfiles?: number | null,
  developersJoined?: number | null,
  recruitersJoined?: number | null,
  hiredToday?: number | null,
  takenDomainTests?: number | null,
  passedDomainTests?: number | null,
  takenProblemSolvingTests?: number | null,
  passedProblemSolving?: number | null,
  developersReferredByRecruiters?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expectedVersion: number,
};

export type DeleteAnalyticsInput = {
  id: string,
  expectedVersion: number,
};

export type CreateInvoiceInput = {
  id?: string | null,
  clientId?: string | null,
  invoiceBatchId: string,
  invoiceNumber: string,
  amount: number,
  status: InvoiceStatus,
  reason?: string | null,
  stripeInvoiceId?: string | null,
  stripeInvoicePdfUrl?: string | null,
  description?: string | null,
};

export enum InvoiceStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  CLIENT_NOT_FOUND = "CLIENT_NOT_FOUND",
  PENDING = "PENDING",
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  VOID = "VOID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  DELETED = "DELETED",
}


export type ModelInvoiceConditionInput = {
  clientId?: ModelIDInput | null,
  invoiceBatchId?: ModelIDInput | null,
  invoiceNumber?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelInvoiceStatusInput | null,
  reason?: ModelStringInput | null,
  stripeInvoiceId?: ModelStringInput | null,
  stripeInvoicePdfUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelInvoiceStatusInput = {
  eq?: InvoiceStatus | null,
  ne?: InvoiceStatus | null,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  clientId?: string | null,
  invoiceBatchId: string,
  invoiceNumber: string,
  amount: number,
  status: InvoiceStatus,
  reason?: string | null,
  stripeInvoiceId?: string | null,
  stripeInvoicePdfUrl?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
  invoiceBatch?: InvoiceBatch | null,
  client?: Client | null,
};

export type UpdateInvoiceInput = {
  id: string,
  clientId?: string | null,
  invoiceBatchId?: string | null,
  invoiceNumber?: string | null,
  amount?: number | null,
  status?: InvoiceStatus | null,
  reason?: string | null,
  stripeInvoiceId?: string | null,
  stripeInvoicePdfUrl?: string | null,
  description?: string | null,
};

export type DeleteInvoiceInput = {
  id: string,
};

export type CreateInvoiceBatchInput = {
  id?: string | null,
  csvFileUrl: string,
  createdBy: string,
  approvedBy?: string | null,
  status: InvoiceBatchStatus,
  invoiceBatchNumber: number,
  createdAt?: string | null,
  sortHashKey: SortHashKey,
  errorMsg?: string | null,
  invoicesToProcess?: number | null,
  invoicesProcessed?: number | null,
};

export type ModelInvoiceBatchConditionInput = {
  csvFileUrl?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  approvedBy?: ModelStringInput | null,
  status?: ModelInvoiceBatchStatusInput | null,
  invoiceBatchNumber?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  sortHashKey?: ModelSortHashKeyInput | null,
  errorMsg?: ModelStringInput | null,
  invoicesToProcess?: ModelIntInput | null,
  invoicesProcessed?: ModelIntInput | null,
  and?: Array< ModelInvoiceBatchConditionInput | null > | null,
  or?: Array< ModelInvoiceBatchConditionInput | null > | null,
  not?: ModelInvoiceBatchConditionInput | null,
};

export type ModelInvoiceBatchStatusInput = {
  eq?: InvoiceBatchStatus | null,
  ne?: InvoiceBatchStatus | null,
};

export type ModelSortHashKeyInput = {
  eq?: SortHashKey | null,
  ne?: SortHashKey | null,
};

export type UpdateInvoiceBatchInput = {
  id: string,
  csvFileUrl?: string | null,
  createdBy?: string | null,
  approvedBy?: string | null,
  status?: InvoiceBatchStatus | null,
  invoiceBatchNumber?: number | null,
  createdAt?: string | null,
  sortHashKey?: SortHashKey | null,
  errorMsg?: string | null,
  invoicesToProcess?: number | null,
  invoicesProcessed?: number | null,
  expectedVersion: number,
};

export type DeleteInvoiceBatchInput = {
  id: string,
  expectedVersion: number,
};

export type CreatePaymentInput = {
  id?: string | null,
  invoiceId: string,
  amount: number,
  transactionId: string,
  paymentStatus: PaymentStatus,
  paymentMethod: CustomerPaymentMethod,
  reason?: string | null,
};

export enum PaymentStatus {
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
  REQUIRES_ACTION = "REQUIRES_ACTION",
}


export enum CustomerPaymentMethod {
  ACH_DIRECT_DEBIT = "ACH_DIRECT_DEBIT",
}


export type ModelPaymentConditionInput = {
  invoiceId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  transactionId?: ModelStringInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  paymentMethod?: ModelCustomerPaymentMethodInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type ModelCustomerPaymentMethodInput = {
  eq?: CustomerPaymentMethod | null,
  ne?: CustomerPaymentMethod | null,
};

export type Payment = {
  __typename: "Payment",
  id: string,
  invoiceId: string,
  amount: number,
  transactionId: string,
  paymentStatus: PaymentStatus,
  paymentMethod: CustomerPaymentMethod,
  reason?: string | null,
  createdAt: string,
  updatedAt: string,
  invoice?: Invoice | null,
};

export type UpdatePaymentInput = {
  id: string,
  invoiceId?: string | null,
  amount?: number | null,
  transactionId?: string | null,
  paymentStatus?: PaymentStatus | null,
  paymentMethod?: CustomerPaymentMethod | null,
  reason?: string | null,
};

export type DeletePaymentInput = {
  id: string,
};

export type CreateStripeSetupIntentInput = {
  id?: string | null,
  status: StripeSetupIntentStatus,
  paymentMethodId?: string | null,
  setupIntentId: string,
  mandateId?: string | null,
  mandateStatus: MandateStatus,
  createdAt?: string | null,
  clientId: string,
  clientSecret: string,
};

export enum StripeSetupIntentStatus {
  requires_payment_method = "requires_payment_method",
  requires_confirmation = "requires_confirmation",
  requires_action = "requires_action",
  processing = "processing",
  succeeded = "succeeded",
  canceled = "canceled",
  failed = "failed",
  mandate_withdrawn = "mandate_withdrawn",
}


export enum MandateStatus {
  active = "active",
  inactive = "inactive",
  pending = "pending",
}


export type ModelStripeSetupIntentConditionInput = {
  status?: ModelStripeSetupIntentStatusInput | null,
  paymentMethodId?: ModelStringInput | null,
  setupIntentId?: ModelStringInput | null,
  mandateId?: ModelStringInput | null,
  mandateStatus?: ModelMandateStatusInput | null,
  createdAt?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  clientSecret?: ModelStringInput | null,
  and?: Array< ModelStripeSetupIntentConditionInput | null > | null,
  or?: Array< ModelStripeSetupIntentConditionInput | null > | null,
  not?: ModelStripeSetupIntentConditionInput | null,
};

export type ModelStripeSetupIntentStatusInput = {
  eq?: StripeSetupIntentStatus | null,
  ne?: StripeSetupIntentStatus | null,
};

export type ModelMandateStatusInput = {
  eq?: MandateStatus | null,
  ne?: MandateStatus | null,
};

export type StripeSetupIntent = {
  __typename: "StripeSetupIntent",
  id: string,
  status: StripeSetupIntentStatus,
  paymentMethodId?: string | null,
  setupIntentId: string,
  mandateId?: string | null,
  mandateStatus: MandateStatus,
  createdAt?: string | null,
  clientId: string,
  clientSecret: string,
  updatedAt: string,
};

export type UpdateStripeSetupIntentInput = {
  id: string,
  status?: StripeSetupIntentStatus | null,
  paymentMethodId?: string | null,
  setupIntentId?: string | null,
  mandateId?: string | null,
  mandateStatus?: MandateStatus | null,
  createdAt?: string | null,
  clientId?: string | null,
  clientSecret?: string | null,
};

export type DeleteStripeSetupIntentInput = {
  id: string,
};

export type CreateCalendarBookingInput = {
  id?: string | null,
  clientId: string,
  eventId: string,
  startTime?: string | null,
  endTime?: string | null,
  meetingLink?: string | null,
  talentId: string,
  jobMatchId: string,
  inviteToken?: string | null,
  feedback?: string | null,
};

export type ModelCalendarBookingConditionInput = {
  clientId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  meetingLink?: ModelStringInput | null,
  talentId?: ModelIDInput | null,
  jobMatchId?: ModelIDInput | null,
  inviteToken?: ModelStringInput | null,
  feedback?: ModelStringInput | null,
  and?: Array< ModelCalendarBookingConditionInput | null > | null,
  or?: Array< ModelCalendarBookingConditionInput | null > | null,
  not?: ModelCalendarBookingConditionInput | null,
};

export type CalendarBooking = {
  __typename: "CalendarBooking",
  id: string,
  clientId: string,
  eventId: string,
  startTime?: string | null,
  endTime?: string | null,
  meetingLink?: string | null,
  talentId: string,
  jobMatchId: string,
  inviteToken?: string | null,
  feedback?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  engineerId?: string | null,
  client?: Client | null,
};

export type UpdateCalendarBookingInput = {
  id: string,
  clientId?: string | null,
  eventId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  meetingLink?: string | null,
  talentId?: string | null,
  jobMatchId?: string | null,
  inviteToken?: string | null,
  feedback?: string | null,
  expectedVersion: number,
};

export type DeleteCalendarBookingInput = {
  id: string,
  expectedVersion: number,
};

export type CreateClientInput = {
  id?: string | null,
  fullName: string,
  email: string,
  phone: string,
  country?: Country | null,
  role: UserRoles,
  companyID?: string | null,
  timezone?: string | null,
  availability?: Array< ClientAvailabilityInput > | null,
  googleCalendarId?: string | null,
  remainingCredits?: number | null,
  totalCredits?: number | null,
  stripeCustomerId?: string | null,
  currentStripeSubscriptionId?: string | null,
  hasMandate?: boolean | null,
};

export type ClientAvailabilityInput = {
  day: number,
  date: string,
  availableSlots: Array< AvailableSlotInput >,
};

export type AvailableSlotInput = {
  startTime: string,
  endTime: string,
};

export type ModelClientConditionInput = {
  fullName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  role?: ModelUserRolesInput | null,
  companyID?: ModelIDInput | null,
  timezone?: ModelStringInput | null,
  googleCalendarId?: ModelStringInput | null,
  remainingCredits?: ModelIntInput | null,
  totalCredits?: ModelIntInput | null,
  stripeCustomerId?: ModelStringInput | null,
  currentStripeSubscriptionId?: ModelStringInput | null,
  hasMandate?: ModelBooleanInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
};

export type ModelCountryInput = {
  eq?: Country | null,
  ne?: Country | null,
};

export type ModelUserRolesInput = {
  eq?: UserRoles | null,
  ne?: UserRoles | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateClientInput = {
  id: string,
  fullName?: string | null,
  email?: string | null,
  phone?: string | null,
  country?: Country | null,
  role?: UserRoles | null,
  companyID?: string | null,
  timezone?: string | null,
  availability?: Array< ClientAvailabilityInput > | null,
  googleCalendarId?: string | null,
  remainingCredits?: number | null,
  totalCredits?: number | null,
  stripeCustomerId?: string | null,
  currentStripeSubscriptionId?: string | null,
  hasMandate?: boolean | null,
  expectedVersion: number,
};

export type DeleteClientInput = {
  id: string,
  expectedVersion: number,
};

export type CreateClientCalendarInviteInput = {
  id?: string | null,
  clientID: string,
  title: string,
  clientEmail: string,
  eventLength: number,
};

export type ModelClientCalendarInviteConditionInput = {
  clientID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  eventLength?: ModelIntInput | null,
  and?: Array< ModelClientCalendarInviteConditionInput | null > | null,
  or?: Array< ModelClientCalendarInviteConditionInput | null > | null,
  not?: ModelClientCalendarInviteConditionInput | null,
};

export type ClientCalendarInvite = {
  __typename: "ClientCalendarInvite",
  id: string,
  clientID: string,
  title: string,
  clientEmail: string,
  eventLength: number,
  createdAt: string,
  updatedAt: string,
  version: number,
  client?: Client | null,
};

export type UpdateClientCalendarInviteInput = {
  id: string,
  clientID?: string | null,
  title?: string | null,
  clientEmail?: string | null,
  eventLength?: number | null,
  expectedVersion: number,
};

export type DeleteClientCalendarInviteInput = {
  id: string,
  expectedVersion: number,
};

export type CreateCompanyInput = {
  id?: string | null,
  name: string,
  logo: string,
  stage: companyStage,
  tagline?: string | null,
  description?: string | null,
  address: AddressInput,
  industry: industry,
  associatedWith?: companyAssociation | null,
};

export type ModelCompanyConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  stage?: ModelcompanyStageInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  industry?: ModelindustryInput | null,
  associatedWith?: ModelcompanyAssociationInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
};

export type ModelcompanyStageInput = {
  eq?: companyStage | null,
  ne?: companyStage | null,
};

export type ModelindustryInput = {
  eq?: industry | null,
  ne?: industry | null,
};

export type ModelcompanyAssociationInput = {
  eq?: companyAssociation | null,
  ne?: companyAssociation | null,
};

export type UpdateCompanyInput = {
  id: string,
  name?: string | null,
  logo?: string | null,
  stage?: companyStage | null,
  tagline?: string | null,
  description?: string | null,
  address?: AddressInput | null,
  industry?: industry | null,
  associatedWith?: companyAssociation | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CreateJobInput = {
  id?: string | null,
  companyID: string,
  status: JobStatus,
  title: string,
  description: string,
  responsibilites: string,
  tags: Array< string | null >,
  requiredTechDomainSkills: Array< TechDomainSkills | null >,
  seniorityLevel: SeniorityLevel,
  isPublic?: boolean | null,
  isPermanent?: boolean | null,
  internalNotes?: string | null,
  department?: JobDepartment | null,
  jobPerks?: Array< JobPerks | null > | null,
};

export type ModelJobConditionInput = {
  companyID?: ModelIDInput | null,
  status?: ModelJobStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  responsibilites?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  requiredTechDomainSkills?: ModelTechDomainSkillsListInput | null,
  seniorityLevel?: ModelSeniorityLevelInput | null,
  isPublic?: ModelBooleanInput | null,
  isPermanent?: ModelBooleanInput | null,
  internalNotes?: ModelStringInput | null,
  department?: ModelJobDepartmentInput | null,
  jobPerks?: ModelJobPerksListInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
};

export type ModelJobStatusInput = {
  eq?: JobStatus | null,
  ne?: JobStatus | null,
};

export type ModelTechDomainSkillsListInput = {
  eq?: Array< TechDomainSkills | null > | null,
  ne?: Array< TechDomainSkills | null > | null,
  contains?: TechDomainSkills | null,
  notContains?: TechDomainSkills | null,
};

export type ModelSeniorityLevelInput = {
  eq?: SeniorityLevel | null,
  ne?: SeniorityLevel | null,
};

export type ModelJobDepartmentInput = {
  eq?: JobDepartment | null,
  ne?: JobDepartment | null,
};

export type ModelJobPerksListInput = {
  eq?: Array< JobPerks | null > | null,
  ne?: Array< JobPerks | null > | null,
  contains?: JobPerks | null,
  notContains?: JobPerks | null,
};

export type UpdateJobInput = {
  id: string,
  companyID?: string | null,
  status?: JobStatus | null,
  title?: string | null,
  description?: string | null,
  responsibilites?: string | null,
  tags?: Array< string | null > | null,
  requiredTechDomainSkills?: Array< TechDomainSkills | null > | null,
  seniorityLevel?: SeniorityLevel | null,
  isPublic?: boolean | null,
  isPermanent?: boolean | null,
  internalNotes?: string | null,
  department?: JobDepartment | null,
  jobPerks?: Array< JobPerks | null > | null,
};

export type DeleteJobInput = {
  id: string,
};

export type CreateJobMatchInput = {
  id?: string | null,
  talentID: string,
  jobID: string,
  lastActivity: string,
  stage: matchStage,
  process: matchProcess,
  isRockstar?: boolean | null,
  isPublished: boolean,
  clientFeedback?: ClientFeedbackInput | null,
  isPaidFor?: boolean | null,
  feedback?: JobMatchFeedbackInput | null,
  talentProfileVersionID?: string | null,
};

export type ModelJobMatchConditionInput = {
  jobID?: ModelIDInput | null,
  lastActivity?: ModelStringInput | null,
  stage?: ModelmatchStageInput | null,
  process?: ModelmatchProcessInput | null,
  isRockstar?: ModelBooleanInput | null,
  isPublished?: ModelBooleanInput | null,
  isPaidFor?: ModelBooleanInput | null,
  talentProfileVersionID?: ModelIDInput | null,
  and?: Array< ModelJobMatchConditionInput | null > | null,
  or?: Array< ModelJobMatchConditionInput | null > | null,
  not?: ModelJobMatchConditionInput | null,
};

export type ModelmatchStageInput = {
  eq?: matchStage | null,
  ne?: matchStage | null,
};

export type ModelmatchProcessInput = {
  eq?: matchProcess | null,
  ne?: matchProcess | null,
};

export type UpdateJobMatchInput = {
  id: string,
  talentID?: string | null,
  jobID?: string | null,
  lastActivity?: string | null,
  stage?: matchStage | null,
  process?: matchProcess | null,
  isRockstar?: boolean | null,
  isPublished?: boolean | null,
  clientFeedback?: ClientFeedbackInput | null,
  isPaidFor?: boolean | null,
  feedback?: JobMatchFeedbackInput | null,
  talentProfileVersionID?: string | null,
  expectedVersion: number,
};

export type DeleteJobMatchInput = {
  id: string,
  expectedVersion: number,
};

export type CreatePersonSubscriptionsInput = {
  id?: string | null,
  clientId?: string | null,
  recruiterId?: string | null,
  talentId?: string | null,
  transactionId: string,
  receipt: string,
  startDate: string,
  endDate: string,
  validationResponse?: string | null,
  productId?: string | null,
  isCancelled: boolean,
  platform: SubscriptionAppType,
  status?: SubscriptionStatus | null,
};

export enum SubscriptionStatus {
  PENDING = "PENDING",
  SUBSCRIBED = "SUBSCRIBED",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
}


export type ModelPersonSubscriptionsConditionInput = {
  clientId?: ModelIDInput | null,
  recruiterId?: ModelIDInput | null,
  talentId?: ModelIDInput | null,
  transactionId?: ModelStringInput | null,
  receipt?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  validationResponse?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  isCancelled?: ModelBooleanInput | null,
  platform?: ModelSubscriptionAppTypeInput | null,
  status?: ModelSubscriptionStatusInput | null,
  and?: Array< ModelPersonSubscriptionsConditionInput | null > | null,
  or?: Array< ModelPersonSubscriptionsConditionInput | null > | null,
  not?: ModelPersonSubscriptionsConditionInput | null,
};

export type ModelSubscriptionAppTypeInput = {
  eq?: SubscriptionAppType | null,
  ne?: SubscriptionAppType | null,
};

export type ModelSubscriptionStatusInput = {
  eq?: SubscriptionStatus | null,
  ne?: SubscriptionStatus | null,
};

export type PersonSubscriptions = {
  __typename: "PersonSubscriptions",
  id: string,
  clientId?: string | null,
  recruiterId?: string | null,
  talentId?: string | null,
  transactionId: string,
  receipt: string,
  startDate: string,
  endDate: string,
  validationResponse?: string | null,
  productId?: string | null,
  isCancelled: boolean,
  platform: SubscriptionAppType,
  status?: SubscriptionStatus | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  client?: Client | null,
  recruiter?: Recruiter | null,
  talent?: Talent | null,
};

export type UpdatePersonSubscriptionsInput = {
  id: string,
  clientId?: string | null,
  recruiterId?: string | null,
  talentId?: string | null,
  transactionId?: string | null,
  receipt?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  validationResponse?: string | null,
  productId?: string | null,
  isCancelled?: boolean | null,
  platform?: SubscriptionAppType | null,
  status?: SubscriptionStatus | null,
  expectedVersion: number,
};

export type DeletePersonSubscriptionsInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTalentProfileVersionInput = {
  id?: string | null,
  name: string,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable: boolean,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentID: string,
};

export type ModelTalentProfileVersionConditionInput = {
  name?: ModelStringInput | null,
  skills?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  social?: ModelSocialLinkTypesListInput | null,
  englishProficiency?: ModelenglishProficiencyInput | null,
  expectedSalary?: ModelStringInput | null,
  expectedSalaryInUsd?: ModelFloatInput | null,
  interests?: ModelStringInput | null,
  imgLink?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  title?: ModelStringInput | null,
  talentID?: ModelIDInput | null,
  and?: Array< ModelTalentProfileVersionConditionInput | null > | null,
  or?: Array< ModelTalentProfileVersionConditionInput | null > | null,
  not?: ModelTalentProfileVersionConditionInput | null,
};

export type ModelSocialLinkTypesListInput = {
  eq?: Array< SocialLinkTypes | null > | null,
  ne?: Array< SocialLinkTypes | null > | null,
  contains?: SocialLinkTypes | null,
  notContains?: SocialLinkTypes | null,
};

export type ModelenglishProficiencyInput = {
  eq?: englishProficiency | null,
  ne?: englishProficiency | null,
};

export type UpdateTalentProfileVersionInput = {
  id: string,
  name?: string | null,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable?: boolean | null,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentID?: string | null,
  expectedVersion: number,
};

export type DeleteTalentProfileVersionInput = {
  id: string,
  expectedVersion: number,
};

export type CreateMessageInput = {
  id?: string | null,
  content: string,
  senderInfo: PersonInfoInput,
  conversationId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  isRead: boolean,
  readBy?: Array< PersonInfoInput | null > | null,
};

export type PersonInfoInput = {
  id: string,
  fullName: string,
  email?: string | null,
  phone?: string | null,
  role: UserRoles,
};

export type ModelMessageConditionInput = {
  content?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isRead?: ModelBooleanInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  content: string,
  senderInfo: PersonInfo,
  conversationId: string,
  createdAt: string,
  updatedAt: string,
  isRead: boolean,
  readBy?:  Array<PersonInfo | null > | null,
  version: number,
  conversation?: Conversation | null,
};

export type Conversation = {
  __typename: "Conversation",
  id: string,
  name?: string | null,
  createdAt: string,
  updatedAt: string,
  isActive: boolean,
  lastMessageAt?: string | null,
  version: number,
  messages?: ModelMessageConnection | null,
  conversationParticipants?: ModelConversationParticipantsConnection | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelConversationParticipantsConnection = {
  __typename: "ModelConversationParticipantsConnection",
  items:  Array<ConversationParticipants | null >,
  nextToken?: string | null,
};

export type ConversationParticipants = {
  __typename: "ConversationParticipants",
  id: string,
  conversationId: string,
  talentId: string,
  clientId: string,
  recruiterId: string,
  createdAt: string,
  updatedAt: string,
  version: number,
  client?: Client | null,
  conversation?: Conversation | null,
  recruiter?: Recruiter | null,
  talent?: Talent | null,
};

export type UpdateMessageInput = {
  id: string,
  content?: string | null,
  senderInfo?: PersonInfoInput | null,
  conversationId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isRead?: boolean | null,
  readBy?: Array< PersonInfoInput | null > | null,
  expectedVersion: number,
};

export type DeleteMessageInput = {
  id: string,
  expectedVersion: number,
};

export type CreateConversationInput = {
  id?: string | null,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isActive: boolean,
  lastMessageAt?: string | null,
};

export type ModelConversationConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  lastMessageAt?: ModelStringInput | null,
  and?: Array< ModelConversationConditionInput | null > | null,
  or?: Array< ModelConversationConditionInput | null > | null,
  not?: ModelConversationConditionInput | null,
};

export type UpdateConversationInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  isActive?: boolean | null,
  lastMessageAt?: string | null,
  expectedVersion: number,
};

export type DeleteConversationInput = {
  id: string,
  expectedVersion: number,
};

export type CreateConversationParticipantsInput = {
  id?: string | null,
  conversationId: string,
  talentId: string,
  clientId: string,
  recruiterId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelConversationParticipantsConditionInput = {
  conversationId?: ModelIDInput | null,
  talentId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  recruiterId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConversationParticipantsConditionInput | null > | null,
  or?: Array< ModelConversationParticipantsConditionInput | null > | null,
  not?: ModelConversationParticipantsConditionInput | null,
};

export type UpdateConversationParticipantsInput = {
  id: string,
  conversationId?: string | null,
  talentId?: string | null,
  clientId?: string | null,
  recruiterId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expectedVersion: number,
};

export type DeleteConversationParticipantsInput = {
  id: string,
  expectedVersion: number,
};

export type CreateRecruiterInput = {
  id?: string | null,
  fullName: string,
  email: string,
  phone: string,
  country?: Country | null,
  role: UserRoles,
  recruiterProfileId?: string | null,
};

export type ModelRecruiterConditionInput = {
  fullName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  role?: ModelUserRolesInput | null,
  and?: Array< ModelRecruiterConditionInput | null > | null,
  or?: Array< ModelRecruiterConditionInput | null > | null,
  not?: ModelRecruiterConditionInput | null,
};

export type UpdateRecruiterInput = {
  id: string,
  fullName?: string | null,
  email?: string | null,
  phone?: string | null,
  country?: Country | null,
  role?: UserRoles | null,
  expectedVersion: number,
  recruiterProfileId?: string | null,
};

export type DeleteRecruiterInput = {
  id: string,
  expectedVersion: number,
};

export type CreateRecruiterProfileInput = {
  id?: string | null,
  currentEarnings: number,
  profession?: string | null,
};

export type ModelRecruiterProfileConditionInput = {
  currentEarnings?: ModelIntInput | null,
  profession?: ModelStringInput | null,
  and?: Array< ModelRecruiterProfileConditionInput | null > | null,
  or?: Array< ModelRecruiterProfileConditionInput | null > | null,
  not?: ModelRecruiterProfileConditionInput | null,
};

export type UpdateRecruiterProfileInput = {
  id: string,
  currentEarnings?: number | null,
  profession?: string | null,
  expectedVersion: number,
};

export type DeleteRecruiterProfileInput = {
  id: string,
  expectedVersion: number,
};

export type CreateReferralInput = {
  id?: string | null,
  referrerId?: string | null,
  referralReferredId?: string | null,
  recruiterId?: string | null,
  email?: string | null,
};

export type ModelReferralConditionInput = {
  referrerId?: ModelIDInput | null,
  referralReferredId?: ModelIDInput | null,
  recruiterId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelReferralConditionInput | null > | null,
  or?: Array< ModelReferralConditionInput | null > | null,
  not?: ModelReferralConditionInput | null,
};

export type Referral = {
  __typename: "Referral",
  id: string,
  referrerId?: string | null,
  referralReferredId?: string | null,
  recruiterId?: string | null,
  email?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  recruiter?: Recruiter | null,
  referrer?: Talent | null,
  referred?: Talent | null,
};

export type UpdateReferralInput = {
  id: string,
  referrerId?: string | null,
  referralReferredId?: string | null,
  recruiterId?: string | null,
  email?: string | null,
  expectedVersion: number,
};

export type DeleteReferralInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTalentInput = {
  id?: string | null,
  fullName: string,
  email: string,
  phone: string,
  country?: Country | null,
  role: UserRoles,
  talentProfileId?: string | null,
};

export type ModelTalentConditionInput = {
  fullName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  role?: ModelUserRolesInput | null,
  and?: Array< ModelTalentConditionInput | null > | null,
  or?: Array< ModelTalentConditionInput | null > | null,
  not?: ModelTalentConditionInput | null,
};

export type UpdateTalentInput = {
  id: string,
  fullName?: string | null,
  email?: string | null,
  phone?: string | null,
  country?: Country | null,
  role?: UserRoles | null,
  expectedVersion: number,
  talentProfileId?: string | null,
};

export type DeleteTalentInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTalentProfileInput = {
  id?: string | null,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable: boolean,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgressInput | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?: Array< VideoResponseInput | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?: Array< FeedbackInput | null > | null,
  resumeLink?: string | null,
  takenDomainTests?: Array< TechDomainSkillsProgressInput | null > | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  resetTests?: ResetTestsInput | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: AnalyticsDataInput | null,
  isProfileCompleted?: boolean | null,
  workableId?: string | null,
  aiInterviewInProgress?: boolean | null,
  videoInterviewProvider?: VideoInterviewProvider | null,
  talentProfileTalentId?: string | null,
};

export type VideoResponseInput = {
  question: string,
  thumbnail: string,
  link: string,
};

export type ResetTestsInput = {
  domainSkillTests?: Array< TechDomainSkillsProgressInput | null > | null,
  problemSolvingTests?: Array< TechProblemSolvingProgressInput | null > | null,
  hrInterviews?: Array< HrInterViewResetInput | null > | null,
  finalInterviews?: Array< FinalInterviewResetInput | null > | null,
};

export type HrInterViewResetInput = {
  hrVideoId?: string | null,
  isPass: boolean,
  dateAttempted?: string | null,
  dateOfHRStatus?: string | null,
  feedback?: FeedbackInput | null,
};

export type FinalInterviewResetInput = {
  isPass: boolean,
  dateAttempted?: string | null,
  feedback?: FeedbackInput | null,
};

export type ModelTalentProfileConditionInput = {
  skills?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  social?: ModelSocialLinkTypesListInput | null,
  englishProficiency?: ModelenglishProficiencyInput | null,
  problemSolvingScore?: ModelFloatInput | null,
  problemSolvingPercentage?: ModelFloatInput | null,
  isPassPS?: ModelBooleanInput | null,
  isPassHR?: ModelBooleanInput | null,
  isPassFinalTech?: ModelBooleanInput | null,
  isHired?: ModelBooleanInput | null,
  isNetwork?: ModelBooleanInput | null,
  isPlaced?: ModelBooleanInput | null,
  hrInterviewVideoId?: ModelStringInput | null,
  statusMeta?: ModelStringInput | null,
  heardFrom?: ModelStringInput | null,
  currentSalary?: ModelStringInput | null,
  expectedSalary?: ModelStringInput | null,
  expectedSalaryInUsd?: ModelFloatInput | null,
  interests?: ModelStringInput | null,
  resumeLink?: ModelStringInput | null,
  imgLink?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  title?: ModelStringInput | null,
  talentTypeID?: ModelIDInput | null,
  isProfileCompleted?: ModelBooleanInput | null,
  workableId?: ModelStringInput | null,
  aiInterviewInProgress?: ModelBooleanInput | null,
  videoInterviewProvider?: ModelVideoInterviewProviderInput | null,
  and?: Array< ModelTalentProfileConditionInput | null > | null,
  or?: Array< ModelTalentProfileConditionInput | null > | null,
  not?: ModelTalentProfileConditionInput | null,
};

export type ModelVideoInterviewProviderInput = {
  eq?: VideoInterviewProvider | null,
  ne?: VideoInterviewProvider | null,
};

export type UpdateTalentProfileInput = {
  id: string,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable?: boolean | null,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgressInput | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?: Array< VideoResponseInput | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?: Array< FeedbackInput | null > | null,
  resumeLink?: string | null,
  takenDomainTests?: Array< TechDomainSkillsProgressInput | null > | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  resetTests?: ResetTestsInput | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: AnalyticsDataInput | null,
  isProfileCompleted?: boolean | null,
  workableId?: string | null,
  aiInterviewInProgress?: boolean | null,
  videoInterviewProvider?: VideoInterviewProvider | null,
  expectedVersion: number,
  talentProfileTalentId?: string | null,
};

export type DeleteTalentProfileInput = {
  id: string,
  expectedVersion: number,
};

export type CreateForexRatesInput = {
  id?: string | null,
  currencyType: string,
  usdConversionRate: number,
};

export type ModelForexRatesConditionInput = {
  currencyType?: ModelStringInput | null,
  usdConversionRate?: ModelFloatInput | null,
  and?: Array< ModelForexRatesConditionInput | null > | null,
  or?: Array< ModelForexRatesConditionInput | null > | null,
  not?: ModelForexRatesConditionInput | null,
};

export type ForexRates = {
  __typename: "ForexRates",
  id: string,
  currencyType: string,
  usdConversionRate: number,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type UpdateForexRatesInput = {
  id: string,
  currencyType?: string | null,
  usdConversionRate?: number | null,
  expectedVersion: number,
};

export type DeleteForexRatesInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTalentProfileTransientInput = {
  id?: string | null,
  skills?: Array< string | null > | null,
  experience?: string | null,
  isAvailable?: boolean | null,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgressInput | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?: Array< VideoResponseInput | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?: Array< FeedbackInput | null > | null,
  resumeLink?: string | null,
  takenDomainTests?: Array< TechDomainSkillsProgressInput | null > | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  resetTests?: ResetTestsInput | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: AnalyticsDataInput | null,
  isProfileCompleted?: boolean | null,
  talentProfileTalentId?: string | null,
  source?: string | null,
};

export type ModelTalentProfileTransientConditionInput = {
  skills?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  social?: ModelSocialLinkTypesListInput | null,
  englishProficiency?: ModelenglishProficiencyInput | null,
  problemSolvingScore?: ModelFloatInput | null,
  problemSolvingPercentage?: ModelFloatInput | null,
  isPassPS?: ModelBooleanInput | null,
  isPassHR?: ModelBooleanInput | null,
  isPassFinalTech?: ModelBooleanInput | null,
  isHired?: ModelBooleanInput | null,
  isNetwork?: ModelBooleanInput | null,
  isPlaced?: ModelBooleanInput | null,
  hrInterviewVideoId?: ModelStringInput | null,
  statusMeta?: ModelStringInput | null,
  heardFrom?: ModelStringInput | null,
  currentSalary?: ModelStringInput | null,
  expectedSalary?: ModelStringInput | null,
  expectedSalaryInUsd?: ModelFloatInput | null,
  interests?: ModelStringInput | null,
  resumeLink?: ModelStringInput | null,
  imgLink?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  title?: ModelStringInput | null,
  talentTypeID?: ModelIDInput | null,
  isProfileCompleted?: ModelBooleanInput | null,
  source?: ModelStringInput | null,
  and?: Array< ModelTalentProfileTransientConditionInput | null > | null,
  or?: Array< ModelTalentProfileTransientConditionInput | null > | null,
  not?: ModelTalentProfileTransientConditionInput | null,
};

export type TalentProfileTransient = {
  __typename: "TalentProfileTransient",
  id?: string | null,
  skills?: Array< string | null > | null,
  experience?: string | null,
  isAvailable?: boolean | null,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgress | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?:  Array<VideoResponse | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?:  Array<Feedback | null > | null,
  resumeLink?: string | null,
  takenDomainTests?:  Array<TechDomainSkillsProgress | null > | null,
  educationHistory?:  Array<EducationEntry | null > | null,
  employmentHistory?:  Array<EmploymentEntry | null > | null,
  socialProfiles?:  Array<SocialProfile | null > | null,
  resetTests?: resetTests | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: analyticsData | null,
  isProfileCompleted?: boolean | null,
  talentProfileTalentId?: string | null,
  source?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type UpdateTalentProfileTransientInput = {
  id: string,
  skills?: Array< string | null > | null,
  experience?: string | null,
  isAvailable?: boolean | null,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  problemSolvingTest?: TechProblemSolvingProgressInput | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?: Array< VideoResponseInput | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?: Array< FeedbackInput | null > | null,
  resumeLink?: string | null,
  takenDomainTests?: Array< TechDomainSkillsProgressInput | null > | null,
  educationHistory?: Array< EducationEntryInput | null > | null,
  employmentHistory?: Array< EmploymentEntryInput | null > | null,
  socialProfiles?: Array< SocialProfileInput | null > | null,
  resetTests?: ResetTestsInput | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: AnalyticsDataInput | null,
  isProfileCompleted?: boolean | null,
  talentProfileTalentId?: string | null,
  source?: string | null,
  expectedVersion: number,
};

export type DeleteTalentProfileTransientInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTalentProfileProcessedIDsInput = {
  id?: string | null,
  talentProfileId: string,
};

export type ModelTalentProfileProcessedIDsConditionInput = {
  talentProfileId?: ModelStringInput | null,
  and?: Array< ModelTalentProfileProcessedIDsConditionInput | null > | null,
  or?: Array< ModelTalentProfileProcessedIDsConditionInput | null > | null,
  not?: ModelTalentProfileProcessedIDsConditionInput | null,
};

export type TalentProfileProcessedIDs = {
  __typename: "TalentProfileProcessedIDs",
  id?: string | null,
  talentProfileId: string,
  createdAt: string,
  updatedAt: string,
  version: number,
  talentProfileTalentId?: string | null,
};

export type UpdateTalentProfileProcessedIDsInput = {
  id: string,
  talentProfileId?: string | null,
  expectedVersion: number,
};

export type DeleteTalentProfileProcessedIDsInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTalentTypeInput = {
  id?: string | null,
  name: string,
  title: string,
  icon: string,
  sequence: number,
  skills: Array< TalentSkillInput | null >,
  interests: Array< TalentInterestInput | null >,
  sortType: string,
  stages?: Array< StageInput > | null,
  videoInterviewProvider?: VideoInterviewOptions | null,
  hrInterviewJobId: string,
  hrInterviewAIURL?: string | null,
  atsJobID: string,
  isActive?: boolean | null,
};

export type TalentSkillInput = {
  name: string,
  title: string,
};

export type TalentInterestInput = {
  name: string,
  title: string,
};

export type StageInput = {
  type: AssessmentStage,
  isOptional?: boolean | null,
  sequenceNumber: number,
  passingPercentage?: number | null,
};

export type ModelTalentTypeConditionInput = {
  name?: ModelStringInput | null,
  title?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  sequence?: ModelIntInput | null,
  sortType?: ModelStringInput | null,
  videoInterviewProvider?: ModelVideoInterviewOptionsInput | null,
  hrInterviewJobId?: ModelStringInput | null,
  hrInterviewAIURL?: ModelStringInput | null,
  atsJobID?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelTalentTypeConditionInput | null > | null,
  or?: Array< ModelTalentTypeConditionInput | null > | null,
  not?: ModelTalentTypeConditionInput | null,
};

export type ModelVideoInterviewOptionsInput = {
  eq?: VideoInterviewOptions | null,
  ne?: VideoInterviewOptions | null,
};

export type UpdateTalentTypeInput = {
  id: string,
  name?: string | null,
  title?: string | null,
  icon?: string | null,
  sequence?: number | null,
  skills?: Array< TalentSkillInput | null > | null,
  interests?: Array< TalentInterestInput | null > | null,
  sortType?: string | null,
  stages?: Array< StageInput > | null,
  videoInterviewProvider?: VideoInterviewOptions | null,
  hrInterviewJobId?: string | null,
  hrInterviewAIURL?: string | null,
  atsJobID?: string | null,
  isActive?: boolean | null,
};

export type DeleteTalentTypeInput = {
  id: string,
};

export type GetAvailableSlotsResponse = {
  __typename: "GetAvailableSlotsResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<SlotsPerDay | null > | null,
};

export type SlotsPerDay = {
  __typename: "SlotsPerDay",
  date?: string | null,
  availableSlots?:  Array<SlotType | null > | null,
};

export type SlotType = {
  __typename: "SlotType",
  startTime: string,
  endTime: string,
};

export type TrackReferralsResponse = {
  __typename: "TrackReferralsResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<ReferralStats | null > | null,
};

export type ReferralStats = {
  __typename: "ReferralStats",
  email?: string | null,
  status?: string | null,
  canReinvite?: boolean | null,
  updatedAt?: string | null,
  phoneNo?: string | null,
};

export type DomainTestApiResponse = {
  __typename: "DomainTestApiResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<DomainTest | null > | null,
};

export type DomainTest = {
  __typename: "DomainTest",
  id: string,
  unique_id: string,
  name: string,
  duration: number,
  instructions?: string | null,
  state?: string | null,
  locked?: boolean | null,
  tags?: Array< TechDomainSkills | null > | null,
};

export type DomainTestParam = {
  data?: Array< UserTestMappingInput | null > | null,
};

export type UserTestMappingInput = {
  testId?: string | null,
  candidateId?: string | null,
};

export type GetCandidateTestInfoResponse = {
  __typename: "GetCandidateTestInfoResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<CandidateTestReport | null > | null,
};

export type CandidateTestReport = {
  __typename: "CandidateTestReport",
  attempt_starttime?: string | null,
  attempt_endtime?: string | null,
  testId?: string | null,
};

export type SearchTalentProfileWithUsdSalaryInput = {
  experience?: ExperienceRange | null,
  skills?: Array< string | null > | null,
  englishProficiency?: englishProficiency | null,
  salaryRange?: SalaryRange | null,
  pageSize: number,
  itemNum: number,
};

export type ExperienceRange = {
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
};

export type SalaryRange = {
  min?: string | null,
  max?: string | null,
};

export type SearchTalentProfileWithUsdSalaryResponse = {
  __typename: "SearchTalentProfileWithUsdSalaryResponse",
  code?: number | null,
  message?: string | null,
  data?: searchTalentProfileData | null,
};

export type searchTalentProfileData = {
  __typename: "searchTalentProfileData",
  talents?:  Array<TalentProfileWithJobMatch | null > | null,
  totalCount?: number | null,
};

export type TalentProfileWithJobMatch = {
  __typename: "TalentProfileWithJobMatch",
  talentProfile?: TalentProfile | null,
  jobMatch?: JobMatch | null,
};

export type GetTalentProfileDetailsInput = {
  jobID: string,
  talentID: string,
};

export type GetTalentProfileDetailsResponse = {
  __typename: "GetTalentProfileDetailsResponse",
  code?: number | null,
  message?: string | null,
  data?: TalentProfileDetails | null,
};

export type TalentProfileDetails = {
  __typename: "TalentProfileDetails",
  id: string,
  skills?: Array< string > | null,
  experience?: string | null,
  isAvailable: boolean,
  social?: Array< SocialLinkTypes | null > | null,
  englishProficiency?: englishProficiency | null,
  problemSolvingScore?: number | null,
  problemSolvingPercentage?: number | null,
  isPassPS?: boolean | null,
  isPassHR?: boolean | null,
  isPassFinalTech?: boolean | null,
  isHired?: boolean | null,
  isNetwork?: boolean | null,
  isPlaced?: boolean | null,
  hrInterviewVideoId?: string | null,
  videoResponse?:  Array<VideoResponse | null > | null,
  statusMeta?: string | null,
  heardFrom?: string | null,
  currentSalary?: string | null,
  expectedSalary?: string | null,
  expectedSalaryInUsd?: number | null,
  interests?: Array< string | null > | null,
  talentFeedback?:  Array<Feedback | null > | null,
  resumeLink?: string | null,
  takenDomainTests?:  Array<TechDomainSkillsProgress | null > | null,
  educationHistory?:  Array<EducationEntry | null > | null,
  employmentHistory?:  Array<EmploymentEntry | null > | null,
  socialProfiles?:  Array<SocialProfile | null > | null,
  resetTests?: resetTests | null,
  imgLink?: string | null,
  bio?: string | null,
  title?: string | null,
  talentTypeID?: string | null,
  analyticsData?: analyticsData | null,
  talent?: TalentDetails | null,
  talentType?: TalentType | null,
  createdAt?: string | null,
};

export type TalentDetails = {
  __typename: "TalentDetails",
  id: string,
  fullName?: string | null,
  email?: string | null,
  phone?: string | null,
  country?: Country | null,
  role?: UserRoles | null,
};

export type GetTalentTestReportResponse = {
  __typename: "GetTalentTestReportResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<GetTalentTestReport | null > | null,
};

export type GetTalentTestReport = {
  __typename: "GetTalentTestReport",
  attemptStarttime?: string | null,
  attemptEndtime?: string | null,
  testId?: string | null,
  tags?: Array< string | null > | null,
  pdfReportUrl?: string | null,
};

export type GetConversationLambdaInput = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ConversationsListResponse = {
  __typename: "ConversationsListResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<ConversationResponse | null > | null,
  nextToken?: string | null,
};

export type GetMessagesByConversationLambdaInput = {
  conversationId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesListResponse = {
  __typename: "MessagesListResponse",
  code?: number | null,
  message?: string | null,
  data?:  Array<MessageResponse | null > | null,
  nextToken?: string | null,
};

export type GetMessagesCountByConversationIdLambdaInput = {
  conversationId: string,
};

export type MessagesCountResponse = {
  __typename: "MessagesCountResponse",
  code?: number | null,
  message?: string | null,
  data?: MessageCount | null,
};

export type MessageCount = {
  __typename: "MessageCount",
  count: number,
};

export type GetAllConversationsInput = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProductPriceListFromStripeResponse = {
  __typename: "GetProductPriceListFromStripeResponse",
  code?: number | null,
  message?: string | null,
  data?: GetProductPriceListFromStripeResponseData | null,
};

export type GetProductPriceListFromStripeResponseData = {
  __typename: "GetProductPriceListFromStripeResponseData",
  publishableKey?: string | null,
  prices?:  Array<StripeProductPrice > | null,
};

export type StripeProductPrice = {
  __typename: "StripeProductPrice",
  id?: string | null,
  object?: string | null,
  active?: boolean | null,
  billing_scheme?: string | null,
  created?: number | null,
  currency?: string | null,
  custom_unit_amount?: string | null,
  livemode?: string | null,
  lookup_key?: string | null,
  metadata?: string | null,
  nickname?: string | null,
  product?: StripeProduct | null,
  recurring?: StripeRecurring | null,
  tax_behavior?: string | null,
  tiers_mode?: string | null,
  transform_quantity?: string | null,
  type?: string | null,
  unit_amount?: number | null,
  unit_amount_decimal?: number | null,
};

export type StripeProduct = {
  __typename: "StripeProduct",
  id?: string | null,
  object?: string | null,
  active?: boolean | null,
  attributes?: string | null,
  created?: number | null,
  default_price?: string | null,
  description?: string | null,
  images?: string | null,
  livemode?: boolean | null,
  metadata?: string | null,
  name?: string | null,
  package_dimensions?: string | null,
  shippable?: string | null,
  statement_descriptor?: string | null,
  tax_code?: string | null,
  type?: string | null,
  unit_label?: string | null,
  updated?: string | null,
  url?: string | null,
};

export type GetMandateAndPaymentMethodResponse = {
  __typename: "GetMandateAndPaymentMethodResponse",
  code?: number | null,
  message?: string | null,
  data?: MandateAndPaymentMethodResponse | null,
};

export type MandateAndPaymentMethodResponse = {
  __typename: "MandateAndPaymentMethodResponse",
  mandate: StripeMandateResponse,
  paymentMethod: StripePaymentMethodResponse,
};

export type StripeMandateResponse = {
  __typename: "StripeMandateResponse",
  customerAcceptance: StripeMandateAcceptanceResponse,
};

export type StripeMandateAcceptanceResponse = {
  __typename: "StripeMandateAcceptanceResponse",
  acceptedAt: string,
};

export type StripePaymentMethodResponse = {
  __typename: "StripePaymentMethodResponse",
  id: string,
  usBankAccount: StripeUSBankAccountResponse,
};

export type StripeUSBankAccountResponse = {
  __typename: "StripeUSBankAccountResponse",
  last4: string,
  bankName: string,
  accountType: string,
};

export type GetWorkableJobInput = {
  jobShortCode: string,
};

export type GetWorkableJobResponse = {
  __typename: "GetWorkableJobResponse",
  code: number,
  data?: WorkableJob | null,
  message?: string | null,
};

export type WorkableJob = {
  __typename: "WorkableJob",
  id?: string | null,
  title?: string | null,
  full_title?: string | null,
  shortcode?: string | null,
  code?: string | null,
  url?: string | null,
  application_url?: string | null,
  shortlink?: string | null,
  created_at?: string | null,
  full_description?: string | null,
  description?: string | null,
  requirements?: string | null,
  benefits?: string | null,
  employment_type?: string | null,
  industry?: string | null,
  function?: string | null,
  experience?: string | null,
  education?: string | null,
  keywords?: string | null,
};

export type GetUserCanSignUpStatusResponse = {
  __typename: "GetUserCanSignUpStatusResponse",
  httpStatusCode: number,
  message?: string | null,
  data?: string | null,
};

export type ModelAnalyticsFilterInput = {
  id?: ModelIDInput | null,
  developersCompletedProfiles?: ModelIntInput | null,
  developersJoined?: ModelIntInput | null,
  recruitersJoined?: ModelIntInput | null,
  hiredToday?: ModelIntInput | null,
  takenDomainTests?: ModelIntInput | null,
  passedDomainTests?: ModelIntInput | null,
  takenProblemSolvingTests?: ModelIntInput | null,
  passedProblemSolving?: ModelIntInput | null,
  developersReferredByRecruiters?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAnalyticsFilterInput | null > | null,
  or?: Array< ModelAnalyticsFilterInput | null > | null,
  not?: ModelAnalyticsFilterInput | null,
};

export type ModelAnalyticsConnection = {
  __typename: "ModelAnalyticsConnection",
  items:  Array<Analytics | null >,
  nextToken?: string | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  invoiceBatchId?: ModelIDInput | null,
  invoiceNumber?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelInvoiceStatusInput | null,
  reason?: ModelStringInput | null,
  stripeInvoiceId?: ModelStringInput | null,
  stripeInvoicePdfUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelInvoiceBatchFilterInput = {
  id?: ModelIDInput | null,
  csvFileUrl?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  approvedBy?: ModelStringInput | null,
  status?: ModelInvoiceBatchStatusInput | null,
  invoiceBatchNumber?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  sortHashKey?: ModelSortHashKeyInput | null,
  errorMsg?: ModelStringInput | null,
  invoicesToProcess?: ModelIntInput | null,
  invoicesProcessed?: ModelIntInput | null,
  and?: Array< ModelInvoiceBatchFilterInput | null > | null,
  or?: Array< ModelInvoiceBatchFilterInput | null > | null,
  not?: ModelInvoiceBatchFilterInput | null,
};

export type ModelInvoiceBatchConnection = {
  __typename: "ModelInvoiceBatchConnection",
  items:  Array<InvoiceBatch | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null,
  invoiceId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  transactionId?: ModelStringInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  paymentMethod?: ModelCustomerPaymentMethodInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items:  Array<Payment | null >,
  nextToken?: string | null,
};

export type ModelStripeSetupIntentFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelStripeSetupIntentStatusInput | null,
  paymentMethodId?: ModelStringInput | null,
  setupIntentId?: ModelStringInput | null,
  mandateId?: ModelStringInput | null,
  mandateStatus?: ModelMandateStatusInput | null,
  createdAt?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  clientSecret?: ModelStringInput | null,
  and?: Array< ModelStripeSetupIntentFilterInput | null > | null,
  or?: Array< ModelStripeSetupIntentFilterInput | null > | null,
  not?: ModelStripeSetupIntentFilterInput | null,
};

export type ModelStripeSetupIntentConnection = {
  __typename: "ModelStripeSetupIntentConnection",
  items:  Array<StripeSetupIntent | null >,
  nextToken?: string | null,
};

export type ModelCalendarBookingFilterInput = {
  id?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  meetingLink?: ModelStringInput | null,
  talentId?: ModelIDInput | null,
  jobMatchId?: ModelIDInput | null,
  inviteToken?: ModelStringInput | null,
  feedback?: ModelStringInput | null,
  and?: Array< ModelCalendarBookingFilterInput | null > | null,
  or?: Array< ModelCalendarBookingFilterInput | null > | null,
  not?: ModelCalendarBookingFilterInput | null,
};

export type ModelCalendarBookingConnection = {
  __typename: "ModelCalendarBookingConnection",
  items:  Array<CalendarBooking | null >,
  nextToken?: string | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  role?: ModelUserRolesInput | null,
  companyID?: ModelIDInput | null,
  timezone?: ModelStringInput | null,
  googleCalendarId?: ModelStringInput | null,
  remainingCredits?: ModelIntInput | null,
  totalCredits?: ModelIntInput | null,
  stripeCustomerId?: ModelStringInput | null,
  currentStripeSubscriptionId?: ModelStringInput | null,
  hasMandate?: ModelBooleanInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
};

export type ModelClientCalendarInviteFilterInput = {
  id?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  clientEmail?: ModelStringInput | null,
  eventLength?: ModelIntInput | null,
  and?: Array< ModelClientCalendarInviteFilterInput | null > | null,
  or?: Array< ModelClientCalendarInviteFilterInput | null > | null,
  not?: ModelClientCalendarInviteFilterInput | null,
};

export type ModelClientCalendarInviteConnection = {
  __typename: "ModelClientCalendarInviteConnection",
  items:  Array<ClientCalendarInvite | null >,
  nextToken?: string | null,
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  stage?: ModelcompanyStageInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  industry?: ModelindustryInput | null,
  associatedWith?: ModelcompanyAssociationInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null,
  companyID?: ModelIDInput | null,
  status?: ModelJobStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  responsibilites?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  requiredTechDomainSkills?: ModelTechDomainSkillsListInput | null,
  seniorityLevel?: ModelSeniorityLevelInput | null,
  isPublic?: ModelBooleanInput | null,
  isPermanent?: ModelBooleanInput | null,
  internalNotes?: ModelStringInput | null,
  department?: ModelJobDepartmentInput | null,
  jobPerks?: ModelJobPerksListInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type ModelJobMatchFilterInput = {
  id?: ModelIDInput | null,
  talentID?: ModelIDInput | null,
  jobID?: ModelIDInput | null,
  lastActivity?: ModelStringInput | null,
  stage?: ModelmatchStageInput | null,
  process?: ModelmatchProcessInput | null,
  isRockstar?: ModelBooleanInput | null,
  isPublished?: ModelBooleanInput | null,
  isPaidFor?: ModelBooleanInput | null,
  talentProfileVersionID?: ModelIDInput | null,
  and?: Array< ModelJobMatchFilterInput | null > | null,
  or?: Array< ModelJobMatchFilterInput | null > | null,
  not?: ModelJobMatchFilterInput | null,
};

export type ModelJobMatchConnection = {
  __typename: "ModelJobMatchConnection",
  items:  Array<JobMatch | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPersonSubscriptionsFilterInput = {
  id?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  recruiterId?: ModelIDInput | null,
  talentId?: ModelIDInput | null,
  transactionId?: ModelStringInput | null,
  receipt?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  validationResponse?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  isCancelled?: ModelBooleanInput | null,
  platform?: ModelSubscriptionAppTypeInput | null,
  status?: ModelSubscriptionStatusInput | null,
  and?: Array< ModelPersonSubscriptionsFilterInput | null > | null,
  or?: Array< ModelPersonSubscriptionsFilterInput | null > | null,
  not?: ModelPersonSubscriptionsFilterInput | null,
};

export type ModelPersonSubscriptionsConnection = {
  __typename: "ModelPersonSubscriptionsConnection",
  items:  Array<PersonSubscriptions | null >,
  nextToken?: string | null,
};

export type ModelTalentProfileVersionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  skills?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  social?: ModelSocialLinkTypesListInput | null,
  englishProficiency?: ModelenglishProficiencyInput | null,
  expectedSalary?: ModelStringInput | null,
  expectedSalaryInUsd?: ModelFloatInput | null,
  interests?: ModelStringInput | null,
  imgLink?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  title?: ModelStringInput | null,
  talentID?: ModelIDInput | null,
  and?: Array< ModelTalentProfileVersionFilterInput | null > | null,
  or?: Array< ModelTalentProfileVersionFilterInput | null > | null,
  not?: ModelTalentProfileVersionFilterInput | null,
};

export type ModelTalentProfileVersionConnection = {
  __typename: "ModelTalentProfileVersionConnection",
  items:  Array<TalentProfileVersion | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  content?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isRead?: ModelBooleanInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelConversationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  lastMessageAt?: ModelStringInput | null,
  and?: Array< ModelConversationFilterInput | null > | null,
  or?: Array< ModelConversationFilterInput | null > | null,
  not?: ModelConversationFilterInput | null,
};

export type ModelConversationConnection = {
  __typename: "ModelConversationConnection",
  items:  Array<Conversation | null >,
  nextToken?: string | null,
};

export type ModelConversationParticipantsFilterInput = {
  id?: ModelIDInput | null,
  conversationId?: ModelIDInput | null,
  talentId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  recruiterId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConversationParticipantsFilterInput | null > | null,
  or?: Array< ModelConversationParticipantsFilterInput | null > | null,
  not?: ModelConversationParticipantsFilterInput | null,
};

export type ModelRecruiterFilterInput = {
  id?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  role?: ModelUserRolesInput | null,
  and?: Array< ModelRecruiterFilterInput | null > | null,
  or?: Array< ModelRecruiterFilterInput | null > | null,
  not?: ModelRecruiterFilterInput | null,
};

export type ModelRecruiterConnection = {
  __typename: "ModelRecruiterConnection",
  items:  Array<Recruiter | null >,
  nextToken?: string | null,
};

export type ModelRecruiterProfileFilterInput = {
  id?: ModelIDInput | null,
  currentEarnings?: ModelIntInput | null,
  profession?: ModelStringInput | null,
  and?: Array< ModelRecruiterProfileFilterInput | null > | null,
  or?: Array< ModelRecruiterProfileFilterInput | null > | null,
  not?: ModelRecruiterProfileFilterInput | null,
};

export type ModelRecruiterProfileConnection = {
  __typename: "ModelRecruiterProfileConnection",
  items:  Array<RecruiterProfile | null >,
  nextToken?: string | null,
};

export type ModelReferralFilterInput = {
  id?: ModelIDInput | null,
  referrerId?: ModelIDInput | null,
  referralReferredId?: ModelIDInput | null,
  recruiterId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelReferralFilterInput | null > | null,
  or?: Array< ModelReferralFilterInput | null > | null,
  not?: ModelReferralFilterInput | null,
};

export type ModelReferralConnection = {
  __typename: "ModelReferralConnection",
  items:  Array<Referral | null >,
  nextToken?: string | null,
};

export type ModelTalentFilterInput = {
  id?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  role?: ModelUserRolesInput | null,
  and?: Array< ModelTalentFilterInput | null > | null,
  or?: Array< ModelTalentFilterInput | null > | null,
  not?: ModelTalentFilterInput | null,
};

export type ModelTalentConnection = {
  __typename: "ModelTalentConnection",
  items:  Array<Talent | null >,
  nextToken?: string | null,
};

export type ModelTalentProfileFilterInput = {
  id?: ModelIDInput | null,
  skills?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  social?: ModelSocialLinkTypesListInput | null,
  englishProficiency?: ModelenglishProficiencyInput | null,
  problemSolvingScore?: ModelFloatInput | null,
  problemSolvingPercentage?: ModelFloatInput | null,
  isPassPS?: ModelBooleanInput | null,
  isPassHR?: ModelBooleanInput | null,
  isPassFinalTech?: ModelBooleanInput | null,
  isHired?: ModelBooleanInput | null,
  isNetwork?: ModelBooleanInput | null,
  isPlaced?: ModelBooleanInput | null,
  hrInterviewVideoId?: ModelStringInput | null,
  statusMeta?: ModelStringInput | null,
  heardFrom?: ModelStringInput | null,
  currentSalary?: ModelStringInput | null,
  expectedSalary?: ModelStringInput | null,
  expectedSalaryInUsd?: ModelFloatInput | null,
  interests?: ModelStringInput | null,
  resumeLink?: ModelStringInput | null,
  imgLink?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  title?: ModelStringInput | null,
  talentTypeID?: ModelIDInput | null,
  isProfileCompleted?: ModelBooleanInput | null,
  workableId?: ModelStringInput | null,
  aiInterviewInProgress?: ModelBooleanInput | null,
  videoInterviewProvider?: ModelVideoInterviewProviderInput | null,
  and?: Array< ModelTalentProfileFilterInput | null > | null,
  or?: Array< ModelTalentProfileFilterInput | null > | null,
  not?: ModelTalentProfileFilterInput | null,
};

export type ModelTalentProfileConnection = {
  __typename: "ModelTalentProfileConnection",
  items:  Array<TalentProfile | null >,
  nextToken?: string | null,
};

export type ModelForexRatesFilterInput = {
  id?: ModelIDInput | null,
  currencyType?: ModelStringInput | null,
  usdConversionRate?: ModelFloatInput | null,
  and?: Array< ModelForexRatesFilterInput | null > | null,
  or?: Array< ModelForexRatesFilterInput | null > | null,
  not?: ModelForexRatesFilterInput | null,
};

export type ModelForexRatesConnection = {
  __typename: "ModelForexRatesConnection",
  items:  Array<ForexRates | null >,
  nextToken?: string | null,
};

export type ModelTalentProfileTransientFilterInput = {
  id?: ModelIDInput | null,
  skills?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  social?: ModelSocialLinkTypesListInput | null,
  englishProficiency?: ModelenglishProficiencyInput | null,
  problemSolvingScore?: ModelFloatInput | null,
  problemSolvingPercentage?: ModelFloatInput | null,
  isPassPS?: ModelBooleanInput | null,
  isPassHR?: ModelBooleanInput | null,
  isPassFinalTech?: ModelBooleanInput | null,
  isHired?: ModelBooleanInput | null,
  isNetwork?: ModelBooleanInput | null,
  isPlaced?: ModelBooleanInput | null,
  hrInterviewVideoId?: ModelStringInput | null,
  statusMeta?: ModelStringInput | null,
  heardFrom?: ModelStringInput | null,
  currentSalary?: ModelStringInput | null,
  expectedSalary?: ModelStringInput | null,
  expectedSalaryInUsd?: ModelFloatInput | null,
  interests?: ModelStringInput | null,
  resumeLink?: ModelStringInput | null,
  imgLink?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  title?: ModelStringInput | null,
  talentTypeID?: ModelIDInput | null,
  isProfileCompleted?: ModelBooleanInput | null,
  talentProfileTalentId?: ModelStringInput | null,
  source?: ModelStringInput | null,
  and?: Array< ModelTalentProfileTransientFilterInput | null > | null,
  or?: Array< ModelTalentProfileTransientFilterInput | null > | null,
  not?: ModelTalentProfileTransientFilterInput | null,
};

export type ModelTalentProfileTransientConnection = {
  __typename: "ModelTalentProfileTransientConnection",
  items:  Array<TalentProfileTransient | null >,
  nextToken?: string | null,
};

export type ModelTalentProfileProcessedIDsFilterInput = {
  id?: ModelIDInput | null,
  talentProfileId?: ModelStringInput | null,
  and?: Array< ModelTalentProfileProcessedIDsFilterInput | null > | null,
  or?: Array< ModelTalentProfileProcessedIDsFilterInput | null > | null,
  not?: ModelTalentProfileProcessedIDsFilterInput | null,
};

export type ModelTalentProfileProcessedIDsConnection = {
  __typename: "ModelTalentProfileProcessedIDsConnection",
  items:  Array<TalentProfileProcessedIDs | null >,
  nextToken?: string | null,
};

export type ModelTalentTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  title?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  sequence?: ModelIntInput | null,
  sortType?: ModelStringInput | null,
  videoInterviewProvider?: ModelVideoInterviewOptionsInput | null,
  hrInterviewJobId?: ModelStringInput | null,
  hrInterviewAIURL?: ModelStringInput | null,
  atsJobID?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelTalentTypeFilterInput | null > | null,
  or?: Array< ModelTalentTypeFilterInput | null > | null,
  not?: ModelTalentTypeFilterInput | null,
};

export type ModelTalentTypeConnection = {
  __typename: "ModelTalentTypeConnection",
  items:  Array<TalentType | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type CompleteTalentProfileMutation = {
  completeTalentProfile?:  {
    __typename: "CreateCandidateResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type CreateCandidateMutation = {
  createCandidate?:  {
    __typename: "CreateCandidateResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type DisqualifyCandidateFromWorkableMutationVariables = {
  candidateId?: string | null,
};

export type DisqualifyCandidateFromWorkableMutation = {
  disqualifyCandidateFromWorkable?:  {
    __typename: "CreateCandidateResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  email?: string | null,
  comment?: string | null,
  isPrivate?: boolean | null,
  attachment?: WorkableAttachmentInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "CreateCandidateResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type MatchTalentToJobsMutationVariables = {
  jobs: Array< TalentJobsInput | null >,
  talentID: string,
  talentEmail: string,
};

export type MatchTalentToJobsMutation = {
  matchTalentToJobs?:  {
    __typename: "MatchEngToJobResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
  } | null,
};

export type MoveCandidateMutationVariables = {
  email?: string | null,
  targetStage?: string | null,
};

export type MoveCandidateMutation = {
  moveCandidate?:  {
    __typename: "CreateCandidateResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type SendClientNewJobMatchEmailMutationVariables = {
  jobMatch?: NewClientJobMatchInput | null,
};

export type SendClientNewJobMatchEmailMutation = {
  sendClientNewJobMatchEmail?:  {
    __typename: "UpdateJobMatchStageResponseType",
    code: number,
    message?: string | null,
    data?:  {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type SendEmailMutationVariables = {
  input?: SendEmailInputType | null,
};

export type SendEmailMutation = {
  sendEmail?:  {
    __typename: "SendEmailResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type SubscribeToMoveMutationVariables = {
  stage?: string | null,
  atsJobId?: string | null,
  talentCategory?: string | null,
};

export type SubscribeToMoveMutation = {
  subscribeToMove?:  {
    __typename: "CreateCandidateResponseType",
    code?: number | null,
    message?: string | null,
    data?: boolean | null,
  } | null,
};

export type ClientInterviewInviteMutationVariables = {
  talentMatchJobId: string,
  eventID: string,
  clientID: string,
};

export type ClientInterviewInviteMutation = {
  clientInterviewInvite?:  {
    __typename: "ClientInterviewInviteResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateAvailabilityMutationVariables = {
  availabilities: Array< CreateAvailabilityInput >,
  timezone: string,
};

export type CreateAvailabilityMutation = {
  createAvailability?:  {
    __typename: "CreateAvailabilityResponseType",
    code: number,
    message: string,
  } | null,
};

export type FetchJobMatchesMutationVariables = {
  companyID?: string | null,
  filter?: MatchStageFilterInput | null,
};

export type FetchJobMatchesMutation = {
  fetchJobMatches?:  {
    __typename: "MatchEngToJobResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null > | null,
  } | null,
};

export type SubmitCalendarBookingMutationVariables = {
  token: string,
  startTime: string,
  endTime: string,
  timezone: string,
};

export type SubmitCalendarBookingMutation = {
  submitCalendarBooking?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type UpdateJobMatchStageMutationVariables = {
  payload: UpdateJobMatchStageInput,
};

export type UpdateJobMatchStageMutation = {
  updateJobMatchStage?:  {
    __typename: "UpdateJobMatchStageResponseType",
    code: number,
    message?: string | null,
    data?:  {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type SaveClientSubscriptionReceiptMutationVariables = {
  payload: SaveSubscriptionReceiptInput,
};

export type SaveClientSubscriptionReceiptMutation = {
  saveClientSubscriptionReceipt?:  {
    __typename: "SaveSubscriptionReceiptResponse",
    code?: number | null,
    message?: string | null,
  } | null,
};

export type ChargeClientForTalentInformationMutationVariables = {
  talentID: string,
  jobID: string,
};

export type ChargeClientForTalentInformationMutation = {
  chargeClientForTalentInformation?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type DisableClientBasicUserMutation = {
  disableClientBasicUser?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type CreateOrUpdateJobMatchMutationVariables = {
  input: CreateOrUpdateJobMatchInput,
};

export type CreateOrUpdateJobMatchMutation = {
  createOrUpdateJobMatch?:  {
    __typename: "CreateOrUpdateJobMatchResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrUpdateJobMatchAdminMutationVariables = {
  input: CreateOrUpdateJobMatchInput,
};

export type CreateOrUpdateJobMatchAdminMutation = {
  createOrUpdateJobMatchAdmin?:  {
    __typename: "CreateOrUpdateJobMatchResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateJobMatchFeedbackMutationVariables = {
  payload: UpdateJobMatchFeedbackInput,
};

export type UpdateJobMatchFeedbackMutation = {
  updateJobMatchFeedback?:  {
    __typename: "UpdateJobMatchStageResponseType",
    code: number,
    message?: string | null,
    data?:  {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCompanyDataMutationVariables = {
  payload: updateCompanyDataInput,
};

export type UpdateCompanyDataMutation = {
  updateCompanyData?:  {
    __typename: "updateCompanyDataResponseType",
    code: number,
    message?: string | null,
    data?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type AcceptInvitationMutationVariables = {
  referrerId?: string | null,
};

export type AcceptInvitationMutation = {
  acceptInvitation?:  {
    __typename: "AcceptInvitationResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "RefferalData",
      refferedBy?: string | null,
    } | null,
  } | null,
};

export type InviteEmailsMutationVariables = {
  emails?: Array< string | null > | null,
};

export type InviteEmailsMutation = {
  inviteEmails?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type ReInviteEmailMutationVariables = {
  email?: string | null,
};

export type ReInviteEmailMutation = {
  reInviteEmail?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GenerateRecruitersReportMutationVariables = {
  input?: GenerateRecruitersReportInput | null,
};

export type GenerateRecruitersReportMutation = {
  generateRecruitersReport?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GenerateLeaderBoardReportMutationVariables = {
  input?: GenerateRecruitersReportInput | null,
};

export type GenerateLeaderBoardReportMutation = {
  generateLeaderBoardReport?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GetProblemSolvingTestMutation = {
  getProblemSolvingTest?: string | null,
};

export type InviteUserForTestMutationVariables = {
  testId: string,
};

export type InviteUserForTestMutation = {
  inviteUserForTest?: string | null,
};

export type SetVideoResponseMutationVariables = {
  videoId: string,
};

export type SetVideoResponseMutation = {
  setVideoResponse?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type CreateTalentProfileWithUsdSalaryMutationVariables = {
  input: CreateTalentProfileWithUsdSalaryInput,
};

export type CreateTalentProfileWithUsdSalaryMutation = {
  createTalentProfileWithUsdSalary?:  {
    __typename: "TalentProfileWithUsdSalaryResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateTalentProfileWithUsdSalaryMutationVariables = {
  input: UpdateTalentProfileWithUsdSalaryInput,
};

export type UpdateTalentProfileWithUsdSalaryMutation = {
  updateTalentProfileWithUsdSalary?:  {
    __typename: "TalentProfileWithUsdSalaryResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ApplyJobWorkableMutationVariables = {
  input?: ApplyJobWorkableType | null,
};

export type ApplyJobWorkableMutation = {
  applyJobWorkable?:  {
    __typename: "ApplyJobWorkableResponseType",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "ApplyJobWorkableDataResponseType",
      uid?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      email?: string | null,
    } | null,
  } | null,
};

export type SendMessageMutationVariables = {
  input?: SendMessageInput | null,
};

export type SendMessageMutation = {
  sendMessage?:  {
    __typename: "SendMessageResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "MessageResponse",
      id?: string | null,
      content?: string | null,
      senderInfo?:  {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null,
      conversationId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      isRead?: boolean | null,
      version?: number | null,
      readBy?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
    } | null,
  } | null,
};

export type BuildConversationMutationVariables = {
  input?: CreateConversationLambdaInput | null,
};

export type BuildConversationMutation = {
  buildConversation?:  {
    __typename: "CreateConversationResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "ConversationResponse",
      id?: string | null,
      name?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      isActive?: boolean | null,
      version?: number | null,
      messages?:  Array< {
        __typename: "MessageResponse",
        id?: string | null,
        content?: string | null,
        senderInfo?:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null,
        conversationId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        isRead?: boolean | null,
        version?: number | null,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
      } | null > | null,
      conversationParticipants?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
    } | null,
  } | null,
};

export type MarkMessageAsReadMutationVariables = {
  input?: MarkMessageAsReadInput | null,
};

export type MarkMessageAsReadMutation = {
  markMessageAsRead?:  {
    __typename: "MarkMessageAsReadResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "MessageResponse",
      id?: string | null,
      content?: string | null,
      senderInfo?:  {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null,
      conversationId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      isRead?: boolean | null,
      version?: number | null,
      readBy?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateOrGetStripeCustomerMutation = {
  createOrGetStripeCustomer?:  {
    __typename: "CreateOrGetStripeCustomerResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "CreateOrGetStripeCustomerResponseData",
      customer?:  {
        __typename: "StripeCustomer",
        id?: string | null,
        object?: string | null,
        address?: string | null,
        balance?: number | null,
        created?: number | null,
        currency?: string | null,
        default_source?: string | null,
        delinquent?: boolean | null,
        description?: string | null,
        discount?: string | null,
        email?: string | null,
        invoice_prefix?: string | null,
        invoice_settings?:  {
          __typename: "InvoiceSettingResponse",
          custom_fields?: string | null,
          default_payment_method?: string | null,
          footer?: string | null,
          rendering_options?: string | null,
        } | null,
        livemode?: boolean | null,
        metadata?: string | null,
        name?: string | null,
        next_invoice_sequence?: number | null,
        phone?: string | null,
        preferred_locales?: string | null,
        shipping?: string | null,
        tax_exempt?: string | null,
        test_clock?: string | null,
      } | null,
      paymentMethods?:  Array< {
        __typename: "PaymentMethod",
        pid?: string | null,
        lastFour?: string | null,
        networks?:  {
          __typename: "NetworksResponse",
          available?: Array< string > | null,
          preferred?: string | null,
        } | null,
      } > | null,
      subscription?:  {
        __typename: "StripeSubscription",
        id?: string | null,
        object?: string | null,
        application?: string | null,
        application_fee_percent?: string | null,
        automatic_tax?:  {
          __typename: "StripeAutomaticTax",
          enabled?: boolean | null,
        } | null,
        billing_cycle_anchor?: number | null,
        billing_thresholds?: string | null,
        cancel_at?: string | null,
        cancel_at_period_end?: boolean | null,
        canceled_at?: string | null,
        collection_method?: string | null,
        created?: number | null,
        currency?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        customer?: string | null,
        days_until_due?: string | null,
        default_payment_method?: string | null,
        default_source?: string | null,
        default_tax_rates?: string | null,
        description?: string | null,
        discount?: string | null,
        ended_at?: string | null,
        items?:  {
          __typename: "StripeSubscriptionItems",
          object?: string | null,
          data?:  Array< {
            __typename: "StripeSubscriptionItemsData",
            id?: string | null,
            object?: string | null,
            billing_thresholds?: string | null,
            created?: number | null,
            metadata?: string | null,
            price?:  {
              __typename: "StripeSubscriptionItemsDataPrice",
              id?: string | null,
              object?: string | null,
              active?: boolean | null,
              billing_scheme?: string | null,
              created?: number | null,
              currency?: string | null,
              custom_unit_amount?: string | null,
              livemode?: boolean | null,
              lookup_key?: string | null,
              metadata?: string | null,
              nickname?: string | null,
              product?: string | null,
              tax_behavior?: string | null,
              tiers_mode?: string | null,
              transform_quantity?: string | null,
              type?: string | null,
              unit_amount?: number | null,
              unit_amount_decimal?: number | null,
            } | null,
            quantity?: number | null,
            subscription?: string | null,
            tax_rates?: string | null,
          } > | null,
          has_more?: boolean | null,
          url?: string | null,
        } | null,
        latest_invoice?: string | null,
        livemode?: boolean | null,
        metadata?: string | null,
        next_pending_invoice_item_invoice?: string | null,
        on_behalf_of?: string | null,
        pause_collection?: string | null,
        payment_settings?:  {
          __typename: "StripeSubscriptionPaymentSettings",
          payment_method_options?: string | null,
          payment_method_types?: string | null,
          save_default_payment_method?: string | null,
        } | null,
        pending_invoice_item_interval?: string | null,
        pending_setup_intent?: string | null,
        pending_update?: string | null,
        schedule?: string | null,
        start_date?: number | null,
        status?: string | null,
        test_clock?: string | null,
        transfer_data?: string | null,
        trial_end?: string | null,
        trial_start?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateStripeSubscriptionMutationVariables = {
  priceId: string,
};

export type CreateStripeSubscriptionMutation = {
  createStripeSubscription?:  {
    __typename: "CreateStripeSubscriptionResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "CreateStripeSubscriptionResponseData",
      stripeSubscriptionId?: string | null,
      clientSecret?: string | null,
    } | null,
  } | null,
};

export type CancelStripeSubscriptionMutationVariables = {
  subscriptionId: string,
};

export type CancelStripeSubscriptionMutation = {
  cancelStripeSubscription?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type UpdateSubscriptionPaymentMethodMutationVariables = {
  subscriptionId: string,
  paymentMethodId: string,
};

export type UpdateSubscriptionPaymentMethodMutation = {
  updateSubscriptionPaymentMethod?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type CreateSetupIntentForACHMandateMutation = {
  createSetupIntentForACHMandate?:  {
    __typename: "CreateSetupIntentForACHMandateResponse",
    code?: number | null,
    data:  {
      __typename: "SetupIntentClientSecretAndSetupIntentIdResponse",
      clientSecret: string,
      setupIntentId: string,
    },
    message?: string | null,
  } | null,
};

export type WithDrawACHMandateMutationVariables = {
  paymentMethodId: string,
};

export type WithDrawACHMandateMutation = {
  withDrawACHMandate?:  {
    __typename: "LambdaJSONResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type SaveStripeSetupIntentMutationVariables = {
  setupIntentId: string,
};

export type SaveStripeSetupIntentMutation = {
  saveStripeSetupIntent?:  {
    __typename: "LambdaJSONResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GetParsedResumeDataMutationVariables = {
  resumeLink: string,
};

export type GetParsedResumeDataMutation = {
  getParsedResumeData?:  {
    __typename: "ParsedResumeDataResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "ResumeData",
      basic_info?:  {
        __typename: "ResumeBasicInfo",
        github_main_page_url?: string | null,
        linkedin_url?: string | null,
        portfolio_website_url?: string | null,
      } | null,
      education_history?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employment_history?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateAndValidateInvoiceBatchMutationVariables = {
  payload: string,
};

export type CreateAndValidateInvoiceBatchMutation = {
  createAndValidateInvoiceBatch?:  {
    __typename: "createAndValidateInvoiceBatchResponse",
    code: number,
    message?: string | null,
    data?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
  } | null,
};

export type ConsumeBatchIdMutationVariables = {
  batchId: string,
};

export type ConsumeBatchIdMutation = {
  consumeBatchId?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type CreateAnalyticsMutationVariables = {
  input: CreateAnalyticsInput,
  condition?: ModelAnalyticsConditionInput | null,
};

export type CreateAnalyticsMutation = {
  createAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateAnalyticsMutationVariables = {
  input: UpdateAnalyticsInput,
  condition?: ModelAnalyticsConditionInput | null,
};

export type UpdateAnalyticsMutation = {
  updateAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteAnalyticsMutationVariables = {
  input: DeleteAnalyticsInput,
  condition?: ModelAnalyticsConditionInput | null,
};

export type DeleteAnalyticsMutation = {
  deleteAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type CreateInvoiceBatchMutationVariables = {
  input: CreateInvoiceBatchInput,
  condition?: ModelInvoiceBatchConditionInput | null,
};

export type CreateInvoiceBatchMutation = {
  createInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateInvoiceBatchMutationVariables = {
  input: UpdateInvoiceBatchInput,
  condition?: ModelInvoiceBatchConditionInput | null,
};

export type UpdateInvoiceBatchMutation = {
  updateInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteInvoiceBatchMutationVariables = {
  input: DeleteInvoiceBatchInput,
  condition?: ModelInvoiceBatchConditionInput | null,
};

export type DeleteInvoiceBatchMutation = {
  deleteInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateStripeSetupIntentMutationVariables = {
  input: CreateStripeSetupIntentInput,
  condition?: ModelStripeSetupIntentConditionInput | null,
};

export type CreateStripeSetupIntentMutation = {
  createStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type UpdateStripeSetupIntentMutationVariables = {
  input: UpdateStripeSetupIntentInput,
  condition?: ModelStripeSetupIntentConditionInput | null,
};

export type UpdateStripeSetupIntentMutation = {
  updateStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type DeleteStripeSetupIntentMutationVariables = {
  input: DeleteStripeSetupIntentInput,
  condition?: ModelStripeSetupIntentConditionInput | null,
};

export type DeleteStripeSetupIntentMutation = {
  deleteStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type CreateCalendarBookingMutationVariables = {
  input: CreateCalendarBookingInput,
  condition?: ModelCalendarBookingConditionInput | null,
};

export type CreateCalendarBookingMutation = {
  createCalendarBooking?:  {
    __typename: "CalendarBooking",
    id: string,
    clientId: string,
    eventId: string,
    startTime?: string | null,
    endTime?: string | null,
    meetingLink?: string | null,
    talentId: string,
    jobMatchId: string,
    inviteToken?: string | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    engineerId?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateCalendarBookingMutationVariables = {
  input: UpdateCalendarBookingInput,
  condition?: ModelCalendarBookingConditionInput | null,
};

export type UpdateCalendarBookingMutation = {
  updateCalendarBooking?:  {
    __typename: "CalendarBooking",
    id: string,
    clientId: string,
    eventId: string,
    startTime?: string | null,
    endTime?: string | null,
    meetingLink?: string | null,
    talentId: string,
    jobMatchId: string,
    inviteToken?: string | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    engineerId?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeleteCalendarBookingMutationVariables = {
  input: DeleteCalendarBookingInput,
  condition?: ModelCalendarBookingConditionInput | null,
};

export type DeleteCalendarBookingMutation = {
  deleteCalendarBooking?:  {
    __typename: "CalendarBooking",
    id: string,
    clientId: string,
    eventId: string,
    startTime?: string | null,
    endTime?: string | null,
    meetingLink?: string | null,
    talentId: string,
    jobMatchId: string,
    inviteToken?: string | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    engineerId?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    companyID?: string | null,
    timezone?: string | null,
    availability?:  Array< {
      __typename: "ClientAvailability",
      day: number,
      date: string,
      availableSlots:  Array< {
        __typename: "AvailableSlot",
        startTime: string,
        endTime: string,
      } >,
    } > | null,
    googleCalendarId?: string | null,
    remainingCredits?: number | null,
    totalCredits?: number | null,
    stripeCustomerId?: string | null,
    currentStripeSubscriptionId?: string | null,
    hasMandate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    companyID?: string | null,
    timezone?: string | null,
    availability?:  Array< {
      __typename: "ClientAvailability",
      day: number,
      date: string,
      availableSlots:  Array< {
        __typename: "AvailableSlot",
        startTime: string,
        endTime: string,
      } >,
    } > | null,
    googleCalendarId?: string | null,
    remainingCredits?: number | null,
    totalCredits?: number | null,
    stripeCustomerId?: string | null,
    currentStripeSubscriptionId?: string | null,
    hasMandate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    companyID?: string | null,
    timezone?: string | null,
    availability?:  Array< {
      __typename: "ClientAvailability",
      day: number,
      date: string,
      availableSlots:  Array< {
        __typename: "AvailableSlot",
        startTime: string,
        endTime: string,
      } >,
    } > | null,
    googleCalendarId?: string | null,
    remainingCredits?: number | null,
    totalCredits?: number | null,
    stripeCustomerId?: string | null,
    currentStripeSubscriptionId?: string | null,
    hasMandate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateClientCalendarInviteMutationVariables = {
  input: CreateClientCalendarInviteInput,
  condition?: ModelClientCalendarInviteConditionInput | null,
};

export type CreateClientCalendarInviteMutation = {
  createClientCalendarInvite?:  {
    __typename: "ClientCalendarInvite",
    id: string,
    clientID: string,
    title: string,
    clientEmail: string,
    eventLength: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateClientCalendarInviteMutationVariables = {
  input: UpdateClientCalendarInviteInput,
  condition?: ModelClientCalendarInviteConditionInput | null,
};

export type UpdateClientCalendarInviteMutation = {
  updateClientCalendarInvite?:  {
    __typename: "ClientCalendarInvite",
    id: string,
    clientID: string,
    title: string,
    clientEmail: string,
    eventLength: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeleteClientCalendarInviteMutationVariables = {
  input: DeleteClientCalendarInviteInput,
  condition?: ModelClientCalendarInviteConditionInput | null,
};

export type DeleteClientCalendarInviteMutation = {
  deleteClientCalendarInvite?:  {
    __typename: "ClientCalendarInvite",
    id: string,
    clientID: string,
    title: string,
    clientEmail: string,
    eventLength: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateJobMatchMutationVariables = {
  input: CreateJobMatchInput,
  condition?: ModelJobMatchConditionInput | null,
};

export type CreateJobMatchMutation = {
  createJobMatch?:  {
    __typename: "JobMatch",
    id: string,
    talentID: string,
    jobID: string,
    lastActivity: string,
    stage: matchStage,
    process: matchProcess,
    isRockstar?: boolean | null,
    isPublished: boolean,
    clientFeedback?:  {
      __typename: "ClientFeedback",
      referred?: string | null,
      interviewing?: string | null,
      passTechInterview?: string | null,
      failTechInterview?: string | null,
      passCulturalFitInterview?: string | null,
      failCulturalFitInterview?: string | null,
      passSystemDesignInterview?: string | null,
      failSystemDesignInterview?: string | null,
      rejected?: string | null,
      onboarded?: string | null,
      interested?: string | null,
    } | null,
    isPaidFor?: boolean | null,
    feedback?:  {
      __typename: "JobMatchFeedback",
      communicationSkillsLevel?: number | null,
      techSkillAndDomainKnowledgeLevel?: number | null,
      problemSolvingSkillLevel?: number | null,
      isRejected?: boolean | null,
      otherFeedback?: string | null,
    } | null,
    talentProfileVersionID?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    job?:  {
      __typename: "Job",
      id: string,
      companyID: string,
      status: JobStatus,
      title: string,
      description: string,
      responsibilites: string,
      tags: Array< string | null >,
      requiredTechDomainSkills: Array< TechDomainSkills | null >,
      seniorityLevel: SeniorityLevel,
      isPublic?: boolean | null,
      isPermanent?: boolean | null,
      internalNotes?: string | null,
      department?: JobDepartment | null,
      jobPerks?: Array< JobPerks | null > | null,
      createdAt: string,
      updatedAt: string,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    talentProfileVersion?:  {
      __typename: "TalentProfileVersion",
      id: string,
      name: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentID: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateJobMatchMutationVariables = {
  input: UpdateJobMatchInput,
  condition?: ModelJobMatchConditionInput | null,
};

export type UpdateJobMatchMutation = {
  updateJobMatch?:  {
    __typename: "JobMatch",
    id: string,
    talentID: string,
    jobID: string,
    lastActivity: string,
    stage: matchStage,
    process: matchProcess,
    isRockstar?: boolean | null,
    isPublished: boolean,
    clientFeedback?:  {
      __typename: "ClientFeedback",
      referred?: string | null,
      interviewing?: string | null,
      passTechInterview?: string | null,
      failTechInterview?: string | null,
      passCulturalFitInterview?: string | null,
      failCulturalFitInterview?: string | null,
      passSystemDesignInterview?: string | null,
      failSystemDesignInterview?: string | null,
      rejected?: string | null,
      onboarded?: string | null,
      interested?: string | null,
    } | null,
    isPaidFor?: boolean | null,
    feedback?:  {
      __typename: "JobMatchFeedback",
      communicationSkillsLevel?: number | null,
      techSkillAndDomainKnowledgeLevel?: number | null,
      problemSolvingSkillLevel?: number | null,
      isRejected?: boolean | null,
      otherFeedback?: string | null,
    } | null,
    talentProfileVersionID?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    job?:  {
      __typename: "Job",
      id: string,
      companyID: string,
      status: JobStatus,
      title: string,
      description: string,
      responsibilites: string,
      tags: Array< string | null >,
      requiredTechDomainSkills: Array< TechDomainSkills | null >,
      seniorityLevel: SeniorityLevel,
      isPublic?: boolean | null,
      isPermanent?: boolean | null,
      internalNotes?: string | null,
      department?: JobDepartment | null,
      jobPerks?: Array< JobPerks | null > | null,
      createdAt: string,
      updatedAt: string,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    talentProfileVersion?:  {
      __typename: "TalentProfileVersion",
      id: string,
      name: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentID: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteJobMatchMutationVariables = {
  input: DeleteJobMatchInput,
  condition?: ModelJobMatchConditionInput | null,
};

export type DeleteJobMatchMutation = {
  deleteJobMatch?:  {
    __typename: "JobMatch",
    id: string,
    talentID: string,
    jobID: string,
    lastActivity: string,
    stage: matchStage,
    process: matchProcess,
    isRockstar?: boolean | null,
    isPublished: boolean,
    clientFeedback?:  {
      __typename: "ClientFeedback",
      referred?: string | null,
      interviewing?: string | null,
      passTechInterview?: string | null,
      failTechInterview?: string | null,
      passCulturalFitInterview?: string | null,
      failCulturalFitInterview?: string | null,
      passSystemDesignInterview?: string | null,
      failSystemDesignInterview?: string | null,
      rejected?: string | null,
      onboarded?: string | null,
      interested?: string | null,
    } | null,
    isPaidFor?: boolean | null,
    feedback?:  {
      __typename: "JobMatchFeedback",
      communicationSkillsLevel?: number | null,
      techSkillAndDomainKnowledgeLevel?: number | null,
      problemSolvingSkillLevel?: number | null,
      isRejected?: boolean | null,
      otherFeedback?: string | null,
    } | null,
    talentProfileVersionID?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    job?:  {
      __typename: "Job",
      id: string,
      companyID: string,
      status: JobStatus,
      title: string,
      description: string,
      responsibilites: string,
      tags: Array< string | null >,
      requiredTechDomainSkills: Array< TechDomainSkills | null >,
      seniorityLevel: SeniorityLevel,
      isPublic?: boolean | null,
      isPermanent?: boolean | null,
      internalNotes?: string | null,
      department?: JobDepartment | null,
      jobPerks?: Array< JobPerks | null > | null,
      createdAt: string,
      updatedAt: string,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    talentProfileVersion?:  {
      __typename: "TalentProfileVersion",
      id: string,
      name: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentID: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePersonSubscriptionsMutationVariables = {
  input: CreatePersonSubscriptionsInput,
  condition?: ModelPersonSubscriptionsConditionInput | null,
};

export type CreatePersonSubscriptionsMutation = {
  createPersonSubscriptions?:  {
    __typename: "PersonSubscriptions",
    id: string,
    clientId?: string | null,
    recruiterId?: string | null,
    talentId?: string | null,
    transactionId: string,
    receipt: string,
    startDate: string,
    endDate: string,
    validationResponse?: string | null,
    productId?: string | null,
    isCancelled: boolean,
    platform: SubscriptionAppType,
    status?: SubscriptionStatus | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePersonSubscriptionsMutationVariables = {
  input: UpdatePersonSubscriptionsInput,
  condition?: ModelPersonSubscriptionsConditionInput | null,
};

export type UpdatePersonSubscriptionsMutation = {
  updatePersonSubscriptions?:  {
    __typename: "PersonSubscriptions",
    id: string,
    clientId?: string | null,
    recruiterId?: string | null,
    talentId?: string | null,
    transactionId: string,
    receipt: string,
    startDate: string,
    endDate: string,
    validationResponse?: string | null,
    productId?: string | null,
    isCancelled: boolean,
    platform: SubscriptionAppType,
    status?: SubscriptionStatus | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePersonSubscriptionsMutationVariables = {
  input: DeletePersonSubscriptionsInput,
  condition?: ModelPersonSubscriptionsConditionInput | null,
};

export type DeletePersonSubscriptionsMutation = {
  deletePersonSubscriptions?:  {
    __typename: "PersonSubscriptions",
    id: string,
    clientId?: string | null,
    recruiterId?: string | null,
    talentId?: string | null,
    transactionId: string,
    receipt: string,
    startDate: string,
    endDate: string,
    validationResponse?: string | null,
    productId?: string | null,
    isCancelled: boolean,
    platform: SubscriptionAppType,
    status?: SubscriptionStatus | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTalentProfileVersionMutationVariables = {
  input: CreateTalentProfileVersionInput,
  condition?: ModelTalentProfileVersionConditionInput | null,
};

export type CreateTalentProfileVersionMutation = {
  createTalentProfileVersion?:  {
    __typename: "TalentProfileVersion",
    id: string,
    name: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentID: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTalentProfileVersionMutationVariables = {
  input: UpdateTalentProfileVersionInput,
  condition?: ModelTalentProfileVersionConditionInput | null,
};

export type UpdateTalentProfileVersionMutation = {
  updateTalentProfileVersion?:  {
    __typename: "TalentProfileVersion",
    id: string,
    name: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentID: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTalentProfileVersionMutationVariables = {
  input: DeleteTalentProfileVersionInput,
  condition?: ModelTalentProfileVersionConditionInput | null,
};

export type DeleteTalentProfileVersionMutation = {
  deleteTalentProfileVersion?:  {
    __typename: "TalentProfileVersion",
    id: string,
    name: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentID: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    content: string,
    senderInfo:  {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    },
    conversationId: string,
    createdAt: string,
    updatedAt: string,
    isRead: boolean,
    readBy?:  Array< {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    } | null > | null,
    version: number,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    content: string,
    senderInfo:  {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    },
    conversationId: string,
    createdAt: string,
    updatedAt: string,
    isRead: boolean,
    readBy?:  Array< {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    } | null > | null,
    version: number,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    content: string,
    senderInfo:  {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    },
    conversationId: string,
    createdAt: string,
    updatedAt: string,
    isRead: boolean,
    readBy?:  Array< {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    } | null > | null,
    version: number,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateConversationMutationVariables = {
  input: CreateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateConversationMutationVariables = {
  input: UpdateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type UpdateConversationMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteConversationMutationVariables = {
  input: DeleteConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type DeleteConversationMutation = {
  deleteConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateConversationParticipantsMutationVariables = {
  input: CreateConversationParticipantsInput,
  condition?: ModelConversationParticipantsConditionInput | null,
};

export type CreateConversationParticipantsMutation = {
  createConversationParticipants?:  {
    __typename: "ConversationParticipants",
    id: string,
    conversationId: string,
    talentId: string,
    clientId: string,
    recruiterId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateConversationParticipantsMutationVariables = {
  input: UpdateConversationParticipantsInput,
  condition?: ModelConversationParticipantsConditionInput | null,
};

export type UpdateConversationParticipantsMutation = {
  updateConversationParticipants?:  {
    __typename: "ConversationParticipants",
    id: string,
    conversationId: string,
    talentId: string,
    clientId: string,
    recruiterId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteConversationParticipantsMutationVariables = {
  input: DeleteConversationParticipantsInput,
  condition?: ModelConversationParticipantsConditionInput | null,
};

export type DeleteConversationParticipantsMutation = {
  deleteConversationParticipants?:  {
    __typename: "ConversationParticipants",
    id: string,
    conversationId: string,
    talentId: string,
    clientId: string,
    recruiterId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateRecruiterMutationVariables = {
  input: CreateRecruiterInput,
  condition?: ModelRecruiterConditionInput | null,
};

export type CreateRecruiterMutation = {
  createRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type UpdateRecruiterMutationVariables = {
  input: UpdateRecruiterInput,
  condition?: ModelRecruiterConditionInput | null,
};

export type UpdateRecruiterMutation = {
  updateRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type DeleteRecruiterMutationVariables = {
  input: DeleteRecruiterInput,
  condition?: ModelRecruiterConditionInput | null,
};

export type DeleteRecruiterMutation = {
  deleteRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type CreateRecruiterProfileMutationVariables = {
  input: CreateRecruiterProfileInput,
  condition?: ModelRecruiterProfileConditionInput | null,
};

export type CreateRecruiterProfileMutation = {
  createRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type UpdateRecruiterProfileMutationVariables = {
  input: UpdateRecruiterProfileInput,
  condition?: ModelRecruiterProfileConditionInput | null,
};

export type UpdateRecruiterProfileMutation = {
  updateRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type DeleteRecruiterProfileMutationVariables = {
  input: DeleteRecruiterProfileInput,
  condition?: ModelRecruiterProfileConditionInput | null,
};

export type DeleteRecruiterProfileMutation = {
  deleteRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type CreateReferralMutationVariables = {
  input: CreateReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type CreateReferralMutation = {
  createReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReferralMutationVariables = {
  input: UpdateReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type UpdateReferralMutation = {
  updateReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReferralMutationVariables = {
  input: DeleteReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type DeleteReferralMutation = {
  deleteReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTalentMutationVariables = {
  input: CreateTalentInput,
  condition?: ModelTalentConditionInput | null,
};

export type CreateTalentMutation = {
  createTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateTalentMutationVariables = {
  input: UpdateTalentInput,
  condition?: ModelTalentConditionInput | null,
};

export type UpdateTalentMutation = {
  updateTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeleteTalentMutationVariables = {
  input: DeleteTalentInput,
  condition?: ModelTalentConditionInput | null,
};

export type DeleteTalentMutation = {
  deleteTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type CreateTalentProfileMutationVariables = {
  input: CreateTalentProfileInput,
  condition?: ModelTalentProfileConditionInput | null,
};

export type CreateTalentProfileMutation = {
  createTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateTalentProfileMutationVariables = {
  input: UpdateTalentProfileInput,
  condition?: ModelTalentProfileConditionInput | null,
};

export type UpdateTalentProfileMutation = {
  updateTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteTalentProfileMutationVariables = {
  input: DeleteTalentProfileInput,
  condition?: ModelTalentProfileConditionInput | null,
};

export type DeleteTalentProfileMutation = {
  deleteTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateForexRatesMutationVariables = {
  input: CreateForexRatesInput,
  condition?: ModelForexRatesConditionInput | null,
};

export type CreateForexRatesMutation = {
  createForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateForexRatesMutationVariables = {
  input: UpdateForexRatesInput,
  condition?: ModelForexRatesConditionInput | null,
};

export type UpdateForexRatesMutation = {
  updateForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteForexRatesMutationVariables = {
  input: DeleteForexRatesInput,
  condition?: ModelForexRatesConditionInput | null,
};

export type DeleteForexRatesMutation = {
  deleteForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateTalentProfileTransientMutationVariables = {
  input: CreateTalentProfileTransientInput,
  condition?: ModelTalentProfileTransientConditionInput | null,
};

export type CreateTalentProfileTransientMutation = {
  createTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateTalentProfileTransientMutationVariables = {
  input: UpdateTalentProfileTransientInput,
  condition?: ModelTalentProfileTransientConditionInput | null,
};

export type UpdateTalentProfileTransientMutation = {
  updateTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteTalentProfileTransientMutationVariables = {
  input: DeleteTalentProfileTransientInput,
  condition?: ModelTalentProfileTransientConditionInput | null,
};

export type DeleteTalentProfileTransientMutation = {
  deleteTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateTalentProfileProcessedIDsMutationVariables = {
  input: CreateTalentProfileProcessedIDsInput,
  condition?: ModelTalentProfileProcessedIDsConditionInput | null,
};

export type CreateTalentProfileProcessedIDsMutation = {
  createTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type UpdateTalentProfileProcessedIDsMutationVariables = {
  input: UpdateTalentProfileProcessedIDsInput,
  condition?: ModelTalentProfileProcessedIDsConditionInput | null,
};

export type UpdateTalentProfileProcessedIDsMutation = {
  updateTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type DeleteTalentProfileProcessedIDsMutationVariables = {
  input: DeleteTalentProfileProcessedIDsInput,
  condition?: ModelTalentProfileProcessedIDsConditionInput | null,
};

export type DeleteTalentProfileProcessedIDsMutation = {
  deleteTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type CreateTalentTypeMutationVariables = {
  input: CreateTalentTypeInput,
  condition?: ModelTalentTypeConditionInput | null,
};

export type CreateTalentTypeMutation = {
  createTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTalentTypeMutationVariables = {
  input: UpdateTalentTypeInput,
  condition?: ModelTalentTypeConditionInput | null,
};

export type UpdateTalentTypeMutation = {
  updateTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTalentTypeMutationVariables = {
  input: DeleteTalentTypeInput,
  condition?: ModelTalentTypeConditionInput | null,
};

export type DeleteTalentTypeMutation = {
  deleteTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type VerifyAndGetAvailableSlotsQueryVariables = {
  token: string,
  utcTime: string,
  talentID: string,
};

export type VerifyAndGetAvailableSlotsQuery = {
  verifyAndGetAvailableSlots?:  {
    __typename: "GetAvailableSlotsResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "SlotsPerDay",
      date?: string | null,
      availableSlots?:  Array< {
        __typename: "SlotType",
        startTime: string,
        endTime: string,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type TrackReferralsQuery = {
  trackReferrals?:  {
    __typename: "TrackReferralsResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "ReferralStats",
      email?: string | null,
      status?: string | null,
      canReinvite?: boolean | null,
      updatedAt?: string | null,
      phoneNo?: string | null,
    } | null > | null,
  } | null,
};

export type GetAllDomainTestsQuery = {
  getAllDomainTests?:  {
    __typename: "DomainTestApiResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "DomainTest",
      id: string,
      unique_id: string,
      name: string,
      duration: number,
      instructions?: string | null,
      state?: string | null,
      locked?: boolean | null,
      tags?: Array< TechDomainSkills | null > | null,
    } | null > | null,
  } | null,
};

export type GetCandidateTestInfoQueryVariables = {
  candidateTests?: DomainTestParam | null,
};

export type GetCandidateTestInfoQuery = {
  getCandidateTestInfo?:  {
    __typename: "GetCandidateTestInfoResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "CandidateTestReport",
      attempt_starttime?: string | null,
      attempt_endtime?: string | null,
      testId?: string | null,
    } | null > | null,
  } | null,
};

export type SearchTalentProfileWithUsdSalaryQueryVariables = {
  input?: SearchTalentProfileWithUsdSalaryInput | null,
  jobID?: string | null,
};

export type SearchTalentProfileWithUsdSalaryQuery = {
  searchTalentProfileWithUsdSalary?:  {
    __typename: "SearchTalentProfileWithUsdSalaryResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "searchTalentProfileData",
      talents?:  Array< {
        __typename: "TalentProfileWithJobMatch",
        talentProfile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        jobMatch?:  {
          __typename: "JobMatch",
          id: string,
          talentID: string,
          jobID: string,
          lastActivity: string,
          stage: matchStage,
          process: matchProcess,
          isRockstar?: boolean | null,
          isPublished: boolean,
          clientFeedback?:  {
            __typename: "ClientFeedback",
            referred?: string | null,
            interviewing?: string | null,
            passTechInterview?: string | null,
            failTechInterview?: string | null,
            passCulturalFitInterview?: string | null,
            failCulturalFitInterview?: string | null,
            passSystemDesignInterview?: string | null,
            failSystemDesignInterview?: string | null,
            rejected?: string | null,
            onboarded?: string | null,
            interested?: string | null,
          } | null,
          isPaidFor?: boolean | null,
          feedback?:  {
            __typename: "JobMatchFeedback",
            communicationSkillsLevel?: number | null,
            techSkillAndDomainKnowledgeLevel?: number | null,
            problemSolvingSkillLevel?: number | null,
            isRejected?: boolean | null,
            otherFeedback?: string | null,
          } | null,
          talentProfileVersionID?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          job?:  {
            __typename: "Job",
            id: string,
            companyID: string,
            status: JobStatus,
            title: string,
            description: string,
            responsibilites: string,
            tags: Array< string | null >,
            requiredTechDomainSkills: Array< TechDomainSkills | null >,
            seniorityLevel: SeniorityLevel,
            isPublic?: boolean | null,
            isPermanent?: boolean | null,
            internalNotes?: string | null,
            department?: JobDepartment | null,
            jobPerks?: Array< JobPerks | null > | null,
            createdAt: string,
            updatedAt: string,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          talentProfileVersion?:  {
            __typename: "TalentProfileVersion",
            id: string,
            name: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentID: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null,
      } | null > | null,
      totalCount?: number | null,
    } | null,
  } | null,
};

export type GetTalentProfileDetailsQueryVariables = {
  input?: GetTalentProfileDetailsInput | null,
};

export type GetTalentProfileDetailsQuery = {
  getTalentProfileDetails?:  {
    __typename: "GetTalentProfileDetailsResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "TalentProfileDetails",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      talent?:  {
        __typename: "TalentDetails",
        id: string,
        fullName?: string | null,
        email?: string | null,
        phone?: string | null,
        country?: Country | null,
        role?: UserRoles | null,
      } | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt?: string | null,
    } | null,
  } | null,
};

export type GetTalentTestReportsQueryVariables = {
  talentId?: string | null,
  status?: string | null,
};

export type GetTalentTestReportsQuery = {
  getTalentTestReports?:  {
    __typename: "GetTalentTestReportResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "GetTalentTestReport",
      attemptStarttime?: string | null,
      attemptEndtime?: string | null,
      testId?: string | null,
      tags?: Array< string | null > | null,
      pdfReportUrl?: string | null,
    } | null > | null,
  } | null,
};

export type GetConversationsByParticipantQueryVariables = {
  input?: GetConversationLambdaInput | null,
};

export type GetConversationsByParticipantQuery = {
  getConversationsByParticipant?:  {
    __typename: "ConversationsListResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "ConversationResponse",
      id?: string | null,
      name?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      isActive?: boolean | null,
      version?: number | null,
      messages?:  Array< {
        __typename: "MessageResponse",
        id?: string | null,
        content?: string | null,
        senderInfo?:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null,
        conversationId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        isRead?: boolean | null,
        version?: number | null,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
      } | null > | null,
      conversationParticipants?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMessagesByConversationQueryVariables = {
  input?: GetMessagesByConversationLambdaInput | null,
};

export type GetMessagesByConversationQuery = {
  getMessagesByConversation?:  {
    __typename: "MessagesListResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "MessageResponse",
      id?: string | null,
      content?: string | null,
      senderInfo?:  {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null,
      conversationId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      isRead?: boolean | null,
      version?: number | null,
      readBy?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMessagesCountByConversationIdQueryVariables = {
  input?: GetMessagesCountByConversationIdLambdaInput | null,
};

export type GetMessagesCountByConversationIdQuery = {
  getMessagesCountByConversationId?:  {
    __typename: "MessagesCountResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "MessageCount",
      count: number,
    } | null,
  } | null,
};

export type GetAllConversationsQueryVariables = {
  input?: GetAllConversationsInput | null,
};

export type GetAllConversationsQuery = {
  getAllConversations?:  {
    __typename: "ConversationsListResponse",
    code?: number | null,
    message?: string | null,
    data?:  Array< {
      __typename: "ConversationResponse",
      id?: string | null,
      name?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      isActive?: boolean | null,
      version?: number | null,
      messages?:  Array< {
        __typename: "MessageResponse",
        id?: string | null,
        content?: string | null,
        senderInfo?:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null,
        conversationId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        isRead?: boolean | null,
        version?: number | null,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
      } | null > | null,
      conversationParticipants?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProductPriceListFromStripeQuery = {
  getProductPriceListFromStripe?:  {
    __typename: "GetProductPriceListFromStripeResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "GetProductPriceListFromStripeResponseData",
      publishableKey?: string | null,
      prices?:  Array< {
        __typename: "StripeProductPrice",
        id?: string | null,
        object?: string | null,
        active?: boolean | null,
        billing_scheme?: string | null,
        created?: number | null,
        currency?: string | null,
        custom_unit_amount?: string | null,
        livemode?: string | null,
        lookup_key?: string | null,
        metadata?: string | null,
        nickname?: string | null,
        product?:  {
          __typename: "StripeProduct",
          id?: string | null,
          object?: string | null,
          active?: boolean | null,
          attributes?: string | null,
          created?: number | null,
          default_price?: string | null,
          description?: string | null,
          images?: string | null,
          livemode?: boolean | null,
          metadata?: string | null,
          name?: string | null,
          package_dimensions?: string | null,
          shippable?: string | null,
          statement_descriptor?: string | null,
          tax_code?: string | null,
          type?: string | null,
          unit_label?: string | null,
          updated?: string | null,
          url?: string | null,
        } | null,
        recurring?:  {
          __typename: "StripeRecurring",
          aggregate_usage?: string | null,
          interval?: string | null,
          interval_count?: number | null,
          trial_period_days?: string | null,
          usage_type?: string | null,
        } | null,
        tax_behavior?: string | null,
        tiers_mode?: string | null,
        transform_quantity?: string | null,
        type?: string | null,
        unit_amount?: number | null,
        unit_amount_decimal?: number | null,
      } > | null,
    } | null,
  } | null,
};

export type GetMandateAndPaymentMethodQuery = {
  getMandateAndPaymentMethod?:  {
    __typename: "GetMandateAndPaymentMethodResponse",
    code?: number | null,
    message?: string | null,
    data?:  {
      __typename: "MandateAndPaymentMethodResponse",
      mandate:  {
        __typename: "StripeMandateResponse",
        customerAcceptance:  {
          __typename: "StripeMandateAcceptanceResponse",
          acceptedAt: string,
        },
      },
      paymentMethod:  {
        __typename: "StripePaymentMethodResponse",
        id: string,
        usBankAccount:  {
          __typename: "StripeUSBankAccountResponse",
          last4: string,
          bankName: string,
          accountType: string,
        },
      },
    } | null,
  } | null,
};

export type GetStripePublishableKeyQuery = {
  getStripePublishableKey?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GetBillingToggleQuery = {
  getBillingToggle?:  {
    __typename: "LambdaStringResponse",
    code?: number | null,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GetWorkableJobQueryVariables = {
  input: GetWorkableJobInput,
};

export type GetWorkableJobQuery = {
  getWorkableJob?:  {
    __typename: "GetWorkableJobResponse",
    code: number,
    data?:  {
      __typename: "WorkableJob",
      id?: string | null,
      title?: string | null,
      full_title?: string | null,
      shortcode?: string | null,
      code?: string | null,
      url?: string | null,
      application_url?: string | null,
      shortlink?: string | null,
      created_at?: string | null,
      full_description?: string | null,
      description?: string | null,
      requirements?: string | null,
      benefits?: string | null,
      employment_type?: string | null,
      industry?: string | null,
      function?: string | null,
      experience?: string | null,
      education?: string | null,
      keywords?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetUserCanSignUpStatusQueryVariables = {
  email: string,
};

export type GetUserCanSignUpStatusQuery = {
  getUserCanSignUpStatus?:  {
    __typename: "GetUserCanSignUpStatusResponse",
    httpStatusCode: number,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type GetAnalyticsQueryVariables = {
  id: string,
};

export type GetAnalyticsQuery = {
  getAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListAnalyticsQueryVariables = {
  filter?: ModelAnalyticsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnalyticsQuery = {
  listAnalytics?:  {
    __typename: "ModelAnalyticsConnection",
    items:  Array< {
      __typename: "Analytics",
      id: string,
      developersCompletedProfiles?: number | null,
      developersJoined: number,
      recruitersJoined: number,
      hiredToday?: number | null,
      takenDomainTests: number,
      passedDomainTests: number,
      takenProblemSolvingTests: number,
      passedProblemSolving: number,
      developersReferredByRecruiters: number,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoicesByClientQueryVariables = {
  clientId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetInvoicesByClientQuery = {
  getInvoicesByClient?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoicesByBatchQueryVariables = {
  invoiceBatchId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetInvoicesByBatchQuery = {
  getInvoicesByBatch?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoiceBatchQueryVariables = {
  id: string,
};

export type GetInvoiceBatchQuery = {
  getInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListInvoiceBatchesQueryVariables = {
  filter?: ModelInvoiceBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoiceBatchesQuery = {
  listInvoiceBatches?:  {
    __typename: "ModelInvoiceBatchConnection",
    items:  Array< {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoiceBatchBySortHashKeyQueryVariables = {
  sortHashKey?: SortHashKey | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetInvoiceBatchBySortHashKeyQuery = {
  getInvoiceBatchBySortHashKey?:  {
    __typename: "ModelInvoiceBatchConnection",
    items:  Array< {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      invoiceId: string,
      amount: number,
      transactionId: string,
      paymentStatus: PaymentStatus,
      paymentMethod: CustomerPaymentMethod,
      reason?: string | null,
      createdAt: string,
      updatedAt: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        clientId?: string | null,
        invoiceBatchId: string,
        invoiceNumber: string,
        amount: number,
        status: InvoiceStatus,
        reason?: string | null,
        stripeInvoiceId?: string | null,
        stripeInvoicePdfUrl?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        invoiceBatch?:  {
          __typename: "InvoiceBatch",
          id: string,
          csvFileUrl: string,
          createdBy: string,
          approvedBy?: string | null,
          status: InvoiceBatchStatus,
          invoiceBatchNumber: number,
          createdAt?: string | null,
          sortHashKey: SortHashKey,
          errorMsg?: string | null,
          invoicesToProcess?: number | null,
          invoicesProcessed?: number | null,
          updatedAt: string,
          version: number,
        } | null,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentByInvoiceQueryVariables = {
  invoiceId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPaymentByInvoiceQuery = {
  getPaymentByInvoice?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      invoiceId: string,
      amount: number,
      transactionId: string,
      paymentStatus: PaymentStatus,
      paymentMethod: CustomerPaymentMethod,
      reason?: string | null,
      createdAt: string,
      updatedAt: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        clientId?: string | null,
        invoiceBatchId: string,
        invoiceNumber: string,
        amount: number,
        status: InvoiceStatus,
        reason?: string | null,
        stripeInvoiceId?: string | null,
        stripeInvoicePdfUrl?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        invoiceBatch?:  {
          __typename: "InvoiceBatch",
          id: string,
          csvFileUrl: string,
          createdBy: string,
          approvedBy?: string | null,
          status: InvoiceBatchStatus,
          invoiceBatchNumber: number,
          createdAt?: string | null,
          sortHashKey: SortHashKey,
          errorMsg?: string | null,
          invoicesToProcess?: number | null,
          invoicesProcessed?: number | null,
          updatedAt: string,
          version: number,
        } | null,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentByTransactionQueryVariables = {
  transactionId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPaymentByTransactionQuery = {
  getPaymentByTransaction?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      invoiceId: string,
      amount: number,
      transactionId: string,
      paymentStatus: PaymentStatus,
      paymentMethod: CustomerPaymentMethod,
      reason?: string | null,
      createdAt: string,
      updatedAt: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        clientId?: string | null,
        invoiceBatchId: string,
        invoiceNumber: string,
        amount: number,
        status: InvoiceStatus,
        reason?: string | null,
        stripeInvoiceId?: string | null,
        stripeInvoicePdfUrl?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        invoiceBatch?:  {
          __typename: "InvoiceBatch",
          id: string,
          csvFileUrl: string,
          createdBy: string,
          approvedBy?: string | null,
          status: InvoiceBatchStatus,
          invoiceBatchNumber: number,
          createdAt?: string | null,
          sortHashKey: SortHashKey,
          errorMsg?: string | null,
          invoicesToProcess?: number | null,
          invoicesProcessed?: number | null,
          updatedAt: string,
          version: number,
        } | null,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripeSetupIntentQueryVariables = {
  id: string,
};

export type GetStripeSetupIntentQuery = {
  getStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type ListStripeSetupIntentsQueryVariables = {
  filter?: ModelStripeSetupIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStripeSetupIntentsQuery = {
  listStripeSetupIntents?:  {
    __typename: "ModelStripeSetupIntentConnection",
    items:  Array< {
      __typename: "StripeSetupIntent",
      id: string,
      status: StripeSetupIntentStatus,
      paymentMethodId?: string | null,
      setupIntentId: string,
      mandateId?: string | null,
      mandateStatus: MandateStatus,
      createdAt?: string | null,
      clientId: string,
      clientSecret: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripeSetupIntentByPaymentMethodIdQueryVariables = {
  paymentMethodId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStripeSetupIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStripeSetupIntentByPaymentMethodIdQuery = {
  getStripeSetupIntentByPaymentMethodId?:  {
    __typename: "ModelStripeSetupIntentConnection",
    items:  Array< {
      __typename: "StripeSetupIntent",
      id: string,
      status: StripeSetupIntentStatus,
      paymentMethodId?: string | null,
      setupIntentId: string,
      mandateId?: string | null,
      mandateStatus: MandateStatus,
      createdAt?: string | null,
      clientId: string,
      clientSecret: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripeSetupIntentBySetupIntentIdQueryVariables = {
  setupIntentId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStripeSetupIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStripeSetupIntentBySetupIntentIdQuery = {
  getStripeSetupIntentBySetupIntentId?:  {
    __typename: "ModelStripeSetupIntentConnection",
    items:  Array< {
      __typename: "StripeSetupIntent",
      id: string,
      status: StripeSetupIntentStatus,
      paymentMethodId?: string | null,
      setupIntentId: string,
      mandateId?: string | null,
      mandateStatus: MandateStatus,
      createdAt?: string | null,
      clientId: string,
      clientSecret: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripeSetupIntentByClientIdQueryVariables = {
  clientId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStripeSetupIntentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStripeSetupIntentByClientIdQuery = {
  getStripeSetupIntentByClientId?:  {
    __typename: "ModelStripeSetupIntentConnection",
    items:  Array< {
      __typename: "StripeSetupIntent",
      id: string,
      status: StripeSetupIntentStatus,
      paymentMethodId?: string | null,
      setupIntentId: string,
      mandateId?: string | null,
      mandateStatus: MandateStatus,
      createdAt?: string | null,
      clientId: string,
      clientSecret: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCalendarBookingQueryVariables = {
  id: string,
};

export type GetCalendarBookingQuery = {
  getCalendarBooking?:  {
    __typename: "CalendarBooking",
    id: string,
    clientId: string,
    eventId: string,
    startTime?: string | null,
    endTime?: string | null,
    meetingLink?: string | null,
    talentId: string,
    jobMatchId: string,
    inviteToken?: string | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    engineerId?: string | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListCalendarBookingsQueryVariables = {
  filter?: ModelCalendarBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalendarBookingsQuery = {
  listCalendarBookings?:  {
    __typename: "ModelCalendarBookingConnection",
    items:  Array< {
      __typename: "CalendarBooking",
      id: string,
      clientId: string,
      eventId: string,
      startTime?: string | null,
      endTime?: string | null,
      meetingLink?: string | null,
      talentId: string,
      jobMatchId: string,
      inviteToken?: string | null,
      feedback?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      engineerId?: string | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    companyID?: string | null,
    timezone?: string | null,
    availability?:  Array< {
      __typename: "ClientAvailability",
      day: number,
      date: string,
      availableSlots:  Array< {
        __typename: "AvailableSlot",
        startTime: string,
        endTime: string,
      } >,
    } > | null,
    googleCalendarId?: string | null,
    remainingCredits?: number | null,
    totalCredits?: number | null,
    stripeCustomerId?: string | null,
    currentStripeSubscriptionId?: string | null,
    hasMandate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClientByCompanyQueryVariables = {
  companyID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClientByCompanyQuery = {
  clientByCompany?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClientByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClientByEmailQuery = {
  clientByEmail?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientCalendarInviteQueryVariables = {
  id: string,
};

export type GetClientCalendarInviteQuery = {
  getClientCalendarInvite?:  {
    __typename: "ClientCalendarInvite",
    id: string,
    clientID: string,
    title: string,
    clientEmail: string,
    eventLength: number,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListClientCalendarInvitesQueryVariables = {
  filter?: ModelClientCalendarInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientCalendarInvitesQuery = {
  listClientCalendarInvites?:  {
    __typename: "ModelClientCalendarInviteConnection",
    items:  Array< {
      __typename: "ClientCalendarInvite",
      id: string,
      clientID: string,
      title: string,
      clientEmail: string,
      eventLength: number,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CalEventsForClientQueryVariables = {
  clientID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClientCalendarInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CalEventsForClientQuery = {
  calEventsForClient?:  {
    __typename: "ModelClientCalendarInviteConnection",
    items:  Array< {
      __typename: "ClientCalendarInvite",
      id: string,
      clientID: string,
      title: string,
      clientEmail: string,
      eventLength: number,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompaniesByAssociationQueryVariables = {
  associatedWith?: companyAssociation | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompaniesByAssociationQuery = {
  companiesByAssociation?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyByNameQuery = {
  companyByName?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      companyID: string,
      status: JobStatus,
      title: string,
      description: string,
      responsibilites: string,
      tags: Array< string | null >,
      requiredTechDomainSkills: Array< TechDomainSkills | null >,
      seniorityLevel: SeniorityLevel,
      isPublic?: boolean | null,
      isPermanent?: boolean | null,
      internalNotes?: string | null,
      department?: JobDepartment | null,
      jobPerks?: Array< JobPerks | null > | null,
      createdAt: string,
      updatedAt: string,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobsByCompanyIDQueryVariables = {
  companyID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetJobsByCompanyIDQuery = {
  getJobsByCompanyID?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      companyID: string,
      status: JobStatus,
      title: string,
      description: string,
      responsibilites: string,
      tags: Array< string | null >,
      requiredTechDomainSkills: Array< TechDomainSkills | null >,
      seniorityLevel: SeniorityLevel,
      isPublic?: boolean | null,
      isPermanent?: boolean | null,
      internalNotes?: string | null,
      department?: JobDepartment | null,
      jobPerks?: Array< JobPerks | null > | null,
      createdAt: string,
      updatedAt: string,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobMatchQueryVariables = {
  id: string,
};

export type GetJobMatchQuery = {
  getJobMatch?:  {
    __typename: "JobMatch",
    id: string,
    talentID: string,
    jobID: string,
    lastActivity: string,
    stage: matchStage,
    process: matchProcess,
    isRockstar?: boolean | null,
    isPublished: boolean,
    clientFeedback?:  {
      __typename: "ClientFeedback",
      referred?: string | null,
      interviewing?: string | null,
      passTechInterview?: string | null,
      failTechInterview?: string | null,
      passCulturalFitInterview?: string | null,
      failCulturalFitInterview?: string | null,
      passSystemDesignInterview?: string | null,
      failSystemDesignInterview?: string | null,
      rejected?: string | null,
      onboarded?: string | null,
      interested?: string | null,
    } | null,
    isPaidFor?: boolean | null,
    feedback?:  {
      __typename: "JobMatchFeedback",
      communicationSkillsLevel?: number | null,
      techSkillAndDomainKnowledgeLevel?: number | null,
      problemSolvingSkillLevel?: number | null,
      isRejected?: boolean | null,
      otherFeedback?: string | null,
    } | null,
    talentProfileVersionID?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    job?:  {
      __typename: "Job",
      id: string,
      companyID: string,
      status: JobStatus,
      title: string,
      description: string,
      responsibilites: string,
      tags: Array< string | null >,
      requiredTechDomainSkills: Array< TechDomainSkills | null >,
      seniorityLevel: SeniorityLevel,
      isPublic?: boolean | null,
      isPermanent?: boolean | null,
      internalNotes?: string | null,
      department?: JobDepartment | null,
      jobPerks?: Array< JobPerks | null > | null,
      createdAt: string,
      updatedAt: string,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    talentProfileVersion?:  {
      __typename: "TalentProfileVersion",
      id: string,
      name: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentID: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListJobMatchesQueryVariables = {
  filter?: ModelJobMatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobMatchesQuery = {
  listJobMatches?:  {
    __typename: "ModelJobMatchConnection",
    items:  Array< {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MatchesForJobQueryVariables = {
  jobID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobMatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MatchesForJobQuery = {
  matchesForJob?:  {
    __typename: "ModelJobMatchConnection",
    items:  Array< {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MatchesForJobAndTalentQueryVariables = {
  jobID?: string | null,
  talentID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobMatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MatchesForJobAndTalentQuery = {
  matchesForJobAndTalent?:  {
    __typename: "ModelJobMatchConnection",
    items:  Array< {
      __typename: "JobMatch",
      id: string,
      talentID: string,
      jobID: string,
      lastActivity: string,
      stage: matchStage,
      process: matchProcess,
      isRockstar?: boolean | null,
      isPublished: boolean,
      clientFeedback?:  {
        __typename: "ClientFeedback",
        referred?: string | null,
        interviewing?: string | null,
        passTechInterview?: string | null,
        failTechInterview?: string | null,
        passCulturalFitInterview?: string | null,
        failCulturalFitInterview?: string | null,
        passSystemDesignInterview?: string | null,
        failSystemDesignInterview?: string | null,
        rejected?: string | null,
        onboarded?: string | null,
        interested?: string | null,
      } | null,
      isPaidFor?: boolean | null,
      feedback?:  {
        __typename: "JobMatchFeedback",
        communicationSkillsLevel?: number | null,
        techSkillAndDomainKnowledgeLevel?: number | null,
        problemSolvingSkillLevel?: number | null,
        isRejected?: boolean | null,
        otherFeedback?: string | null,
      } | null,
      talentProfileVersionID?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      job?:  {
        __typename: "Job",
        id: string,
        companyID: string,
        status: JobStatus,
        title: string,
        description: string,
        responsibilites: string,
        tags: Array< string | null >,
        requiredTechDomainSkills: Array< TechDomainSkills | null >,
        seniorityLevel: SeniorityLevel,
        isPublic?: boolean | null,
        isPermanent?: boolean | null,
        internalNotes?: string | null,
        department?: JobDepartment | null,
        jobPerks?: Array< JobPerks | null > | null,
        createdAt: string,
        updatedAt: string,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      talentProfileVersion?:  {
        __typename: "TalentProfileVersion",
        id: string,
        name: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentID: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPersonSubscriptionsQueryVariables = {
  id: string,
};

export type GetPersonSubscriptionsQuery = {
  getPersonSubscriptions?:  {
    __typename: "PersonSubscriptions",
    id: string,
    clientId?: string | null,
    recruiterId?: string | null,
    talentId?: string | null,
    transactionId: string,
    receipt: string,
    startDate: string,
    endDate: string,
    validationResponse?: string | null,
    productId?: string | null,
    isCancelled: boolean,
    platform: SubscriptionAppType,
    status?: SubscriptionStatus | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListPersonSubscriptionsQueryVariables = {
  filter?: ModelPersonSubscriptionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPersonSubscriptionsQuery = {
  listPersonSubscriptions?:  {
    __typename: "ModelPersonSubscriptionsConnection",
    items:  Array< {
      __typename: "PersonSubscriptions",
      id: string,
      clientId?: string | null,
      recruiterId?: string | null,
      talentId?: string | null,
      transactionId: string,
      receipt: string,
      startDate: string,
      endDate: string,
      validationResponse?: string | null,
      productId?: string | null,
      isCancelled: boolean,
      platform: SubscriptionAppType,
      status?: SubscriptionStatus | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClientSubscriptionsByTransactionIdQueryVariables = {
  transactionId?: string | null,
  clientId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonSubscriptionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClientSubscriptionsByTransactionIdQuery = {
  clientSubscriptionsByTransactionId?:  {
    __typename: "ModelPersonSubscriptionsConnection",
    items:  Array< {
      __typename: "PersonSubscriptions",
      id: string,
      clientId?: string | null,
      recruiterId?: string | null,
      talentId?: string | null,
      transactionId: string,
      receipt: string,
      startDate: string,
      endDate: string,
      validationResponse?: string | null,
      productId?: string | null,
      isCancelled: boolean,
      platform: SubscriptionAppType,
      status?: SubscriptionStatus | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecruiterSubscriptionsByTransactionIdQueryVariables = {
  transactionId?: string | null,
  recruiterId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonSubscriptionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecruiterSubscriptionsByTransactionIdQuery = {
  recruiterSubscriptionsByTransactionId?:  {
    __typename: "ModelPersonSubscriptionsConnection",
    items:  Array< {
      __typename: "PersonSubscriptions",
      id: string,
      clientId?: string | null,
      recruiterId?: string | null,
      talentId?: string | null,
      transactionId: string,
      receipt: string,
      startDate: string,
      endDate: string,
      validationResponse?: string | null,
      productId?: string | null,
      isCancelled: boolean,
      platform: SubscriptionAppType,
      status?: SubscriptionStatus | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TalentSubscriptionsByTransactionIdQueryVariables = {
  transactionId?: string | null,
  talentId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonSubscriptionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TalentSubscriptionsByTransactionIdQuery = {
  talentSubscriptionsByTransactionId?:  {
    __typename: "ModelPersonSubscriptionsConnection",
    items:  Array< {
      __typename: "PersonSubscriptions",
      id: string,
      clientId?: string | null,
      recruiterId?: string | null,
      talentId?: string | null,
      transactionId: string,
      receipt: string,
      startDate: string,
      endDate: string,
      validationResponse?: string | null,
      productId?: string | null,
      isCancelled: boolean,
      platform: SubscriptionAppType,
      status?: SubscriptionStatus | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SubscriptionsByTransactionIdQueryVariables = {
  transactionId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonSubscriptionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubscriptionsByTransactionIdQuery = {
  subscriptionsByTransactionId?:  {
    __typename: "ModelPersonSubscriptionsConnection",
    items:  Array< {
      __typename: "PersonSubscriptions",
      id: string,
      clientId?: string | null,
      recruiterId?: string | null,
      talentId?: string | null,
      transactionId: string,
      receipt: string,
      startDate: string,
      endDate: string,
      validationResponse?: string | null,
      productId?: string | null,
      isCancelled: boolean,
      platform: SubscriptionAppType,
      status?: SubscriptionStatus | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentProfileVersionQueryVariables = {
  id: string,
};

export type GetTalentProfileVersionQuery = {
  getTalentProfileVersion?:  {
    __typename: "TalentProfileVersion",
    id: string,
    name: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentID: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListTalentProfileVersionsQueryVariables = {
  filter?: ModelTalentProfileVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTalentProfileVersionsQuery = {
  listTalentProfileVersions?:  {
    __typename: "ModelTalentProfileVersionConnection",
    items:  Array< {
      __typename: "TalentProfileVersion",
      id: string,
      name: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentID: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProfileVersionsForTalentQueryVariables = {
  talentID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTalentProfileVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProfileVersionsForTalentQuery = {
  profileVersionsForTalent?:  {
    __typename: "ModelTalentProfileVersionConnection",
    items:  Array< {
      __typename: "TalentProfileVersion",
      id: string,
      name: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentID: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    content: string,
    senderInfo:  {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    },
    conversationId: string,
    createdAt: string,
    updatedAt: string,
    isRead: boolean,
    readBy?:  Array< {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    } | null > | null,
    version: number,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      content: string,
      senderInfo:  {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      },
      conversationId: string,
      createdAt: string,
      updatedAt: string,
      isRead: boolean,
      readBy?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
      version: number,
      conversation?:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
        isActive: boolean,
        lastMessageAt?: string | null,
        version: number,
        messages?:  {
          __typename: "ModelMessageConnection",
          items:  Array< {
            __typename: "Message",
            id: string,
            content: string,
            senderInfo:  {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            },
            conversationId: string,
            createdAt: string,
            updatedAt: string,
            isRead: boolean,
            readBy?:  Array< {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            } | null > | null,
            version: number,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        conversationParticipants?:  {
          __typename: "ModelConversationParticipantsConnection",
          items:  Array< {
            __typename: "ConversationParticipants",
            id: string,
            conversationId: string,
            talentId: string,
            clientId: string,
            recruiterId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            client?:  {
              __typename: "Client",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              companyID?: string | null,
              timezone?: string | null,
              googleCalendarId?: string | null,
              remainingCredits?: number | null,
              totalCredits?: number | null,
              stripeCustomerId?: string | null,
              currentStripeSubscriptionId?: string | null,
              hasMandate?: boolean | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
            recruiter?:  {
              __typename: "Recruiter",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessagesByConversationIDQueryVariables = {
  conversationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMessagesByConversationIDQuery = {
  getMessagesByConversationID?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      content: string,
      senderInfo:  {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      },
      conversationId: string,
      createdAt: string,
      updatedAt: string,
      isRead: boolean,
      readBy?:  Array< {
        __typename: "PersonInfo",
        id: string,
        fullName: string,
        email?: string | null,
        phone?: string | null,
        role: UserRoles,
      } | null > | null,
      version: number,
      conversation?:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
        isActive: boolean,
        lastMessageAt?: string | null,
        version: number,
        messages?:  {
          __typename: "ModelMessageConnection",
          items:  Array< {
            __typename: "Message",
            id: string,
            content: string,
            senderInfo:  {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            },
            conversationId: string,
            createdAt: string,
            updatedAt: string,
            isRead: boolean,
            readBy?:  Array< {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            } | null > | null,
            version: number,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        conversationParticipants?:  {
          __typename: "ModelConversationParticipantsConnection",
          items:  Array< {
            __typename: "ConversationParticipants",
            id: string,
            conversationId: string,
            talentId: string,
            clientId: string,
            recruiterId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            client?:  {
              __typename: "Client",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              companyID?: string | null,
              timezone?: string | null,
              googleCalendarId?: string | null,
              remainingCredits?: number | null,
              totalCredits?: number | null,
              stripeCustomerId?: string | null,
              currentStripeSubscriptionId?: string | null,
              hasMandate?: boolean | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
            recruiter?:  {
              __typename: "Recruiter",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationsQuery = {
  listConversations?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConversationParticipantsQueryVariables = {
  id: string,
};

export type GetConversationParticipantsQuery = {
  getConversationParticipants?:  {
    __typename: "ConversationParticipants",
    id: string,
    conversationId: string,
    talentId: string,
    clientId: string,
    recruiterId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListConversationParticipantsQueryVariables = {
  filter?: ModelConversationParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationParticipantsQuery = {
  listConversationParticipants?:  {
    __typename: "ModelConversationParticipantsConnection",
    items:  Array< {
      __typename: "ConversationParticipants",
      id: string,
      conversationId: string,
      talentId: string,
      clientId: string,
      recruiterId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      conversation?:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
        isActive: boolean,
        lastMessageAt?: string | null,
        version: number,
        messages?:  {
          __typename: "ModelMessageConnection",
          items:  Array< {
            __typename: "Message",
            id: string,
            content: string,
            senderInfo:  {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            },
            conversationId: string,
            createdAt: string,
            updatedAt: string,
            isRead: boolean,
            readBy?:  Array< {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            } | null > | null,
            version: number,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        conversationParticipants?:  {
          __typename: "ModelConversationParticipantsConnection",
          items:  Array< {
            __typename: "ConversationParticipants",
            id: string,
            conversationId: string,
            talentId: string,
            clientId: string,
            recruiterId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            client?:  {
              __typename: "Client",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              companyID?: string | null,
              timezone?: string | null,
              googleCalendarId?: string | null,
              remainingCredits?: number | null,
              totalCredits?: number | null,
              stripeCustomerId?: string | null,
              currentStripeSubscriptionId?: string | null,
              hasMandate?: boolean | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
            recruiter?:  {
              __typename: "Recruiter",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentConversationsQueryVariables = {
  talentId?: string | null,
  conversationId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTalentConversationsQuery = {
  getTalentConversations?:  {
    __typename: "ModelConversationParticipantsConnection",
    items:  Array< {
      __typename: "ConversationParticipants",
      id: string,
      conversationId: string,
      talentId: string,
      clientId: string,
      recruiterId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      conversation?:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
        isActive: boolean,
        lastMessageAt?: string | null,
        version: number,
        messages?:  {
          __typename: "ModelMessageConnection",
          items:  Array< {
            __typename: "Message",
            id: string,
            content: string,
            senderInfo:  {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            },
            conversationId: string,
            createdAt: string,
            updatedAt: string,
            isRead: boolean,
            readBy?:  Array< {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            } | null > | null,
            version: number,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        conversationParticipants?:  {
          __typename: "ModelConversationParticipantsConnection",
          items:  Array< {
            __typename: "ConversationParticipants",
            id: string,
            conversationId: string,
            talentId: string,
            clientId: string,
            recruiterId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            client?:  {
              __typename: "Client",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              companyID?: string | null,
              timezone?: string | null,
              googleCalendarId?: string | null,
              remainingCredits?: number | null,
              totalCredits?: number | null,
              stripeCustomerId?: string | null,
              currentStripeSubscriptionId?: string | null,
              hasMandate?: boolean | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
            recruiter?:  {
              __typename: "Recruiter",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientConversationsQueryVariables = {
  clientId?: string | null,
  conversationId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetClientConversationsQuery = {
  getClientConversations?:  {
    __typename: "ModelConversationParticipantsConnection",
    items:  Array< {
      __typename: "ConversationParticipants",
      id: string,
      conversationId: string,
      talentId: string,
      clientId: string,
      recruiterId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      conversation?:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
        isActive: boolean,
        lastMessageAt?: string | null,
        version: number,
        messages?:  {
          __typename: "ModelMessageConnection",
          items:  Array< {
            __typename: "Message",
            id: string,
            content: string,
            senderInfo:  {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            },
            conversationId: string,
            createdAt: string,
            updatedAt: string,
            isRead: boolean,
            readBy?:  Array< {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            } | null > | null,
            version: number,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        conversationParticipants?:  {
          __typename: "ModelConversationParticipantsConnection",
          items:  Array< {
            __typename: "ConversationParticipants",
            id: string,
            conversationId: string,
            talentId: string,
            clientId: string,
            recruiterId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            client?:  {
              __typename: "Client",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              companyID?: string | null,
              timezone?: string | null,
              googleCalendarId?: string | null,
              remainingCredits?: number | null,
              totalCredits?: number | null,
              stripeCustomerId?: string | null,
              currentStripeSubscriptionId?: string | null,
              hasMandate?: boolean | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
            recruiter?:  {
              __typename: "Recruiter",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecruiterConversationsQueryVariables = {
  recruiterId?: string | null,
  conversationId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRecruiterConversationsQuery = {
  getRecruiterConversations?:  {
    __typename: "ModelConversationParticipantsConnection",
    items:  Array< {
      __typename: "ConversationParticipants",
      id: string,
      conversationId: string,
      talentId: string,
      clientId: string,
      recruiterId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      conversation?:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
        isActive: boolean,
        lastMessageAt?: string | null,
        version: number,
        messages?:  {
          __typename: "ModelMessageConnection",
          items:  Array< {
            __typename: "Message",
            id: string,
            content: string,
            senderInfo:  {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            },
            conversationId: string,
            createdAt: string,
            updatedAt: string,
            isRead: boolean,
            readBy?:  Array< {
              __typename: "PersonInfo",
              id: string,
              fullName: string,
              email?: string | null,
              phone?: string | null,
              role: UserRoles,
            } | null > | null,
            version: number,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        conversationParticipants?:  {
          __typename: "ModelConversationParticipantsConnection",
          items:  Array< {
            __typename: "ConversationParticipants",
            id: string,
            conversationId: string,
            talentId: string,
            clientId: string,
            recruiterId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
            client?:  {
              __typename: "Client",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              companyID?: string | null,
              timezone?: string | null,
              googleCalendarId?: string | null,
              remainingCredits?: number | null,
              totalCredits?: number | null,
              stripeCustomerId?: string | null,
              currentStripeSubscriptionId?: string | null,
              hasMandate?: boolean | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            conversation?:  {
              __typename: "Conversation",
              id: string,
              name?: string | null,
              createdAt: string,
              updatedAt: string,
              isActive: boolean,
              lastMessageAt?: string | null,
              version: number,
            } | null,
            recruiter?:  {
              __typename: "Recruiter",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecruiterQueryVariables = {
  id: string,
};

export type GetRecruiterQuery = {
  getRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type ListRecruitersQueryVariables = {
  filter?: ModelRecruiterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecruitersQuery = {
  listRecruiters?:  {
    __typename: "ModelRecruiterConnection",
    items:  Array< {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecruiterByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecruiterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecruiterByEmailQuery = {
  recruiterByEmail?:  {
    __typename: "ModelRecruiterConnection",
    items:  Array< {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecruiterProfileQueryVariables = {
  id: string,
};

export type GetRecruiterProfileQuery = {
  getRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type ListRecruiterProfilesQueryVariables = {
  filter?: ModelRecruiterProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecruiterProfilesQuery = {
  listRecruiterProfiles?:  {
    __typename: "ModelRecruiterProfileConnection",
    items:  Array< {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReferralQueryVariables = {
  id: string,
};

export type GetReferralQuery = {
  getReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListReferralsQueryVariables = {
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsQuery = {
  listReferrals?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      referrerId?: string | null,
      referralReferredId?: string | null,
      recruiterId?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      referrer?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      referred?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReferralByReferrerQueryVariables = {
  referrerId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReferralByReferrerQuery = {
  referralByReferrer?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      referrerId?: string | null,
      referralReferredId?: string | null,
      recruiterId?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      referrer?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      referred?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReferralByRecruiterQueryVariables = {
  recruiterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReferralByRecruiterQuery = {
  referralByRecruiter?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      referrerId?: string | null,
      referralReferredId?: string | null,
      recruiterId?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiter?:  {
        __typename: "Recruiter",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "RecruiterProfile",
          id: string,
          currentEarnings: number,
          profession?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          recruiterProfileRecruiterId?: string | null,
        } | null,
      } | null,
      referrer?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      referred?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentQueryVariables = {
  id: string,
};

export type GetTalentQuery = {
  getTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListTalentsQueryVariables = {
  filter?: ModelTalentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTalentsQuery = {
  listTalents?:  {
    __typename: "ModelTalentConnection",
    items:  Array< {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TalentByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTalentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TalentByEmailQuery = {
  talentByEmail?:  {
    __typename: "ModelTalentConnection",
    items:  Array< {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentProfileQueryVariables = {
  id: string,
};

export type GetTalentProfileQuery = {
  getTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListTalentProfilesQueryVariables = {
  filter?: ModelTalentProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTalentProfilesQuery = {
  listTalentProfiles?:  {
    __typename: "ModelTalentProfileConnection",
    items:  Array< {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetForexRatesQueryVariables = {
  id: string,
};

export type GetForexRatesQuery = {
  getForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListForexRatesQueryVariables = {
  filter?: ModelForexRatesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForexRatesQuery = {
  listForexRates?:  {
    __typename: "ModelForexRatesConnection",
    items:  Array< {
      __typename: "ForexRates",
      id: string,
      currencyType: string,
      usdConversionRate: number,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentProfileTransientQueryVariables = {
  id: string,
};

export type GetTalentProfileTransientQuery = {
  getTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListTalentProfileTransientsQueryVariables = {
  filter?: ModelTalentProfileTransientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTalentProfileTransientsQuery = {
  listTalentProfileTransients?:  {
    __typename: "ModelTalentProfileTransientConnection",
    items:  Array< {
      __typename: "TalentProfileTransient",
      id?: string | null,
      skills?: Array< string | null > | null,
      experience?: string | null,
      isAvailable?: boolean | null,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      talentProfileTalentId?: string | null,
      source?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentProfileProcessedIDsQueryVariables = {
  id: string,
};

export type GetTalentProfileProcessedIDsQuery = {
  getTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type ListTalentProfileProcessedIDsQueryVariables = {
  filter?: ModelTalentProfileProcessedIDsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTalentProfileProcessedIDsQuery = {
  listTalentProfileProcessedIDs?:  {
    __typename: "ModelTalentProfileProcessedIDsConnection",
    items:  Array< {
      __typename: "TalentProfileProcessedIDs",
      id?: string | null,
      talentProfileId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talentProfileTalentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProcessedByProfileIdQueryVariables = {
  talentProfileId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTalentProfileProcessedIDsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProcessedByProfileIdQuery = {
  processedByProfileId?:  {
    __typename: "ModelTalentProfileProcessedIDsConnection",
    items:  Array< {
      __typename: "TalentProfileProcessedIDs",
      id?: string | null,
      talentProfileId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
      talentProfileTalentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentTypeQueryVariables = {
  id: string,
};

export type GetTalentTypeQuery = {
  getTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTalentTypesQueryVariables = {
  filter?: ModelTalentTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTalentTypesQuery = {
  listTalentTypes?:  {
    __typename: "ModelTalentTypeConnection",
    items:  Array< {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTalentTypeSortedQueryVariables = {
  sortType?: string | null,
  sequence?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTalentTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTalentTypeSortedQuery = {
  getTalentTypeSorted?:  {
    __typename: "ModelTalentTypeConnection",
    items:  Array< {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    content: string,
    senderInfo:  {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    },
    conversationId: string,
    createdAt: string,
    updatedAt: string,
    isRead: boolean,
    readBy?:  Array< {
      __typename: "PersonInfo",
      id: string,
      fullName: string,
      email?: string | null,
      phone?: string | null,
      role: UserRoles,
    } | null > | null,
    version: number,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateConversationParticipantSubscriptionVariables = {
  talentId?: string | null,
  clientId?: string | null,
  recruiterId?: string | null,
};

export type OnCreateConversationParticipantSubscription = {
  onCreateConversationParticipant?:  {
    __typename: "ConversationParticipants",
    id: string,
    conversationId: string,
    talentId: string,
    clientId: string,
    recruiterId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    conversation?:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
      isActive: boolean,
      lastMessageAt?: string | null,
      version: number,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          content: string,
          senderInfo:  {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          },
          conversationId: string,
          createdAt: string,
          updatedAt: string,
          isRead: boolean,
          readBy?:  Array< {
            __typename: "PersonInfo",
            id: string,
            fullName: string,
            email?: string | null,
            phone?: string | null,
            role: UserRoles,
          } | null > | null,
          version: number,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      conversationParticipants?:  {
        __typename: "ModelConversationParticipantsConnection",
        items:  Array< {
          __typename: "ConversationParticipants",
          id: string,
          conversationId: string,
          talentId: string,
          clientId: string,
          recruiterId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
          client?:  {
            __typename: "Client",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            companyID?: string | null,
            timezone?: string | null,
            availability?:  Array< {
              __typename: "ClientAvailability",
              day: number,
              date: string,
            } > | null,
            googleCalendarId?: string | null,
            remainingCredits?: number | null,
            totalCredits?: number | null,
            stripeCustomerId?: string | null,
            currentStripeSubscriptionId?: string | null,
            hasMandate?: boolean | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            company?:  {
              __typename: "Company",
              id: string,
              name: string,
              logo: string,
              stage: companyStage,
              tagline?: string | null,
              description?: string | null,
              industry: industry,
              associatedWith?: companyAssociation | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
          conversation?:  {
            __typename: "Conversation",
            id: string,
            name?: string | null,
            createdAt: string,
            updatedAt: string,
            isActive: boolean,
            lastMessageAt?: string | null,
            version: number,
            messages?:  {
              __typename: "ModelMessageConnection",
              nextToken?: string | null,
            } | null,
            conversationParticipants?:  {
              __typename: "ModelConversationParticipantsConnection",
              nextToken?: string | null,
            } | null,
          } | null,
          recruiter?:  {
            __typename: "Recruiter",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "RecruiterProfile",
              id: string,
              currentEarnings: number,
              profession?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              recruiterProfileRecruiterId?: string | null,
            } | null,
          } | null,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateAnalyticsSubscription = {
  onCreateAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateAnalyticsSubscription = {
  onUpdateAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteAnalyticsSubscription = {
  onDeleteAnalytics?:  {
    __typename: "Analytics",
    id: string,
    developersCompletedProfiles?: number | null,
    developersJoined: number,
    recruitersJoined: number,
    hiredToday?: number | null,
    takenDomainTests: number,
    passedDomainTests: number,
    takenProblemSolvingTests: number,
    passedProblemSolving: number,
    developersReferredByRecruiters: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    clientId?: string | null,
    invoiceBatchId: string,
    invoiceNumber: string,
    amount: number,
    status: InvoiceStatus,
    reason?: string | null,
    stripeInvoiceId?: string | null,
    stripeInvoicePdfUrl?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    invoiceBatch?:  {
      __typename: "InvoiceBatch",
      id: string,
      csvFileUrl: string,
      createdBy: string,
      approvedBy?: string | null,
      status: InvoiceBatchStatus,
      invoiceBatchNumber: number,
      createdAt?: string | null,
      sortHashKey: SortHashKey,
      errorMsg?: string | null,
      invoicesToProcess?: number | null,
      invoicesProcessed?: number | null,
      updatedAt: string,
      version: number,
    } | null,
    client?:  {
      __typename: "Client",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      companyID?: string | null,
      timezone?: string | null,
      availability?:  Array< {
        __typename: "ClientAvailability",
        day: number,
        date: string,
        availableSlots:  Array< {
          __typename: "AvailableSlot",
          startTime: string,
          endTime: string,
        } >,
      } > | null,
      googleCalendarId?: string | null,
      remainingCredits?: number | null,
      totalCredits?: number | null,
      stripeCustomerId?: string | null,
      currentStripeSubscriptionId?: string | null,
      hasMandate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        logo: string,
        stage: companyStage,
        tagline?: string | null,
        description?: string | null,
        address:  {
          __typename: "Address",
          id: string,
          streetAddress: string,
          apartmentAddress?: string | null,
          postalCode?: number | null,
          district?: string | null,
          city: string,
          country: Country,
        },
        industry: industry,
        associatedWith?: companyAssociation | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnCreateInvoiceBatchSubscription = {
  onCreateInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateInvoiceBatchSubscription = {
  onUpdateInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteInvoiceBatchSubscription = {
  onDeleteInvoiceBatch?:  {
    __typename: "InvoiceBatch",
    id: string,
    csvFileUrl: string,
    createdBy: string,
    approvedBy?: string | null,
    status: InvoiceBatchStatus,
    invoiceBatchNumber: number,
    createdAt?: string | null,
    sortHashKey: SortHashKey,
    errorMsg?: string | null,
    invoicesToProcess?: number | null,
    invoicesProcessed?: number | null,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment?:  {
    __typename: "Payment",
    id: string,
    invoiceId: string,
    amount: number,
    transactionId: string,
    paymentStatus: PaymentStatus,
    paymentMethod: CustomerPaymentMethod,
    reason?: string | null,
    createdAt: string,
    updatedAt: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      clientId?: string | null,
      invoiceBatchId: string,
      invoiceNumber: string,
      amount: number,
      status: InvoiceStatus,
      reason?: string | null,
      stripeInvoiceId?: string | null,
      stripeInvoicePdfUrl?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      invoiceBatch?:  {
        __typename: "InvoiceBatch",
        id: string,
        csvFileUrl: string,
        createdBy: string,
        approvedBy?: string | null,
        status: InvoiceBatchStatus,
        invoiceBatchNumber: number,
        createdAt?: string | null,
        sortHashKey: SortHashKey,
        errorMsg?: string | null,
        invoicesToProcess?: number | null,
        invoicesProcessed?: number | null,
        updatedAt: string,
        version: number,
      } | null,
      client?:  {
        __typename: "Client",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        companyID?: string | null,
        timezone?: string | null,
        availability?:  Array< {
          __typename: "ClientAvailability",
          day: number,
          date: string,
          availableSlots:  Array< {
            __typename: "AvailableSlot",
            startTime: string,
            endTime: string,
          } >,
        } > | null,
        googleCalendarId?: string | null,
        remainingCredits?: number | null,
        totalCredits?: number | null,
        stripeCustomerId?: string | null,
        currentStripeSubscriptionId?: string | null,
        hasMandate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
          logo: string,
          stage: companyStage,
          tagline?: string | null,
          description?: string | null,
          address:  {
            __typename: "Address",
            id: string,
            streetAddress: string,
            apartmentAddress?: string | null,
            postalCode?: number | null,
            district?: string | null,
            city: string,
            country: Country,
          },
          industry: industry,
          associatedWith?: companyAssociation | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateStripeSetupIntentSubscription = {
  onCreateStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStripeSetupIntentSubscription = {
  onUpdateStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStripeSetupIntentSubscription = {
  onDeleteStripeSetupIntent?:  {
    __typename: "StripeSetupIntent",
    id: string,
    status: StripeSetupIntentStatus,
    paymentMethodId?: string | null,
    setupIntentId: string,
    mandateId?: string | null,
    mandateStatus: MandateStatus,
    createdAt?: string | null,
    clientId: string,
    clientSecret: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    logo: string,
    stage: companyStage,
    tagline?: string | null,
    description?: string | null,
    address:  {
      __typename: "Address",
      id: string,
      streetAddress: string,
      apartmentAddress?: string | null,
      postalCode?: number | null,
      district?: string | null,
      city: string,
      country: Country,
    },
    industry: industry,
    associatedWith?: companyAssociation | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    id: string,
    companyID: string,
    status: JobStatus,
    title: string,
    description: string,
    responsibilites: string,
    tags: Array< string | null >,
    requiredTechDomainSkills: Array< TechDomainSkills | null >,
    seniorityLevel: SeniorityLevel,
    isPublic?: boolean | null,
    isPermanent?: boolean | null,
    internalNotes?: string | null,
    department?: JobDepartment | null,
    jobPerks?: Array< JobPerks | null > | null,
    createdAt: string,
    updatedAt: string,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      logo: string,
      stage: companyStage,
      tagline?: string | null,
      description?: string | null,
      address:  {
        __typename: "Address",
        id: string,
        streetAddress: string,
        apartmentAddress?: string | null,
        postalCode?: number | null,
        district?: string | null,
        city: string,
        country: Country,
      },
      industry: industry,
      associatedWith?: companyAssociation | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateConversationSubscription = {
  onCreateConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateConversationSubscription = {
  onUpdateConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteConversationSubscription = {
  onDeleteConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    lastMessageAt?: string | null,
    version: number,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        content: string,
        senderInfo:  {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        },
        conversationId: string,
        createdAt: string,
        updatedAt: string,
        isRead: boolean,
        readBy?:  Array< {
          __typename: "PersonInfo",
          id: string,
          fullName: string,
          email?: string | null,
          phone?: string | null,
          role: UserRoles,
        } | null > | null,
        version: number,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversationParticipants?:  {
      __typename: "ModelConversationParticipantsConnection",
      items:  Array< {
        __typename: "ConversationParticipants",
        id: string,
        conversationId: string,
        talentId: string,
        clientId: string,
        recruiterId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
        client?:  {
          __typename: "Client",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          companyID?: string | null,
          timezone?: string | null,
          availability?:  Array< {
            __typename: "ClientAvailability",
            day: number,
            date: string,
            availableSlots:  Array< {
              __typename: "AvailableSlot",
              startTime: string,
              endTime: string,
            } >,
          } > | null,
          googleCalendarId?: string | null,
          remainingCredits?: number | null,
          totalCredits?: number | null,
          stripeCustomerId?: string | null,
          currentStripeSubscriptionId?: string | null,
          hasMandate?: boolean | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            logo: string,
            stage: companyStage,
            tagline?: string | null,
            description?: string | null,
            address:  {
              __typename: "Address",
              id: string,
              streetAddress: string,
              apartmentAddress?: string | null,
              postalCode?: number | null,
              district?: string | null,
              city: string,
              country: Country,
            },
            industry: industry,
            associatedWith?: companyAssociation | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        conversation?:  {
          __typename: "Conversation",
          id: string,
          name?: string | null,
          createdAt: string,
          updatedAt: string,
          isActive: boolean,
          lastMessageAt?: string | null,
          version: number,
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              content: string,
              conversationId: string,
              createdAt: string,
              updatedAt: string,
              isRead: boolean,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
          conversationParticipants?:  {
            __typename: "ModelConversationParticipantsConnection",
            items:  Array< {
              __typename: "ConversationParticipants",
              id: string,
              conversationId: string,
              talentId: string,
              clientId: string,
              recruiterId: string,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        recruiter?:  {
          __typename: "Recruiter",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "RecruiterProfile",
            id: string,
            currentEarnings: number,
            profession?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            recruiterProfileRecruiterId?: string | null,
          } | null,
        } | null,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateRecruiterSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateRecruiterSubscription = {
  onCreateRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type OnUpdateRecruiterSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateRecruiterSubscription = {
  onUpdateRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type OnDeleteRecruiterSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteRecruiterSubscription = {
  onDeleteRecruiter?:  {
    __typename: "Recruiter",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "RecruiterProfile",
      id: string,
      currentEarnings: number,
      profession?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      recruiterProfileRecruiterId?: string | null,
    } | null,
  } | null,
};

export type OnCreateRecruiterProfileSubscriptionVariables = {
  recruiterProfileRecruiterId?: string | null,
};

export type OnCreateRecruiterProfileSubscription = {
  onCreateRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type OnUpdateRecruiterProfileSubscriptionVariables = {
  recruiterProfileRecruiterId?: string | null,
};

export type OnUpdateRecruiterProfileSubscription = {
  onUpdateRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type OnDeleteRecruiterProfileSubscriptionVariables = {
  recruiterProfileRecruiterId?: string | null,
};

export type OnDeleteRecruiterProfileSubscription = {
  onDeleteRecruiterProfile?:  {
    __typename: "RecruiterProfile",
    id: string,
    currentEarnings: number,
    profession?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiterProfileRecruiterId?: string | null,
  } | null,
};

export type OnCreateReferralSubscription = {
  onCreateReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReferralSubscription = {
  onUpdateReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReferralSubscription = {
  onDeleteReferral?:  {
    __typename: "Referral",
    id: string,
    referrerId?: string | null,
    referralReferredId?: string | null,
    recruiterId?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    recruiter?:  {
      __typename: "Recruiter",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "RecruiterProfile",
        id: string,
        currentEarnings: number,
        profession?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        recruiterProfileRecruiterId?: string | null,
      } | null,
    } | null,
    referrer?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    referred?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTalentSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateTalentSubscription = {
  onCreateTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTalentSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateTalentSubscription = {
  onUpdateTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTalentSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteTalentSubscription = {
  onDeleteTalent?:  {
    __typename: "Talent",
    id: string,
    fullName: string,
    email: string,
    phone: string,
    country?: Country | null,
    role: UserRoles,
    createdAt: string,
    updatedAt: string,
    version: number,
    profile?:  {
      __typename: "TalentProfile",
      id: string,
      skills?: Array< string > | null,
      experience?: string | null,
      isAvailable: boolean,
      social?: Array< SocialLinkTypes | null > | null,
      englishProficiency?: englishProficiency | null,
      problemSolvingScore?: number | null,
      problemSolvingPercentage?: number | null,
      problemSolvingTest?:  {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null,
      isPassPS?: boolean | null,
      isPassHR?: boolean | null,
      isPassFinalTech?: boolean | null,
      isHired?: boolean | null,
      isNetwork?: boolean | null,
      isPlaced?: boolean | null,
      hrInterviewVideoId?: string | null,
      videoResponse?:  Array< {
        __typename: "VideoResponse",
        question: string,
        thumbnail: string,
        link: string,
      } | null > | null,
      statusMeta?: string | null,
      heardFrom?: string | null,
      currentSalary?: string | null,
      expectedSalary?: string | null,
      expectedSalaryInUsd?: number | null,
      interests?: Array< string | null > | null,
      talentFeedback?:  Array< {
        __typename: "Feedback",
        type: feedbackType,
        score?: number | null,
        sentiment?: feedbackSentiment | null,
        description: string,
        givenAt: string,
        meta?: string | null,
      } | null > | null,
      resumeLink?: string | null,
      takenDomainTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      educationHistory?:  Array< {
        __typename: "EducationEntry",
        degree: string,
        school: string,
        startDate?: string | null,
        endDate: string,
      } | null > | null,
      employmentHistory?:  Array< {
        __typename: "EmploymentEntry",
        title?: string | null,
        summary?: string | null,
        startDate: string,
        endDate?: string | null,
        company: string,
        currentJob?: boolean | null,
      } | null > | null,
      socialProfiles?:  Array< {
        __typename: "SocialProfile",
        urlType: SocialLinkTypes,
        url: string,
      } | null > | null,
      resetTests?:  {
        __typename: "resetTests",
        domainSkillTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        problemSolvingTests?:  Array< {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null > | null,
        hrInterviews?:  Array< {
          __typename: "hrInterViewReset",
          hrVideoId?: string | null,
          isPass: boolean,
          dateAttempted?: string | null,
          dateOfHRStatus?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
        finalInterviews?:  Array< {
          __typename: "finalInterviewReset",
          isPass: boolean,
          dateAttempted?: string | null,
          feedback?:  {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null,
        } | null > | null,
      } | null,
      imgLink?: string | null,
      bio?: string | null,
      title?: string | null,
      talentTypeID?: string | null,
      analyticsData?:  {
        __typename: "analyticsData",
        source?: string | null,
        medium?: string | null,
        campaign?: string | null,
        referral?: string | null,
      } | null,
      isProfileCompleted?: boolean | null,
      workableId?: string | null,
      aiInterviewInProgress?: boolean | null,
      videoInterviewProvider?: VideoInterviewProvider | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      talent?:  {
        __typename: "Talent",
        id: string,
        fullName: string,
        email: string,
        phone: string,
        country?: Country | null,
        role: UserRoles,
        createdAt: string,
        updatedAt: string,
        version: number,
        profile?:  {
          __typename: "TalentProfile",
          id: string,
          skills?: Array< string > | null,
          experience?: string | null,
          isAvailable: boolean,
          social?: Array< SocialLinkTypes | null > | null,
          englishProficiency?: englishProficiency | null,
          problemSolvingScore?: number | null,
          problemSolvingPercentage?: number | null,
          problemSolvingTest?:  {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null,
          isPassPS?: boolean | null,
          isPassHR?: boolean | null,
          isPassFinalTech?: boolean | null,
          isHired?: boolean | null,
          isNetwork?: boolean | null,
          isPlaced?: boolean | null,
          hrInterviewVideoId?: string | null,
          videoResponse?:  Array< {
            __typename: "VideoResponse",
            question: string,
            thumbnail: string,
            link: string,
          } | null > | null,
          statusMeta?: string | null,
          heardFrom?: string | null,
          currentSalary?: string | null,
          expectedSalary?: string | null,
          expectedSalaryInUsd?: number | null,
          interests?: Array< string | null > | null,
          talentFeedback?:  Array< {
            __typename: "Feedback",
            type: feedbackType,
            score?: number | null,
            sentiment?: feedbackSentiment | null,
            description: string,
            givenAt: string,
            meta?: string | null,
          } | null > | null,
          resumeLink?: string | null,
          takenDomainTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          educationHistory?:  Array< {
            __typename: "EducationEntry",
            degree: string,
            school: string,
            startDate?: string | null,
            endDate: string,
          } | null > | null,
          employmentHistory?:  Array< {
            __typename: "EmploymentEntry",
            title?: string | null,
            summary?: string | null,
            startDate: string,
            endDate?: string | null,
            company: string,
            currentJob?: boolean | null,
          } | null > | null,
          socialProfiles?:  Array< {
            __typename: "SocialProfile",
            urlType: SocialLinkTypes,
            url: string,
          } | null > | null,
          resetTests?:  {
            __typename: "resetTests",
            domainSkillTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            problemSolvingTests?:  Array< {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null > | null,
            hrInterviews?:  Array< {
              __typename: "hrInterViewReset",
              hrVideoId?: string | null,
              isPass: boolean,
              dateAttempted?: string | null,
              dateOfHRStatus?: string | null,
            } | null > | null,
            finalInterviews?:  Array< {
              __typename: "finalInterviewReset",
              isPass: boolean,
              dateAttempted?: string | null,
            } | null > | null,
          } | null,
          imgLink?: string | null,
          bio?: string | null,
          title?: string | null,
          talentTypeID?: string | null,
          analyticsData?:  {
            __typename: "analyticsData",
            source?: string | null,
            medium?: string | null,
            campaign?: string | null,
            referral?: string | null,
          } | null,
          isProfileCompleted?: boolean | null,
          workableId?: string | null,
          aiInterviewInProgress?: boolean | null,
          videoInterviewProvider?: VideoInterviewProvider | null,
          createdAt: string,
          updatedAt: string,
          version: number,
          talent?:  {
            __typename: "Talent",
            id: string,
            fullName: string,
            email: string,
            phone: string,
            country?: Country | null,
            role: UserRoles,
            createdAt: string,
            updatedAt: string,
            version: number,
            profile?:  {
              __typename: "TalentProfile",
              id: string,
              skills?: Array< string > | null,
              experience?: string | null,
              isAvailable: boolean,
              social?: Array< SocialLinkTypes | null > | null,
              englishProficiency?: englishProficiency | null,
              problemSolvingScore?: number | null,
              problemSolvingPercentage?: number | null,
              isPassPS?: boolean | null,
              isPassHR?: boolean | null,
              isPassFinalTech?: boolean | null,
              isHired?: boolean | null,
              isNetwork?: boolean | null,
              isPlaced?: boolean | null,
              hrInterviewVideoId?: string | null,
              statusMeta?: string | null,
              heardFrom?: string | null,
              currentSalary?: string | null,
              expectedSalary?: string | null,
              expectedSalaryInUsd?: number | null,
              interests?: Array< string | null > | null,
              resumeLink?: string | null,
              imgLink?: string | null,
              bio?: string | null,
              title?: string | null,
              talentTypeID?: string | null,
              isProfileCompleted?: boolean | null,
              workableId?: string | null,
              aiInterviewInProgress?: boolean | null,
              videoInterviewProvider?: VideoInterviewProvider | null,
              createdAt: string,
              updatedAt: string,
              version: number,
              talentProfileTalentId?: string | null,
            } | null,
          } | null,
          talentProfileTalentId?: string | null,
          talentType?:  {
            __typename: "TalentType",
            id: string,
            name: string,
            title: string,
            icon: string,
            sequence: number,
            skills:  Array< {
              __typename: "TalentSkill",
              name: string,
              title: string,
            } | null >,
            interests:  Array< {
              __typename: "TalentInterest",
              name: string,
              title: string,
            } | null >,
            sortType: string,
            stages?:  Array< {
              __typename: "Stage",
              type: AssessmentStage,
              isOptional?: boolean | null,
              sequenceNumber: number,
              passingPercentage?: number | null,
            } > | null,
            videoInterviewProvider?: VideoInterviewOptions | null,
            hrInterviewJobId: string,
            hrInterviewAIURL?: string | null,
            atsJobID: string,
            isActive?: boolean | null,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
      } | null,
      talentProfileTalentId?: string | null,
      talentType?:  {
        __typename: "TalentType",
        id: string,
        name: string,
        title: string,
        icon: string,
        sequence: number,
        skills:  Array< {
          __typename: "TalentSkill",
          name: string,
          title: string,
        } | null >,
        interests:  Array< {
          __typename: "TalentInterest",
          name: string,
          title: string,
        } | null >,
        sortType: string,
        stages?:  Array< {
          __typename: "Stage",
          type: AssessmentStage,
          isOptional?: boolean | null,
          sequenceNumber: number,
          passingPercentage?: number | null,
        } > | null,
        videoInterviewProvider?: VideoInterviewOptions | null,
        hrInterviewJobId: string,
        hrInterviewAIURL?: string | null,
        atsJobID: string,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTalentProfileSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnCreateTalentProfileSubscription = {
  onCreateTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateTalentProfileSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnUpdateTalentProfileSubscription = {
  onUpdateTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteTalentProfileSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnDeleteTalentProfileSubscription = {
  onDeleteTalentProfile?:  {
    __typename: "TalentProfile",
    id: string,
    skills?: Array< string > | null,
    experience?: string | null,
    isAvailable: boolean,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    workableId?: string | null,
    aiInterviewInProgress?: boolean | null,
    videoInterviewProvider?: VideoInterviewProvider | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    talent?:  {
      __typename: "Talent",
      id: string,
      fullName: string,
      email: string,
      phone: string,
      country?: Country | null,
      role: UserRoles,
      createdAt: string,
      updatedAt: string,
      version: number,
      profile?:  {
        __typename: "TalentProfile",
        id: string,
        skills?: Array< string > | null,
        experience?: string | null,
        isAvailable: boolean,
        social?: Array< SocialLinkTypes | null > | null,
        englishProficiency?: englishProficiency | null,
        problemSolvingScore?: number | null,
        problemSolvingPercentage?: number | null,
        problemSolvingTest?:  {
          __typename: "TechProblemSolvingProgress",
          testId?: string | null,
          candidateId?: string | null,
          score: number,
          isPass: boolean,
          provider?: TechSkillTestProvider | null,
          dateAttempted?: string | null,
          testEnd?: string | null,
          attemptId?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
        } | null,
        isPassPS?: boolean | null,
        isPassHR?: boolean | null,
        isPassFinalTech?: boolean | null,
        isHired?: boolean | null,
        isNetwork?: boolean | null,
        isPlaced?: boolean | null,
        hrInterviewVideoId?: string | null,
        videoResponse?:  Array< {
          __typename: "VideoResponse",
          question: string,
          thumbnail: string,
          link: string,
        } | null > | null,
        statusMeta?: string | null,
        heardFrom?: string | null,
        currentSalary?: string | null,
        expectedSalary?: string | null,
        expectedSalaryInUsd?: number | null,
        interests?: Array< string | null > | null,
        talentFeedback?:  Array< {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null > | null,
        resumeLink?: string | null,
        takenDomainTests?:  Array< {
          __typename: "TechDomainSkillsProgress",
          skill: TechDomainSkills,
          score: number,
          isPass: boolean,
          provider: TechSkillTestProvider,
          testStart?: string | null,
          testEnd?: string | null,
          percentage?: number | null,
          totalScore?: number | null,
          testLink?: string | null,
          domainTestData?: string | null,
        } | null > | null,
        educationHistory?:  Array< {
          __typename: "EducationEntry",
          degree: string,
          school: string,
          startDate?: string | null,
          endDate: string,
        } | null > | null,
        employmentHistory?:  Array< {
          __typename: "EmploymentEntry",
          title?: string | null,
          summary?: string | null,
          startDate: string,
          endDate?: string | null,
          company: string,
          currentJob?: boolean | null,
        } | null > | null,
        socialProfiles?:  Array< {
          __typename: "SocialProfile",
          urlType: SocialLinkTypes,
          url: string,
        } | null > | null,
        resetTests?:  {
          __typename: "resetTests",
          domainSkillTests?:  Array< {
            __typename: "TechDomainSkillsProgress",
            skill: TechDomainSkills,
            score: number,
            isPass: boolean,
            provider: TechSkillTestProvider,
            testStart?: string | null,
            testEnd?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
            domainTestData?: string | null,
          } | null > | null,
          problemSolvingTests?:  Array< {
            __typename: "TechProblemSolvingProgress",
            testId?: string | null,
            candidateId?: string | null,
            score: number,
            isPass: boolean,
            provider?: TechSkillTestProvider | null,
            dateAttempted?: string | null,
            testEnd?: string | null,
            attemptId?: string | null,
            percentage?: number | null,
            totalScore?: number | null,
            testLink?: string | null,
          } | null > | null,
          hrInterviews?:  Array< {
            __typename: "hrInterViewReset",
            hrVideoId?: string | null,
            isPass: boolean,
            dateAttempted?: string | null,
            dateOfHRStatus?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
          finalInterviews?:  Array< {
            __typename: "finalInterviewReset",
            isPass: boolean,
            dateAttempted?: string | null,
            feedback?:  {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null,
          } | null > | null,
        } | null,
        imgLink?: string | null,
        bio?: string | null,
        title?: string | null,
        talentTypeID?: string | null,
        analyticsData?:  {
          __typename: "analyticsData",
          source?: string | null,
          medium?: string | null,
          campaign?: string | null,
          referral?: string | null,
        } | null,
        isProfileCompleted?: boolean | null,
        workableId?: string | null,
        aiInterviewInProgress?: boolean | null,
        videoInterviewProvider?: VideoInterviewProvider | null,
        createdAt: string,
        updatedAt: string,
        version: number,
        talent?:  {
          __typename: "Talent",
          id: string,
          fullName: string,
          email: string,
          phone: string,
          country?: Country | null,
          role: UserRoles,
          createdAt: string,
          updatedAt: string,
          version: number,
          profile?:  {
            __typename: "TalentProfile",
            id: string,
            skills?: Array< string > | null,
            experience?: string | null,
            isAvailable: boolean,
            social?: Array< SocialLinkTypes | null > | null,
            englishProficiency?: englishProficiency | null,
            problemSolvingScore?: number | null,
            problemSolvingPercentage?: number | null,
            problemSolvingTest?:  {
              __typename: "TechProblemSolvingProgress",
              testId?: string | null,
              candidateId?: string | null,
              score: number,
              isPass: boolean,
              provider?: TechSkillTestProvider | null,
              dateAttempted?: string | null,
              testEnd?: string | null,
              attemptId?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
            } | null,
            isPassPS?: boolean | null,
            isPassHR?: boolean | null,
            isPassFinalTech?: boolean | null,
            isHired?: boolean | null,
            isNetwork?: boolean | null,
            isPlaced?: boolean | null,
            hrInterviewVideoId?: string | null,
            videoResponse?:  Array< {
              __typename: "VideoResponse",
              question: string,
              thumbnail: string,
              link: string,
            } | null > | null,
            statusMeta?: string | null,
            heardFrom?: string | null,
            currentSalary?: string | null,
            expectedSalary?: string | null,
            expectedSalaryInUsd?: number | null,
            interests?: Array< string | null > | null,
            talentFeedback?:  Array< {
              __typename: "Feedback",
              type: feedbackType,
              score?: number | null,
              sentiment?: feedbackSentiment | null,
              description: string,
              givenAt: string,
              meta?: string | null,
            } | null > | null,
            resumeLink?: string | null,
            takenDomainTests?:  Array< {
              __typename: "TechDomainSkillsProgress",
              skill: TechDomainSkills,
              score: number,
              isPass: boolean,
              provider: TechSkillTestProvider,
              testStart?: string | null,
              testEnd?: string | null,
              percentage?: number | null,
              totalScore?: number | null,
              testLink?: string | null,
              domainTestData?: string | null,
            } | null > | null,
            educationHistory?:  Array< {
              __typename: "EducationEntry",
              degree: string,
              school: string,
              startDate?: string | null,
              endDate: string,
            } | null > | null,
            employmentHistory?:  Array< {
              __typename: "EmploymentEntry",
              title?: string | null,
              summary?: string | null,
              startDate: string,
              endDate?: string | null,
              company: string,
              currentJob?: boolean | null,
            } | null > | null,
            socialProfiles?:  Array< {
              __typename: "SocialProfile",
              urlType: SocialLinkTypes,
              url: string,
            } | null > | null,
            imgLink?: string | null,
            bio?: string | null,
            title?: string | null,
            talentTypeID?: string | null,
            analyticsData?:  {
              __typename: "analyticsData",
              source?: string | null,
              medium?: string | null,
              campaign?: string | null,
              referral?: string | null,
            } | null,
            isProfileCompleted?: boolean | null,
            workableId?: string | null,
            aiInterviewInProgress?: boolean | null,
            videoInterviewProvider?: VideoInterviewProvider | null,
            createdAt: string,
            updatedAt: string,
            version: number,
            talent?:  {
              __typename: "Talent",
              id: string,
              fullName: string,
              email: string,
              phone: string,
              country?: Country | null,
              role: UserRoles,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            talentProfileTalentId?: string | null,
            talentType?:  {
              __typename: "TalentType",
              id: string,
              name: string,
              title: string,
              icon: string,
              sequence: number,
              sortType: string,
              videoInterviewProvider?: VideoInterviewOptions | null,
              hrInterviewJobId: string,
              hrInterviewAIURL?: string | null,
              atsJobID: string,
              isActive?: boolean | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null,
        } | null,
        talentProfileTalentId?: string | null,
        talentType?:  {
          __typename: "TalentType",
          id: string,
          name: string,
          title: string,
          icon: string,
          sequence: number,
          skills:  Array< {
            __typename: "TalentSkill",
            name: string,
            title: string,
          } | null >,
          interests:  Array< {
            __typename: "TalentInterest",
            name: string,
            title: string,
          } | null >,
          sortType: string,
          stages?:  Array< {
            __typename: "Stage",
            type: AssessmentStage,
            isOptional?: boolean | null,
            sequenceNumber: number,
            passingPercentage?: number | null,
          } > | null,
          videoInterviewProvider?: VideoInterviewOptions | null,
          hrInterviewJobId: string,
          hrInterviewAIURL?: string | null,
          atsJobID: string,
          isActive?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null,
    talentProfileTalentId?: string | null,
    talentType?:  {
      __typename: "TalentType",
      id: string,
      name: string,
      title: string,
      icon: string,
      sequence: number,
      skills:  Array< {
        __typename: "TalentSkill",
        name: string,
        title: string,
      } | null >,
      interests:  Array< {
        __typename: "TalentInterest",
        name: string,
        title: string,
      } | null >,
      sortType: string,
      stages?:  Array< {
        __typename: "Stage",
        type: AssessmentStage,
        isOptional?: boolean | null,
        sequenceNumber: number,
        passingPercentage?: number | null,
      } > | null,
      videoInterviewProvider?: VideoInterviewOptions | null,
      hrInterviewJobId: string,
      hrInterviewAIURL?: string | null,
      atsJobID: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateForexRatesSubscription = {
  onCreateForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateForexRatesSubscription = {
  onUpdateForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteForexRatesSubscription = {
  onDeleteForexRates?:  {
    __typename: "ForexRates",
    id: string,
    currencyType: string,
    usdConversionRate: number,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateTalentProfileTransientSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnCreateTalentProfileTransientSubscription = {
  onCreateTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateTalentProfileTransientSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnUpdateTalentProfileTransientSubscription = {
  onUpdateTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteTalentProfileTransientSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnDeleteTalentProfileTransientSubscription = {
  onDeleteTalentProfileTransient?:  {
    __typename: "TalentProfileTransient",
    id?: string | null,
    skills?: Array< string | null > | null,
    experience?: string | null,
    isAvailable?: boolean | null,
    social?: Array< SocialLinkTypes | null > | null,
    englishProficiency?: englishProficiency | null,
    problemSolvingScore?: number | null,
    problemSolvingPercentage?: number | null,
    problemSolvingTest?:  {
      __typename: "TechProblemSolvingProgress",
      testId?: string | null,
      candidateId?: string | null,
      score: number,
      isPass: boolean,
      provider?: TechSkillTestProvider | null,
      dateAttempted?: string | null,
      testEnd?: string | null,
      attemptId?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
    } | null,
    isPassPS?: boolean | null,
    isPassHR?: boolean | null,
    isPassFinalTech?: boolean | null,
    isHired?: boolean | null,
    isNetwork?: boolean | null,
    isPlaced?: boolean | null,
    hrInterviewVideoId?: string | null,
    videoResponse?:  Array< {
      __typename: "VideoResponse",
      question: string,
      thumbnail: string,
      link: string,
    } | null > | null,
    statusMeta?: string | null,
    heardFrom?: string | null,
    currentSalary?: string | null,
    expectedSalary?: string | null,
    expectedSalaryInUsd?: number | null,
    interests?: Array< string | null > | null,
    talentFeedback?:  Array< {
      __typename: "Feedback",
      type: feedbackType,
      score?: number | null,
      sentiment?: feedbackSentiment | null,
      description: string,
      givenAt: string,
      meta?: string | null,
    } | null > | null,
    resumeLink?: string | null,
    takenDomainTests?:  Array< {
      __typename: "TechDomainSkillsProgress",
      skill: TechDomainSkills,
      score: number,
      isPass: boolean,
      provider: TechSkillTestProvider,
      testStart?: string | null,
      testEnd?: string | null,
      percentage?: number | null,
      totalScore?: number | null,
      testLink?: string | null,
      domainTestData?: string | null,
    } | null > | null,
    educationHistory?:  Array< {
      __typename: "EducationEntry",
      degree: string,
      school: string,
      startDate?: string | null,
      endDate: string,
    } | null > | null,
    employmentHistory?:  Array< {
      __typename: "EmploymentEntry",
      title?: string | null,
      summary?: string | null,
      startDate: string,
      endDate?: string | null,
      company: string,
      currentJob?: boolean | null,
    } | null > | null,
    socialProfiles?:  Array< {
      __typename: "SocialProfile",
      urlType: SocialLinkTypes,
      url: string,
    } | null > | null,
    resetTests?:  {
      __typename: "resetTests",
      domainSkillTests?:  Array< {
        __typename: "TechDomainSkillsProgress",
        skill: TechDomainSkills,
        score: number,
        isPass: boolean,
        provider: TechSkillTestProvider,
        testStart?: string | null,
        testEnd?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
        domainTestData?: string | null,
      } | null > | null,
      problemSolvingTests?:  Array< {
        __typename: "TechProblemSolvingProgress",
        testId?: string | null,
        candidateId?: string | null,
        score: number,
        isPass: boolean,
        provider?: TechSkillTestProvider | null,
        dateAttempted?: string | null,
        testEnd?: string | null,
        attemptId?: string | null,
        percentage?: number | null,
        totalScore?: number | null,
        testLink?: string | null,
      } | null > | null,
      hrInterviews?:  Array< {
        __typename: "hrInterViewReset",
        hrVideoId?: string | null,
        isPass: boolean,
        dateAttempted?: string | null,
        dateOfHRStatus?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
      finalInterviews?:  Array< {
        __typename: "finalInterviewReset",
        isPass: boolean,
        dateAttempted?: string | null,
        feedback?:  {
          __typename: "Feedback",
          type: feedbackType,
          score?: number | null,
          sentiment?: feedbackSentiment | null,
          description: string,
          givenAt: string,
          meta?: string | null,
        } | null,
      } | null > | null,
    } | null,
    imgLink?: string | null,
    bio?: string | null,
    title?: string | null,
    talentTypeID?: string | null,
    analyticsData?:  {
      __typename: "analyticsData",
      source?: string | null,
      medium?: string | null,
      campaign?: string | null,
      referral?: string | null,
    } | null,
    isProfileCompleted?: boolean | null,
    talentProfileTalentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateTalentProfileProcessedIDsSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnCreateTalentProfileProcessedIDsSubscription = {
  onCreateTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type OnUpdateTalentProfileProcessedIDsSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnUpdateTalentProfileProcessedIDsSubscription = {
  onUpdateTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type OnDeleteTalentProfileProcessedIDsSubscriptionVariables = {
  talentProfileTalentId?: string | null,
};

export type OnDeleteTalentProfileProcessedIDsSubscription = {
  onDeleteTalentProfileProcessedIDs?:  {
    __typename: "TalentProfileProcessedIDs",
    id?: string | null,
    talentProfileId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
    talentProfileTalentId?: string | null,
  } | null,
};

export type OnCreateTalentTypeSubscription = {
  onCreateTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTalentTypeSubscription = {
  onUpdateTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTalentTypeSubscription = {
  onDeleteTalentType?:  {
    __typename: "TalentType",
    id: string,
    name: string,
    title: string,
    icon: string,
    sequence: number,
    skills:  Array< {
      __typename: "TalentSkill",
      name: string,
      title: string,
    } | null >,
    interests:  Array< {
      __typename: "TalentInterest",
      name: string,
      title: string,
    } | null >,
    sortType: string,
    stages?:  Array< {
      __typename: "Stage",
      type: AssessmentStage,
      isOptional?: boolean | null,
      sequenceNumber: number,
      passingPercentage?: number | null,
    } > | null,
    videoInterviewProvider?: VideoInterviewOptions | null,
    hrInterviewJobId: string,
    hrInterviewAIURL?: string | null,
    atsJobID: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
