import React, { useEffect } from "react";
import { autoScrollOnTop } from "utils";
import AOS from "aos";
import SupportComponent from "components/dashboard/SupportComponent";

export default function Support(): ReturnType<React.FC> {
  useEffect(() => {
    autoScrollOnTop();
    AOS.init();
  }, []);

  return <SupportComponent />;
}
