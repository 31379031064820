import { FC, useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import { upperFirst } from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ShouldRender } from "..";
import * as Styled from "./styles";

interface Props {
  initialValue: string;
  label?: string;
  setValue?: (value: string) => void;
  errorMsg?: string;
  disabled?: boolean;
}
export const TextDraft: FC<Props> = ({ initialValue, setValue, errorMsg, label, disabled }) => {
  const [editorState, setEditorState] = useState(
    initialValue
      ? EditorState.createWithContent(convertFromHTML(initialValue))
      : EditorState.createEmpty(),
  );

  useEffect(() => {
    if (editorState && setValue) setValue(convertToHTML(editorState.getCurrentContent()));
  }, [editorState]);

  return (
    <Styled.DraftGroup>
      <ShouldRender if={label}>
        <Styled.DraftLabel>{label}</Styled.DraftLabel>
      </ShouldRender>
      <Styled.QuestionDraft className={disabled ? "inactive" : ""}>
        <Editor
          readOnly={disabled}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list", "textAlign"],
            inline: {
              options: ["bold", "italic", "underline", "monospace", "superscript"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "Blockquote"],
            },
          }}
        />
      </Styled.QuestionDraft>
      <Styled.ErrorMessage>{upperFirst(errorMsg)}</Styled.ErrorMessage>
    </Styled.DraftGroup>
  );
};

export default TextDraft;
