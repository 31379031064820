import styled from "styled-components";

export const UploadPhotoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const UploadPhotoAvatar = styled.div`
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: #f2f6ff;
  border: 1px solid #c0b0ff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  & svg {
    width: 28px;
    height: 28px;
  }
  @media screen and (min-width: 576px) {
    width: 123px;
    height: 123px;
    & svg {
      width: 36px;
      height: 36px;
    }
  }
  & img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UploadAvatarIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: rgba(157, 157, 157, 0.35);
    opacity: 1;
    backdrop-filter: blur(2px);
  }

  & svg {
    width: 25px;
  }
`;

export const UploadImgLink = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 136px;
  min-height: 21px;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    opacity: 0.8;
  }
`;

export const UploadImgInput = styled.input`
  display: none;
`;

export const UploadImgText = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  font-weight: 300;
  color: #3615bd;
  margin: 0 4px 0 0;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: #3615bd;
  }
`;

export const UploadCameraIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  & img {
    max-width: 100%;
  }
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const UploadedProfileImage = styled.img`
  width: 110px;
  height: 110px;
  left: 5px;
  top: 5px;
  border-radius: 100px;
  z-index: 0;
  transition: all 0.2s ease;
`;
