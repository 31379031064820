import SliderComponent from "rc-slider";
import { FC } from "react";
import { Color } from "@remotebase/constants/commons";
import { ShouldRender } from "../../..";
import * as Styled from "./styles";
import "rc-slider/assets/index.css";

interface CustomSliderProps {
  setValue?: (number: number) => void;
  value: number;
  label?: string;
  disabled?: boolean;
  draggable: boolean;
}

export const Slider: FC<CustomSliderProps> = ({ value, label, disabled }): JSX.Element => {
  const scoreLabel = `${value}%`;
  return (
    <Styled.SliderWrapper>
      <ShouldRender if={label}>
        <Styled.SkillsLabel>{label}</Styled.SkillsLabel>
      </ShouldRender>
      <Styled.SliderContainer>
        <SliderComponent
          min={0}
          max={100}
          defaultValue={value}
          disabled={disabled}
          value={value}
          trackStyle={{ backgroundColor: Color.DARK_BLUE, height: "9px" }}
          railStyle={{ backgroundColor: Color.LIGHT_GREY, height: "9px" }}
          handleStyle={{ display: "none" }}
        />
        <Styled.SliderValue>{scoreLabel}</Styled.SliderValue>
      </Styled.SliderContainer>
    </Styled.SliderWrapper>
  );
};
export default Slider;
