import { FC } from "react";
import {
  DetailListProps,
  DetailProps,
  extractCodingExpertise,
  ProfileDetail,
} from "../../../utils";
import * as Styled from "../styles";
import { Detail } from "./detail";

export const detailList: FC<DetailListProps> = ({ data, detailsToShow }) => {
  const { takenDomainTests } = data?.profile || {};
  const topCodingExpertises = extractCodingExpertise(takenDomainTests).slice(0, 2);

  const detailPropMap = new Map<ProfileDetail, DetailProps>([
    [
      ProfileDetail.Expertise,
      {
        detailName: ProfileDetail.Expertise,
        detailValue: topCodingExpertises.join(", "),
        verifiedFlag: false,
      },
    ],
    [
      ProfileDetail.EnglishLevel,
      {
        detailName: ProfileDetail.EnglishLevel,
        detailValue: data?.profile?.englishProficiency || null,
        verifiedFlag: true,
      },
    ],
    [
      ProfileDetail.YearOfExperience,
      {
        detailName: ProfileDetail.YearOfExperience,
        detailValue: data?.profile?.experience ? `${data?.profile?.experience} years` : null,
        verifiedFlag: false,
      },
    ],
    [
      ProfileDetail.Email,
      {
        detailName: "Email",
        detailValue: data?.email ? data?.email : null,
        verifiedFlag: false,
      },
    ],
    [
      ProfileDetail.Phone,
      {
        detailName: "Phone",
        detailValue: data?.phone ? data?.phone : null,
        verifiedFlag: false,
      },
    ],
  ]);

  const generateDetailList = (): DetailProps[] => {
    const detailProps: DetailProps[] = [];
    for (const detail of detailsToShow) {
      const detailProp = detailPropMap.get(detail);
      if (detailProp) detailProps.push(detailProp);
    }
    return detailProps;
  };

  const items: JSX.Element[] = [];
  let index = 0;
  for (const detailProps of generateDetailList()) {
    if (detailProps.detailValue) {
      items.push(
        <Detail
          key={index}
          detailName={detailProps.detailName}
          detailValue={detailProps.detailValue}
          verifiedFlag={detailProps.verifiedFlag}
        />,
      );
    }
    index += 1;
  }
  return <Styled.TalentProfileDetailList>{items}</Styled.TalentProfileDetailList>;
};

export default detailList;
