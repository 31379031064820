import { FC } from "react";
import { TalentProvider } from "state/talent";
import FavoritesComponent from "components/dashboard";
import * as S from "./styles";

const Favorites: FC = () => {
  return (
    <S.FavoritesWrapper>
      <S.FavoritesInner>
        <TalentProvider>
          <FavoritesComponent interviewingOnly />
        </TalentProvider>
      </S.FavoritesInner>
    </S.FavoritesWrapper>
  );
};

export default Favorites;
