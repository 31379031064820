/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
import { ButtonGroup, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ITalentProps } from "state/types";
import { ErrorProps } from "@remotebase/constants";
import { withTalent } from "state/talent";
import withError from "state/error/withErrorHoc";
import { useMemo } from "react";
import {
  BillingDetailsFormColumn,
  BillingDetailsFormInputFieldRow,
  BillingDetailsFormRowContainer,
  BillingDetailsProceedBtn,
  Container,
  HeaderInfoPara,
  SectionHeader,
  SectionHeaderSection,
} from "./styles";
import useMandateForm from "../hooks/useMandateForm";
import useBasicDetailsFormGroup from "../hooks/useBasicDetailsFormGroup";
import useAddressSection, { AddressSectionType } from "../hooks/useAddressSection";
import { pleaseProvideBillingDetailsHeaderText, pleaseProvideInfoParaText } from "./constants";

type Props = {
  clientSecret: string;
  setShowConfirmation: (value: boolean) => void;
};

export const BillingDetailsForm: React.FC<ITalentProps & ErrorProps & Props> = ({
  talentState,
  showError,
  clientSecret,
  setShowConfirmation,
}) => {
  const formProps = useMandateForm(
    talentState?.client,
    showError,
    clientSecret,
    setShowConfirmation,
  );
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: formProps.resolver,
    defaultValues: formProps.defaultValues,
    mode: "onChange",
  });

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleSubmit(formProps.onSubmit)();
  };

  const { billingDetailsItems, addressDetailSectionA, addressDetailSectionB } = useMemo(() => {
    const billingDetailsItems = useBasicDetailsFormGroup({
      formFields: formProps.fieldSections.basicDetails,
      setValue,
      control,
      errors,
      formProps,
      register,
    });

    const addressDetailSectionA = useAddressSection({
      type: AddressSectionType.A,
      formFields: formProps.fieldSections.address.slice(0, -2),
      formProps,
      register,
      control,
      errors,
    });

    const addressDetailSectionB = useAddressSection({
      type: AddressSectionType.B,
      formFields: formProps.fieldSections.address.slice(-2),
      formProps,
      register,
      control,
      errors,
    });

    return { billingDetailsItems, addressDetailSectionA, addressDetailSectionB };
  }, [formProps.fieldSections]);

  return (
    <Container>
      <SectionHeaderSection>
        <SectionHeader>{pleaseProvideBillingDetailsHeaderText}</SectionHeader>
        <HeaderInfoPara>{pleaseProvideInfoParaText}</HeaderInfoPara>
      </SectionHeaderSection>
      <Form className="mt-4" id={formProps.formId} onSubmit={onSubmitHandler}>
        <BillingDetailsFormRowContainer className="flex-lg-row flex-sm-column">
          <BillingDetailsFormColumn>
            <h5 className="mb-4">Basic Details</h5>
            {billingDetailsItems}
          </BillingDetailsFormColumn>

          <BillingDetailsFormColumn>
            <h5 className="mb-4">Address Details</h5>
            {addressDetailSectionA}
            <BillingDetailsFormInputFieldRow className="flex-lg-row flex-sm-column">
              {addressDetailSectionB}
            </BillingDetailsFormInputFieldRow>
          </BillingDetailsFormColumn>
        </BillingDetailsFormRowContainer>

        <ButtonGroup className="w-50 mt-4 text-white">
          <BillingDetailsProceedBtn
            className="btn"
            type="submit"
            disabled={!isValid || formProps.submitBtn.disabled}
          >
            {formProps.submitBtn.value}
          </BillingDetailsProceedBtn>
        </ButtonGroup>
      </Form>
    </Container>
  );
};

export default withTalent(withError(BillingDetailsForm));
