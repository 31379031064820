import { ShouldRender } from "@remotebase/components";
import { JobMatchTalent } from "state/types/talent.interface";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getOverallTestScore } from "@remotebase/components/components.helpers";
import * as Styled from "../../styles";
import TalentCard from "./TalentCard";
import BtnGroup from "./btnGroup";

interface Props {
  talents?: JobMatchTalent["talent"];
  feedback?: JobMatchTalent["feedback"];
  jobMatchIds?: JobMatchTalent["jobMatchIds"];
}
export const TalentList: React.FC<Props> = ({ talents, feedback, jobMatchIds }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 640 },
      items: 2,
    },
    mobileSm: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  };

  return (
    <Styled.TalentGird>
      <ShouldRender if={talents?.length}>
        <Carousel
          responsive={responsive}
          arrows={false}
          sliderClass="talentCarousel__ul"
          itemClass="talentCarousel__li"
          containerClass="talent__carousel"
          renderButtonGroupOutside={true}
          customButtonGroup={<BtnGroup />}
          shouldResetAutoplay={false}
        >
          {talents?.map((talentObj, index) => (
            <TalentCard
              key={talentObj?.id}
              imgLink={talentObj?.imgLink}
              fullName={talentObj?.talent?.fullName}
              title={talentObj?.title}
              salary={
                talentObj?.expectedSalaryInUsd ? `$${talentObj?.expectedSalaryInUsd}/ Month` : " "
              }
              feedback={Boolean(feedback?.[index])}
              bgLink={index % 2 === 0 ? "/images/bg-1.png" : "/images/bg-2.png"}
              skillScore={getOverallTestScore(talentObj?.talent?.profile?.takenDomainTests)}
              talentID={talentObj?.talentID}
              jobMatchId={jobMatchIds?.[index]}
            />
          ))}
        </Carousel>
      </ShouldRender>
    </Styled.TalentGird>
  );
};

export default TalentList;
