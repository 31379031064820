export const autoScrollOnTop = (): void => {
  window.scrollTo(0, 0);
};

export const initialTimeConvert = (time: string): string => {
  const dateTime = new Date(time);
  let hours = `${dateTime.getHours()}`;
  if (hours.length === 1) hours = "0".concat(hours);
  let mins = `${dateTime.getMinutes()}`;
  if (mins.length === 1) mins = "0".concat(mins);
  return hours.concat(":").concat(mins);
};

export const convertTime = (time: string): string => {
  const dateTime = new Date("1970");
  const hours = parseInt(time.split(":")[0], 10);
  const mins = parseInt(time.split(":")[1], 10);
  dateTime.setHours(hours);
  dateTime.setMinutes(mins);
  return dateTime.toISOString();
};

export const paginate = <T,>(data: T[], currentPage: number, offset: number): T[] => {
  const start = (currentPage - 1) * offset;
  const end = start + offset;
  return data.slice(start, end);
};
