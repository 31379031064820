import { FC, Fragment, useMemo } from "react";
import { VideoResponse } from "@remotebase/amplify-constants/API";
import * as Styled from "./styles";
import VideoWidget from "../videoWidget";

interface Props {
  hrVideoResponse: Array<VideoResponse | null>;
}
export const VideoIntroduction: FC<Props> = ({ hrVideoResponse }) => {
  const videoUrls = useMemo(() => {
    return hrVideoResponse?.map((item) => ({
      src: item?.link || "",
      question: item?.question || "",
    }));
  }, hrVideoResponse);

  return videoUrls?.length ? (
    <Fragment>
      <Styled.VideoHeader> Video Introduction </Styled.VideoHeader>
      <VideoWidget urls={videoUrls || []} />
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default VideoIntroduction;
