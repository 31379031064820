import { Button, Modal, DropdownButton } from "react-bootstrap";
import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #11142d;
  font-family: "Poppins", sans-serif;
  margin-bottom: 36px;
  text-align: left;

  @media (max-width: 575px) {
    font-size: 24px;
  }
`;

export const H2 = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #11142d;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  text-align: left;

  @media (max-width: 575px) {
    font-size: 18px;
  }
`;

export const TestModal = styled(Modal)`
  .modal-dialog {
    max-width: 640px;
  }
  .modal-content {
    border-radius: 16px;
    text-align: center;
  }
  .modal-body {
    padding: 60px 60px;
  }
  @media (max-width: 767px) {
    padding-left: 0 !important;
    .modal-dialog {
      margin: 20px;
    }
    .modal-body {
      padding: 60px 32px 32px;
    }
  }
`;
export const CrossIconBox = styled.span`
  font-size: 16px;
  color: #11142d;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  right: 18px;
  top: 18px;
  font-family: "Poppins", sans-serif;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: #11142d;
    color: #fff;
  }
  @media (max-width: 767px) {
    top: 15px;
    right: 15px;
  }
`;
export const ModalPara = styled.p`
  font-size: 14px;
  color: #11142d;
  margin-bottom: 22px;
  font-family: "Poppins", sans-serif;
  text-align: center;

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;
export const ModalBtn = styled(Button)`
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 10px;
  min-width: 150px;
  background-color: #3f73f6;
  border: none;
  transition: all 0.2s ease;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #2a5bd7;
    color: #fff;
    box-shadow: none;
  }
`;

export const DropdownBtn = styled(DropdownButton)`
  width: 100%;
  background: #3c65fe;
  border-radius: 8px;
  width: 194px;
  height: 42px;
  font-size: 14px;
  margin-bottom: 3px;

  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #3c65fe;
    }
  }
  & .dropdown-toggle {
    font-size: 14px;
    width: 194px;
    height: 42px;
    background-color: #3c65fe;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      height: 46px;
      font-size: 12px;
      padding: 4px 15px;
    }

    &:focus,
    &:active {
      background-color: #3c65fe;
      box-shadow: none !important;
      border: 1px solid #dddfe2 !important;
    }
  }
  & .dropdown-menu {
    border-radius: 6px;
    padding: 6px 0;
    width: 194px;
    left: 0 !important;
    box-shadow: 0px 0px 14px rgb(0 0 0 / 10%);
  }
  & .dropdown-item {
    color: #474d58;
    font-size: 14px;
    padding: 6px 12px;
    white-space: initial;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 5px 10px;
    }

    &:hover,
    &:focus,
    &.active {
      color: #474d58;
      background-color: #e9ecef;
    }
  }
`;

export const ErrorDiv = styled.div`
  text-align: left;
  color: red;
`;

export const SortDropdown = styled.div`
  width: 100%;

  & .dropdown {
    & .dropdown-toggle {
      text-align: left;
      font-size: 14px;
      color: #979ba4;
      font-weight: 500;
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid #979ba4;
      padding: 7px 10px;
      height: 42px;
      width: 100%;
      transition: all 0.25s;

      &:after {
        content: none;
      }

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
        color: #979ba4;
      }
    }

    & .dropdown-menu {
      border: none;
      border-radius: 12px;
      left: 0 !important;
      right: 0 !important;
      min-width: 10px;
      box-shadow: 0px 1px 10px rgb(0 0 0 / 5%);
    }

    & .dropdown-item {
      font-size: 14px;
      color: #979ba4;
      font-weight: 500;
      padding: 6px 16px;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: #e9ecef;
        color: #979ba4;
      }
    }
  }
`;
