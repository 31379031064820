import { AuthStateType } from "hooks/utils";
import { FlattenSimpleInterpolation } from "styled-components";

export enum SidebarType {
  Login = "login",
  Create_Profile = "Create profile",
  Dashboard = "Dashboard",
  DashboardWithoutBilling = "Dashboard without billing",
}

export type StyledProps = FlattenSimpleInterpolation | string;
export type AuthProps = {
  authState: AuthStateType;
  getAuth: (shouldContactSupport?: boolean) => void;
  setAuthState: React.Dispatch<React.SetStateAction<AuthStateType>>;
};

export type FormErrorType = {
  [x: string]: string;
};

export type SidebarProps = {
  sidebarType: SidebarType;
  setSidebarType: React.Dispatch<React.SetStateAction<SidebarType>>;
  sidebarIsCollapsed: boolean;
  toggleSidebar: () => void;
};

export type SidebarPropsWithChildren = SidebarProps & {
  children: React.ReactNode;
};

export type CanNullOrUndefined<T> = T | null | undefined;

export enum AuthType {
  Login = "login",
  Register = "register",
  ForgotPassword = "forgotPassword",
  ResetPassword = "resetPassword",
  Reverify = "reverify",
}

export enum TestStatus {
  PASSED = "Passed",
  FAILED = "Did Not Pass",
  READY = "Ready",
  PENDING = "Pending",
}

export const allowedResumeSize = 8388608; // 8 * 1024(M) * 1024(K)
export const profileExperienceBoxes = 7;

export const requiredDomainTests = 1;
export const toastDelayTime = 6000;
export const sampleTestLink = "https://www.hackerrank.com/test/sample";

export const joinOnSlackLink =
  "https://join.slack.com/t/pakdevforum/shared_invite/zt-v6eroohc-A0CknLIf32yDhvRr821lrg";

export const initialAvatarValue = "?";

export const environment = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};
export const purpleBlueColor = "#4c40f7";
export const defaultRoute = "#";
export const shouldUseIntercom = process.env.REACT_APP_USE_INTERCOM === "true";
export const useAnalytics = process.env.REACT_APP_USE_ANALYTICS === "true";
export const shouldShowMaintenence = process.env.REACT_APP_MAINTENANCE_MODE === "true";
export const sentryDSN = !process.env.REACT_APP_IS_DEV && process.env.REACT_APP_SENTRY_DSN;

export const overall = "Overall ";
