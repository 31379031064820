import { FC, useState, useMemo } from "react";
import { ShouldRender } from "../../shouldRender";
import * as Styled from "../styles";

interface Props {
  summary: string | null;
  client: boolean;
  children?: React.ReactNode;
}

export const SummarySection: FC<Props> = ({ summary, children, client }) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const defaultBioLength = 300;
  const displaySummary = useMemo(
    () => (isReadMore ? summary : summary?.slice(0, defaultBioLength)),
    [isReadMore, summary, defaultBioLength],
  );

  return (
    <Styled.OuterSummarSection client={client}>
      <Styled.SummaryConatiner>
        <Styled.ProfileSummaryHd>Summary</Styled.ProfileSummaryHd>
        {children}
      </Styled.SummaryConatiner>

      {summary ? (
        <>
          <Styled.ProfileSummaryPara>
            <div dangerouslySetInnerHTML={{ __html: displaySummary || " " }} />
          </Styled.ProfileSummaryPara>
          <ShouldRender if={summary.length > defaultBioLength}>
            <Styled.ProfileSummaryLink onClick={(): void => setIsReadMore(!isReadMore)}>
              {isReadMore ? "Read less" : "Read more"}
            </Styled.ProfileSummaryLink>
          </ShouldRender>
        </>
      ) : (
        <Styled.NoSummary>No Data Available</Styled.NoSummary>
      )}
    </Styled.OuterSummarSection>
  );
};

export default SummarySection;
