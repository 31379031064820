import { FC, Fragment } from "react";
import { BtnLoader } from "../../loader";
import { ShouldRender } from "../..";
import * as Styled from "./styles";

interface Props {
  isLoading?: boolean;
  buttonText?: string;
}

export const AuthSubmitBtn: FC<Props> = (props) => {
  const { isLoading, buttonText } = props;
  return (
    <Styled.LoginBtn type="submit" disabled={isLoading}>
      <ShouldRender if={!isLoading}>
        <Fragment>{buttonText}</Fragment>
      </ShouldRender>
      <ShouldRender if={isLoading}>
        <BtnLoader />
      </ShouldRender>
    </Styled.LoginBtn>
  );
};

export default AuthSubmitBtn;
