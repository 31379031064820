import React, { useMemo } from "react";
import { Auth } from "aws-amplify";
import { ICredentials } from "@aws-amplify/core";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { ShouldRender } from "../../index";
import { GoogleIcon } from "../../assets/icons";
import * as Styled from "./styles";

interface AuthGoogleProps {
  isSignup?: boolean;
  wide?: boolean;
  separator?: string;
}

export const AuthGoogle: React.FC<AuthGoogleProps> = ({ separator, wide }) => {
  const continueWithGoogle = (): Promise<ICredentials> =>
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

  const EntryButton = useMemo(() => (wide ? Styled.EntryBtnFull : Styled.EntryBtn), [wide]);

  return (
    <Styled.EntryBtnsContainer>
      <EntryButton onClick={continueWithGoogle}>
        <GoogleIcon height={45} />
        Continue with Google
      </EntryButton>
      <ShouldRender if={separator}>
        <Styled.LoginSeparator>
          <Styled.LoginSeparatorText>{separator}</Styled.LoginSeparatorText>
        </Styled.LoginSeparator>
      </ShouldRender>
    </Styled.EntryBtnsContainer>
  );
};

export default AuthGoogle;
