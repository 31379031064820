import styled from "styled-components";
import { Button } from "react-bootstrap";
import { StyledProps } from "../../../utils";

export const TopRightPosition = styled.div`
  position: absolute;
  right: 40px;
  top: 57px;
  padding: 0;
`;

export const ProfileInfoContainer = styled.div`
  border: 1px solid #e0e0e0;
  background: #ffffff;
  padding: 40px 40px 20px;
  display: grid;
  column-gap: 1rem;
  row-gap: 0.5rem;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-column: 3;
  grid-row: 2;
  width: 100%;
  margin-bottom: 0.25rem;
  gap: 16px;
  height: fit-content;
  border: 1px solid #e0e0e0;
  position: relative;
  border-radius: 20px 20px 0px 0px;

  * {
    font-family: "inter400", sans-serif;
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media only screen and (max-width: 650px) {
    row-gap: 1.5rem;
  }

  @media only screen and (max-width: 575px) {
    padding: 32px 24px 24px;
  }
`;

export const ProfileImageCover = styled.div<{ isMouseOver: boolean }>`
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;

  @media only screen and (max-width: 767px) {
    margin: 0 auto;
  }
`;

export const ProfileImage = styled.img<{ isMouseOver: boolean }>`
  width: 110px;
  height: 110px;
  left: 5px;
  top: 5px;
  border-radius: 100px;
  z-index: 0;
  transition: all 0.2s ease;
  filter: ${(props): StyledProps => (props.isMouseOver ? "brightness(0.4)" : "brightness(1)")};
`;

export const TalentNameCover = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 767px) {
    text-align: center;
    align-items: center;
  }
`;

export const JobTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  text-transform: capitalize;
  margin-bottom: 16px;
  color: darkgrey;
`;

export const TalentName = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  text-transform: capitalize;
  margin-bottom: 16px;
`;

export const TalentAvailabilityCover = styled.div`
  padding: 12px 0 7px 0;
  margin-right: auto;
  font-size: 12px;

  @media only screen and (max-width: 767px) {
    margin: 0 auto;
  }
`;

export const TalentExpertise = styled.div`
  display: flex;
  column-gap: 0.5rem;
  color: #3c3c3c;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-transform: capitalize;
    margin-bottom: 0px;
  }

  span {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    margin: 5px 0;
    background: grey;
  }
`;

export const TalentProfileStats = styled.div`
  grid-row-start: 2;
  grid-column-start: 2;
  display: flex;
  column-gap: 2.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  @media only screen and (max-width: 950px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 1rem;
  }

  @media only screen and (max-width: 550px) {
    grid-row-start: 3;
    grid-column-end: 2;
  }
`;

export const TalentProfileStat = styled.div`
  font-size: 14px;

  p:first-child {
    margin-bottom: 0.1rem;

    span {
      font-family: "inter700", sans-serif;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }
`;

export const StatSliderCover = styled.div`
  margin-top: 0.25rem;
`;

export const InviteBtnCover = styled.div`
  text-align: right;
  grid-row-start: 2;
  grid-column-start: 3;
`;

export const InviteBtn = styled(Button)`
  background: #3c65fe;
  border-radius: 8px;
  padding: 0.75rem 1rem;
`;
export const EntryBtnsContainer = styled.div`
  margin: 16px 0;
`;

export const EntryBtn = styled.button`
  width: 100%;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  min-width: 222px;
  height: auto;
  min-height: 50px;
  border: none;
  border-radius: 10px;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: #3f73f6;
  background: #fff;
  border: 1px solid #e3e6e9;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    color: #3f73f6;
  }
  &:active {
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px);
    -moz-transform: translateY(2px);
    -o-transform: translateY(2px);
  }
  &[href] {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1023px) {
    min-width: 100%;
  }
`;

export const TalentNameEdit = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
`;

export const ImageEditContainer = styled.div<{ isMouseOver: boolean }>`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 40px;
  left: 45px;
  filter: invert(1);
  display: ${(props): StyledProps => (props.isMouseOver ? "block" : "none")};

  &:hover {
    cursor: pointer;
  }
`;

export const PhotoInput = styled.input`
  display: none;
`;

export const WrapTip = styled.div`
  position: absolute;
  right: -16px;
  top: 0;
`;
