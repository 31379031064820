import React from "react";
import { Link } from "react-router-dom";
import * as Styled from "./styles";

export const NotFound: React.FC = () => {
  return (
    <Styled.NotFoundWrapper>
      <Styled.H1>404</Styled.H1>
      <Styled.H2>Oops, page not found</Styled.H2>
      <Styled.ParaMd>
        We apologise for the inconvenience. It seems like you are trying to access a page that has
        been deleted or never existed.
      </Styled.ParaMd>
      <Link to="/">
        <Styled.BackBtn>Back To Home page</Styled.BackBtn>
      </Link>
    </Styled.NotFoundWrapper>
  );
};

export default NotFound;
