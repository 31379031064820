export const dataUrl = "https://remotebase-images.s3.amazonaws.com/engineersList.json";
export const dashboardPara = "Fullstack Engineers";
export const pageLimit = 9;
export const fetchLimit = 300;
export const paginationDataLimit = 9;
export const pagesLimit = 5;
export const totalPagesInitialization = 0;
export const currentPageInitialization = -1;
export const nextPageJump = 1;
export const previousPageJump = -1;

export const initialTitle = "Engineer Stage";
// eslint-disable-next-line
export const SupportEmail = "hello@remotebase.com";
export const CustomerSuccessEmail = "customersuccess@remotebase.com";
