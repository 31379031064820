import { FC } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ProfileEditIcon } from "../../assets";
import { ShouldRender } from "../../shouldRender";
import * as S from "./style";

interface IToolTip {
  handleEditClick?: () => void;
  icon?: JSX.Element;
  popoverText?: string;
}

export const EditToolTip: FC<IToolTip> = ({ handleEditClick, popoverText, icon }) => {
  const basicPopover = (): JSX.Element => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>{popoverText || "Edit"}</Popover.Content>
      </Popover>
    );
  };

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={basicPopover()}
      transition={false}
    >
      <S.IconWrapper>
        <ShouldRender if={!icon}>
          <ProfileEditIcon onClick={handleEditClick} width={20} />
        </ShouldRender>
        {icon && <ShouldRender if={icon}>{icon}</ShouldRender>}
      </S.IconWrapper>
    </OverlayTrigger>
  );
};

export default EditToolTip;
