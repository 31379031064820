import { TechDomainSkills } from "../commons";

export enum DomainTestStatus {
  TAKE = "TAKE",
  PASSED = "PASS",
  FAILED = "FAIL",
}

export const SkillsSuffix: { [key: string]: string } = {
  [TechDomainSkills.Java]: "JAVA",
  [TechDomainSkills.ReactAndNode]: "REACT_NODE",
  [TechDomainSkills.Ror]: "RAILS",
  [TechDomainSkills.Python]: "PYTHON",
  [TechDomainSkills.Android]: "APP",
  [TechDomainSkills.ReactNative]: "APP",
  [TechDomainSkills.PERFORMANCE_MARKETING_SPECIALIST]: "PM",
  [TechDomainSkills.SDR]: "SDR",
  [TechDomainSkills.TECHNICAL_RECRUITER]: "TR",
  [TechDomainSkills.DESIGNER]: "DESIGN",
};

export const TalentTypeNameSuffix: { [key: string]: string } = {
  SOFTWARE_DEVELOPER: "DEV",
  PERFORMANCE_MARKETING_SPECIALIST: "PM",
  SDR: "SDR",
  TECHNICAL_RECRUITER: "TR",
  DESIGNER: "DESIGN",
  SOFTWARE_DEVELOPER_AI_TRAINING: "AIDEV",
};
