import styled from "styled-components";
import { StyledProps } from "@remotebase/constants";

export const SkillScoreContainer = styled.div<{
  $removeBorderTop?: boolean;
}>`
  background-color: #ffffff;
  padding: 48px 40px;
  border-width: ${(props): StyledProps =>
    props.$removeBorderTop ? "0px 0px 1px 0px" : "1px 0px 1px 0px"};
  border-style: solid;
  border-color: #e0e0e0;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: 575px) {
    padding: 24px;
  }
`;

export const SkillScoreTitle = styled.div`
  /* Skill Score */

  width: 251px;
  height: 22px;

  font-family: "poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  /* Additional Black */

  color: #212121;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 28px;
`;

export const SkillScoreScoreCover = styled.div`
  margin: 0.5rem 0 1.5rem;
`;

export const TakeTestBtn = styled.button`
  margin-top: 1rem;
  padding: 0.8rem 1.25rem;
  border-radius: 0.4rem;
  font-size: 0.875rem;
  letter-spacing: 0.35px;
  background-color: transparent;
  border: 1px solid #3615bd;
  color: #3615bd;
  cursor: pointer;
`;
