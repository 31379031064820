/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";
import { useHistory } from "react-router-dom";
import { AuthRoutes } from "utils";
import { InfoItem } from "../styles";
import MandateInfoCard from "../MandateInfoCard";
import CalendarIcon from "../../icons/CalendarIcon";
import BankIcon from "../../icons/BankIcon";
import MoneyIcon from "../../icons/MoneyIcon";
import ATMCardIcon from "../../icons/ATMCardIcon";
import { MandateProvidedProps } from ".";

export function processMandateData(
  data: Record<string, string | number>,
  showError: (input: { title: string; message: string }) => void,
  withdrawMandateBtnRef: React.MutableRefObject<HTMLButtonElement | null>,
  history: ReturnType<typeof useHistory>,
): void {
  if (data.code === 200) {
    showError({
      title: "Mandate withdrawal Successful",
      message: "You have successfully withdrawn your mandate",
    });
    if (withdrawMandateBtnRef.current) {
      // eslint-disable-next-line no-param-reassign
      withdrawMandateBtnRef.current.disabled = true;
    }
    setTimeout(() => {
      history.push(AuthRoutes.Dashbord);
    }, 2000);
  } else {
    showError({ title: "Bad Request", message: data.message as string });
  }
}

/**
 * The function takes an ISO string and returns a formatted date and time string using the moment
 * library.
 * @param {string} isoString - The isoString parameter is a string that represents a date and time in
 * ISO format. It typically looks like this: "2022-01-01T12:00:00Z".
 * @returns The function `parseTime` takes an ISO string as input and returns a formatted string
 * representing the date and time in the format "Do, MMMM. YYYY. h:mm:ss a". The formatted string is
 * returned as the output of the function.
 */

export function parseTime(isoString: string): string {
  return moment(isoString).format("Do, MMMM. YYYY. h:mm:ss a");
}

type MandateType = MandateProvidedProps["paymentAndMandate"]["mandate"];
type PaymentMethodType = MandateProvidedProps["paymentAndMandate"]["paymentMethod"];

export function getMandateInfoItems(
  mandate: MandateType,
  paymentMethod: PaymentMethodType,
): JSX.Element[] {
  return [
    {
      labelName: "Created At",
      value: parseTime(mandate.customerAcceptance.acceptedAt),
      Icon: CalendarIcon,
    },
    { labelName: "Bank Name", value: paymentMethod.usBankAccount.bankName, Icon: BankIcon },
    {
      labelName: "Account Type",
      value: paymentMethod.usBankAccount.accountType,
      Icon: MoneyIcon,
    },
    { labelName: "Last4", value: `XXX-${paymentMethod.usBankAccount.last4}`, Icon: ATMCardIcon },
  ].map(({ labelName, value, Icon }, index) => {
    return (
      <InfoItem key={index}>
        <MandateInfoCard labelName={labelName} value={value} Icon={Icon} />
      </InfoItem>
    );
  });
}
