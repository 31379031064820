import { FC } from "react";
import * as Styled from "./styles";

export default function SupportHero(): ReturnType<FC> {
  return (
    <Styled.HeroStyled>
      <Styled.Wrapper>
        <Styled.HeroContent>
          <Styled.HeroTitle className="aos-item" data-aos="fade-up" data-aos-duration="500">
            Got Any Questions or Concerns? We&apos;re Here to Help
          </Styled.HeroTitle>
          <Styled.HeroPara
            className="aos-item"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="200"
          >
            If you have any questions, comments, or concerns, please reach out to us using the
            support form below, and we will get back to you as soon as possible.
          </Styled.HeroPara>
        </Styled.HeroContent>
      </Styled.Wrapper>
    </Styled.HeroStyled>
  );
}
