// eslint-disable-next-line import/prefer-default-export
export const matchesForJob = /* GraphQL */ `
  query MatchesForJob(
    $jobID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelJobMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesForJob(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        talentID
        jobID
        lastActivity
        stage
        process
        isRockstar
        isPublished
        createdAt
        updatedAt
        version
        talent {
          id
          fullName
          email
          phone
          country
          role
          createdAt
          updatedAt
          version
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            problemSolvingPercentage
            isPassPS
            isPassHR
            isPassFinalTech
            isHired
            isNetwork
            hrInterviewVideoId
            statusMeta
            heardFrom
            currentSalary
            expectedSalary
            interests
            resumeLink
            imgLink
            createdAt
            updatedAt
            version
            talentProfileTalentId
          }
        }
        job {
          id
          companyID
          status
          title
          description
          responsibilites
          tags
          requiredTechDomainSkills
          seniorityLevel
          isPublic
          isPermanent
          internalNotes
          department
          jobPerks
          createdAt
          updatedAt
          company {
            id
            name
            logo
            stage
            tagline
            description
            address {
              apartmentAddress
              city
              id
              country
              district
              postalCode
              streetAddress
            }
            industry
            createdAt
            updatedAt
          }
        }
        feedback {
          communicationSkillsLevel
          techSkillAndDomainKnowledgeLevel
          problemSolvingSkillLevel
          isRejected
          otherFeedback
        }
        talentProfileVersionID
        talentProfileVersion {
          id
          name
          skills
          experience
          isAvailable
          social
          englishProficiency
          expectedSalary
          expectedSalaryInUsd
          interests
          educationHistory {
            degree
            school
            startDate
            endDate
          }
          employmentHistory {
            title
            summary
            startDate
            endDate
            company
            currentJob
          }
          socialProfiles {
            urlType
            url
          }
          imgLink
          bio
          title
          talentID
          createdAt
          updatedAt
          version
        }
      }
      nextToken
    }
  }
`;

export const calEventsForClient = /* GraphQL */ `
  query CalEventsForClient(
    $clientID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClientCalendarInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calEventsForClient(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        title
        clientEmail
        eventLength
        createdAt
        updatedAt
        version
        client {
          id
          fullName
          email
          phone
          country
          role
          companyID
          timezone
          availability {
            day
            date
          }
          createdAt
          updatedAt
          version
          company {
            id
            name
            logo
            stage
            tagline
            description
            industry
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
