/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { useHistory } from "react-router-dom";
import { MutationFunctionOptions } from "@apollo/client";
import {
  SaveStripeSetupIntentMutation,
  SaveStripeSetupIntentMutationVariables,
} from "@remotebase/amplify-constants/API";

import { SetupIntent } from "@stripe/stripe-js";

type ShowInfoType = (input: { title: string; message: string }) => void;

async function handleSaveSetupIntentInDb(
  setupIntentId: string,
  saveSetupIntentInDb: (
    options: MutationFunctionOptions<
      SaveStripeSetupIntentMutation,
      SaveStripeSetupIntentMutationVariables
    >,
  ) => Promise<Record<string, any>>,
  errorCB: () => void,
  successCB: () => void,
): Promise<void> {
  saveSetupIntentInDb({
    variables: {
      setupIntentId,
    },
  }).then((response): void => {
    const { code } = response.data.saveStripeSetupIntent;
    if (code !== 200) {
      errorCB();
    } else {
      successCB();
    }
  });
}

function showInfoAndReloadPage(
  showInfo: ShowInfoType,
  history: ReturnType<typeof useHistory>,
  title: string,
  message: string,
  timeout = 0,
): void {
  showInfo({
    title,
    message,
  });

  setTimeout(() => {
    history.go(0);
  }, timeout);
}

export function saveSetupIntentToDbAndUpdateUserAgreement(
  saveSetupIntentInDb: (
    options: MutationFunctionOptions<
      SaveStripeSetupIntentMutation,
      SaveStripeSetupIntentMutationVariables
    >,
  ) => Promise<Record<string, any>>,
  setupIntentId: string,
  showError: ShowInfoType,
  errorMsg: string,
  history: ReturnType<typeof useHistory>,
  handleConfirmSetupIntent: () => Promise<void>,
): void {
  const errorCB = (): void => {
    const timeoutInMilliSeconds = 4000;
    showInfoAndReloadPage(showError, history, "Error", errorMsg, timeoutInMilliSeconds);
  };
  const successCB = (): void => {
    handleConfirmSetupIntent();
  };
  handleSaveSetupIntentInDb(setupIntentId, saveSetupIntentInDb, errorCB, successCB);
}

export function processNextAction(
  setupIntent: SetupIntent,
  showInfo: ShowInfoType,
  cancelledMsg: string,
  history: ReturnType<typeof useHistory>,
  successMessage: string,
  setShowProcessMandagePage: (showPage: boolean) => void,
  setCustomMsg: (customMsg) => void,
  processingMicroDepositMsg: string,
): void {
  if (setupIntent.status === "requires_payment_method") {
    const title = "Action Cancelled";
    showInfoAndReloadPage(showInfo, history, title, cancelledMsg);
  } else if (setupIntent.status === "succeeded" || setupIntent.status === "processing") {
    // Confirmation succeeded! The account is now saved.
    // Display a message to customer.
    showInfo({
      title: "Success",
      message: successMessage,
    });
    setShowProcessMandagePage(true);
  } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
    // The account needs to be verified via microdeposits.
    // Display a message to consumer with next steps (consumer waits for
    // microdeposits, then enters a statement descriptor code on a page sent to them via email).
    setCustomMsg(processingMicroDepositMsg);
    setShowProcessMandagePage(true);
  }
}
