import ProcessingIcon from "../icons/ProcessingIcon";
import { defaultMandateProcessingText } from "./constants";
import {
  Container,
  InfoTextContainer,
  LoaderIconContainer,
  MiddleContainer,
  ProcessInfoPara,
} from "./styles";

type Prop = {
  customMsg?: string;
};

function MandateProcessing({ customMsg }: Prop): JSX.Element {
  const info = customMsg || defaultMandateProcessingText;
  return (
    <Container className="h-100 bg-white d-flex justify-content-center align-items-center">
      <MiddleContainer>
        <LoaderIconContainer>
          <ProcessingIcon />
        </LoaderIconContainer>
        <InfoTextContainer>
          <ProcessInfoPara>{info}</ProcessInfoPara>
        </InfoTextContainer>
      </MiddleContainer>
    </Container>
  );
}

export default MandateProcessing;
