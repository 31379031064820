import React, { useMemo } from "react";
import { TalentProfileFeedback, YourProfileFeedback } from "components";
import { isEmptyString, ShouldRender } from "@remotebase/components";
import { withTalent, getTalentFeedback, getYourFeedback } from "state/talent";
import { ITalentProps } from "state/types";
import * as Styled from "./styles";

const ProfileSpecs: React.FC<ITalentProps> = ({ talentState }) => {
  // eslint-disable-next-line
  const talentFeedback = useMemo(
    () => getTalentFeedback(talentState.currentTalent?.talent?.profile?.talentFeedback || []),
    [talentState.currentTalent?.talent?.profile],
  );
  const yourFeedback = useMemo(
    () => getYourFeedback(talentState?.clientFeedback || []),
    [talentState?.clientFeedback],
  );
  return (
    <Styled.ProfileSpecs>
      {yourFeedback?.map((item, index) => {
        return (
          <ShouldRender key={index} if={!isEmptyString(item?.feedback)}>
            <YourProfileFeedback key={index} {...item} />
          </ShouldRender>
        );
      })}

      {talentFeedback?.map((item, index) => (
        <ShouldRender key={index} if={!isEmptyString(item.feedback?.description)}>
          <TalentProfileFeedback key={index} {...item} />
        </ShouldRender>
      ))}
    </Styled.ProfileSpecs>
  );
};

export default withTalent(ProfileSpecs);
