import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddIcon, SubtractIcon } from "assets/icons";
import * as S from "./styles";
import TimeRange from "./TimeRange";

type Props = {
  name: string;
  text: string;
};

export const TimeSelector: FC<Props> = ({ name, text }) => {
  const {
    formState: { errors, isValid },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name });
  return (
    <S.TimeSelector>
      <S.SideContent>
        <S.Day>{text}</S.Day>
      </S.SideContent>
      <div>
        {fields.map(({ id }, index) => (
          <div key={id} className="d-flex align-items-center mb-3">
            <div>
              <TimeRange name={`${name}.${index}`} />
              <S.Error>{errors?.[name]?.[index]?.startTime?.message}</S.Error>
              <S.Error>{errors?.[name]?.[index]?.endTime?.message}</S.Error>
            </div>
            <S.SideContent>
              <S.Action
                type="button"
                className="ml-5"
                onClick={(): void => {
                  append({ startTime: "09:00", endTime: "17:00" });
                }}
              >
                <AddIcon />
              </S.Action>
              {index !== 0 && (
                <S.Action
                  type="button"
                  className="ml-1"
                  onClick={(): void => {
                    remove(index);
                  }}
                >
                  <SubtractIcon />
                </S.Action>
              )}
            </S.SideContent>
          </div>
        ))}
        {!isValid && <S.Error className="mb-3">{errors?.[name]?.message}</S.Error>}
      </div>
    </S.TimeSelector>
  );
};

export default TimeSelector;
