import { FC, useEffect } from "react";
import { Spinner } from "react-bootstrap";

import useSetupIntentClientSecret from "../hooks/useSetupIntentClientSecret";
import { MiddleContainer } from "./styles";
import BillingDetails from "./BillingDetails";
import ErrorComponent from "./Error";

const FetchClientSecretForBillingDetails: FC = () => {
  const { createSetupIntent, data, error, createSetupIntentTriggered, isLoading } =
    useSetupIntentClientSecret();

  useEffect(() => {
    createSetupIntent();
  }, []);

  if (error) {
    return <ErrorComponent />;
  }

  if (isLoading || !createSetupIntentTriggered) {
    return (
      <MiddleContainer>
        <Spinner animation="border" />
      </MiddleContainer>
    );
  }

  if (!data) {
    return <ErrorComponent />;
  }

  const { clientSecret, setupIntentId } = data;

  if (!clientSecret || !setupIntentId) {
    return <ErrorComponent />;
  }

  return <BillingDetails clientSecret={clientSecret} setupIntentId={setupIntentId} />;
};

export default FetchClientSecretForBillingDetails;
