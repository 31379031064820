import styled from "styled-components";

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 16px;
  }

  &.active {
    label {
      color: #898989;
    }
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  color: #bfbfbf;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 24px;
`;

export const FormField = styled.input`
  font-size: 16px;
  color: #898989;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: flex;
  background-color: #fff;
  border: 1px solid #bbbbbb;
  border-radius: 27.5px;
  padding: 8px 24px;
  width: 100%;
  height: calc(2.375em + 1rem + 2px);
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    height: calc(2em + 1rem + 2px);
  }

  &.focus-visible,
  &:focus {
    background-color: #fff;
    border: 2px solid #5138ed;
    outline: none;
    box-shadow: none;
    color: #404040;
  }
`;
