import { FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  LAPTOP_VIEW,
  mainNavItems,
  SidebarDropdown,
  sidebarRoutes,
  withSidebar,
} from "state/sidebar";
import { SidebarPropsWithChildren } from "utils";
import * as Styled from "layouts/sidebar_layout_copy/styles";
import MainNavItem from "./main-nav-item";

const SidebarMain: FC<SidebarPropsWithChildren> = ({
  children,
  sidebarIsCollapsed,
  toggleSidebar,
}) => {
  const { pathname } = useLocation();
  const title = useMemo(() => {
    const mainPaths = Object.keys(sidebarRoutes);
    if (pathname.includes("perks")) return "Perks";
    if (!mainPaths.map((key) => sidebarRoutes[key]).includes(pathname)) return "Engineer Profile";
    return mainPaths.find((key) => sidebarRoutes[key] === pathname);
  }, [pathname]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState<SidebarDropdown | null>();
  const collapsedBySidebar = useMemo(
    () => window.innerWidth < LAPTOP_VIEW && !sidebarIsCollapsed,
    [sidebarIsCollapsed],
  );
  const toggleIsCollapsed = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const { collapse, name } = e.currentTarget.dataset;

      if (!sidebarIsCollapsed && collapse === "false" && window.innerWidth < LAPTOP_VIEW)
        toggleSidebar();
      setIsCollapsed(collapse === "true");
      setActiveDropdown(name as SidebarDropdown);
    },
    [sidebarIsCollapsed, toggleSidebar],
  );
  const DropdownComponent = useMemo(
    () => (activeDropdown ? mainNavItems[activeDropdown] : null),
    [activeDropdown],
  );

  return (
    <Styled.Main data-full={sidebarIsCollapsed}>
      <Styled.MainHeader>
        <Styled.MainHeaderTitle>{title}</Styled.MainHeaderTitle>
        <Styled.MainHeaderNav>
          {Object.keys(mainNavItems).map((name: SidebarDropdown) => (
            <MainNavItem key={name} name={name} handleOnClick={toggleIsCollapsed} />
          ))}
          {DropdownComponent && (
            <Styled.MainNavDropdown
              data-collapsed={isCollapsed || collapsedBySidebar}
              data-collapse="true"
              data-full={sidebarIsCollapsed}
              onClick={toggleIsCollapsed}
            >
              <Styled.MainNavDropdownWrapper>
                <DropdownComponent />
              </Styled.MainNavDropdownWrapper>
            </Styled.MainNavDropdown>
          )}
        </Styled.MainHeaderNav>
      </Styled.MainHeader>
      {children}
    </Styled.Main>
  );
};

export default withSidebar(SidebarMain);
