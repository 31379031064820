import { Button } from "react-bootstrap";
import styled from "styled-components";

export const CompanyMatchingHeadOpt = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const EyeButton = styled(Button)`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: #3f73f6;
  border: none;
  margin-left: 5px;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }

  & svg {
    width: 14px;
    height: 14px;

    & path {
      fill: #e4e4e4;
    }
  }
`;

export const ProfileHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

export const ProfileBlockTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  font-weight: 600;

  font-style: normal;
  font-size: 18px;
  line-height: 27px;

  color: #212121;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;

  @media only screen and (max-width: 991px) {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  @media only screen and (max-width: 575px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
`;

export const ProfileBadge = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  padding: 5px 14px;
  display: inline-flex;
  border-radius: 8px;
  line-height: 1;
  margin: 8px 0px auto 8px;
`;

export const PositiveBadge = styled(ProfileBadge)`
  color: #fff;
  background-color: #27ae60;
`;

export const NegativeBadge = styled(ProfileBadge)`
  color: #fff;
  background-color: #d8453e;
`;

export const NeutralBadge = styled(ProfileBadge)`
  /* Secondary - Grey */
  background: #c4c4c4;
  color: #fff;
`;

export const Badge = styled.span`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 10px;

  width: 74px;
  height: 22px;

  border-radius: 44px;
  &.badge__positive {
    background-color: #27ae60;
    color: #fff;
  }
  &.badge__negative {
    background-color: #d8453e;
    color: #fff;
  }
  &.badge__neutral {
    background: #c4c4c4;
    color: #fff;
  }
`;

export const HelpIcon = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-left: 6.33px;
  display: inline-flex;
  border-radius: 8px;
  line-height: 1;
  margin: 6px 0px auto 8px;
`;

export const IconBlock = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 22px;
  padding: 0px;
`;

export const OptButton = styled.button`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: #3f73f6;
  border: none;
  transition: all 0.25s;
  margin-left: 5px;

  & svg {
    width: 14px;
    height: 14px;

    & path {
      fill: #e4e4e4;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }
`;
