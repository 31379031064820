import React, { useEffect, useState } from "react";
import SliderComponent from "rc-slider";
import { Feedback } from "@remotebase/amplify-constants/API";
import * as Styled from "../styles";

interface Props {
  feedback?: Feedback | null;
}

export const ViewFeedbackRating: React.FC<Props> = ({ feedback }) => {
  const { score } = feedback || {};
  const calculatedScore = score || 0;
  const [profileScore, setProfileScore] = useState(
    calculatedScore < 10 ? calculatedScore * 10 : calculatedScore,
  );
  useEffect(() => {
    if (feedback) {
      const pScore =
        feedback.score !== undefined && feedback.score !== null && feedback.score < 10
          ? feedback.score * 10
          : feedback?.score;
      setProfileScore(pScore || 0);
    }
  }, [feedback]);
  return (
    <Styled.TechFeedbackWrapper>
      <Styled.ProfileBlockRating>
        <Styled.ProfileScore>{`Overall • ${profileScore}%`}</Styled.ProfileScore>
        <SliderComponent
          min={0}
          max={100}
          defaultValue={profileScore}
          draggableTrack={false}
          style={{ padding: "3px 0" }}
          trackStyle={{ backgroundColor: "#27ae60", height: "8px" }}
          railStyle={{ backgroundColor: "#e0e0e0", height: "8px" }}
          value={profileScore}
          handleStyle={{ display: "none" }}
        />
      </Styled.ProfileBlockRating>
    </Styled.TechFeedbackWrapper>
  );
};

export default ViewFeedbackRating;
