import { EmploymentEntry } from "@remotebase/amplify-constants/API";
import { FC, Fragment, useState } from "react";
import { CrossIcon, EditRoundedIcon } from "../../assets/icons";
import { ShouldRender } from "../../shouldRender";
import { extractMonthAndYear } from "../../utils";
import * as Styled from "../styles";

interface Props {
  children?: React.ReactNode;
  employmentHistory: Array<EmploymentEntry | null>;
  addEmployementBtn?: React.ReactNode;
  editEmployment?: (index: number, education: EmploymentEntry) => void;
  deleteEmployment?: (id: number) => void;
  isAdmin?: boolean;
}

export const EmploymentSection: FC<Props> = ({
  employmentHistory,
  addEmployementBtn,
  editEmployment,
  deleteEmployment,
  isAdmin,
}) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const defaultBioLength = 300;
  const deleteHandler = (e: React.MouseEvent, index: number): void => {
    e.stopPropagation();
    deleteEmployment?.(index);
  };
  return (
    <Fragment>
      <Styled.EmployementHead>
        <Styled.JobHistoryTitle>Employment history</Styled.JobHistoryTitle>
        {addEmployementBtn}
      </Styled.EmployementHead>
      {employmentHistory.length > 0 ? (
        <Styled.JobHistoryContainer>
          {employmentHistory?.map(
            (employment, index) =>
              employment && (
                <Styled.HistoryCardWrapper key={`employement-${index}`}>
                  <ShouldRender if={isAdmin}>
                    <Styled.ModifyTopIcon>
                      <CrossIcon onClick={(e): void => deleteHandler(e, index)} />
                      <EditRoundedIcon onClick={(): void => editEmployment?.(index, employment)} />
                    </Styled.ModifyTopIcon>
                  </ShouldRender>
                  <Styled.HistoryCardTop>
                    <Styled.HistoryCardTitle>
                      <Styled.HistoryTitleText>{employment?.title}</Styled.HistoryTitleText>•{" "}
                      <span>{employment?.company}</span>
                    </Styled.HistoryCardTitle>
                  </Styled.HistoryCardTop>
                  <Styled.HistoryCardDate>
                    {`${extractMonthAndYear(employment?.startDate)} -
                  ${extractMonthAndYear(employment?.endDate)}`}
                  </Styled.HistoryCardDate>
                  <Styled.HistoryCardPara $isReadMore={isReadMore}>
                    {employment?.summary}
                  </Styled.HistoryCardPara>
                  <ShouldRender
                    if={employment?.summary && employment?.summary?.length > defaultBioLength}
                  >
                    <Styled.ProfileSummaryLink onClick={(): void => setIsReadMore(!isReadMore)}>
                      {isReadMore ? "Read less" : "Read more"}
                    </Styled.ProfileSummaryLink>
                  </ShouldRender>
                </Styled.HistoryCardWrapper>
              ),
          )}
        </Styled.JobHistoryContainer>
      ) : (
        <Styled.NoEmployment>No Data Available</Styled.NoEmployment>
      )}
    </Fragment>
  );
};

export default EmploymentSection;
