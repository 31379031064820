import { FC } from "react";
import { Talent } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "../..";
import { DownloadIcon, UploadArrowIcon } from "../../assets/icons";
import * as Styled from "./styles";

interface Props {
  data: Talent | null;
  isEditable?: boolean;
  handleEditClick?: () => void;
}
export const ProfileResumeDownload: FC<Props> = ({ data, isEditable, handleEditClick }) => {
  const resumeLink = data?.profile?.resumeLink || "";
  const fullName = data?.fullName || "";
  const resumeName = `${fullName} • `;
  const currentYear = new Date().getFullYear();

  const handleClick = (): void => {
    if (handleEditClick) {
      handleEditClick();
    }
  };

  return (
    <Styled.ProfileGridWrapper>
      <ShouldRender if={resumeLink?.length}>
        <Styled.ProfileResumeDownload>
          <Styled.ProfileDownloadTopSection>
            <Styled.ProfileDownloadTitle>
              <ShouldRender if={!isEditable}>
                <Styled.SectionHeading>Download</Styled.SectionHeading>
              </ShouldRender>
              <ShouldRender if={isEditable}>
                <Styled.SectionHeading>Resume</Styled.SectionHeading>
              </ShouldRender>
            </Styled.ProfileDownloadTitle>
          </Styled.ProfileDownloadTopSection>
          <Styled.ProfileDownloadBottomSection>
            <Styled.ProfileDownloadBottomBody>
              <Styled.ProfileDownloadBottomChest>
                <Styled.ProfileDownloadBottomText>
                  <Styled.ProfileDownloadBottomName>
                    {resumeName}
                    <Styled.ProfileDownloadBottomNameSpan>
                      Resume {currentYear}
                    </Styled.ProfileDownloadBottomNameSpan>
                  </Styled.ProfileDownloadBottomName>
                  <ShouldRender if={!isEditable}>
                    <Styled.ProfileDownloadBottomDesc>
                      You can download my current resume to click button below
                    </Styled.ProfileDownloadBottomDesc>
                  </ShouldRender>
                  <ShouldRender if={isEditable}>
                    <Styled.ProfileDownloadBottomDesc>
                      You can upload or download your current resume by clicking the button below
                    </Styled.ProfileDownloadBottomDesc>
                  </ShouldRender>
                </Styled.ProfileDownloadBottomText>
                <ShouldRender if={!isEditable}>
                  <a href={resumeLink}>
                    <Styled.ProfileDownloadButton>
                      <Styled.ProfileDownloadButtonText>
                        Download file
                      </Styled.ProfileDownloadButtonText>
                      <Styled.ProfileDownloadButtonVectorContainer>
                        <DownloadIcon width={16} height={16} />
                      </Styled.ProfileDownloadButtonVectorContainer>
                    </Styled.ProfileDownloadButton>
                  </a>
                </ShouldRender>
                <ShouldRender if={isEditable}>
                  <Styled.UploadWrapper>
                    <Styled.ProfileDownloadButton onClick={handleClick}>
                      <Styled.ProfileDownloadButtonText>
                        Upload file
                      </Styled.ProfileDownloadButtonText>
                      <Styled.ProfileDownloadButtonVectorContainer>
                        <UploadArrowIcon width={16} height={16} />
                      </Styled.ProfileDownloadButtonVectorContainer>
                    </Styled.ProfileDownloadButton>
                    <a href={resumeLink}>or download file</a>
                  </Styled.UploadWrapper>
                </ShouldRender>
              </Styled.ProfileDownloadBottomChest>
            </Styled.ProfileDownloadBottomBody>
          </Styled.ProfileDownloadBottomSection>
        </Styled.ProfileResumeDownload>
      </ShouldRender>
    </Styled.ProfileGridWrapper>
  );
};

export default ProfileResumeDownload;
