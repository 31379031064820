import styled from "styled-components";

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0.6;
`;

export const SliderValue = styled.div`
  /* 95% */

  width: 25px;
  height: 12px;

  /* Lead Small */

  font-family: "roboto400";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  /* Additional Black */

  color: #212121;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 8px;
  margin-top: 6px;
`;

export const SkillsLabel = styled.label`
  /* Overall */

  width: 251px;
  height: 17px;

  font-family: "inter400";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Additional - Dark Grey */

  color: #7c7c7c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 0px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
