import styled from "styled-components";

export const ProfileGridWrapper = styled.div`
  width: 100%;
`;

export const ProfilePortfolio = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 27px 48px;
  //gap: 10px;

  width: 100%;
  height: 430px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  z-index: 50;
`;

export const ProfilePortfolioTopSection = styled.div`
  width: 100%;
  height: 83px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;

  padding: 27px 48px;

  z-index: 70;
`;

export const ProfilePortfolioBottomSection = styled.div`
  width: 100%;
  height: 344px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0 0 10px 10px;

  overflow: scroll;

  padding: 42px 48px;

  z-index: 70;
`;

export const ProfilePortfolioTitle = styled.div`
  width: 824px;
  height: 29px;

  font-family: "poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;

  /* identical to box height, or 29px */

  /* Additional Black */
  color: #212121;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;

  z-index: 100;
`;

export const ProfilePortfolioBottomBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 824px;
  height: 260px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ProfilePortfolioImageContainer = styled.img`
  /* Rectangle 16575 */

  width: 200px;
  height: 200px;

  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ProfilePortfolioDescription = styled.label`
  /* Title */

  width: 195px;
  height: 44px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ProfilePortfolioFrame = styled.div`
  /* Frame 7621 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 200px;
  height: 260px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ProfilePortfolioCaseStudyTitle = styled.b`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ProfilePortfolioCaseStudyDescription = styled.b`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
