export enum ReferralStages {
  Total = "Total Referrals",
  Joinned = "Signed Up",
  ClearedDomainTest = "Passed Skill Tests",
  FailedDomainTest = "Failed Skill Tests",
  ClearedPSTest = "Passed PS Tests",
  FailedPSTest = "Failed PS Tests",
  ClearedInterviews = "Passed interviews",
  Rejected = "Rejected",
  Networked = "Networked",
  Hired = "Hired",
  ThreeMonthsCompleted = "3 months at Remotebase",
}

export const sample = "";
export const refDefaultStatus = "Hasn’t signed up";
