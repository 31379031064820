import React from "react";
import { PrivacyPolicyStyled } from "./styles";

const Terms: React.FC = () => {
  return (
    <PrivacyPolicyStyled>
      <PrivacyPolicyStyled>
        <div className="privacy__wrapper">
          <h1 className="header__title">Privacy Policy</h1>
          <div className="privacy__container">
            <p className="privacy__date">Last updated: July 7, 2022</p>
            <p className="privacy__para">
              Remotebase provides this Privacy Policy to let you know our policies and procedures
              regarding the collection, use and disclosure of information through
              www.remotebase.com. By using our site or site services, you agree to this Privacy
              Policy. Also, any other websites, features, or online services that are controlled by
              Remotebase and any information Remotebase collects offline. This Privacy Policy also
              explains the collection, usage, and sharing of personal information.
            </p>
            <div className="privacy__defination">
              <p className="privacy__para">
                <strong>Definitions</strong>
              </p>
              <ul className="privacy__list">
                <li>
                  <strong>"Remotebase"</strong> We may also refer to Remotebase as "we", "us", or
                  "our".
                </li>
                <li>
                  <strong>"Remotebase Client"</strong> means any customer that has entered into an
                  agreement with Remotebase to use our services.
                </li>
                <li>
                  <strong>"Remotebase Developer"</strong> means any authorized member of the
                  Remotebase Talent Network.
                </li>
                <li>
                  <strong>"Personal Information"</strong> means any information which may identify
                  an individual, directly or indirectly. Examples include, but are not limited to,
                  first and last name, contact, home address, etc.
                </li>
                <li>
                  <strong>"Visitor"</strong> anyone accessing the Remotebase site,
                  www.remotebase.com, is referred to as a visitor.
                </li>
              </ul>
            </div>
            <div className="privacy__inf-col">
              <h2 className="privacy__heading">Information Collection</h2>
              <h3 className="privacy__sub-hd">A. Automatically Collected Information</h3>
              <p className="privacy__para mb-4">
                When you use our site or access particular site services, we, like other businesses
                with online services, receive technical information. We employ these technologies to
                examine how users interact with the site services and to enhance the functionality
                of our website.
              </p>
              <p className="privacy__para">
                When you access or interact with the site or site services, we and our third-party
                service providers, including analytics and third-party content providers, may
                automatically collect the following data from you: links clicked, pages visited,
                contact form information or chat widgets. We might also record the Internet Protocol
                ("IP") address you used to access the site services, together with your browser,
                operating system, and referring URL. Based on your IP address, we (and/or our
                service providers) may use publicly accessible sources to estimate your geographic
                region and Internet service provider. We gather this data to enhance the services we
                provide to you as well as to enhance marketing, analytics, and site service
                operations.
              </p>
              <h3 className="privacy__sub-hd">B. Voluntarily Collected Information</h3>
              <p className="privacy__para">
                Site visitors may decide to interact with the site in a way that results in you
                giving personal information to Remotebase, such as when you sign up to learn more
                about Remotebase's client services or to apply to be a Remotebase Developer in the
                Remotebase Talent Network, providing us with your name, email address, phone number,
                and country location. You will be required to submit personal information if you use
                our site to sign up for certain services, download e-books, participate in online
                meetings or webinars, finish online registration forms, apply for jobs via our site,
                or set up or modify any of your marketing choices. We will utilise the personal
                information you choose to give us to answer your request if you choose to do so.
              </p>
              <br />
              <p className="privacy__para">
                If you permit us, we may send you information about our products and services,
                including marketing communications, as follows:
              </p>
              <div className="privacy__inf-list">
                <ul className="privacy__list">
                  <li>
                    to advertise occasions, giveaways, challenges, seminars, studies, our services,
                    news, or pertinent business updates.
                  </li>
                </ul>
              </div>
              <p className="privacy__para">
                While there is no obligation for visitors to provide us with any information,
                Remotebase Developers may be contractually obligated to do so (in accordance with
                the Talent Network Terms & Conditions). If we do not get certain information from
                you, we will not be able to provide you with certain site services.
              </p>
            </div>
            <h2 className="privacy__heading">Information Usage</h2>
            <p className="privacy__para">
              You don't have to give us any personal information in order to use or access our site.
              For internal business objectives or for profit, Remotebase does not purchase or sell
              personal information.
            </p>
            <p className="privacy__para">
              In order to carry out its business functions for our employees, Remotebase stores,
              maintains, processes, and/or handles personal information data of Remotebase employees
              as needed.
            </p>
            <p className="privacy__para">
              In order to provide services to Remotebase Clients, we might also obtain personal
              information from them. Except as otherwise stipulated in this Agreement, we
              exclusively share the personal information back with our separate Clients. Any
              personal information utilised for such reasons will be restricted to access by only
              those who need it given their job function at Remotebase. We do not keep, utilise, or
              disclose any personal information gathered on behalf of our Clients for any reason
              (including any commercial purpose).
            </p>
            <p className="privacy__para">
              Remotebase does not use or disclose any personal information collected on behalf of
              our Clients for any purpose other than the specific purpose of performing the
              services.
            </p>
            <p className="privacy__para">
              Only employees and third-party vendors who: (1) require access to the personal
              information of our users in order to process it on our behalf or to provide the
              services mentioned above; and (2) have agreed in writing not to disclose the
              information to any other parties without Remotebase's consent are permitted to receive
              it from Remotebase. By using the site or site services, you consent to the transfer of
              information to those individuals and organisations in order to carry out these
              objectives, some of whom may be situated outside of your country of residence.
            </p>
            <p className="privacy__para">
              By law, court order, law enforcement request, or for other reasons to protect our
              sites or other sites due to security threats, there may be times when we are compelled
              to divulge information you electronically transmit to us or email to us. As needed by
              law or by court order, we may disclose this information, along with any other
              information that could be used to identify you, to state and local authorities or law
              enforcement.
            </p>
            <h2 className="privacy__heading">Third-Party Resources Usage</h2>
            <p className="privacy__para">
              Please be aware that any comments or opinions posted by visitors on third-party
              websites are not under the authority, supervision, or endorsement of Remotebase.
              Personal information may become accessible to or available to Remotebase through the
              use of the establishment of official Remotebase accounts. When a user submits,
              communicates, posts, or associates information with designated Remotebase accounts on
              social media, such information may become available to Remotebase. Occasionally,
              Remotebase, through its representatives, staff, officers, directors, agents, and
              assignees, may publicly respond to content or media made available through "share,"
              "retweet," "friend," "follow," or similar activities, or publicly respond to content
              made on designated and official Remotebase accounts.
            </p>
            <p className="privacy__para">
              Remotebase occasionally participates in joint marketing campaigns with selected
              corporate partners. We may share pertinent personal information with such partners if
              you sign up for a joint webinar or particularly indicate an interest in a product,
              promotion, or service that is offered in conjunction (s). These business partners may
              send you marketing materials for their own goods and services if you have given them
              permission to do so. Data may be transferred by Remotebase to third-party suppliers
              serving as our agents, in the event that another organisation purchases business
              assets, through regular business operations owing to expansions or fund-raising, or in
              accordance with any other provisions of this policy. Any impacted parties must receive
              written notice from Remotebase of any transfer of personal information during any
              change of ownership.
            </p>
            <p className="privacy__para">
              Third parties may host embedded content or cookies on the website that collect
              information from your machine while you are on our website. These services provide
              Remotebase with website analytics, enhance the user experience, provide ease of
              navigation through Remotebase's sites, send tailored marketing communications or ads,
              and promote your access to information.
            </p>
            <p className="privacy__para">
              This privacy policy does not apply to websites or services operated by third parties,
              and Remotebase has no control over how our business partners utilise personal
              information. These organisations are in charge of controlling how they utilise the
              personal information they gather under these conditions. For more information on how
              each third party collects, uses, and shares information, you may want to consult their
              privacy policies. The use of cookies can be managed by each browser separately.
            </p>
            <h2 className="privacy__heading">External Website Links</h2>
            <p className="privacy__para">
              Links to websites developed and maintained by other public or private organisations
              may be found on our site and in our site services. As a service to our users, we offer
              these links. Please be aware that when you click on a link to an external site,
              Remotebase's site services will no longer be available to you, and you will be subject
              to the privacy and security rules of the website you are visiting. The security
              procedures or the content of such external websites are not within the control of
              Remotebase.
            </p>
            <h2 className="privacy__heading">Your Rights & Choices</h2>
            <p className="privacy__para">
              Without giving any personal information, you can use our website. However, you might
              not be able to access some site services if you decide not to supply any personal
              information.
            </p>
            <p className="privacy__para">
              Additionally, people can decide whether or not their personal information is (1)
              revealed to a third party or (2) used for a purpose aside from the one for which it
              was initially acquired or afterwards given their consent. You may be entitled to the
              following depending on the law that applies:
            </p>
            <div className="privacy__rights-list">
              <ul className="privacy__list">
                <li>
                  Access to personal data. You can acquire a copy of the types of information we
                  might have gathered on your behalf as well as request confirmation as to whether
                  we are processing your personal information.
                </li>
                <li>
                  Demanding correction You have the right to ask that any outdated, inaccurate, or
                  incomplete personal information be changed. For Remotebase Developers, you can log
                  in and change your profile to update your personal information in your Remotebase
                  accounts.
                </li>
                <li>
                  Ask to be removed ("Opt-out") or to revoke consent. You can ask for your personal
                  information to be deleted from our files. Please be aware that deleting your
                  personal information may prevent you from using some site services and deactivate
                  any active accounts you may have with us.
                </li>
                <li>
                  Opt-out of direct marketing. If we are processing your personal information for
                  direct marketing reasons, you have the right to object. By clicking the
                  "unsubscribe" link in any of Remotebase's marketing communications, you may always
                  stop receiving them.
                </li>
              </ul>
            </div>
            <h2 className="privacy__heading">Security</h2>
            <p className="privacy__para">
              To safeguard the security, privacy, and integrity of any information or data that we
              collect, Remotebase employs state-of-the-art administrative, technical, and physical
              measures.
            </p>
            <p className="privacy__para">
              However, no website, service, mobile application, database or system is completely
              secure or "hacker proof." You are in charge of taking reasonable precautions to guard
              your personal data from misuse or unauthorised disclosure.
            </p>
            <h2 className="privacy__heading">Children's Privacy</h2>
            <p className="privacy__para">
              Our services are not intended for, and our site and site services are not targeted
              towards, minors under the age of 13, due to the nature of our business. Children under
              the age of 13 are not the target of Remotebase's solicitation or data collection
              efforts, and we do not intentionally acquire their personal data. Please get in touch
              with us if you think a child under the age of 13 may have provided us with Personal
              Data through the service, and we'll do our best to remove the data from our records.
            </p>
            <h2 className="privacy__heading">Changes to Remotebase Privacy Policy</h2>
            <p className="privacy__para">
              By publishing a new version of this Privacy Policy on our website, Remotebase may
              change it at any moment. You are responsible for periodically reviewing this Privacy
              Policy as your continued use of the site and its services signify your consent to the
              Privacy Policy in effect at the time.
            </p>
            <h2 className="privacy__heading">Contact Us</h2>
            <p className="privacy__para">
              Please contact us if you have any questions about Our Privacy Policy or the
              information practices of this service. You may contact us by email at
              contact@remotebase.com or by post at:
            </p>
            <address className="prv__comp-address">
              Remotebase HQ
              <br />
              55 E, 3rd Avenue
              <br />
              San Mateo, CA 94401
              <br />
              United States
            </address>
          </div>
        </div>
      </PrivacyPolicyStyled>
    </PrivacyPolicyStyled>
  );
};

export default Terms;
