import { LogoutIcon } from "assets/icons";
import React from "react";
import { AuthRoutes } from "utils";
import * as Styled from "./styles";

export const SidebarLogoutBtn: React.FC = () => {
  return (
    <Styled.LogoutBtnBox to={AuthRoutes.Logout}>
      <Styled.LogoutBtnInner>
        <Styled.SidebarIcon>
          <LogoutIcon />
        </Styled.SidebarIcon>
        <Styled.SidebarLogoutText>Logout</Styled.SidebarLogoutText>
      </Styled.LogoutBtnInner>
    </Styled.LogoutBtnBox>
  );
};

export default SidebarLogoutBtn;
