import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ConfirmationWrapper = styled.div`
  margin: 1rem 1.5rem;
  padding: 3rem 0 1rem 0;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

export const CancelBtn = styled(Button)`
  background-color: transparent;
  color: #3c65fe;
  border-radius: 0.5rem;
  width: 5rem;
  height: 3rem;
  font-size: 0.875rem;
  border-width: 0;
  &:hover {
    background-color: transparent;
    color: #3c65fe;
  }
`;

export const SubmitBtn = styled(Button)`
  position: relative;
  width: 5rem;
  height: 3rem;
  background-color: #3c65fe;
  border: 1px solid #3c65fe;
  color: #ffffff;
  font-size: 0.875rem;
  gap: 5px;
  border-radius: 0.5rem;
  font-weight: 500;
  &:hover {
    background-color: #3c65fe;
    color: #ffffff;
  }
`;

export const MessageText = styled.p`
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1.2rem;
  text-align: center;
`;
