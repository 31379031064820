import { FC, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { MiddleContainer } from "./styles";
import { useMandatePaymentMethod } from "../hooks";
import MandateProvidedPage from "./MandateProvided";
import ErrorComponent from "./Error";

const HandleMandatePage: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { fetchMandatePaymentMethod, error, data } = useMandatePaymentMethod();

  useEffect(() => {
    fetchMandatePaymentMethod().then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <MiddleContainer>
        <Spinner animation="border" />
      </MiddleContainer>
    );
  }

  if (error || !data) {
    return <ErrorComponent />;
  }

  return (
    <Container>
      <MandateProvidedPage paymentAndMandate={data} />
    </Container>
  );
};

export default HandleMandatePage;
