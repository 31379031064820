import styled from "styled-components";

const animate = `
animation: shimmer 4s infinite linear;
background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
background-size: 1000px 100%;

@keyframes shimmer {
  0% { background-position: -1000px 0;}
  100% {background-position: 1000px 0;}
}
`;

const shimmerStyle = `
background-color: #ededed;
border-radius: 4px;
display: inline-flex;`;

export const TalentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  @media only screen and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const TalentBox = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  padding: 26px 38px 26px 26px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  background-color: #ededed;

  @media only screen and (max-width: 1279px) {
    padding: 26px 30px 26px 26px;
  }

  @media only screen and (max-width: 575px) {
    padding: 26px 20px;
    flex-direction: column;
  }
`;

export const TalentLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const TalentImage = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 92px;
  height: 92px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  pointer-events: none;
  margin-bottom: 12px;
`;

export const TalentName = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100px;
  height: 16px;
  margin-bottom: 4px;
`;

export const TalentInfo = styled.div`
  ${shimmerStyle}
  ${animate}
  width: 100px;
  height: 10px;
  margin-bottom: 4px;
`;

export const TalentRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 30px;

  @media only screen and (max-width: 575px) {
    padding: 24px 0px 0px;
  }
`;

export const TalentProgress = styled.div`
  ${shimmerStyle}
  ${animate}
  width: '100%';
  height: 20px;
  margin-bottom: 18px;
`;
