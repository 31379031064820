import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Spinner } from "react-bootstrap";
import { useGetStripePK } from "../hooks";
import ErrorComponent from "./Error";

type Props = {
  children: JSX.Element | Array<JSX.Element>;
  clientSecret: string;
};
function StripeElementProvider({ children, clientSecret }: Props): JSX.Element | null {
  const {
    performGetStripePK,
    data: stripePK,
    called: performGetStripePKCalled,
    error,
    isLoading: stripePKLoading,
  } = useGetStripePK();

  useEffect(() => {
    performGetStripePK();
  }, []);

  const stripePromise = useMemo(() => {
    if (!stripePK) return null;
    return loadStripe(stripePK);
  }, [stripePK]);

  const componentIsLoading = !clientSecret || stripePKLoading || !performGetStripePKCalled;

  if (componentIsLoading) {
    return <Spinner animation="border" />;
  }

  if (error || !stripePK) {
    return <ErrorComponent />;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      {children}
    </Elements>
  );
}

export default StripeElementProvider;
