import { StyledProps } from "@remotebase/components";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  font-family: "Avenir", sans-serif;
  flex-direction: column-reverse;

  @media only screen and (min-width: 992px) {
    flex-direction: row-reverse;
  }
`;

export const LoginContent = styled.div`
  flex: 1;
  padding: 50px 24px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 10px;
  }

  @media only screen and (min-width: 992px) {
    flex: 0 0 44%;
  }
`;

export const LoginCanvas = styled.div`
  height: 100%;
  width: 100%;
  max-width: 393px;
  margin: 0 auto;

  @media only screen and (min-width: 1399px) {
    max-width: 64%;
  }
`;

export const LoginBg = styled.div<{ "data-background"?: string }>`
  display: flex;
  flex-direction: column;
  background: ${(props): StyledProps =>
    `url(${props?.["data-background"]}) no-repeat top center / cover`};

  @media only screen and (min-width: 992px) {
    flex: 1;
  }
`;

export const LoginBgTop = styled.div`
  position: relative;
  flex: 0 1 0;
  overflow: hidden;

  @media only screen and (min-width: 992px) {
    flex: 1;
  }
`;

export const Card = styled.div`
  z-index: 1;
  position: absolute;
  width: 18vw;
  height: 18vh;
  background: black;
  top: 70%;
  left: 24%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.67) 88.96%);
  backdrop-filter: blur(7.24503px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  padding-left: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
export const CardText2 = styled.div`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
`;

export const CardText1 = styled.div`
  font-family: Platform;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding-bottom: 5px;
`;

export const Br = styled.div`
  height: 15px;
`;

export const LoginBgBottom = styled.footer<{ "data-background"?: string }>`
  height: 273px;
  background: ${(props): StyledProps =>
    `url(${props?.["data-background"]}) no-repeat center center / cover`};
  overflow: hidden;
  z-index: 2;
`;

export const LoginBgBottomContent = styled.div`
  background: linear-gradient(154.78deg, rgba(20, 6, 82, 0) 21.72%, rgba(20, 6, 82, 0.5402) 83.99%);
  backdrop-filter: blur(17px);
  padding: 50px 35px;
  height: 100%;
  width: 100%;
  position: relative;
  top: 5px;
  transform: rotate(-10deg);

  @media only screen and (min-width: 991px) {
    padding: 50px 65px;
  }
`;

export const LoginTalentList = styled.div`
  // border: 3px solid red;
  transform: rotate(10deg);
`;

export const LoginTalent = styled.div`
  max-width: 516px;
  // width: 60%;
  height: 176px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  // border: 3px solid red;
`;
