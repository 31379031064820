import { MutationFunctionOptions, useMutation } from "@apollo/client";
import {
  SaveStripeSetupIntentMutation,
  SaveStripeSetupIntentMutationVariables,
} from "@remotebase/amplify-constants/API";
import { getQuery } from "hooks/utils";
import { saveStripeSetupIntent } from "@remotebase/amplify-constants/graphql/mutations";
import { ReturnPropsSaveStripeSetupIntent } from "./types";

export const useSaveStripeSetupIntent = (
  completionHandler?: () => void,
): ReturnPropsSaveStripeSetupIntent<
  MutationFunctionOptions<SaveStripeSetupIntentMutation, SaveStripeSetupIntentMutationVariables>
> => {
  const [saveSetupIntentInDb, { data: response, loading: isLoading, error, called }] = useMutation<
    SaveStripeSetupIntentMutation,
    SaveStripeSetupIntentMutationVariables
  >(getQuery(saveStripeSetupIntent), {
    onCompleted: () => {
      if (completionHandler) completionHandler();
    },
  });
  const data = response?.saveStripeSetupIntent ?? null;
  return {
    saveSetupIntentInDb,
    data,
    isLoading,
    error,
    called,
  };
};

export default useSaveStripeSetupIntent;
