import { Card } from "react-bootstrap";
import { FC } from "react";
import {
  MandateInfoCardDetailDiv,
  MandateInfoCardDetailLabelPara,
  MandateInfoCardDetailLabelValuePara,
  MandateInfoCardIconDiv,
} from "./styles";

type Props = {
  labelName: string;
  value: string;
  Icon: FC;
};

function MandateInfoCard({ labelName, value, Icon }: Props): JSX.Element {
  return (
    <Card className="p-2 d-flex flex-row h-100 ">
      <MandateInfoCardIconDiv>
        <Icon />
      </MandateInfoCardIconDiv>
      <MandateInfoCardDetailDiv>
        <MandateInfoCardDetailLabelPara>{labelName}</MandateInfoCardDetailLabelPara>
        <MandateInfoCardDetailLabelValuePara>{value}</MandateInfoCardDetailLabelValuePara>
      </MandateInfoCardDetailDiv>
    </Card>
  );
}

export default MandateInfoCard;
