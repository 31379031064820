import { Form } from "react-bootstrap";
import cls from "classnames";
import { Control, Controller, FieldErrors, UseFormRegister } from "react-hook-form";
import flags from "react-phone-number-input/flags";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { FormField } from "./types";

export const renderFormFields = (
  formField: FormField,
  register: UseFormRegister<Record<string, string>>,
  errors: FieldErrors<Record<string, string>>,
  control: Control<Record<string, string>, Record<string, string>>,
):
  | ReturnType<typeof Form.Control>
  | ReturnType<typeof Select>
  | ReturnType<typeof PhoneInputWithCountrySelect> => {
  const { type, placeholder, name, required, autoFocus, styles, options, rows, readOnly } =
    formField;
  const rowsProp = type === "textarea" ? { rows } : undefined;
  const menuPortalProp = type === "select" ? { menuPortalTarget: document.body } : undefined;

  if (!name) return null;

  switch (type) {
    case "select":
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }): ReturnType<typeof Select> => (
            <Select
              {...field}
              {...menuPortalProp}
              instanceId={name}
              options={options}
              components={{ IndicatorSeparator: (): null => null }}
              styles={styles}
            />
          )}
        />
      );
    case "tel":
      return (
        <PhoneInputWithCountrySelect
          name={name}
          defaultCountry="US"
          international={true}
          className={`${cls({ "is-invalid": errors?.[name] })}`}
          control={control}
          flags={flags}
          readOnly={readOnly}
          numberInputProps={{ className: "form-control" }}
          {...(formField.onCountryChange ? { onCountryChange: formField.onCountryChange } : {})}
        />
      );
    default:
      return (
        <Form.Control
          {...register(name, { required })}
          {...rowsProp}
          className={`${cls("form-control", { "is-invalid": errors?.[name] })} ${
            formField.className ? formField.className : ""
          }`}
          type={type}
          as={type === "textarea" ? "textarea" : "input"}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
        />
      );
  }
};

export default renderFormFields;
