import styled from "styled-components";
import { Link } from "react-router-dom";

const ShimmerStyle = `
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;`;

const animate = `
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
  background-size: 1000px 100%;

  @keyframes fullView {
  100% {
    width: 100%;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
  `;

export const H3 = styled.h3`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 250px;
  height: 38px;
`;

export const paragraph = styled.p`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  height: 24px;
`;

export const H5 = styled.h5`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  max-width: 250px;
  height: 24px;
`;

export const cardWrapper = styled.div`
  ${ShimmerStyle}
  ${animate}
  position: relative;
  display: grid;
  grid-template-columns: 1fr 110px;
  gap: 48px;
  justify-content: space-between;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  padding: 20px 32px 8px 32px;
  margin-bottom: 8px;
  height: 150px;
`;

export const innerWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const companyLogo = styled.div`
  ${ShimmerStyle}
  ${animate}
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const nameLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
`;

export const span = styled.span`
  ${ShimmerStyle}
  ${animate}
  font-size: 12px;
`;

export const dot = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #000000;
`;

export const companyDetail = styled.p`
  ${ShimmerStyle}
  ${animate}
  font-size: 12px;
`;

export const btnGroup = styled.p`
  ${ShimmerStyle}
  ${animate}
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const stackBtn = styled.button`
  ${ShimmerStyle}
  ${animate}
  padding: 0 24px;
  border-radius: 16px;
  border: none;
  font-size: 12px;
  height: 24px;
`;

export const viewBtn = styled.button`
  ${ShimmerStyle}
  ${animate}
  padding: 0 24px;
  border-radius: 16px;
  border: none;
  font-size: 12px;
  height: 24px;
  cursor: pointer;
`;

export const ViewMoreButton = styled(Link)`
  ${ShimmerStyle}
  ${animate}
  font-size: 0;
  height: 30px;
  @media (max-width: 1023px) {
    opacity: 0;
  }
`;
