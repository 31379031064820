import styled from "styled-components";

export const Box = styled.div`
  border: 1px solid #e0e0e0;
  background: #ffffff;
  padding: 2rem 2rem 1rem;
`;

export const Heading = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: centered;
  min-width: 194px;
  min-height: 42px;
  margin-top: -15px;
  background: #3c65fe;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
    background: #3c65fe;
    border: none;
    box-shadow: none;
    outline: none;
  }
`;
