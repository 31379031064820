import { ProfileInfoSectionProps, imageUrls } from "@remotebase/components";
import { FC, useState } from "react";
import Popup from "./Popup";
import TalentFeedback from "./TalentFeedback";
import FeedbackSubmitted from "../dashboard/components/talentList/FeedbackSubmitted";
import * as Styled from "./styles";

export default function FeedbackWrapper({
  fullName,
  title,
  imageLink,
  expectedSalary,
  skillScore,
  jobMatchId,
  jobMatchVersion,
  feedbackLength,
  isAvailable,
}: ProfileInfoSectionProps): ReturnType<FC> {
  const [showPopup, setShowPopup] = useState(false);

  const [currentView, setCurrentView] = useState(1);

  const handleShowPopup = (): void => {
    setShowPopup(true);
  };

  const handleHidePopup = (): void => {
    setShowPopup(false);
    setCurrentView(1);
  };

  return (
    <Styled.EditTalentInfoTopRight>
      {feedbackLength ? (
        <FeedbackSubmitted />
      ) : (
        <Styled.EditTalentFeedbackBtn
          type="button"
          onClick={(): void => handleShowPopup()}
          disabled={!isAvailable}
        >
          Send Feedback
        </Styled.EditTalentFeedbackBtn>
      )}

      <Popup show={showPopup} onHide={handleHidePopup}>
        <TalentFeedback
          name={fullName}
          role={title}
          imgLink={imageLink ?? imageUrls.userDefault}
          expectedSalary={expectedSalary}
          skillScore={skillScore}
          jobMatchId={jobMatchId}
          version={jobMatchVersion}
          closeFeedbackPopup={handleHidePopup}
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
      </Popup>
    </Styled.EditTalentInfoTopRight>
  );
}
