import styled from "styled-components";

export const videoWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 18px 26px;

  & div:last-child {
    border-radius: 12px;
    overflow: hidden;

    & video {
      float: left;
    }
  }
`;

export const dummy = styled.div`
  width: 100%;
`;

export const videoLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

export const questionHeading = styled.div`
  font-size: 14px;
  color: #7b8292;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 26px;
  white-space: pre-line;
`;

export const videoLinkItem = styled.a`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: none;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  background-color: #b5b7bb;
  border-radius: 50%;

  &.selected,
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #3c1fc9;
    text-decoration: none;
    color: #fff;
  }
`;
