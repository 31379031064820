import styled from "styled-components";
import { Container } from "react-bootstrap";
import { StyledProps } from "../../../utils";

export const EditTalentInfoTopRight = styled.div`
  display: flex;
  justify-content: center;
`;

export const EditTalentFeedbackBtn = styled.button<{
  $isAvailable?: boolean;
}>`
  background-color: #3617ba;
  border-radius: 8px;
  width: 194px;
  height: 42px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  cursor: ${(props): StyledProps => (props.$isAvailable ? "pointer" : "auto")};
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;

  & .go-back {
    box-shadow: 0px -6px 34px rgba(168, 168, 168, 0.25);
    @media ${(props): string => props?.theme?.breakpoints?.min?.laptop} {
      display: none;
    }
  }
`;

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;

  @media ${({ theme }): string => theme?.breakpoints?.min.desktop} {
    display: flex;
    align-items: center;
  }
`;

export const MainContainer = styled(Container)`
  @media ${(props): string => props?.theme?.breakpoints?.min?.laptop} {
    max-width: 70%;
  }
`;

export const MainHeader = styled.div`
  margin-top: 4rem;
  max-width: 52%;
  color: ${(props): string => props?.theme?.colors?.blue[10]};

  @media ${({ theme }): string => theme?.breakpoints?.max.laptopL} {
    max-width: 75%;
  }

  @media ${({ theme }): string => theme?.breakpoints?.max.mobileL} {
    margin-top: 2rem;
    max-width: 100%;
  }

  &.title-centred {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @media ${({ theme }): string => theme?.breakpoints?.min.laptop} {
      max-width: 300px;
    }
  }

  &.is-feedback {
    @media ${({ theme }): string => theme?.breakpoints?.min.laptop} {
      max-width: unset;
    }
  }
`;

export const FeedbackCompleteMainHeader = styled.div`
  margin-top: 4rem;
  max-width: 52%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media ${({ theme }): string => theme?.breakpoints?.min.laptop} {
    max-width: 300px;
  }
  color: ${(props): string => props?.theme?.colors?.blue[10]};

  @media ${({ theme }): string => theme?.breakpoints?.max.laptopL} {
    max-width: 75%;
  }

  @media ${({ theme }): string => theme?.breakpoints?.max.mobileL} {
    margin-top: 2rem;
    max-width: 100%;
  }

  &.is-feedback {
    @media ${({ theme }): string => theme?.breakpoints?.min.laptop} {
      max-width: unset;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
  line-height: 28px;
  margin: 0;
  font-weight: 700;
  color: inherit;
  padding: 1.5rem 0 1rem 0;

  &.is-feedback {
    font-size: 1.125rem;
    line-height: 23px;
    letter-spacing: 0.175px;
    text-transform: capitalize;
  }
`;

export const FeedbackCompleteTitle = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
  line-height: 28px;
  margin: 0;
  font-weight: 700;
  color: inherit;
  padding: 1.5rem 0 1rem 0;

  font-size: 1.125rem;
  line-height: 23px;
  letter-spacing: 0.175px;
  text-transform: capitalize;
`;

export const AvatarCanvas = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Avatar = styled.img`
  object-fit: cover;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const AvatarDetails = styled.div`
  padding-left: 5rem;
  padding-top: 0.5rem;
`;

export const AvatarName = styled.div`
  font-family: Raleway;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
`;

export const AvataRole = styled.div`
  font-family: Avenir;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
`;

export const SubTitle = styled.h2`
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
  color: inherit;

  & .highlighted {
    color: ${(props): string => props?.theme?.colors?.green[2]};
  }

  &.is-feedback {
    font-size: 1.2rem;
    color: ${(props): string => props?.theme?.colors?.blue[10]};
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 349px;
      height: 0px;
      left: calc((100% - 349px) / 2);
      bottom: -25px;
      border: 0.5px solid #a8c5ef;
    }
  }
`;

export const FeedbackChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CustomeProps extends React.HTMLAttributes<HTMLDivElement> {
  selected?: boolean;
}

export const FeedbackChoice = styled.div<CustomeProps>`
  display: flex;
  padding: 25px;
  height: 3rem;
  align-items: center;
  font-family: Avenir;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: 0.5rem;

  &:hover {
    background-color: #f5f5f5;
  }

  ${({ selected }): string => (selected ? "border-color: #3617BA;" : "")}
`;

export const FeedbackCommentCanvas = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
`;

export const AcceptButton = styled.button<CustomeProps>`
  background: ${(props): string => (props.selected ? "#049583" : "#ffffff")};
  color: ${(props): string => (props.selected ? "#ffffff" : "#312a50")};
  border-radius: 8px;
  padding: 10px 30px;
  outline: none;
  border: 1px solid ${(props): string => (props.selected ? "#049583" : "#000000")};
  font-family: Avenir;
`;

export const RejectButton = styled.button<CustomeProps>`
  margin-left: 10px;
  background: ${(props): string => (props.selected ? "#ff0000" : "#ffffff")};
  color: ${(props): string => (props.selected ? "#ffffff" : "#312a50")};
  border-radius: 8px;
  padding: 10px 30px;
  outline: none;
  border: 1px solid ${(props): string => (props.selected ? "#ff0000" : "#000000")};
  font-family: Avenir;
`;
export const FeedbackTextarea = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 10rem;
  resize: none;
  border-radius: 8px;
`;

export const SubmitButton = styled.button`
  font-size: 16px;
  position: relative;
  color: #ffffff;
  margin: 20px 0;
  font-weight: 400;
  outline: none;
  font-family: Avenir;
  letter-spacing: -0.025em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3617ba;
  border: 1px solid #3617ba;
  border-radius: 3px;
  width: 100%;
  min-height: 56px;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    min-height: 50px;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #2b15ba;
    border: none;
    box-shadow: none;
    color: #fff;
  }
  &[disabled] {
    background-color: #524c4c61 !important;
    color: #eef1fc !important;
    border: none;
  }
`;
