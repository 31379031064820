import styled from "styled-components";
import { Link } from "react-router-dom";

export const LoginWrapper = styled.div`
  width: 100%;
`;

export const LoginHeading = styled.h1`
  font-size: 40px;
  color: #404040;
  font-weight: 700;
  letter-spacing: -0.045em;
  margin-bottom: 10px;

  @media only screen and (min-width: 1600px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const LoginDescrip = styled.p`
  font-size: 20px;
  color: #404040;
  font-weight: 300;
  letter-spacing: -0.045em;
  margin-bottom: 66px;

  @media only screen and (min-width: 1600px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const FormGroups = styled.div`
  width: 100%;
`;

export const AccountText = styled.div`
  font-size: 16px;
  color: #bbbbbb;
  line-height: 24px;
  letter-spacing: -0.09em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 126px;

  @media only screen and (max-width: 767px) {
    margin-top: 70px;
  }
`;

export const SignupLink = styled(Link)`
  display: inline-flex;
  cursor: pointer;
  color: #bbbbbb;
  text-decoration: none;
  transition: all 0.25s ease;
  margin-left: 4px;

  &:hover,
  &:focus {
    color: #5138ed;
    text-decoration: underline;
  }
`;
