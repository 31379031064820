import { createContext } from "react";
import { ITalentContextType } from "state/types/talent.interface";

export const TalentContext = createContext<ITalentContextType>({
  talentState: {
    data: [],
    filter: "",
    searchName: "",
    currentTalent: null,
    loading: false,
    client: null,
    clientFeedback: undefined,
    JobMatchTalents: [],
  },
  setTalentState: (): void => {},
});

export default TalentContext;
