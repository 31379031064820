import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;

  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 50px 24px;

  @media only screen and (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
    height: 100vh;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 10px;
  }
`;

export const LoginCanvas = styled.div`
  width: 100%;
  max-width: 420px;
  margin: auto;
`;

export const LoginBg = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(64, 44, 198, 0.6), rgba(64, 44, 198, 0.6)), #8c7cf2;
  padding: 50px 86px;

  @media only screen and (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (max-width: 1439px) {
    padding: 50px 65px;
  }

  @media only screen and (max-width: 1279px) {
    padding: 50px 65px;
  }

  @media only screen and (max-width: 991px) {
    padding: 50px 35px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b8afe9;
    border-radius: 10px;
  }
`;

export const LoginAccessBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: "";
    background: linear-gradient(180deg, #9e8ff6 0%, #b8afe9 99.99%, #d4cdff 100%);
    mix-blend-mode: normal;
    border-radius: 55px;
    opacity: 0.5;
    position: absolute;
    inset: 0;
  }
`;

export const LoginAccessContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 78px 103px 78px 65px;
  position: relative;
  overflow: hidden;
  min-height: 720px;

  @media only screen and (max-width: 1439px) {
    padding: 60px 65px;
  }

  @media only screen and (max-width: 1279px) {
    padding: 50px 40px;
  }

  @media only screen and (max-width: 991px) {
    padding: 40px 25px;
    border-radius: 55px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 55px;
    min-height: 200px;
  }
`;

export const Tagline = styled.div`
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: -0.045em;
  margin-bottom: 20px;

  @media only screen and (min-width: 1600px) {
    font-size: 48px;
    line-height: 1.1;
  }

  @media only screen and (max-width: 767px) {
    font-size: 22px;
  }
`;

export const AccessOne = styled.div`
  width: 660px;
  position: absolute;
  bottom: -132px;
  right: 0;
  margin-right: -68px;
  pointer-events: none;

  @media only screen and (min-width: 1600px) {
    width: 720px;
    bottom: -148px;
    margin-right: -65px;
  }

  @media only screen and (max-width: 1279px) {
    width: 516px;
    bottom: -76px;
    margin-right: -74px;
  }

  @media only screen and (max-width: 767px) {
    width: 300px;
    right: 0;
    bottom: -170px;
  }
`;

export const AccessOneImg = styled.img`
  max-width: 100%;
`;

export const TaglineBoxOne = styled.div`
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 15px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 254px;
  right: -32px;

  @media only screen and (min-width: 1600px) {
    width: 108px;
    height: 108px;
  }

  @media only screen and (max-width: 991px) {
    width: 60px;
    height: 60px;
  }

  @media only screen and (max-width: 767px) {
    top: -14px;
    right: -18px;
  }
`;

export const TaglineBoxOneImg = styled.img`
  max-width: 100%;
`;

export const TaglineBoxTwo = styled.div`
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  bottom: 104px;
  left: -32px;

  @media only screen and (min-width: 1600px) {
    width: 108px;
    height: 108px;
  }

  @media only screen and (max-width: 991px) {
    width: 60px;
    height: 60px;
  }

  @media only screen and (max-width: 767px) {
    bottom: -14px;
    left: -18px;
  }
`;

export const TaglineBoxTwoImg = styled.img`
  max-width: 100%;
`;
