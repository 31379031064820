import styled from "styled-components";

export const EntryBtnsContainer = styled.div`
  min-width: 100%;
  margin: 16px 0;
`;

export const EntryBtn = styled.button`
  width: 100%;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  min-width: 222px;
  height: auto;
  min-height: 50px;
  border: none;
  border-radius: 10px;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: #3f73f6;
  background: #fff;
  border: 1px solid #e3e6e9;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    color: #3f73f6;
  }
  &:active {
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px);
    -moz-transform: translateY(2px);
    -o-transform: translateY(2px);
  }
  &[href] {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1023px) {
    min-width: 100%;
  }
`;

export const EntryBtnFull = styled(EntryBtn)`
  @media only screen and (min-width: 1023px) {
    min-width: 390px;
  }
`;

export const AlternateTextDiv = styled.div`
  position: relative;
  margin: -22px 0 12px;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #d0d0d0;
    top: 22px;
    left: 0;
  }

  @media only screen and (min-width: 768px) {
    margin-top: unset;
  }

  @media only screen and (min-width: 992px) {
    max-width: 390px;

    &:before {
      max-width: 390px;
    }
  }
`;

export const AlternateTextDivSignup = styled(AlternateTextDiv)`
  @media only screen and (min-width: 992px) {
    &:before {
      left: 22px;
      right: 22px;
    }
  }

  @media only screen and (min-width: 1198px) {
    &:before {
      left: unset;
      right: unset;
    }
  }
`;

export const AlternateText = styled.p`
  text-align: center;
  background: #fff;
  padding: 10px;
  width: fit-content;
  margin: 0 auto;
  position: relative;
`;

export const LoginSeparator = styled.div`
  display: flex;
  justify-content: center;
  min-height: 28px;
  position: relative;
  margin-bottom: 14px;

  &:before {
    content: "";
    background-color: #bbbbbb;
    height: 1px;
    position: absolute;
    top: 13px;
    left: 0;
    right: 0;
  }
`;

export const LoginSeparatorText = styled.span`
  font-size: 16px;
  color: #b9b9b9;
  letter-spacing: -0.025em;
  padding: 0 10px;
  display: inline-flex;
  position: relative;
  background-color: #fff;
`;
