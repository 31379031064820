import styled from "styled-components";
import { Link } from "react-router-dom";

export const PerksWrapper = styled.div`
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
`;

export const PageHead = styled.div`
  margin-bottom: 36px;
`;

export const PerksWelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 160px;
  background-color: #fff9f2;
  border: 1px solid #f4f3f6;
  padding: 15px;
  row-gap: 10px;
`;

export const BackLink = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #4d526d;
  text-transform: capitalize;
  font-family: "Avenir", sans-serif;
  display: inline-flex;
  align-items: center;
  grid-gap: 12px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #4d526d;
  }
`;

export const BackLinkIcon = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;

    & path {
      fill: #4d526d;
    }
  }
`;

export const BackLinkText = styled.span`
  display: inline-flex;
`;

export const PageBody = styled.section`
  background-color: #faf6ff;
  border-radius: 16px;
  padding: 20px 20px 16px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    padding: 32px 35px 16px;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    background: url("/images/mixed-lines.png") no-repeat center center / cover;
    height: 266px;
    border-radius: 16px 16px 5px 5px;
    z-index: 0;
  }
`;

export const PerksContent = styled.div`
  padding: 50px 20px 0;
  z-index: 0;
  border-radius: 1rem;
  background: #f8fced;
  margin-bottom: 86px;

  @media only screen and (min-width: 768px) {
    padding-top: 90px;
  }
`;

export const PerksHead = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 auto 104px;
`;

export const PerksTitle = styled.div`
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #312a50;
  font-family: "Platform";
  margin-bottom: 15px;
`;

export const PerksPara = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #312a50;
  font-family: "Avenir", sans-serif;
`;

export const OfferBoxWrapper = styled.div`
  width: 100%;
  max-width: 610px;
  margin: 0 auto -40px;
`;

export const OfferBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 30.8491px 61.6981px rgba(52, 64, 84, 0.14);

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const OfferImgCanvas = styled.div`
  width: 100%;
  height: 120px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    width: 220px;
    height: 190px;
    border-radius: 5px;
  }
  &.hubspot {
    background: #ff5c35;
  }
  &.iwg {
    background: rgb(0, 164, 199);
  }
  &.deskpass {
    background: rgb(242, 237, 142);
  }
  &.dailybot {
    background: rgb(238, 242, 249);
  }
  &.mercury {
    background: rgb(212, 214, 230);
  }
  &.letswork {
    background: rgb(68, 117, 246);
  }
  &.carta {
    background: rgb(228, 238, 242);
  }
  &.ecologi {
    background: rgb(40, 94, 73);
  }
  &.deel {
    background: rgb(21, 53, 122);
  }
  &.notion {
    background: rgb(247, 246, 243);
  }
  &.onepassword {
    background: rgb(26, 140, 255);
  }
  &.close {
    background: rgb(217, 217, 217);
  }
  &.vouch {
    background: rgb(238, 244, 227);
  }
  &.secureframe {
    background: rgb(30, 41, 77);
  }
  &.clevertap {
    background: rgb(218, 50, 50);
  }
  &.swagup {
    background: rgb(53, 119, 211);
  }
  &.veremark {
    background: rgb(248, 248, 249);
  }
  &.letswork {
    background: rgb(68, 117, 246);
  }
`;

export const OfferImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 42px;

  & img {
    object-fit: contain;
    pointer-events: none;
    max-width: 100%;
  }
`;

export const OfferContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 32px 24px;
  background: #fff;

  @media only screen and (min-width: 768px) {
    padding: 40px 26px 13px 20px;
  }
`;

export const OfferInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  grid-gap: 20px;
  text-align: center;

  @media only screen and (min-width: 576px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
`;

export const OfferSquare = styled.div`
  width: 62px;
  height: 62px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efeffa;
  border-radius: 9px;
  overflow: hidden;

  & svg {
    width: 28px;
    height: 28px;

    & path {
      fill: #3617ba;
    }
  }
`;

export const OfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 576px) {
    align-items: flex-start;
  }
`;

export const OfferTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #312a50;
  font-family: "Platform";
  margin-bottom: 12px;
`;

export const OfferPara = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #312a50;
  margin-bottom: 12px;
  font-family: "Avenir", sans-serif;

  @media only screen and (min-width: 576px) {
    min-height: 32px;
  }
`;

export const OfferBtn = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #312a50;
  background-color: transparent;
  border: 1px solid #312a50;
  border-radius: 10px;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  padding: 8px 16px;
  min-width: 112px;
  min-height: 33px;
  transition: all 0.35s;
  font-family: "Avenir", sans-serif;

  & svg {
    width: 12px;
    height: 12px;

    & path {
      fill: #312a50;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #312a50;
    border-color: #312a50;
    box-shadow: none;
    text-decoration: none;
    color: #fff !important;

    & svg {
      & path {
        fill: #fff;
      }
    }
  }
`;

export const PerksFeature = styled.div`
  width: 100%;
`;

export const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  background: #fff;
  padding: 32px 20px;
  min-height: 150px;

  @media only screen and (min-width: 768px) {
    padding: 44px 30px 40px 42px;
    min-height: 252px;
  }

  &.feature__full {
    @media only screen and (min-width: 768px) {
      grid-column: span 2;
    }
  }
`;

export const FeatureHead = styled.div`
  display: flex;
  grid-gap: 7px;
`;

export const FeatureIcon = styled.div`
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & img {
    object-fit: contain;
    pointer-events: none;
  }
`;

export const FeatureTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #000;
  margin-top: 3px;
  font-family: "Avenir", sans-serif;
`;

export const FeatureBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
`;

export const FeatureText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000;
  font-family: "Avenir", sans-serif;
`;

export const FeatureItem = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000;
  font-family: "Avenir", sans-serif;

  &:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 10px;
    margin: 0 10px;
    position: relative;
    top: -2px;
    display: inline-flex;
    flex-shrink: 0;
  }
`;

export const FeatureLink = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #3617ba;
  display: inline-flex;
  text-decoration: none;
  transition: all 0.35s;
  word-break: break-word;

  &:hover,
  &:focus,
  &:active {
    color: #3c65fe;
    text-decoration: none;
  }
`;

export const FeatureLinkWhite = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000;
  font-family: "Avenir", sans-serif;
  display: inline-flex;
  text-decoration: none;
  transition: all 0.35s;
  word-break: break-word;
  max-width: 312px;

  &:hover,
  &:focus,
  &:active {
    color: #3c65fe;
    text-decoration: none;
  }
`;

export const FeatureTextRedemption = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #000;
  font-family: "Avenir", sans-serif;
  display: inline-flex;
  text-decoration: none;
  transition: all 0.35s;
  word-break: break-word;
  max-width: 312px;
`;

export const FeatureImgGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 40px;
  }
`;

export const FeatureImg = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  min-height: 150px;

  & span {
    width: 100% !important;
    height: 100% !important;
  }

  & img {
    object-fit: cover;
    pointer-events: none;
  }
`;
