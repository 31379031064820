import { FC } from "react";
import { LogoutIcon } from "assets/icons";
import * as Styled from "layouts/sidebar_layout_copy/styles";

export default function MainDropdownProfile(): ReturnType<FC> {
  return (
    <Styled.MainDropdownContainer>
      <Styled.MainDropdownLink to="/logout" className="logout">
        <LogoutIcon /> Logout
      </Styled.MainDropdownLink>
    </Styled.MainDropdownContainer>
  );
}
