import { UserType } from "@remotebase/constants";
import { Fragment } from "react";
import * as text from "./utils";

export enum ICONS {
  SENDING_MAIL = "sending-mail",
  LOCK = "lock",
  MESSAGE = "message",
  USER = "user",
  CLOSE = "cross",
}

export const getIcon = (icon: ICONS, color?: string): JSX.Element => {
  switch (icon) {
    case ICONS.SENDING_MAIL:
    case ICONS.MESSAGE:
    case ICONS.USER:
      return (
        <i className={`icon-${icon}${color ? `-${color}` : ""} mltColorIcon`}>
          <span className="path1" />
          <span className="path2" />
        </i>
      );
    case ICONS.LOCK:
    case ICONS.CLOSE:
      return <i className={`icon-${icon}`} />;
    default:
      return <Fragment />;
  }
};

export const authSubHeading = (userType?: UserType): string => {
  switch (userType) {
    case UserType.Talent:
      return text.talentSubHeader;
    case UserType.Client:
      return text.clientSubHeader;
    case UserType.Recruiter:
      return text.recruiterSubHeader;
    default:
      return text.adminSubHeader;
  }
};

export const getOverallTestScore = (
  takenDomainTests?: (TechDomainSkillsProgress | null)[] | null,
): number => {
  if (!takenDomainTests) return 0;
  return takenDomainTests.reduce((acc, curr) => {
    if (!curr?.percentage) return acc;
    return acc + curr?.percentage || 0;
  }, 0);
};
