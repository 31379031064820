import { FC, Fragment } from "react";
import { withTalent } from "state/talent";
import { Col } from "react-bootstrap";
import { ITalentProps } from "state/types";
import TalentList from "../talentList";
import * as S from "../../styles";

const TalentListPagination: FC<ITalentProps> = ({ talentState }) => {
  return (
    <>
      <S.DashboardRow>
        <Col xs={12} xl={12}>
          {Number(talentState?.JobMatchTalents?.length) > 0 ? (
            talentState?.JobMatchTalents?.map((jobMatchTalent) => {
              return (
                <Fragment key={jobMatchTalent?.name}>
                  <S.DashboardBoPara>{jobMatchTalent?.name}</S.DashboardBoPara>
                  <S.DashboardRow>
                    <Col xs={12} xl={12}>
                      {Number(jobMatchTalent?.talent?.length) > 0 ? (
                        <TalentList
                          talents={jobMatchTalent.talent}
                          feedback={jobMatchTalent?.feedback}
                          jobMatchIds={jobMatchTalent?.jobMatchIds}
                        />
                      ) : (
                        <span>No matching talent found!</span>
                      )}
                    </Col>
                  </S.DashboardRow>
                </Fragment>
              );
            })
          ) : (
            <span>No matching Job or talent found!</span>
          )}
        </Col>
      </S.DashboardRow>
    </>
  );
};

export default withTalent(TalentListPagination);
