import styled, { css, FlattenSimpleInterpolation } from "styled-components";

type ActiveType = {
  active: string;
  color: string;
};

export type StyledProps = FlattenSimpleInterpolation | string;

export const TrackRefBoxText = styled.p`
  font-size: 11px;
  font-weight: 700;
  color: #919191;
  line-height: 16px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  max-width: 88px;
`;

export const InfoIcon = styled.div`
  position: absolute;
  top: -11px;
  right: 7px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Digit = styled.span`
  font-size: 15px;
  color: #e5e5e5;
  font-weight: 600;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c7c7c7;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 12px;
`;

export const TrackRefBox = styled.div<ActiveType>`
  background-color: #e5e5e5;
  border-radius: 24px;
  padding: 18px 16px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  width: 122px;
  flex-shrink: 0;
  min-height: 114px;
  position: relative;
  transition: all 0.25s;
  z-index: 1;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    transform: scale(1.03);
  }

  ${(props): StyledProps =>
    props.active === "active"
      ? css`
          background: ${props.color};
          ${Digit} {
            background-color: #fff;
            color: ${props.color};
          }
          ${TrackRefBoxText} {
            color: #fff;
          }
        `
      : css``}
  @media (max-width: 1199px) {
    min-width: 10px;
  }
`;

export const OverlayWrapper = styled.div`
  width: 122px;
  &:hover {
    cursor: pointer;
  }
  &.active {
    transition: all 0.25s;
    border-radius: 27px;
    box-shadow: 0 0px 15px #595959;
  }
`;
