import * as S from "../../styles";

export const FeedbackSubmitted: React.FC = () => {
  return (
    <S.FeedbackSubmittedContainer>
      <S.FeedbackSubmittedMessage>Feedback Submitted</S.FeedbackSubmittedMessage>
    </S.FeedbackSubmittedContainer>
  );
};

export default FeedbackSubmitted;
