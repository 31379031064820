import { UseFormSetValue, UseFormRegister, Control, FieldError } from "react-hook-form";
import { Form } from "react-bootstrap";
import cls from "classnames";
import { FormField } from "../utils/types";
import useMandateForm from "./useMandateForm";
import renderFormFields from "../utils";

type Props = {
  formFields: FormField[];
  setValue: UseFormSetValue<Record<string, string>>;
  formProps: ReturnType<typeof useMandateForm>;
  register: UseFormRegister<Record<string, string>>;
  control: Control<Record<string, string>, Record<string, string>>;
  errors: Record<string, FieldError>;
};
export function useBasicDetailsFormGroup({
  formFields,
  setValue,
  formProps,
  control,
  register,
  errors,
}: Props): JSX.Element[] {
  function stylePhoneNumberInputField(input: FormField): FormField {
    return {
      ...input,
      numberInputProps: { className: "form-control" },
    };
  }

  function addOnCountryChangeListener(input: FormField): FormField {
    return {
      ...input,
      onCountryChange: (data): void => {
        if (data) {
          setValue("country", data);
        }
      },
    };
  }
  return formFields.map((field) => {
    if (field.name === "phone") {
      // eslint-disable-next-line no-param-reassign
      field = stylePhoneNumberInputField(addOnCountryChangeListener(field));
    }

    return (
      <Form.Group
        key={field.name}
        data-aos-easing="ease-in-back"
        data-aos-delay="100"
        data-aos-offset="0"
        controlId={`${formProps.formId}${field.name}}`}
        className={cls("aos-item", { hidden: field.hidden })}
      >
        {field?.["aria-label"] && (
          <Form.Label>
            {field?.["aria-label"]}
            {field?.required && " *"}
          </Form.Label>
        )}
        {renderFormFields(field, register, errors, control)}
        {field?.name && (
          <Form.Text className="text-danger text-capitalize">
            {errors[field.name]?.message}
          </Form.Text>
        )}
      </Form.Group>
    );
  });
}

export default useBasicDetailsFormGroup;
