const perks = [
  {
    path: "/perks/hubspot",
    heading: "HubSpot",
    class: "hubspot",
    para: "HubSpot is a marketing, sales, and customer service platform that helps companies grow better.",
    offer: "Discount on Hubspot Professional and Enterprise Products",
    logo: "/images/hubSpot-logo.png",
    learnMore:
      "https://app.hubspot.com/signup-hubspot/hubspot-for-startups?partner-code=z5ZsJOF51TcR&program=startups&__hstc=1443758.1365be24f0751807025fa5432436d4d4.1667895179760.1685538476362.1685597590417.145&__hssc=1443758.29.1685597590417&__hsfp=3953688718&uuid=f339b1b4-4514-4fbd-a9c4-80f835ef6a13",
    features: [
      "Omni-channel marketing automation, ABM tools and automation",
      "Dynamic personalization",
      "Multi-language content, Social media",
      "Video hosting & management, Contact and company scoring",
    ],
    redemption:
      "https://app.hubspot.com/signup-hubspot/hubspot-for-startups?partner-code=z5ZsJOF51TcR&program=startups",
    regionSupported: ["Global"],
    contact: ["hsfs-bdm@hubspot.com"],
  },
  {
    path: "/perks/IWG",
    heading: "IWG",
    class: "iwg",
    para: `Remotebase members can now benefit from up to $1,000 off* a workspace solution
    when they order via the link below.
    This offer includes world leading brands such as Regus,
    Spaces, HQ and Signature and is available at thousands of locations globally.
    Drop in and have a great day at work in a beautifully designed workspace,
    where you‘ll be looked after by a professional team,
    so you can be left to do your best work distraction-free.`,
    offer: "10% off all bookings for the first 3 months",
    logo: "/images/iwg-logo.png",
    learnMore: "https://events.iwgplc.com/remotebase",
    features: [
      "A wide variety of lounges",
      "Coworking spaces",
      "Offices or meeting rooms",
      "Tailored to your unique needs",
    ],
    redemption: "https://events.iwgplc.com/remotebase",
    regionSupported: ["Global"],
    contact: ["Partnerships Team", "partnerships.global@iwgplc.com"],
  },
  {
    path: "/perks/dailybot",
    heading: "DailyBot",
    class: "dailybot",
    para: `Keep your team in sync from anywhere. Automate workflows, daily stand-ups,
    improve efficiency, and save time for your hybrid or remote team.`,
    offer: "Remotebase users get 20% off any plan – ongoing",
    logo: "/images/dailybot-logo.png",
    learnMore:
      "https://app.dailybot.com/forms/c7e9a799-950b-43d0-bfaf-7fab168923c0/responses/create",
    features: [
      "Daily team check-ins and async reporting",
      "Agile stand-ups and scrum tools for chat",
      "Commands for team recognition/appreciation",
      "Surveys and in-chat automation",
    ],
    redemption:
      "https://app.dailybot.com/forms/c7e9a799-950b-43d0-bfaf-7fab168923c0/responses/create",
    regionSupported: ["Global"],
    contact: ["Albert", "support@dailybot.com"],
  },
  {
    path: "/perks/mercury",
    heading: "Mercury",
    class: "mercury",
    para: `Mercury offers banking* for startups — at any size or stage.
     With an intuitive product experience,
    founders can access free checking and savings accounts, debit and credit cards,
    domestic and international wire transfers,
     Treasury, venture debt, and more — and manage their business with confidence.
      Mercury also offers vibrant community
     programs that provide founders with the connections, advice,
     and resources to help them build the next great companies.
     Launched in 2019, Mercury is trusted by more than 100,000 startups.`,
    offer: "$500 Credit",
    logo: "/images/mercury.png",
    learnMore: "https://mercury.com/partner/remotebase",
    features: [
      "Free checking and savings accounts",
      "Debit and credit cards",
      "Domestic and international wire transfers",
      "Treasury, venture debt, and more",
    ],
    redemption: "https://mercury.com/partner/remotebase",
    regionSupported: ["US only businesses", "US and international founders"],
    contact: ["Alex Lasnik ", "alexanderl@mercury.com"],
  },
  {
    path: "/perks/letswork",
    heading: "Letswork",
    class: "letswork",
    para: `Letswork is a global membership workspace platform that allows users to work from cafes,
     hotels and coworking spaces with benefits like unlimited tea, coffee, water,
      discounts on food, access to wifi, reserved seating near plugs and access to meeting rooms.`,
    offer: "10 Free Credits on sign up.",
    logo: "/images/lets-work.png",
    learnMore: "https://www.letswork.io/campaign-register/409290",
    features: [
      "Access to 200+ coworking spaces, cafes, hotels across the globe",
      "Unlimited tea, coffee, water",
      "Discounts on F&B,- Access to community events & perks",
      "Reserved seating near plugs & access to superfast wifi",
    ],
    redemption: "https://app.letswork.io//campaign-register/409290/",
    regionSupported: ["UAE", "Saudi Arabia", "Portugal", "Spain"],
    contact: ["Thomas", "thomas@letswork.io"],
  },
  {
    path: "/perks/carta",
    heading: "Carta",
    class: "carta",
    para: `Carta is an ownership and equity management platform trusted by thousands of founders,
     investors, and employees.`,
    offer: `Remotebase clients can use to sign up for either
       our free Carta Launch plan or one of our paid plans with a 20% first year discount.`,
    logo: "/images/carta.png",
    learnMore:
      "https://carta.com/partners/referral/?utm_medium=ChannelReferral&utm_source=remotebase&PID=remotebase",
    features: [
      "Cap table management, Electronic securities & exercising",
      "Scenario modeling (SAFEs & notes), SAFE financings",
      "HRIS & payroll integration, Data Room",
      "Board consents​, Standard reporting, Equity plan generation",
    ],
    redemption:
      "https://carta.com/partners/referral/?utm_medium=ChannelReferral&utm_source=remotebase&PID=remotebase",
    regionSupported: ["Global"],
    contact: ["partnersales@carta.com"],
  },
  {
    path: "/perks/ecologi",
    heading: "Ecologi",
    class: "ecologi",
    para: `Ecologi makes climate action simple by providing a platform for businesses
    and individuals to collectively reverse climate change through funding some of the world’s
     best climate solutions. So far, over 39,299 users have collectively funded
      the planting of over 55 million trees and the reduction of 2.4 million tonnes of
      carbon emissions through Ecologi's supported reforestation and verified carbon reduction projects.`,
    offer: "100% off our Climate Positive Workforce subscription for the first month.",
    logo: "/images/ecologi.png",
    learnMore: "https://ecologi.com/",
    features: [
      "Set sustainable goals",
      "Our team's carbon footprint offset",
      "Plant trees & fund climate projects",
    ],
    redemption: "ENTER CODE 'CPWFamily' at checkout",
    regionSupported: ["Global"],
    contact: ["Camilla Watkiss", "camilla.watkiss@ecologi.com"],
  },
  {
    path: "/perks/deel",
    heading: "Deel.",
    class: "deel",
    para: `Deel is global HRIS, global payroll and compliant hiring built for remote teams.
    That means anyone, anywhere can get hired and paid with Deel.
    Using a tech-enabled self-serve process, companies can compliantly hire full-time employees
    or independent contractors across the world in minutes.
    Create localized legal contracts, track time off, grant bonuses, manage equity, and more.
    Book a demo for a personal guide to Deel's platform and unlock your global workforce today.`,
    offer: "Free HRIS for teams of less than 200. 20% off on other services for 12 months.",
    learnMore: "https://www.deel.com/partners/remotebase",
    logo: "/images/deel.png",
    features: ["HRIS", "Employer of Record", "Global Payrol", "Global Compliance Integrations"],
    redemption: "https://www.deel.com/partners/remotebase",
    regionSupported: ["Global"],
    contact: ["Dan Taylor", "daniel.taylor@deel.com"],
  },
  {
    path: "/perks/notion",
    heading: "Notion",
    class: "notion",
    para: `Notion is a single space where you can think, write, and plan.
    Capture thoughts, manage projects, or even run an entire company — and do it exactly the way you want.`,
    offer: "$1000 in credit",
    learnMore:
      "https://www.notion.so/startups?utm_medium=partner&utm_source=startup_partner&utm_campaign=startup-program-partner-remotebase",
    logo: "/images/notion.png",
    features: [
      "Teams use Notion to connect",
      "Note taking",
      "Data management",
      "Project management, among others",
    ],
    redemption:
      "Submit an application using our custom link: https://bit.ly/3IjIRy1 and select our name on the partner list",
    regionSupported: ["Global - Net new Notion Plus plan", "Customers only with < 100 employees"],
    contact: ["Yann Alexandre Petretti", "startups-help@makenotion.com"],
  },
  {
    path: "/perks/onepassword",
    heading: "1Password",
    class: "onepassword",
    para: `82% of breaches involve a human element, so protecting
     your business starts with protecting your people.
     And protecting your people starts with 1Password.
    With 1Password you only ever need to memorize one password.
     All your other passwords and important information are protected
    by your Account Password, which only you know.`,
    offer: "25% off for all Remotebase customers for the first year",
    logo: "/images/one-password.png",
    learnMore: "https://start.1password.com/sign-up/plan?c=REMOTEB251Y",
    features: [
      "Generate strong, unique passwords for all your accounts, then log in with a tap or a click.",
      "Store anything from logins and secure notes to SSH keys",
      `Access everything
       you've stored in 1Password on Windows, macOS, Linux, iOS, Android,
      a web browser or even the command line.`,
      "Get passwords out of spreadsheets and email and into 1Password",
    ],
    redemption:
      "Using this landing page: https://start.1password.com/signup/plan?c=REMOTEB251Y or applying this promo code: REMOTEB251Y",
    regionSupported: ["Global - Net new Notion Plus plan", "Customers only with < 100 employees"],
    contact: ["Grace Lu", "grace.lu@agilebits.com"],
  },
  {
    path: "/perks/close",
    heading: "Close",
    class: "close",
    para: `Close is a high-performance sales
     CRM built for teams of all sizes to better engage leads, close more deals,
      and manage all communication in one single platform.
     Close has built-in calling, email (2-way sync), SMS, sequences,
    and pipeline tracking to make your entire sales process runs smoothly from start to finish.`,
    offer: "$300 Close Subscription Credit",
    learnMore: "https://www.close.com/startups",
    logo: "/images/close.png",
    features: [
      "Built in Calling, emailing, SMS, Sequences",
      "Lead engagement and pipeline management",
      "Easy to setup, even easier to adopt",
    ],
    redemption: "close.com/startups",
    regionSupported: ["Global"],
    contact: ["James Urie", "james@close.com"],
  },
  {
    path: "/perks/vouch",
    heading: "Vouch",
    class: "vouch",
    para: `Vouch is business insurance for startups.
     In under 10 minutes, founders and finance teams
    can apply for coverage customized for their startup and industry vertical.
    Vouch offers 10+ lines of proprietary coverage, from General Liability, EPL, D&O, to Cyber policies.
    As the underwriter of these insurance policies, Vouch removes any hidden third party fees,
    and handles all of the paperwork from start to finish.`,
    offer: "5% off coverage for Remotebase customers.",
    logo: "/images/vouch.png",
    learnMore: "https://www.vouch.us/partners/remotebase",
    features: ["Business Insurance"],
    redemption: "https://vouch.us/remotebase",
    regionSupported: ["United States"],
    contact: ["Linda Horn", "linda.horn@vouch.us"],
  },
  {
    path: "/perks/secureframe",
    heading: "Secureframe",
    class: "secureframe",
    para: `Secureframe is the leading, all-in-one security
    and privacy compliance automation platform.
    Secureframe makes it fast and easy to achieve
    SOC 2, ISO 27001, PCI DSS, HIPAA, GDPR, CCPA and NIST compliance.
    Secureframe enables customers to scale compliance at every stage
    of growth while staying focused on growing their business.`,
    offer: "20% off any compliance framework for 1 year.",
    logo: "/images/secureframe.png",
    learnMore: "https://secureframe.com/offer/remotebase",
    features: [
      "All-in-one security and privacy compliance platform for SOC 2, ISO 27001, HIPAA, GDPR & more",
      "100+ integrations with the technologies you use in your business",
      "Training built into the platform for a seamless employee, contractor & admin experience",
      "30+ compliance experts and former auditors to support you",
    ],
    redemption: "https://secureframe.com/offer/remotebase",
    regionSupported: ["Global"],
    contact: ["Jess", "jess@secureframe.com"],
  },
  {
    path: "/perks/swagup",
    heading: "SwagUp",
    class: "swagup",
    para: `SwagUp allows you to easily create, automate,
    and distribute high-quality fully customized swag where and when you need it!`,
    offer: "15% off first order for Remotebase customers.",
    logo: "/images/swagup.png",
    learnMore:
      "https://www.swagup.com/?utm_source=remotebase&utm_medium=marketplace&utm_campaign=15%25firstorder",
    features: [
      "An end-to-end servicing model from strategy to concept to delivery.",
      "Dedicated and integrated team - tech, creative, product, operations and logistics.",
      `Powerful swag management platform - we offer redeem pages and storefronts to manage orders
      , production, fulfillment, logistics and warehousing capabilities.`,
    ],
    redemption:
      "https://www.swagup.com/?utm_source=remotebase&utm_medium=marketplace&utm_campaign=15%25firstorder",
    regionSupported: ["Global"],
    contact: ["Cassidy Miller", "cassidy@swagup.com"],
  },
  {
    path: "/perks/clevertap",
    heading: "CleverTap",
    class: "clevertap",
    para: `CleverTap is the World's #1 Retention
     Cloud that helps app-first brands personalize and optimize
      all consumer touch points to improve user engagement,
      retention, and lifetime value. It's the only solution built to address the needs of
      retention and growth teams, with audience analytics, deep-segmentation, multi-channel
      engagement, product recommendations, and automation in one unified product.`,
    logo: "/images/clevertap.png",
    learnMore: "https://clevertap.com/startup-pricing/",
    offer: "50% discount for 12 months on the CleverTap Basic Plan for qualifying startups",
    features: [
      `Understand your customers with real-time insights
       and immediately trigger campaigns based on user behavior.`,
      "Automated User Segmentation",
      `Group users into real-time segments and perfect your audience targeting with our powerful,
       automated segmentation engine.`,
      "All-in-one Platform",
    ],
    redemption: "https://clevertap.com/leap/?utm_medium=partner&utm_source=remotebase",
    regionSupported: ["Global"],
    contact: ["Shreya Agarwal", "shreya.agarwal@clevertap.com"],
  },
  {
    path: "/perks/veremark",
    heading: "Veremark",
    class: "veremark",
    para: `Fast, accurate, secure, scalable Veremark has pioneered an entirely digital,
    cost-effective global solution that improves quality of hire
    while providing a superior candidate experience.`,
    logo: "/images/veremark.png",
    learnMore: "https://www.veremark.com/",
    offer: "10% off for all Remotebase customers",
    features: ["Simple", "Responsive", "No long contracts", "Dedicated Care"],
    redemption: `Monthly invoicing: notify your Veremark account manager.
      Platform credit purchasing: directly applied when spending a minimum of $450.`,
    regionSupported: ["Global"],
    contact: ["Isaac R. Betanzos", "isaac@veremark.com"],
  },
];

export const perksShowcase = [
  {
    imageLink: "/images/hubSpot-logo.png",
    subHeading: "Discount on Hubspot Professional and Enterprise Products",
    path: "/perks/hubspot",
    content:
      "HubSpot is a marketing, sales, and customer service platform that helps companies grow better.",
    visitLink:
      "https://app.hubspot.com/signup-hubspot/hubspot-for-startups?partner-code=z5ZsJOF51TcR&program=startups&__hstc=1443758.1365be24f0751807025fa5432436d4d4.1667895179760.1685538476362.1685597590417.145&__hssc=1443758.29.1685597590417&__hsfp=3953688718&uuid=f339b1b4-4514-4fbd-a9c4-80f835ef6a13",
  },
  {
    imageLink: "/images/iwg-logo.png",
    path: "/perks/IWG",
    subHeading: "10% off all bookings for the first 3 months",
    content: `Remotebase members can now benefit from up to $1,000 off* a workspace solution
       when they order via the link below.`,
    visitLink: "https://events.iwgplc.com/remotebase",
  },
  {
    imageLink: "/images/dailybot-logo.png",
    path: "/perks/dailybot",
    subHeading: "Remotebase users get 20% off any plan - ongoing",
    content: `Keep your team in sync from anywhere. Automate workflows, daily stand-ups, improve
    efficiency, and save time for your hybrid or remote team.`,
    visitLink:
      "https://app.dailybot.com/forms/c7e9a799-950b-43d0-bfaf-7fab168923c0/responses/create",
  },
  {
    imageLink: "/images/mercury.png",
    path: "/perks/mercury",
    class: "additionalMercury",
    subHeading: "For Remotebase users Special Offer $500",
    content: `Mercury offers banking* for startups — at any size or stage. With an intuitive product
    experience, founders can access free checking and savings accounts, debit and credit
    cards, domestic and international wire transfers.`,
    visitLink: "https://mercury.com/partner/remotebase",
  },
  {
    imageLink: "/images/lets-work.png",
    class: "letswork",
    path: "/perks/letswork",
    subHeading: "Special Offer 10 Free Credits on sign up",
    content: `Letswork is a global membership workspace platform that allows users to work from
    cafes, hotels and coworking spaces with benefits like unlimited tea, coffee, water.`,
    visitLink: "https://www.letswork.io/campaign-register/409290",
  },
  {
    imageLink: "/images/carta.png",
    path: "/perks/carta",
    subHeading: `Remotebase clients can use to sign up for either our free Carta Launch plan or one of
    our paid plans with a 20% first year discount.`,
    content: `Carta is an ownership and equity management platform trusted by thousands of founders,
    investors, and employees.`,
    visitLink:
      "https://carta.com/partners/referral/?utm_medium=ChannelReferral&utm_source=remotebase&PID=remotebase",
  },
  {
    imageLink: "/images/ecologi.png",
    path: "/perks/ecologi",
    class: "additional",
    subHeading: `Remotebase clients can use to sign up for either our free Carta Launch plan or one of
    our paid plans with a 20% first year discount.`,
    content: `Ecologi makes climate action simple by providing a platform for businesses and
    individuals to collectively reverse climate change through funding some of the world's
    best climate solutions.`,
    visitLink: "https://ecologi.com/",
  },
  {
    imageLink: "/images/deel.png",
    class: "additionalDeel",
    path: "/perks/deel",
    subHeading: "Free HRIS for teams of less than 200. 20% off on other services for 12 months",
    content: `Deel is global HRIS, global payroll and compliant hiring built for remote teams. That
    means anyone, anywhere can get hired and paid with Deel`,
    visitLink: "https://www.deel.com/partners/remotebase",
  },
  {
    imageLink: "/images/notion.png",
    path: "/perks/notion",
    subHeading: " Special Offer for Remotebase customers $1000 in credit",
    content: `Notion is a single space where you can think, write, and plan. Capture thoughts,
    manage projects, or even run an entire company — and do it exactly the way you want.`,
    visitLink:
      "https://www.notion.so/startups?utm_medium=partner&utm_source=startup_partner&utm_campaign=startup-program-partner-remotebase",
  },
  {
    imageLink: "/images/one-password.png",
    path: "/perks/onepassword",
    class: "additonalPassword",
    subHeading: "25% off for all Remotebase customers for the first year",
    content: `82% of breaches involve a human element, so protecting your business starts with
    protecting your people.And protecting your people starts with 1Password. With
    1Password you only ever need to memorize one password.`,
    visitLink: "https://start.1password.com/sign-up/plan?c=REMOTEB251Y",
  },
  {
    imageLink: "/images/close.png",
    path: "/perks/close",
    class: "additionalClose",
    subHeading: "Special Offer $300 Close Subscription Credit",
    content: `Close is a high-performance sales CRM built for teams of all sizes to better engage
    leads, close more deals, and manage all communication in one single platform.`,
    visitLink: "https://www.close.com/startups",
  },
  {
    imageLink: "/images/vouch.png",
    path: "/perks/vouch",
    subHeading: "Special Offer 5% off coverage for Remotebase customers",
    content: `Vouch is business insurance for startups. In under 10 minutes, founders and finance
    teams can apply for coverage customized for their startup and industry vertical. Vouch
    offers 10+ lines of proprietary coverage, from General Liability, EPL, D&O, to Cyber
    policies.`,
    visitLink: "https://www.vouch.us/partners/remotebase",
  },
  {
    imageLink: "/images/secureframe.png",
    path: "/perks/secureframe",
    class: "additionalDeel",
    subHeading: "Special Offer 20% off any compliance framework for 1 year",
    content: `Secureframe is the leading, all-in-one security and privacy compliance automation
    platform. Secureframe makes it fast and easy to achieve SOC 2, ISO 27001, PCI DSS,
    HIPAA, GDPR, CCPA and NIST compliance.`,
    visitLink: "https://secureframe.com/offer/remotebase",
  },
  {
    imageLink: "/images/swagup.png",
    path: "/perks/swagup",
    class: "additionalSwag",
    subHeading: "15% off first order for Remotebase customers",
    content: `SwagUp allows you to easily create, automate, and distribute high-quality fully
    customized swag where and when you need it!`,
    visitLink:
      "https://www.swagup.com/?utm_source=remotebase&utm_medium=marketplace&utm_campaign=15%25firstorder",
  },
  {
    imageLink: "/images/clevertap.png",
    path: "/perks/clevertap",
    class: "additionalClever",
    subHeading: "50% discount for 12 months on the CleverTap Basic Plan for qualifying startups",
    content: `CleverTap is the World's #1 Retention Cloud that helps app-first brands personalize
    and optimize all consumer touch points to improve user engagement, retention, and
    lifetime value.`,
    visitLink: "https://clevertap.com/startup-pricing/",
  },
  {
    imageLink: "/images/veremark.png",
    path: "/perks/veremark",
    class: "additionalVeremark",
    subHeading: "10% off for all Remotebase customers",
    content: `Fast, accurate, secure, scalable Veremark has pioneered an entirely digital,
    cost-effective global solution that improves quality of hire while providing a
    superior candidate experience.`,
    visitLink: "https://www.veremark.com/",
  },
];
export default perks;
