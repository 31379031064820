import styled from "styled-components";

export const MediumContainer = styled.div`
  padding: 4rem 2rem;
`;

export const MediumList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-content: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: 330px 330px;
    grid-gap: 2.89rem;
  }
`;

export const MediumItem = styled.div`
  text-align: center;
  padding: 0.5rem;
  max-width: 330px;
  margin: 0 auto;
`;

export const MediumIcon = styled.div`
  width: 4rem;
  height: 4rem;
  background: rgba(237, 37, 47, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MediumTitle = styled.h4`
  margin: 1.5rem 0 0.75rem;
  font-family: "Platform";
  font-size: 1.5rem;
  line-height: 2rem;
  color: inherit;
`;

export const MediumText = styled.p`
  margin-bottom: 1rem;
  font-family: "Avenir";
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const MediumLink = styled.p`
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #312a50;
  text-decoration: none;

  &.active,
  &:hover {
    color: #312a50;
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.05);
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
