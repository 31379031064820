import _ from "lodash";
import { EmploymentFormEnum, IEmploymentHistory, ReturnEmploymentValidationType } from "./types";

export const markPresent = "Present";

export const fullMonthYearPattern = "MMMM YYYY";

export const editEmploymentHistoryTitle = "Edit Employment History";

export const addEmploymentHistoryTitle = "Add Employment History";

export const companyPlaceholder = "Ex: Remotebase";

export const companyLabel = "Company *";

export const startDateLabel = "Start Date *";

export const endDateLabel = "End Date";

export const checkBoxLabel = "I currently work here";

export const titlePlaceholder = "Ex: UI/UX Designer";

export const summaryPlaceholder = "Description";

export const summaryLabel = "Description (optional)";

export const titleLabel = "Title *";

export const companyErrorText = "Company is required";

export const titleErrorText = "Title is required";

export const startDateErrorText = "Start date is required";

export const inValidDatesPair = "Start date must be less than end date";

export const currentJobErrorText = "Current job is required";

export const deleteEmploymentMessage = "Are you sure you want to delete this employment entry?";

export const employmentFormValidation = (
  input: IEmploymentHistory,
): ReturnEmploymentValidationType => {
  const company = input[EmploymentFormEnum.company].length < 1 ? companyErrorText : undefined;
  const title = input[EmploymentFormEnum.title].length < 1 ? titleErrorText : undefined;
  let startDate = input[EmploymentFormEnum.startDate].length < 1 ? startDateErrorText : undefined;
  if (
    input[EmploymentFormEnum.endDate] &&
    new Date(input[EmploymentFormEnum.startDate]) >=
      new Date(input[EmploymentFormEnum.endDate] as string)
  ) {
    startDate = inValidDatesPair;
  }
  const currentJob = !_.isBoolean(input.currentJob) ? currentJobErrorText : undefined;
  return {
    company,
    title,
    startDate,
    currentJob,
  };
};
