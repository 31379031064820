import styled from "styled-components";
import { Link } from "react-router-dom";

export const EntryForm = styled.form`
  width: 100%;
  max-width: 386px;
  margin-right: 107px;

  @media only screen and (max-width: 1199px) {
    margin: 0 auto;
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 1.15;
  letter-spacing: -0.045em;
  @media only screen and (max-width: 1023px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 1199px) {
    letter-spacing: inherit;
  }
`;

export const DotColor = styled.span`
  color: #3f73f6;
`;

export const EntryLinksDiv = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  @media only screen and (max-width: 767px) {
    display: flex;
  }
`;

export const ENTRY_LINK = styled(Link)`
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  font-size: 14px;
  margin-bottom: 16px;
  color: #3f73f6;

  &:first-child {
    color: #999fae;
  }
  &:nth-child(2) {
    color: #3f73f6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 767px) {
    font-size: 15px;
  }
`;

export const LoginWrapper = styled.div`
  width: 100%;
`;

export const LoginHeading = styled.h1`
  font-size: 40px;
  color: #404040;
  font-weight: 700;
  letter-spacing: -0.045em;
  margin-bottom: 10px;

  @media only screen and (min-width: 1600px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const LoginDescrip = styled.p`
  font-size: 20px;
  color: #404040;
  font-weight: 300;
  letter-spacing: -0.045em;
  margin-bottom: 66px;

  @media only screen and (min-width: 1600px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const FormGroups = styled.div`
  width: 100%;
`;

export const FormLabel = styled.label`
  font-size: 16px;
  color: #bfbfbf;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 24px;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 16px;
  }

  &.active {
    label {
      color: #898989;
    }
  }
`;

export const FormField = styled.input`
  font-size: 16px;
  color: #898989;
  font-weight: 500;
  letter-spacing: -0.025em;
  display: flex;
  background-color: #fff;
  border: 1px solid #bbbbbb;
  border-radius: 27.5px;
  padding: 8px 24px;
  width: 100%;
  height: calc(2.375em + 1rem + 2px);
  transition: all 0.25s ease;

  @media only screen and (max-width: 767px) {
    height: calc(2em + 1rem + 2px);
  }

  &.focus-visible,
  &:focus {
    background-color: #fff;
    border: 2px solid #5138ed;
    outline: none;
    box-shadow: none;
    color: #404040;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 15px;
  font-size: 0.8rem;
`;
