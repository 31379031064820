import { FC } from "react";
import { englishProficiencyMap } from "@remotebase/constants";
import * as Styled from "../styles";

interface Props {
  children?: React.ReactNode;
  experience: string | null;
  englishProficiency: string | "";
  expectedSalaryInUsd?: number | null;
}

export const ProfileInnerDetailsSection: FC<Props> = ({
  children,
  expectedSalaryInUsd,
  experience,
  englishProficiency,
}) => {
  return (
    <Styled.ProfileDetailWrapper>
      {children}
      <Styled.ProfileDetailList>
        <Styled.ProfileDetailItem>
          <Styled.ProfileDetailContent>
            <Styled.ProfileDetailSbHd>Experience</Styled.ProfileDetailSbHd>
            <Styled.ProfileDetailPara>{experience} Year(s)</Styled.ProfileDetailPara>
          </Styled.ProfileDetailContent>
        </Styled.ProfileDetailItem>
        <Styled.ProfileDetailItem>
          <Styled.ProfileDetailContent>
            <Styled.ProfileDetailSbHd>English Level</Styled.ProfileDetailSbHd>
            <Styled.ProfileDetailPara>
              {englishProficiencyMap[englishProficiency]}
            </Styled.ProfileDetailPara>
          </Styled.ProfileDetailContent>
        </Styled.ProfileDetailItem>
        <Styled.ProfileDetailItem>
          <Styled.ProfileDetailContent>
            <Styled.ProfileDetailSbHd>
              {expectedSalaryInUsd ? "Salary" : " "}
            </Styled.ProfileDetailSbHd>
            <Styled.ProfileDetailPara>
              {expectedSalaryInUsd ? `$${expectedSalaryInUsd}/ Month` : " "}
            </Styled.ProfileDetailPara>
          </Styled.ProfileDetailContent>
        </Styled.ProfileDetailItem>
      </Styled.ProfileDetailList>
    </Styled.ProfileDetailWrapper>
  );
};

export default ProfileInnerDetailsSection;
