import styled from "styled-components";

export const ConfirmationWrapper = styled.div`
  margin: 1rem 1.5rem;
`;

export const ConfirmationHeading = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #212121;
`;

export const ConfirmationMessage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin-top: 20px;
  color: #212121;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;
