import styled from "styled-components";

export const LoginWrapper = styled.div`
  width: 100%;
`;

export const LoginHeading = styled.h1`
  font-size: 40px;
  color: #404040;
  font-weight: 700;
  letter-spacing: -0.045em;
  margin-bottom: 10px;

  @media only screen and (min-width: 1600px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const LoginDescription = styled.p`
  font-size: 20px;
  color: #404040;
  font-weight: 300;
  letter-spacing: -0.045em;
  margin-bottom: 66px;

  @media only screen and (min-width: 1600px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const FormGroups = styled.div`
  width: 100%;
`;
