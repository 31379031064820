import React, { FC } from "react";
import { ColorObject } from "@remotebase/constants";
import { Wrapper, Text, Icon } from "./styles";

interface Props {
  variant?: keyof typeof ColorObject;
  className?: string;
  size?: "large" | "small";
  title?: string;
  children?: React.ReactNode;
}

const Container: FC<Props> = ({
  children,
  variant = "primary",
  className,
  size = "large",
  title,
}) => {
  return (
    <Wrapper $variant={variant} className={className} $size={size} title={title}>
      {children}
    </Wrapper>
  );
};

export { Container, Text, Icon };
