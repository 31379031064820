import styled from "styled-components";

export const FeedbackInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
`;

export const FeedbackInputField = styled.input`
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  margin-top: 5px;
  font-size: 16px;
  color: #292930;
  margin-bottom: 0 !important;
  border-radius: 27.5px !important;
  &.disable {
    background-color: #ededed;
  }
`;

export const FeedbackInputWrapper = styled.div`
  margin-bottom: 30px;
`;

export const StatusCheckBoxWrapper = styled.div`
  position: relative;
  top: 4px;
  left: 5px;
`;
