import { UseFormRegister, Control, FieldError } from "react-hook-form";
import { Form } from "react-bootstrap";
import cls from "classnames";
import { FormField } from "../utils/types";
import useMandateForm from "./useMandateForm";
import renderFormFields from "../utils";

export enum AddressSectionType {
  A = "A",
  B = "B",
}

type Props = {
  type: AddressSectionType;
  formFields: FormField[];
  formProps: ReturnType<typeof useMandateForm>;
  register: UseFormRegister<Record<string, string>>;
  control: Control<Record<string, string>, Record<string, string>>;
  errors: Record<string, FieldError>;
};
export function useAddressSection({
  formFields,
  formProps,
  control,
  register,
  errors,
  type,
}: Props): JSX.Element[] | null {
  if (type === AddressSectionType.A) {
    return formFields.map((field) => {
      return (
        <Form.Group
          key={field.name}
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="0"
          controlId={`${formProps.formId}${field.name}}`}
          className={cls("aos-item", { hidden: field.hidden })}
        >
          {field?.["aria-label"] && (
            <Form.Label>
              {field?.["aria-label"]}
              {field?.required && " *"}
            </Form.Label>
          )}
          {renderFormFields(field, register, errors, control)}
          {field?.name && (
            <Form.Text className="text-danger text-capitalize">
              {errors[field.name]?.message}
            </Form.Text>
          )}
        </Form.Group>
      );
    });
  }
  if (type === AddressSectionType.B) {
    return formFields.map((field) => {
      return (
        <Form.Group
          key={field.name}
          controlId={`${formProps.formId}${field.name}}`}
          className={`${cls("aos-item", { hidden: field.hidden })} w-100`}
        >
          {field?.["aria-label"] && (
            <Form.Label>
              {field?.["aria-label"]}
              {field?.required && " *"}
            </Form.Label>
          )}
          {renderFormFields(field, register, errors, control)}
          {field?.name && (
            <Form.Text className="text-danger text-capitalize">
              {errors[field.name]?.message}
            </Form.Text>
          )}
        </Form.Group>
      );
    });
  }

  return null;
}

export default useAddressSection;
