import { useLazyQuery } from "@apollo/client";
import { GetMandateAndPaymentMethodQuery } from "@remotebase/amplify-constants/API";
import { getMandateAndPaymentMethod } from "@remotebase/amplify-constants/graphql/queries";
import { getQuery } from "hooks/utils";
import { ReturnPropsMandateAndPaymentMethod } from "./types";

export function useMandatePaymentMethod(
  completionHandler?: () => void,
): ReturnPropsMandateAndPaymentMethod {
  const [fetchMandatePaymentMethod, { data: response, loading: isLoading, error }] =
    useLazyQuery<GetMandateAndPaymentMethodQuery>(getQuery(getMandateAndPaymentMethod), {
      onCompleted: () => {
        if (completionHandler) completionHandler();
      },
    });

  const data = response?.getMandateAndPaymentMethod?.data ?? null;
  return {
    data,
    isLoading,
    error,
    fetchMandatePaymentMethod,
  };
}

export default useMandatePaymentMethod;
