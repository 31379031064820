import styled from "styled-components";

export const AvailabilityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f9fe;
  font-family: "Poppins", sans-serif;
`;

export const AvailabilityContainer = styled.div`
  width: 100%;
  max-width: 775px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 1600px) {
    max-width: 860px;
  }
`;
