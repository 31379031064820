import styled from "styled-components";

export const EducationHistoryCover = styled.div`
  padding: 3rem 3rem 1.5rem;
`;

export const EducationHistoryTitle = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 0rem;
  font-family: "poppins600", sans-serif;
`;

export const EducationHistory = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

export const EducationCover = styled.div`
  display: block;
`;

export const HeadingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1.75rem;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  gap: 0.25rem;
`;

export const IconGroup = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.25rem;

  & svg {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
`;

export const AddNew = styled.p`
  color: #3c65fe;
  margin-bottom: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const HeadingTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0;
`;

export const TitleLabel = styled.h3`
  font-size: 1rem;
  margin-bottom: 0;
`;

export const TextContent = styled.p`
  font-size: 0.875rem;
  color: #7c7c7c;
  margin-bottom: 0;
`;
