import { FC, useState } from "react";
import ACHTermsAndCondition from "./AchTermsAndCondition";
import BillingDetailsForm from "./BillingDetailsForm";

import StripeElementProvider from "./StripeElementProvider";

type Props = {
  skipPaymentMethod?: boolean;
  clientSecret: string;
  setupIntentId: string;
};

const BillingDetails: FC<Props> = ({ skipPaymentMethod = false, clientSecret, setupIntentId }) => {
  const [showConfirmation, setShowConfirmation] = useState(skipPaymentMethod);

  const showTermsAndCondition = showConfirmation && clientSecret && setupIntentId;

  return (
    <StripeElementProvider clientSecret={clientSecret}>
      {showTermsAndCondition ? (
        <ACHTermsAndCondition clientSecret={clientSecret} setupIntentId={setupIntentId} />
      ) : (
        <BillingDetailsForm clientSecret={clientSecret} setShowConfirmation={setShowConfirmation} />
      )}
    </StripeElementProvider>
  );
};

export default BillingDetails;
