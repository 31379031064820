import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";
import { options } from "../../../utils/constants/availabilty";
import { TimeDropDown } from "./styles";

interface Props {
  register: UseFormRegister<FieldValues>;
  name: string;
}

export const SelectTime: React.FC<Props> = ({ register, name, ...rest }) => {
  return (
    <TimeDropDown {...register(name)} {...rest}>
      {options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </TimeDropDown>
  );
};

export default SelectTime;
