export enum WorkableStages {
  Sourced = "sourced",
  Applied = "applied",
  ResumeShortlisted = "resume-shortlisted",
  ClearedDomainTest = "domain-test-cleared",
  HRInterview = "hr-interview",
  VideoInterview = "video-interview",
  MiddletierInterview = "middle-tier-interview",
  FinalInterview = "final-interview",
  Offer = "offer",
  Hired = "hired",
  Network = "network",
  ProblemSolvingCleared = "problem-solving-cleared",
}

export const some = "";
export const DefaultCandidateName = "John Doe";
