export const imageUrl = {
  logoRemotebase: "/images/logo-remotebase.png",
  logoRemotebaseWhiteTxt: "/images/logo-white.png",
  loginPeopleWorking: "/images/login_people-working.png",
  signupPeopleWorking: "/images/signup_people-working.png",
  sidebarPic: "/images/sidebar-pic.svg",
  logo: "/images/logo.svg",
  engineer: "/images/engineer.png",
  engineerAvatar: "/images/engineer-avatar.svg",
  fire: "/images/fire.svg",
  google: "/images/google.svg",
  defaultAvatar: "/images/userDefault.png",
  userDefault: "/images/userDefault.png",
};

export default imageUrl;
