import React, { Fragment } from "react";
import { DragAndDrop, ShouldRender } from "@remotebase/components";
import {
  allowedResumeSize,
  createProfileResumeSize,
  createProfileResumeType,
  docFile,
  docxFile,
  pdfFile,
} from "@remotebase/constants";
import * as Styled from "./styles";
import DocumentSubmit from "./documentSubmit";
import { DocumentUploaderCardProps } from "./types";
import useDocumentUploader from "./hooks/use-document-uploader";

export const DocumentUploaderCard: React.FC<DocumentUploaderCardProps> = ({
  handleSubmit,
  isLoading,
  document,
  setDocument,
  allowedTypes = [pdfFile, docFile, docxFile],
  allowedTypesError = createProfileResumeType,
  noSizeLimit = false,
  allowedSize = noSizeLimit ? Infinity : allowedResumeSize,
  allowedSizeError = createProfileResumeSize,
  pastDocumentUpload,
  uploadButtonId,
}) => {
  const {
    documentErr,
    pastDocumentName,
    allowedSizeInMB,
    submitDocument,
    confirmDocument,
    uploadIcon,
    uploadText,
    allowedTypesExtension,
    getSeperator,
    accept,
  } = useDocumentUploader({
    handleSubmit,
    document,
    setDocument,
    allowedTypes,
    allowedTypesError,
    allowedSize,
    allowedSizeError,
    pastDocumentUpload,
  });

  return (
    <Fragment>
      <DragAndDrop disabled={isLoading} onChange={confirmDocument} accept={accept}>
        <Styled.UploadDocumentBox>
          <Styled.UploadIconBox>
            <img src={`/images/${uploadIcon}.svg`} alt="upload icon" />
          </Styled.UploadIconBox>

          <Styled.UploadDocumentText>{uploadText}</Styled.UploadDocumentText>
          <Styled.UploadDocumentText>
            {document.file?.name ?? pastDocumentName}
          </Styled.UploadDocumentText>
        </Styled.UploadDocumentBox>
      </DragAndDrop>

      <ShouldRender if={documentErr}>
        <Styled.UploadDocumentError>{documentErr}</Styled.UploadDocumentError>
      </ShouldRender>
      <p>
        <Styled.NoteSpan>Supports </Styled.NoteSpan>
        {allowedTypesExtension.map((type, index) => (
          <Styled.TypeSpan key={type}>
            {type}
            {getSeperator(index)}
          </Styled.TypeSpan>
        ))}
        {!noSizeLimit && (
          <Styled.NoteSpan> with max file size of {allowedSizeInMB} MB.</Styled.NoteSpan>
        )}
      </p>
      <DocumentSubmit
        handleSubmit={submitDocument}
        isLoading={isLoading}
        documentUploaded={(document.isUploaded && !document.isSubmitted) || !!pastDocumentUpload}
        uploadButtonId={uploadButtonId}
      />
    </Fragment>
  );
};

export default DocumentUploaderCard;
