/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useMemo, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { MandateAndPaymentMethodResponse } from "@remotebase/amplify-constants/API";
import withError from "state/error/withErrorHoc";
import { ErrorProps } from "@remotebase/constants";
import { useHistory } from "react-router-dom";
import {
  AbsoluteContainer,
  Container,
  InfoList,
  SectionHeader,
  SectionHeaderSection,
  HeaderInfoPara,
  SomeMandateInfoPara,
  WithdrawMandateBtn,
  WithdrawMandateContainer,
} from "../styles";
import GlobeIcon from "../../icons/GlobeIcon";
import { useWithdrawMandate } from "../../hooks";
import { SectionHeaderText, mandateProvidedInfoText, someInfoHereText } from "../constants";
import { getMandateInfoItems, processMandateData } from "./helper";

export type MandateProvidedProps = {
  paymentAndMandate: Omit<MandateAndPaymentMethodResponse, "__typename">;
};
function MandateProvided({
  paymentAndMandate,
  showError,
}: MandateProvidedProps & ErrorProps): JSX.Element {
  const { mandate, paymentMethod } = paymentAndMandate;
  const { isLoading, withDrawMandate, data } = useWithdrawMandate();
  const withdrawMandateBtnRef = useRef<HTMLButtonElement | null>(null);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      processMandateData(data, showError, withdrawMandateBtnRef, history);
    }
  }, [data]);

  const infoItems = useMemo(() => {
    return getMandateInfoItems(mandate, paymentMethod);
  }, [mandate, paymentMethod]);

  const handleWithdrawMandate = (): void => {
    withDrawMandate({ variables: { paymentMethodId: paymentMethod.id } });
  };

  const withdrawBtnText = isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    "Withdraw this Mandate"
  );

  return (
    <Container>
      <SectionHeaderSection>
        <SectionHeader>{SectionHeaderText}</SectionHeader>
        <HeaderInfoPara>{mandateProvidedInfoText}</HeaderInfoPara>
      </SectionHeaderSection>

      <WithdrawMandateContainer>
        <GlobeIcon />
        <AbsoluteContainer>
          <SomeMandateInfoPara>{someInfoHereText}</SomeMandateInfoPara>
          <WithdrawMandateBtn
            ref={withdrawMandateBtnRef}
            disabled={isLoading}
            onClick={handleWithdrawMandate}
          >
            {withdrawBtnText}
          </WithdrawMandateBtn>
        </AbsoluteContainer>
      </WithdrawMandateContainer>

      <InfoList>{infoItems}</InfoList>
    </Container>
  );
}

export default withError(MandateProvided);
