import { FC } from "react";
import * as Styled from "../styles";

interface Props {
  children?: React.ReactNode;
  skills: Array<string | null>;
}
export const ProfileSkillSection: FC<Props> = ({ children, skills }) => {
  return (
    <Styled.ExpertiseOuterWrapper>
      <Styled.ExpertiseWrapper>
        {children}
        <Styled.ExpertiseContainer>
          {skills?.map((skill, index) => (
            <Styled.ExpertiseBox key={`skill-${index}`}>{skill}</Styled.ExpertiseBox>
          ))}
        </Styled.ExpertiseContainer>
      </Styled.ExpertiseWrapper>
    </Styled.ExpertiseOuterWrapper>
  );
};

export default ProfileSkillSection;
