import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderLogoBox = styled(Link)`
  display: block;
  width: 186px;
  margin-bottom: 45px;
`;

export const HeaderLogoImg = styled.img`
  max-width: 100%;
`;
