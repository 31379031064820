import { FC, Fragment } from "react";
import * as Styled from "./styles";
import { TalentBoxShimmer } from "./TalentBoxShimmer";

export const TalentsListShimmer: FC = () => {
  return (
    <Styled.TalentGrid>
      <Fragment>
        {[...Array(6)].map((_, index) => (
          <TalentBoxShimmer key={index} />
        ))}
      </Fragment>
    </Styled.TalentGrid>
  );
};

export default TalentsListShimmer;
