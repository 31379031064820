export enum EducationFormEnum {
  startMonth = "startMonth",
  startYear = "startYear",
  endMonth = "endMonth",
  endYear = "endYear",
  certificate = "degree",
  institution = "school",
  isEdit = "isEdit",
  selectedEducationIndex = "selectedEducationIndex",
  doErrorsExist = "doErrorsExist",
}

export interface FormFields {
  startYear: string;
  startMonth: string;
  endYear: string;
  endMonth: string;
  degree: string;
  school: string;
}

export interface EducationFormData {
  [EducationFormEnum.startYear]?: string | null;
  [EducationFormEnum.startMonth]?: string | null;
  [EducationFormEnum.endYear]?: string | null;
  [EducationFormEnum.endMonth]?: string | null;
  [EducationFormEnum.certificate]?: string | null;
  [EducationFormEnum.institution]?: string | null;
  [EducationFormEnum.isEdit]?: boolean | null;
  [EducationFormEnum.selectedEducationIndex]?: number | null;
}

export interface FormData extends FormFields {
  isEdit: boolean;
  selectedEducationIndex: number;
}
export interface FormErrors extends FormFields {
  doErrorsExist: boolean;
}
