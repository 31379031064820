import { StyledProps } from "@remotebase/constants";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import Viewer from "react-doc-viewer";

export const ProfileGridWrapper = styled.div`
  width: 100%;
`;

export const TechFeedbackWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ProfileGrid = styled.div<{
  $fullGrid?: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props): StyledProps => (!props.$fullGrid ? "2fr 1fr" : "1fr")};
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  margin-bottom: 38px;

  @media only screen and (max-width: 1279px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 575px) {
    grid-row-gap: 32px;
    margin-bottom: 32px;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 18px 26px;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
`;

export const ProfileImgCanvas = styled.div`
  width: 106px;
  height: 106px;
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fe;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  input[type="file"] {
    display: none;
  }

  & img {
    pointer-events: none;
  }

  &:hover {
    label {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 575px) {
    margin: 0px 0px 20px 0px;
  }
`;

export const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LabelInput = styled.label`
  cursor: pointer;
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  opacity: 0;
  transition: all 0.25s;

  & svg {
    width: 32px;
    height: 32px;
    & path {
      fill: #11142d;
    }
  }
`;

export const OverlayIpload = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;

  & svg {
    width: 36px;
    height: 36px;
    & path {
      fill: #11142d;
    }
  }
`;

export const ProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileName = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: -1px;
  margin-bottom: 2px;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 10px;
  }
`;

export const ProfileRole = styled.p`
  font-size: 16px;
  color: #7b8292;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const ProfileExperience = styled.p`
  font-size: 16px;
  color: #7b8292;
  font-weight: 400;
  margin-bottom: 0;
`;

export const ProfileFeature = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  background-color: #fff;
  border-radius: 24px;
  padding: 23px 25px;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
  }
`;

export const ProfileFeatureBox = styled.div`
  width: 44px;
  height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8eede;
  border-radius: 12px;
  filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
  padding: 5px;
  margin: auto;
`;

export const FeatureImg = styled.img`
  max-width: 100%;
`;

export const ProfileScores = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 28px 32px;
  min-width: 276px;

  @media only screen and (max-width: 575px) {
    padding: 26px 20px;
  }
`;

export const ScoresHeading = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 12px;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
`;

export const ProgressBarCanvas = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressBarFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProgressBarHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const ProfileScore = styled.span`
  width: 79px;
  height: 14px;

  font-family: inter500;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 120%;

  color: #3c3c3c;

  margin-right: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ProgressBarLabel = styled.label`
  font-size: 11px;
  color: #7b8292;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;

  &.progressbar__label-lg {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ProgressBarNumber = styled.span`
  font-size: 10px;
  color: #c4c4c4;
  font-weight: 600;
  line-height: 1.4;
  display: inline-flex;
  position: relative;
  margin-left: 5px;
  padding-left: 5px;

  &:before {
    content: "";
    width: 2px;
    height: 2px;
    flex-shrink: 0;
    display: flex;
    background-color: #c4c4c4;
    border-radius: 10px;
    position: absolute;
    top: 5px;
    left: 0;
  }

  &.progressbar__no-lg {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &.progressbar__lg {
    height: 12px;
    margin-top: 4px;
  }
`;

export const ProgressBarFill = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #85f1dd;
`;

export const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 22px 32px 28px;
  margin-bottom: 46px;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProfileBlockTitle = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: -1px;
  margin-bottom: 2px;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 10px;
  }
`;

export const ProfileBlockDescrip = styled.div`
  font-family: inter400;
  font-style: normal;
  font-size: 12px;
  line-height: 160%;

  color: #212121;
  overflow-wrap: anywhere;
`;

export const ProfileBlockRating = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  border-top: 1px solid #e0e0e0;
  padding-top: 19px;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
  }
`;

export const DescripSpan = styled.span`
  display: flex;
`;

export const ProfileBlockFooter = styled.div`
  display: flex;
  border-top: 1px solid #e8e8e8;
  padding-top: 22px;
`;

export const ProfileResume = styled.div`
  display: flex;
  padding-top: 10px;

  & iframe {
    width: 100%;
    min-height: 900px;
    border: none;

    @media only screen and (max-width: 575px) {
      min-height: 280px;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 15px;
  font-size: 0.8rem;
`;

export const ResumeViewer = styled(Viewer)`
  min-height: 500px;
  min-width: 500px;
  width: 100%;

  @media only screen and (max-width: 575px) {
    min-width: 100%;
  }
`;

export const AddTestButton = styled(Button)`
  font-weight: 500;
  padding: 6px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: #3f73f6;
  transition: all 0.25s;
  margin-bottom: auto;
  position: relative;

  & svg {
    width: 24px;
    height: 24px;

    & path {
      fill: #fff;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 28px;
    height: 28px;
    padding: 2px;
    line-height: 1;
    margin-top: 4px;

    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const TestButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProfileNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  margin-left: 15px;
`;
