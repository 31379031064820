/* eslint-disable import/no-extraneous-dependencies */
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Talent } from "@remotebase/amplify-constants/API";
import { nonTechTalentCategorySkills } from "@remotebase/constants";
import { AuthRoutes } from "../../utils";
import { ShouldRender } from "../../shouldRender";
import * as Styled from "./styles";
import Slider from "./slider";

interface Props {
  data: Talent | null;
  removeBorderTop?: boolean;
  isTalent?: boolean;
}

export const SkillsSection: FC<Props> = ({ data, removeBorderTop, isTalent }) => {
  const { push } = useHistory();
  const Default = 0;
  type SkillScore = {
    type: string;
    score: number;
  };
  const talentSkillScore: SkillScore[] = [];
  let overallScore = Default;
  if (data?.profile?.takenDomainTests) {
    let totalTestScore = data?.profile?.takenDomainTests.reduce((prev, current) => {
      if (!current) return prev;
      return prev + (current.percentage || Default);
    }, Default);

    let totalTestsTaken = data?.profile?.takenDomainTests.length;

    if (data?.profile?.problemSolvingPercentage) {
      totalTestScore += data?.profile?.problemSolvingPercentage;
      totalTestsTaken += 1;
    }
    overallScore = totalTestScore / totalTestsTaken;
  } else {
    overallScore = data?.profile?.problemSolvingPercentage || Default;
  }
  talentSkillScore.push({ type: "Overall", score: Math.round(overallScore) });
  data?.profile?.takenDomainTests?.forEach((element) => {
    talentSkillScore.push({
      type: nonTechTalentCategorySkills[element?.skill as string] || element?.skill || "",
      score: element?.percentage || Default,
    });
  });
  if (data?.profile?.problemSolvingPercentage) {
    talentSkillScore.push({
      type: "Problem Solving",
      score: data?.profile?.problemSolvingPercentage || Default,
    });
  }

  return (
    <Styled.SkillScoreContainer $removeBorderTop={removeBorderTop}>
      <Styled.SkillScoreTitle>Skill Score</Styled.SkillScoreTitle>
      {talentSkillScore.map(({ type, score }: SkillScore) => (
        <Styled.SkillScoreScoreCover key={type}>
          <Slider value={score || 0} draggable={false} label={type} />
        </Styled.SkillScoreScoreCover>
      ))}
      <ShouldRender if={isTalent}>
        <Styled.TakeTestBtn onClick={(): void => push(AuthRoutes.DomainTests)}>
          Take a Test
        </Styled.TakeTestBtn>
      </ShouldRender>
    </Styled.SkillScoreContainer>
  );
};

export default SkillsSection;
