import { Button } from "react-bootstrap";
import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px 24px;

  @media (max-width: 575px) {
    min-height: 10px;
  }
`;

export const H1 = styled.h1`
  font-size: 180px;
  color: #11142d;
  font-weight: 700;

  @media (max-width: 575px) {
    font-size: 132px;
  }
`;

export const H2 = styled.h2`
  font-size: 44px;
  color: #11142d;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 575px) {
    font-size: 32px;
  }
`;

export const ParaMd = styled.p`
  font-size: 16px;
  color: #808191;
  text-align: center;
  font-family: "Poppins", sans-serif;
  max-width: 550px;
  margin-bottom: 24px;
`;

export const BackBtn = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  min-width: 190px;
  min-height: 48px;
  border: none;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  transition: all 0.25s;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    transform: translateY(-2px);
    box-shadow: none;
  }
`;
