import styled from "styled-components";
import { StyledProps } from "../../../utils";

export const EditorWrapper = styled.div`
  margin: 1rem 1.5rem;
`;

export const EditSectionHeading = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const FormBody = styled.div`
  display flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const FormGroup = styled.div`
  display: block;
`;

export const FormLabel = styled.label`
  font-size: 0.8rem;
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 24px;
`;

export const SelectLabel = styled.label`
  font-size: 14px;
  color: #808191;
  font-weight: 500;
  display: inline-flex;
  margin-bottom: 0;
`;

export const CheckBox = styled.input`
  width: 24px;
  height: 24px;
  border: 1px solid;
  margin-bottom: 0 !important;
`;

export const FootNote = styled.p`
  margin-bottom: 0;
  font-size: 80%;
  color: #6c757d !important;
`;

export const MonthYearWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  opacity: ${(props): StyledProps => (props.isDisabled ? "0.3" : "1")};
`;
