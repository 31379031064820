import styled from "styled-components";

export const EntryBtnsContainer = styled.div`
  min-width: 100%;
  margin-top: 21px;
`;

export const EntryBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(101, 101, 101, 0.2);
  border-radius: 11px;
  width: 100%;

  font-size: 15px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #3617ba;
`;

export const EntryBtnFull = styled(EntryBtn)`
  @media only screen and (min-width: 1023px) {
    min-width: 390px;
  }
`;

export const AlternateTextDiv = styled.div`
  position: relative;
  margin: -22px 0 12px;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #d0d0d0;
    top: 22px;
    left: 0;
  }

  @media only screen and (min-width: 768px) {
    margin-top: unset;
  }

  @media only screen and (min-width: 992px) {
    max-width: 390px;

    &:before {
      max-width: 390px;
    }
  }
`;

export const AlternateTextDivSignup = styled(AlternateTextDiv)`
  @media only screen and (min-width: 992px) {
    &:before {
      left: 22px;
      right: 22px;
    }
  }

  @media only screen and (min-width: 1198px) {
    &:before {
      left: unset;
      right: unset;
    }
  }
`;

export const AlternateText = styled.p`
  text-align: center;
  background: #fff;
  padding: 10px;
  width: fit-content;
  margin: 0 auto;
  position: relative;
`;

export const LoginSeparator = styled.div`
  display: flex;
  justify-content: center;
  min-height: 28px;
  position: relative;
  margin-bottom: 14px;

  &:before {
    content: "";
    background-color: #bbbbbb;
    height: 1px;
    position: absolute;
    top: 13px;
    left: 0;
    right: 0;
  }
`;

export const LoginSeparatorText = styled.span`
  font-size: 16px;
  color: #b9b9b9;
  letter-spacing: -0.025em;
  padding: 0 10px;
  display: inline-flex;
  position: relative;
  background-color: #fff;
`;
