export const webHookQueueURL = (): string => process.env.QUEUE_URL || "Queue_URL_Not_Found";
export const workableQueueURL = (): string =>
  process.env.WORKABLE_INTEGRATION_QUEUE || "Queue_URL_Not_Found";
export const batchJobQueueURL = (): string => process.env.BATCH_JOB_QUEUE || "Queue_URL_Not_Found";
export const queueSuccessResponse = "Successfully inserted into Queue";
export const queueErrorResponse = "Error inserting into Queue: ";
export const secretsName = "sharedSecrets";
export const API_VERSION_AWS_SQS = "2012-11-05";

export enum QueueEntryType {
  HACKERRANK_WEBHOOK = "hackerrankWebhook",
  WORKABLE_RECEIVE_UPDATE_HR_STATUS = "updateHrStatus",
  WORKABLE_RECEIVE_UPDATE_FINAL_TECH_STATUS = "updateFinalTechStatus",
  WORKABLE_RECEIVE_HANDLE_NETWORK_STATUS = "handleNetworkStatus",
  WORKABLE_RECEIVE_HANDLE_HIRED_STATUS = "handleHiredStatus",
  VALIDATE_INVOICE_BATCH_CSV = "validateInvoiceBatchCsv",
  PROCESS_CLIENT_PAYMENT = "processClientPayment",
  PROCESS_STRIPE_PAYMENT_SUCCEEDED = "processStripeEventPaymentSucceeded",
  PROCESS_STRIPE_PAYMENT_FAILED = "processStripeEventPaymentFailed",
  CREATE_WORKABLE_CANDIDATE = "createWorkableCandidate",
  CREATE_WORKABLE_COMMENT = "createWorkableComment",
  HUBSPOT_WEBHOOK = "hubspotWebhook",
  TALENTLY_WEBHOOK = "talentlyWebhook",
}

export enum BatchJobs {
  GENERATE_RECRUITER_REPORT = "generateRecruiterReport",
  LEADERBOARD_REPORT = "leaderBoardReport",
}
