import * as yup from "yup";
import { EducationFormEnum, FormData, FormErrors } from "./types";

export const EducationFormSchema = yup.object().shape({
  // [EducationFormEnum.endYear]: yup.str
});

export const getinitialFormDataState = (): FormData => {
  return {
    [EducationFormEnum.startMonth]: "",
    [EducationFormEnum.startYear]: "",
    [EducationFormEnum.endMonth]: "",
    [EducationFormEnum.endYear]: "",
    [EducationFormEnum.certificate]: "",
    [EducationFormEnum.institution]: "",
    [EducationFormEnum.isEdit]: false,
    [EducationFormEnum.selectedEducationIndex]: -1,
  };
};

export const getinitialFormErrorsState = (): FormErrors => {
  return {
    [EducationFormEnum.startMonth]: "",
    [EducationFormEnum.startYear]: "",
    [EducationFormEnum.endMonth]: "",
    [EducationFormEnum.endYear]: "",
    [EducationFormEnum.certificate]: "",
    [EducationFormEnum.institution]: "",
    [EducationFormEnum.doErrorsExist]: false,
  };
};

export enum MonthYearFields {
  month = "month",
  year = "year",
}

export const monthPlaceholder = "Select month";
export const yearPlaceholder = "Select year";
export const formInputText = "text";
export const checkboxInputText = "checkbox";

export const institutionLabel = "University *";
export const certificateLabel = "Degree *";
export const institutionPlaceholder = "e.g. Oxford University";
export const certificatePlaceholder = "e.g. Bachelor's in Computer Science";

export const updateBtnText = "Save";
export const addBtnText = "Add";
export const cancelBtnText = "Cancel";
export const confirmBtnText = "Continue";
export const educationBtnWidth = "5rem";
export const mandatoryNotification = "Fields marked with (*) are mandatory";
export const deleteEducationMessage = "Are you sure you want to delete this education entry?";

export const addNewEducationTitle = "Add Education";
export const updateEducationTitle = "Edit Education";

export const submissionProfileErrorMessage = "Oops! Unable to update profile";
export const submissionProfileSuccessMessage = "Profile updated successfully";
export const submissionErrorTitle = "Error";
export const submissionSuccessTitle = "Success";
