import { Feedback, feedbackType, JobMatch } from "@remotebase/amplify-constants/API";
import {
  hrScreeningFeedback,
  middleTierFeedback,
  technicalFeedback,
  culturalFeedback,
  YourFeedbackType as yourFeedbackType,
} from "@remotebase/constants";
import { ITalentJobsInput } from "state/types";

export const getTalentFeedback = (
  talentFeedback: Array<Feedback | null>,
): Array<{
  feedback?: Feedback | null;
  label: string;
  type: feedbackType;
}> => [
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.HRFeedback),
    label: hrScreeningFeedback,
    type: feedbackType.HRFeedback,
  },
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.MiddleTierFeedback),
    label: middleTierFeedback,
    type: feedbackType.MiddleTierFeedback,
  },
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.FinalInterviewFeedback),
    label: technicalFeedback,
    type: feedbackType.FinalInterviewFeedback,
  },
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.CulturalFeedback),
    label: culturalFeedback,
    type: feedbackType.CulturalFeedback,
  },
];
// eslint-disable-next-line
export const getYourFeedback = (yourFeedback: any): any => {
  const feedbacks: Array<
    | {
        feedback?: string | null;
        label: string;
        type: string;
      }
    | []
  > = [];
  if (yourFeedback.failCulturalFitInterview != null) {
    feedbacks.push({
      feedback: yourFeedbackType.failCulturalFitInterview,
      label: yourFeedback.failCulturalFitInterview,
      type: yourFeedbackType.failCulturalFitInterview,
    });
  }
  if (yourFeedback.failSystemDesignInterview != null) {
    feedbacks.push({
      feedback: yourFeedbackType.failSystemDesignInterview,
      label: yourFeedback.failSystemDesignInterview,
      type: yourFeedbackType.failSystemDesignInterview,
    });
  }
  if (yourFeedback.failTechInterview != null) {
    feedbacks.push({
      feedback: yourFeedbackType.failTechInterview,
      label: yourFeedback.failTechInterview,
      type: yourFeedbackType.failTechInterview,
    });
  }
  if (yourFeedback.interested != null) {
    feedbacks.push({
      feedback: yourFeedbackType.interested,
      label: yourFeedback.interested,
      type: yourFeedbackType.interested,
    });
  }
  if (yourFeedback.interviewing != null) {
    feedbacks.push({
      feedback: yourFeedbackType.interviewing,
      label: yourFeedback.interviewing,
      type: yourFeedbackType.interviewing,
    });
  }
  if (yourFeedback.onboarded != null) {
    feedbacks.push({
      feedback: yourFeedbackType.onboarded,
      label: yourFeedback.onboarded,
      type: yourFeedbackType.onboarded,
    });
  }
  if (yourFeedback.passCulturalFitInterview != null) {
    feedbacks.push({
      feedback: yourFeedbackType.passCulturalFitInterview,
      label: yourFeedback.passCulturalFitInterview,
      type: yourFeedbackType.passCulturalFitInterview,
    });
  }
  if (yourFeedback.passSystemDesignInterview != null) {
    feedbacks.push({
      feedback: yourFeedbackType.passSystemDesignInterview,
      label: yourFeedback.passSystemDesignInterview,
      type: yourFeedbackType.passSystemDesignInterview,
    });
  }
  if (yourFeedback.passTechInterview != null) {
    feedbacks.push({
      feedback: yourFeedbackType.passTechInterview,
      label: yourFeedback.passTechInterview,
      type: yourFeedbackType.passTechInterview,
    });
  }
  if (yourFeedback.referred != null) {
    feedbacks.push({
      feedback: yourFeedbackType.referred,
      label: yourFeedback.referred,
      type: yourFeedbackType.referred,
    });
  }
  if (yourFeedback.rejected != null) {
    feedbacks.push({
      feedback: yourFeedbackType.rejected,
      label: yourFeedback.rejected,
      type: yourFeedbackType.rejected,
    });
  }

  return feedbacks;
};

export const getTalentMatchJobs = (talentJobs: Array<JobMatch | null>): Array<ITalentJobsInput> => {
  const talentMatchedJobs: Array<ITalentJobsInput> = [];
  for (const job of talentJobs) {
    if (job) {
      talentMatchedJobs.push({
        job: job.job,
        jobID: job.jobID,
        isPublished: job.isPublished,
        matchProcess: job.process,
        matchStage: job.stage,
        isRockstar: job.isRockstar || false,
      });
    }
  }
  return talentMatchedJobs;
};
