import { feedbackSentiment } from "@remotebase/amplify-constants/API";
import { FC } from "react";
import * as Styled from "./styles";

interface Props {
  sentiment?: feedbackSentiment | null;
}

export const SentimentBadge: FC<Props> = ({ sentiment }) => {
  const getClassName = (): string => {
    switch (sentiment) {
      case feedbackSentiment.positive:
        return "badge__positive";
      case feedbackSentiment.negative:
        return "badge__negative";
      case feedbackSentiment.neutral:
        return "badge__neutral";
      default:
        return "";
    }
  };
  return <Styled.Badge className={getClassName()}>{sentiment}</Styled.Badge>;
};

export default SentimentBadge;
