import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PayloadType } from "@remotebase/constants";
import {
  AuthenticationInput,
  AuthSubmitBtn,
  AuthForm,
  ResetPasswordFormSchema,
  codeText,
  newPassword,
  confirmPassword,
  resetPasswordText,
} from "../..";
import { codePlaceholder, confirmPasswordPlaceholder, newPasswordPlaceholder } from "../../utils";
import * as Styled from "./styles";

interface Props {
  isLoading: boolean;
  submitPass: (payload: PayloadType) => Promise<void>;
}

export const ResetPasswordForm: React.FC<Props> = ({ isLoading, submitPass }) => {
  const handler = useForm({ resolver: yupResolver(ResetPasswordFormSchema), mode: "onChange" });

  return (
    <form onSubmit={handler.handleSubmit(({ code, password }) => submitPass({ code, password }))}>
      <Styled.FormGroups>
        <AuthenticationInput
          type="text"
          label={codeText}
          placeholder={codePlaceholder}
          title={AuthForm.code}
          handler={handler}
        />
        <AuthenticationInput
          type="password"
          placeholder={newPasswordPlaceholder}
          label={newPassword}
          title={AuthForm.password}
          handler={handler}
        />
        <AuthenticationInput
          type="password"
          placeholder={confirmPasswordPlaceholder}
          label={confirmPassword}
          title={AuthForm.confirmPassword}
          handler={handler}
        />
      </Styled.FormGroups>
      <AuthSubmitBtn isLoading={isLoading} buttonText={resetPasswordText} />
    </form>
  );
};

export default ResetPasswordForm;
